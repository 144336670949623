<div class="confirm-modal form-offer __inform" [class.mobile]="isMobile">
    <div class="confirm-modal-wrap animated" [class.zoomIn]="settings.zoomIn" [class.zoomOut]="zoomOut">
        <div class="confirm-modal-message">
            <div *ngIf="isFromOfferCancellation" class="confirm-modal-message__image">
                <img src="assets/images/icons/offers/36-alert.svg" width="36" height="36" alt="alert">
            </div>
            <div *ngIf="isFromOfferTaken" class="confirm-modal-message__image">
                <img src="assets/images/icons/offers/accepted.png" width="36" height="36" alt="alert">
            </div>
            <div *ngIf="isFromLiveOfferNotification" class="confirm-modal-message__image">
                <img src="assets/images/icons/offers/offer-added.svg" width="36" height="36" alt="alert">
            </div>
            <h3 class="confirm-modal-message__h3" [innerHTML]="domSanitizer(settings.headerText)">
            </h3>
            <span class="confirm-modal-message__desc" [innerHTML]="domSanitizer(settings.bodyText)">
            </span>
        </div>
        <div class="confirm-modal-buttons">
            <button type="button" class="confirm-modal-buttons__cancel" (click)="cancel()"
                *ngIf="settings?.cancelButton?.show" [ngClass]="{'reject': settings?.rejectButton?.show}">
                {{settings?.cancelButton?.text}}
            </button>
            <button type="button" class="confirm-modal-buttons__reject" (click)="reject()"
                *ngIf="settings?.rejectButton?.show">
                {{settings?.rejectButton?.text}}
            </button>
            <button type="button" class="confirm-modal-buttons__continue" (click)="accept()"
                *ngIf="settings?.acceptButton?.show">
                {{settings?.acceptButton?.text}}
            </button>
        </div>

    </div>
</div>