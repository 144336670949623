<div class="filter _today-deals">
	<div class="filter-row">
		<div class="filter-current">
			<span class="title-only__icon">
				<svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" class="_offers"><path d="m12 2c5.4998377 0 10 4.50007185 10 9.9999793 0 5.5001924-4.5001055 10.0000207-10.0000244 10.0000207-1.8637175 0-3.72743502-.545356-5.31818177-1.5453702-.13633928-.0909982-.31833604-.136339-.50001623-.136339-.09099838 0-.1816802 0-.27267857.04534l-3.90909903 1.1819781 1.22736201-4.1818095c.0453401-.2273372.0453401-.4546744-.09099837-.6816869-.72734578-1.4547048-1.13636364-3.0456918-1.13636364-4.6821332 0-5.50015095 4.50008117-9.9999793 10-9.9999793zm-4.44444444 8.3333333c-.91677829 0-1.66666667.749933-1.66666667 1.6666667 0 .916778.74993303 1.666667 1.66666667 1.666667.91677828.0005806 1.66666666-.7499336 1.66666666-1.666667 0-.9167784-.74993303-1.6666667-1.66666666-1.6666667zm4.54545454 0c-.9167783 0-1.6666667.749933-1.6666667 1.6666667 0 .916778.7499331 1.666667 1.6666667 1.666667.9167783.0005806 1.6666667-.7499336 1.6666667-1.666667 0-.9167784-.7499331-1.6666667-1.6666667-1.6666667zm4.5454545 0c-.9167782 0-1.6666666.749933-1.6666666 1.6666667 0 .916778.749933 1.666667 1.6666666 1.666667.9167783.0005806 1.6666667-.7499336 1.6666667-1.666667 0-.9167784-.749933-1.6666667-1.6666667-1.6666667z" class="icon-path" fill="#fff"></path>
			    </svg>
			</span>
			<span (click)="selectState(LiveOfferStateEnum.Live)" class="filter-current__value">Live Offers</span>
			<span class="filter-count">{{offerCountersSignal()?.live }} </span>

			<!-- commented till get the final mocks for breadcrumbs mobile -->
			@if (selectedStateSignal() !== LiveOfferStateEnum.Live) {
				<span class="filter-current__value">  &nbsp;- {{ capitalize(selectedStateSignal()) }}</span>
				<span class="filter-count">{{offerCountersSignal()[selectedStateSignal()]}}</span>
			}
		</div>
	</div>
</div>