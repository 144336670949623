// Modules
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BuyerRoutingModule } from './buyer-routing.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
// import { Select2Module } from 'ng2-select2';
import { HttpClientModule } from '@angular/common/http';

import { PaginationModule } from 'ngx-bootstrap/pagination';

// Services
import { AuthGuardBuyerService } from '../auth/auth-guard-buyer-service';
import { CartService } from './cart/cart.service';
import { OrdersService } from './order-history/orders/orders.service';
import { LeftCategoriesService } from '../shared/services/left-categories.service';
import { NotificationService } from '../shared/services/notification.service';
import { TopService } from '../shared/services/top.service';
import { OrdersResolve } from './order-history/orders/orders.resolve';
import { PdfGeneratorService } from '../services/pdf-generator.service';
import { ItemsService } from '../shared/services/items.service';
import { TopCategoriesFileSaverService } from './catalog/top-categories/top-categories-file-saver.service';


// Directives & Pipes
import { InventoryLimitPipe } from '../shared/pipes/inventory-limit.pipe';


// Components
import { BuyerHomeComponent } from './buyer-home/buyer-home.component';
import { OrdersComponent } from './order-history/orders/orders.component';
import { FullOrderComponent } from './order-history/full-order/full-order.component';
import { PreCartComponent } from './cart/pre-cart/pre-cart.component';
import { ItemsListComponent } from './catalog/items-list/items-list.component';
import { GridViewItemComponent } from './catalog/grid-view-item/grid-view-item.component';
import { CartComponent } from './cart/cart.component';
import { ItemsBreadcrumbsComponent } from './catalog/items-breadcrumbs/items-breadcrumbs.component';
import { SplitOrderComponent } from './order-history/split-order/split-order.component';
import { SplitLineComponent } from './order-history/split-order/split-line/split-line.component';
import { ListingViewItemComponent } from '../shared/listing-view-item/listing-view-item.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { PreCartChildComponent } from './cart/pre-cart/pre-cart-child/pre-cart-child.component';
import { AddressComponent } from './address/address.component';
import { EditOrderComponent } from './cart/edit-order/edit-order.component';
import { CartTopComponent } from './cart/cart-top/cart-top.component';
import { CartBottomComponent } from './cart/cart-bottom/cart-bottom.component';
import { ItemAttributesComponent } from './catalog/item-attributes/item-attributes.component';
import { AddToExistingComponent } from './cart/add-to-existing/add-to-existing.component';
import { ProceedToCheckoutTopComponent } from './cart/proceed-to-checkout-top/proceed-to-checkout-top.component';
import { NothingFoundBuyerComponent } from './nothing-found-buyer/nothing-found-buyer.component';
import { CartItemsComponent } from './cart/cart-items/cart-items.component';
import { ItemAttributesMobileComponent } from './item-attributes-mobile/item-attributes-mobile.component';
import { ExportToExcelComponent } from './export-to-excel/export-to-excel.component';
import { SharedModule } from '../shared/shared.module';
import { HomeComponent } from './home/home.component';
import { NotificationItemsComponent } from './notifications/notification-items/notification-items.component';
import { PastPurchaseItemsComponent } from './past-purchase/past-purchase-items/past-purchase-items.component';
import { NothingFoundPurchasesComponent } from './past-purchase/past-purchase-items/nothing-found-purchases/nothing-found-purchases.component';
import { NotificationCenterComponent } from './notification-center/notification-center.component';
import { NotificationCenterHelperComponent } from './notification-center/notification-center-helper/notification-center-helper.component';
import { NotificationCenterHeaderComponent } from './notification-center/notification-center-header/notification-center-header.component';
import { StateService } from '../shared/services/state.service';
import { NotificationCenterSliderComponent } from './notification-center/notification-center-slider/notification-center-slider.component';
import { NotificationCenterItemsListComponent } from './notification-center/notification-center-items-list/notification-center-items-list.component';
import { OutletsComponent } from './outlets/outlets.component';
import { OutletItemComponent } from './outlets/outlet-item/outlet-item.component';
import { AuctionsComponent } from './auctions/auctions.component';
import { AuctionHeaderComponent } from './auctions/auction-header/auction-header.component';
import { AuctionItemsComponent } from './auctions/auction-items/auction-items.component';
import { AuctionDetailsComponent } from './auctions/auction-details/auction-details.component';
import { MyBidsComponent } from './auctions/my-bids/my-bids.component';
import { MyBidsLeftNavComponent } from './auctions/my-bids/my-bids-left-nav/my-bids-left-nav.component';
import { AuctionBidHistoryComponent } from './auctions/bid-history/auction-bid-history.component';
import { NotificationTooltipComponent } from './notifications/notification-tooltip/notification-tooltip.component';
import { GuestLoginComponent } from '../buyer/guest-login/guest-login/guest-login.component';
import { BrowserModule } from '@angular/platform-browser';
import { CanDeactivateCartGuard } from '../auth/canDeactivate-cart-guard';
import { AuctionHeaderMobileComponent } from './auctions/auction-header/auction-header-mobile/auction-header-mobile.component';
import { NotificationCenterHeaderMobileComponent } from './notification-center/notification-center-header/notification-center-header-mobile/notification-center-header-mobile.component';
import { CartItemsMobileComponent } from './cart/cart-items/cart-items-mobile/cart-items-mobile.component';
import { AddToExistingMobileComponent } from './cart/add-to-existing/add-to-existing-mobile/add-to-existing-mobile.component';
import { AddressMobileComponent } from './address/address-mobile/address-mobile.component';
import { CartItemsService } from './cart/cart-items/cart-items.service';
import { CreditCardComponent } from './credit-cards/credit-card/credit-card.component';
import { CreditCardListComponent } from './credit-cards/credit-card-list/credit-card-list.component';
import { CreditCardGuard } from '../auth/credit-card.guard';
import { CreditCardTermsComponent } from './credit-cards/credit-card-terms/credit-card-terms.component';
import { UserFiltersComponent } from './user-filters/user-filters.component';
import { UserFiltersListComponent } from './user-filters/user-filters-list/user-filters-list.component';
import { CreditCardNotificationComponent } from './credit-cards/credit-card-notification/credit-card-notification.component';
import { RedirectAfterBuyerLoginGuard } from '../auth/redirect-after-buyer-login.guard';
import { CartAbandonmentComponent } from './cart/cart-abandonment/cart-abandonment.component';
import { CartProcessingModalComponent } from './cart/cart-processing-modal/cart-processing-modal.component';
import { OrdersListResolve } from './order-history/orders/orders-list.resolve';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        BuyerRoutingModule,
        CarouselModule,
        // Select2Module,
        SharedModule,
        PaginationModule.forRoot(),
        BuyerHomeComponent,
        CartComponent,
        InventoryLimitPipe,
        GridViewItemComponent,
        OrdersComponent,
        PreCartComponent,
        ItemsListComponent,
        FullOrderComponent,
        SplitOrderComponent,
        SplitLineComponent,
        ItemsBreadcrumbsComponent,
        ListingViewItemComponent,
        ThankYouComponent,
        PreCartChildComponent,
        AddressComponent,
        EditOrderComponent,
        CartTopComponent,
        CartBottomComponent,
        ItemAttributesComponent,
        AddToExistingComponent,
        ProceedToCheckoutTopComponent,
        NothingFoundBuyerComponent,
        CartItemsComponent,
        ItemAttributesMobileComponent,
        ExportToExcelComponent,
        HomeComponent,
        NotificationItemsComponent,
        PastPurchaseItemsComponent,
        NothingFoundPurchasesComponent,
        NotificationCenterComponent,
        NotificationCenterHelperComponent,
        NotificationCenterHeaderComponent,
        NotificationCenterSliderComponent,
        NotificationCenterItemsListComponent,
        OutletsComponent,
        OutletItemComponent,
        AuctionsComponent,
        AuctionHeaderComponent,
        AuctionItemsComponent,
        AuctionDetailsComponent,
        MyBidsComponent,
        MyBidsLeftNavComponent,
        AuctionBidHistoryComponent,
        NotificationTooltipComponent,
        AuctionHeaderMobileComponent,
        NotificationCenterHeaderMobileComponent,
        CartItemsMobileComponent,
        AddToExistingMobileComponent,
        AddressMobileComponent, CreditCardComponent,
        CreditCardListComponent,
        CreditCardTermsComponent,
        UserFiltersComponent,
        UserFiltersListComponent,
        CreditCardNotificationComponent,
        CartAbandonmentComponent,
        CartProcessingModalComponent
    ],
    providers: [
        CreditCardGuard,
        AuthGuardBuyerService,
        CanDeactivateCartGuard,
        RedirectAfterBuyerLoginGuard,
        TopService,
        CartService,
        LeftCategoriesService,
        OrdersService,
        OrdersResolve,
        OrdersListResolve,
        PdfGeneratorService,
        InventoryLimitPipe,
        ItemsService,
        NotificationService,
        StateService,
        CartItemsService,
    ]
})
export class BuyerModule { }
