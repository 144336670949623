import { OrderStatusEnum } from '../enums/orderStatusEnum';
import { SalesOrderStatus } from './Order';
import * as models from './model';

export interface OrderListResponse {
  pagination?: models.IPaginationResponse;
  values?: OrderList[];
}

export interface OrderList {
  docEntry: number;
  docNumber: number;
  orderDate: Date;
  dueDate: Date;
  orderStatus: OrderStatusEnum;
  orderTotal: number;
  IsOffer: boolean;
  progressStatus: SalesOrderStatus;
  payment: {
    id: string;
    status: string;
  };
  beingUpdated: boolean;
  canCancel: boolean;
}





