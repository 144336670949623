import * as models from './model';
import { NotificationTypesEnum } from '../../shared/interfaces/INotificationDetail';
export interface ICartItem {
    id?: string;
    lineSequence?: number;
    basePrice?: number;
    orderLineNum?: number;
    quantity?: number;
    unitPrice?: number;
    statusCode?: string;
    statusMessage?: string;
    item?: models.IItem;
    itemCode?: string;
    reservedQty?: number;
    overwrittenPrice?: number;
    isEditable?: boolean;
    cartId?: string | number;
    unitPriceHelper?: number;
    previousITemQuantity?: number;
    notificationInfo?: NotificationTypesEnum[];
    notificationInfoObj?: {
        priceDrop: boolean,
        moreInventory: boolean,
        waitlist: boolean,
        priceDropInitialValue: boolean,
        moreInventoryInitialValue: boolean,
        waitlistInitialValue: boolean
    };
}
