<div class="loader" *ngIf="itemsService.itemsSubscription"></div>
<div class="filter-wrap _today-deals" (swipedown)="close()"
  [class.height-crop]="itemsService.isJustSoldUrl || itemsService.isPastPurchase"
  [class._price-drop]="itemFilterService.selectedZeroLevel?.navName === 'Price Drop'"
  [class._just-launched]="itemFilterService.selectedZeroLevel?.navName === 'Just Launched'">
  <div class="filter-wrap-header">
    <div class="filter-top">
      <app-items-breadcrumbs-mobile></app-items-breadcrumbs-mobile>
    </div>
    <i class="filter-wrap-header__close" (click)="close()">
      <img src="../../../../assets/images/icons/mobile/menu/m-close-white.svg" width="24" height="24" alt="close">
    </i>
  </div>
  <div class="filter-wrap-content" *ngIf="!itemsService.isJustSoldUrl && !itemsService.isPastPurchase">

    <!-- Navigation logo box for Catalog-->
    <div class="filter-items-block" *ngIf="!outletsService.isManufacturerOutlet(); else navLogoOutlets">
      <div class="filter-items-block-list">
        <div class="filter-tile _category-tile _active" (click)="clearAll($event)">
          <div class="filter-tile__img">
            <ng-container [ngSwitch]="itemFilterService.selectedZeroLevel?.redableTitle">
              <img *ngSwitchCase="environment.currentDeal.displayLabel"
                src="../../../assets/images/icons/item-filter/doorbuster-24x24.svg" alt="todays deals">
              <img *ngSwitchCase="'Price Drop'"
                src="../../../../assets/images/icons/mobile/menu/24-price-drop-white.svg" alt="price drop">
              <img *ngSwitchCase="'Just Launched'"
                src="../../../../assets/images/icons/mobile/menu/24-just-lauched-white.svg" alt="just lauched">
              <img *ngSwitchDefault src="../../../../assets/images/icons/mobile/menu/24-categories.svg"
                alt="categories">
            </ng-container>
          </div>
          <div class="filter-tile__count">
            <span> {{
              (topFilterActive?.navName == environment.currentDeal.displayLabel) ?
              topService.getTopFilterByName(environment.currentDeal.displayLabel).count :
              (topFilterActive?.navName == topFiltersEnum.priceDrop) ?
              topService.getTopFilterByName(topFiltersEnum.priceDrop).count :
              (topFilterActive?.navName == topFiltersEnum.justLaunched) ?
              topService.getTopFilterByName(topFiltersEnum.justLaunched).count :
              (itemFilterService.selectedZeroLevel &&
              !itemFilterService.userFilter) ?
              itemFilterService.selectedZeroLevel.count : (topService.searchString == '' &&
              !itemFilterService.userFilter) ? getCount() :
              getZeroLevelSearchCount()
              }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- Navigation logo box for Single Outlet-->
    <ng-template #navLogoOutlets>
      <!-- categories -->
      <div class="filter-items-block" *ngIf="itemFilterService.selectedManufacturer">
        <div class="filter-items-block-list">
          <div class="filter-tile _category-tile" [class._active]="!outletsService.topcatNavName"
            (click)="changeManu(itemFilterService.selectedManufacturer)">
            <div class="filter-tile__img" style="width: 50px; height: 50px;">
              <img [src]="'https://cdn.hubx.com' + outletsService.selectedOutlet?.logoUrl"
                alt="{{ outletsService.selectedOutlet?.navName }}">
              <!-- <img src="../../../../assets/images/icons/mobile/menu/24-categories.svg" alt="categories"> -->
            </div>
            <div class="filter-tile__count">
              <span> {{ itemFilterService.selectedManufacturer.count }}
              </span>
            </div>
          </div>

          <div class="filter-tile _category-tile" *ngFor="let topFilter of topService.topFiltersOutlet"
            [routerLink]="[outletsService.navigateToCategory(topFilter)]" routerLinkActive="_active"
            (click)="outletsService.getTopNavOutletItems(topFilter)">
            <div class="filter-tile__img">
              <img *ngIf="topFilter.navName === 'Todays Deals'"
                src="../../../assets/images/icons/item-filter/doorbuster-24x24.svg" alt="categories">
              <img *ngIf="topFilter.navName === 'Price Drop'"
                src="../../../../assets/images/icons/mobile/menu/24-price-drop-white.svg" alt="categories">
              <img *ngIf="topFilter.navName === 'Just Launched'"
                src="../../../../assets/images/icons/mobile/menu/24-just-lauched-white.svg" alt="categories">
            </div>
            <div class="filter-tile__count">
              <span> {{topFilter.count}}
              </span>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <!-- Manufacturer logo box -->
    <div class="filter-items-block" *ngIf="!outletsService.isManufacturerOutlet()">
      <div class="filter-items-block-list">
        <div class="filter-tile _brand-tile" *ngFor="let manufacturer of itemsService.manufacturersData"
          [class._active]="itemFilterService.selectedManufacturer?.id === manufacturer.id"
          [class.hide]="manufacturer.name==='All Manufacturers'" (click)="changeManu(manufacturer)">
          <div class="filter-tile__img">
            <img *ngIf="manufacturer.logoUrl" [src]="'https://cdn.hubx.com' + manufacturer.logoUrl"
              alt="{{manufacturer.name}}">
          </div>
          <div class="filter-tile__count">
            <span>{{ manufacturer.count }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- Subcats second level starts here -->
    <div class="filter-items-block" *ngIf="itemFilterService.isSecondLevelExpanded">
      <div class="filter-items-block-list">
        <div class="filter-tile _text-tile" *ngFor="let manuChild of itemFilterService.secondLevelCats"
          [class._active]="itemFilterService.selectedSecondLevel?.filterId === manuChild.filterId"
          (click)="selectChildNav(manuChild, 2)">
          <div class="filter-tile__txt">
            {{ manuChild.navName }}
          </div>
          <div class="filter-tile__count">
            <span>{{ manuChild.count }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- SubCat third level starts here -->
    <div class="filter-items-block"
      *ngIf="itemFilterService.selectedSecondLevel?.navChilds?.length && itemFilterService.isThirdLevelExpanded">
      <div class="filter-items-block-list">
        <div class="filter-tile _text-tile" *ngFor="let catChild of itemFilterService.selectedSecondLevel?.navChilds"
          [class._active]="catChild.filterId === itemFilterService.selectedThirdLevel?.filterId"
          (click)="selectChildNav(catChild, 3)">
          <div class="filter-tile__txt">
            {{ catChild.navName }}
          </div>
          <div class="filter-tile__count">
            <span>{{ catChild.count }}</span>
          </div>
        </div>
        <!-- <div class="filter-tile _text-tile">
          <div class="filter-tile__txt">
            MacBooks
          </div>
          <div class="filter-tile__count">
            <span>5</span>
          </div>
        </div> -->
      </div>
    </div>

  </div>
  <form [formGroup]="filterFg">
    <div class="filter-wrap-footer"
      [style.box-shadow]="(itemsService.isJustSoldUrl || itemsService.isPastPurchase) ? 'none' : '0 -2px 10px 0 #000000'">
      <div class="filter-wrap-footer__top">
        <div class="filter-btm-item"
          *ngIf="!outletsService.isManufacturerOutlet() && !itemFilterService.isOutletUserFilter">
          <select class="filter-select" formControlName="manufacturer" (click)="fromManuDropDown = true"
          [attr.disabled]="itemsService.itemsSubscription">
            <option *ngFor="let manu of getManufacturersDataDropDown()" [ngValue]="manu.id">
              {{ manu.name }} {{manu.count?'('+manu.count+')':''}}
            </option>
          </select>
        </div>
        <div class="filter-btm-item" *ngIf="!itemsService.isJustSoldUrl && !itemsService.isPastPurchase">
          <button class="filter-price-range" type="button" (click)="openPriceRangeModal()">Price</button>
        </div>
        <div class="filter-btm-item _condition" *ngIf="!itemsService.isJustSoldUrl && !itemsService.isPastPurchase">
          <select class="filter-select" formControlName="condition" [attr.disabled]="itemsService.itemsSubscription">
            <option *ngFor="let cond of itemsService.conditionAttributeValues" [ngValue]="cond.name">
              {{ cond.value }} {{cond.count?'('+cond.count+')':''}}
            </option>
          </select>
        </div>
        <div class="filter-btm-item _available" *ngIf="!itemsService.isJustSoldUrl">
          <button class="filter-asc-desc" type="button" [ngClass]="(isAsc) ? '_asc' : '_desc'"
            (click)="changeSortingDirection()">
            <!-- class:  _asc _desc -->
            <i class="icon"></i>
          </button>
          <select class="filter-select" formControlName="sortPicker">
            <option *ngIf="filterFg.controls.sortPicker.value==='none'" value="none"> Select Sort </option>
            <option *ngFor="let sort of sortingOptions" [ngValue]="sort.value"
              [class.hide]="sort.value === sortTypesEnum.model">{{ sort.text }}</option>
          </select>
        </div>

        <ng-container *ngIf="!itemsService.isJustSoldUrl && !itemsService.isPastPurchase">
          <div class="filter-btm-item">
            <label class="filter-checkbox">
              <span class="filter-checkbox__label">Sort by Model</span>
              <input type="checkbox" formControlName="sortByModel">
              <i class="filter-checkbox__icon"></i>
            </label>
          </div>
          <div class="filter-btm-item">
            <select class="filter-select" formControlName="exw" [attr.disabled]="itemsService.itemsSubscription">
              <option *ngFor="let exw of itemsService.exwPoints" [ngValue]="exw.value">
                {{ exw.text }} {{exw.count?'('+exw.count+')':''}}
              </option>
            </select>
          </div>
          <div class="filter-btm-item" *ngIf="!itemsService.isJustSoldUrl && !itemsService.isPastPurchase">
            <label class="filter-toggle">
              <input type="checkbox" formControlName="inStock">
              <span class="filter-toggle__label _off">All</span>
              <span class="filter-toggle__icon"></span>
              <span class="filter-toggle__label _on">In Stock</span>
            </label>
          </div>
          <div class="filter-btm-item">
            <button type="button" class="filter-saved-searches" (click)="close();openUserFilterModal($event)"
              *ngIf="showSaveUserFilterBtn()">
              <!--add state-->
              <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fill-rule="evenodd">
                  <circle cx="12" cy="12" fill="#4687d6" r="10" />
                  <path d="m13 7v3.999l4 .001v2l-4-.001v4.001h-2v-4.001l-4 .001v-2l4-.001v-3.999z" fill="#fff" />
                </g>
              </svg>
              Save New Search
              <!--edit state-->
              <!-- <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m19.361 4.23846618c-1.442-1.443-2.525-1.227-2.525-1.227l-6.052 6.052-6.774 6.77300002-1.01 4.762 4.763-1.01 6.774-6.771 6.052-6.05200002c-.001 0 .216-1.083-1.228-2.527zm-11.881 14.77800002-1.624.35c-.156-.293-.345-.586-.69-.932-.346-.346-.639-.533-.932-.691l.35-1.623.47-.469s.883.018 1.881 1.016c.997.996 1.016 1.881 1.016 1.881z" fill="#ffffff" opacity=".8"/></svg>
              Edit Saved Search -->
            </button>
          </div>
          <div class="filter-btm-item">
            <button class="filter-excel" (click)="exportToCSV()">
              <img *ngIf="!isExporting" src="../../../../assets/images/icons/item-filter/excel.svg" width="24"
                height="24" />
              <i *ngIf="isExporting" class="loader"></i>
            </button>
          </div>
        </ng-container>
      </div>
      <div class="filter-wrap-footer__btm">
        <button type="button" class="filter-btn-clear" (click)="clearAll($event)">Clear All</button>
        <button type="button" class="filter-btn-apply" (click)="close()">
          <img src="../../../../assets/images/icons/mobile/24-check-white.svg" alt="check">
        </button>
      </div>
    </div>
  </form>
</div>

<div class="price-range-modal" [class._opened]="isPriceRangeModalOpened">
  <i class="price-range-modal__overlay" (click)="closePriceRangeModal()"></i>
  <div class="price-range">
    <span class="price-range__close" (click)="closePriceRangeModal()"></span>
    <div class="price-range__title">Price Range</div>
    <div class="price-range__inputs">
      <div class="price-range-input">
        <div class="price-range-input__label">min price</div>
        <div class="price-range-input__input">
          <input [ngClass]="{'invalid': !isMinMaxValid}" type="text" prefix="$ " mask="00000"
            [ngModel]="minPrice" [formControl]="minPriceFrm" pattern="[0-9]*" inputmode="numeric" appOnlyDigits />
        </div>
      </div>
      <div class="price-range__inputs-divider">-</div>
      <div class="price-range-input">
        <div class="price-range-input__label">max price</div>
        <div class="price-range-input__input">
          <input [ngClass]="{'invalid': !isMinMaxValid}" type="text" prefix="$ "
            [suffix]="plusSignSuffix" mask="00000" [ngModel]="maxPrice" [formControl]="maxPriceFrm" pattern="[0-9]*" inputmode="numeric" appOnlyDigits />
        </div>
      </div>
    </div>
    <div class="price-range__slider">
      <ngx-slider 
        [(value)]="sliderMinPrice" 
        [(highValue)]="sliderMaxPrice" 
        [options]="priceRangeOptions"
        (userChange)="adjustPriceInputs()"
      ></ngx-slider>
    </div>
    <div class="price-range__buttons">
      <button type="button" class="btn-clear" (click)="clearPriceRange()">Clear</button>
      <button class="btn-apply" (click)="applyPriceRange()" [disabled]="!isMinMaxValid">Apply</button>
    </div>
  </div>
</div>
