<div class="container-fluid excel-view-container">
  <div class="item"  [ngClass]="{'active': (openItemDetails  || showRestrictedCountryPopup) && selectedItem.highlightId === item.highlightId}">
    <div class="row">
      <div class="container-fluid desc">
        <div (click)="openModal()" class="click-helper">
        {{item.description}}
          <span class="copy-number">
            {{item.partNumber}}
          </span>
        </div>
        <div class="item-details" (click)="openModal()">
          <div class="item-details__shadow"><i class="item-details__shadow-inner"></i></div>
          <div class="details-for-hide">
            <div class="item-details-new" *ngIf="item.hasPendingOffers">
              <div class="wrapper offers-svg">
                <svg height="24" width="24">
                  <use xlink:href="#icon-offers-listing"></use>
                </svg>
              </div>
            </div>
            <div class="item-details-new" *ngFor="let attribute of attributes">
              <div *ngIf="attribute.label == 'Packaging' || attribute.label == 'Condition'
                     || attribute.label == 'Warranty' || attribute.label == 'Restrictions'
                     || attribute.label == 'Special Notes' || attribute.label == 'Today\'s Deals'
                     || attribute.label == 'Price Drop' || attribute.label == 'Just Launched'">
                <div *ngIf="attribute.label == priceDrop" class="wrapper price-drop-svg">
                  <div [ngClass]="{'showTooltip': showPriceDropT}" class="tooltip price-drop-green">Price Drop</div>
                  <svg (mouseenter)="priceDropToolTip()" (mouseleave)="priceDropToolTip()" width="24px" height="24px">
                    <use xlink:href="#icon-price-drop"></use>
                  </svg>
                </div>
                <div *ngIf="attribute.label == justLaunched" class="wrapper just-launched-svg">
                  <div [ngClass]="{'justLaunched': showjustLaunchedT}" class="tooltip just-launched-red">Just Launched</div>
                  <svg (mouseenter)="justLaunchedTooltip()" (mouseleave)="justLaunchedTooltip()" class="rocket" width="24px" height="24px">
                    <use xlink:href="#icon-just-launched"></use>
                  </svg>
                </div>
                <div *ngIf="attribute.label == 'Today\'s Deals'" class="wrapper todays-deals-svg">
                  <div [ngClass]="{'todaysD': showTodaysDealtT}" class="tooltip todays-deals-purple">{{environment.currentDeal.displaySingularLabel}}</div>
                  <svg (mouseenter)="todaysDealsTooltip()" (mouseleave)="todaysDealsTooltip()" class="rocket" width="24px" height="24px">
                      <use xlink:href="#icon-todays-deals"></use>
                  </svg>
                </div>
                <div [ngClass]="{'hide': conditionIcon}" *ngIf="attribute.label == 'Condition'"
                     width="24" height="24" class="wrapper refurbished-svg">
                  <div [ngClass]="{'refurbishedT': showRefurbishedT, 'adjust-width': attribute.value.length>=20}"
                    class="tooltip refurbished-purple">{{ attribute.value }}</div>
                  <svg (mouseenter)="refurbishedTooltip()" (mouseleave)="refurbishedTooltip()" class="rocket" width="24px" height="24px">
                    <use xlink:href="#icon-condition-refurbished"></use>
                  </svg>
                </div>
                <div [ngClass]="{'hide': !conditionIcon}" *ngIf="attribute.label == 'Condition'"
                     width="24" height="24" class="wrapper new-factory-svg">
                  <div [ngClass]="{'refurbishedT': showRefurbishedT}" class="tooltip refurbished-purple">{{ attribute.value }}</div>
                  <svg (mouseenter)="refurbishedTooltip()" (mouseleave)="refurbishedTooltip()" class="rocket" width="24px" height="24px">
                    <use xlink:href="#icon-condition-new"></use>
                  </svg>
                </div>
              </div>
            </div>
            <div class="item__count" id="parent" name="parent">
              <div class="item-moq">
                <div *ngFor="let price of item.prices">
                  <div *ngIf="(price.fromQty > 1)" class="item__count--wrapper">
                    <div class="moq-wrap">
                      <span class="price-qty">{{price.fromQty}}+</span>
                      <span class="unit-price">{{price.unitPrice | CustomCurrency}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="wrapper _moq" *ngIf="item.moq>1">
                <div [ngClass]="{'displayItemMoq': showItemMOQ}" class="tooltip itemMoqTooltip">Minimum Order Quantity</div>
                <div (mouseenter)="itemMOQTooltip()" (mouseleave)="itemMOQTooltip()" class="item-moq">MOQ {{item.moq}}</div>
            </div>
            <div class="wrapper _mxq" *ngIf="item.mxq > 0">
              <div [ngClass]="{'displayItemMxq': showItemMXQ}" class="tooltip itemMxqTooltip">Maximum Quantity</div>
              <div (mouseenter)="itemMXQTooltip(1)" (mouseleave)="itemMXQTooltip(1)" class="item-moq">MXQ {{ item.mxq | inventoryLimit }}</div>
            </div>
            <div class="price">
              <div class="col item-price">
                  <div class="wrapper">
                    <div [ngClass]="{'displayUnitPrice': showUnitPrice}" class="tooltip itemUntPriceTooltip">Unit Price</div>
                      <div (mouseenter)="itemUnitPriceTooltip()" (mouseleave)="itemUnitPriceTooltip()" class="text-center item-price-value">{{item.baseUnitPrice | CustomCurrency}}</div>
                  </div>
              </div>
            </div>

          </div>
          <div [ngClass]="{'has-item-moq': item.moq>1}" class="details-for-static">
            <div class="item-exw">
              <div class="wrapper">
                  <div [ngClass]="{'showExw': showExw}" class="tooltip exwTooltip">EXW</div>
                  <div (mouseenter)="exwTooltip()" (mouseleave)="exwTooltip()">
                    {{ item.exwPoint }}
                  </div>
              </div>
            </div>

            <div class="availability" [class.sold-out]="availableToSellItem < 1">
              <div class="wrapper">
                <div [ngClass]="{'showAvailable': showAvailableT && availableToSellItem > 0}" class="tooltip availability-tooltip">
                  <div class="availability-tooltip__header">Available</div>
                  <div class="availability-tooltip-item" *ngIf="itemsService.showInStockTitle(item,sessionService.userRole)">
                    <span class="availability-tooltip__label">In Stock</span>
                    <span class="availability-tooltip__value">
                        {{ itemsService.showInStockValue(item,sessionService.userRole) }}
                    </span>
                  </div>
                  <div *ngIf="itemsService.showInTransitTitle(item,sessionService.userRole)" class="availability-tooltip-item">
                    <span class="availability-tooltip__label">In Transit</span>
                    <span class="availability-tooltip__value">{{ itemsService.showInTransitValue(item,sessionService.userRole) }}</span>
                  </div>
                </div>
                <div class="availability-available" (mouseenter)="availableT()" (mouseleave)="availableT()">
                  <span [ngClass]="{'hide': availableToSellItem < 1}" class="unit-price">{{itemsService.showInStockValueToGrid(item, sessionService.userRole)}}</span>
                </div>
                <div style="clear:both;"></div>
              </div>
            </div>

            <div class="item-offer">
              <a class="item-offer__button"
                 [class.disabled]="offerService.makeOfferButtonDisabled(item)"
                 [class.active]="offerService.offerMade(item)">
                 <span class="_icon">
                  <svg height="20" width="20">
                    <use xlink:href="#icon-offers-grid"></use>
                  </svg>
                </span>
                {{offerService.offerMade(item) ? 'Offer Made' : 'Make Offer'}}
              </a>
            </div>

            <div class="item-sold" *ngIf="availableToSellItem < 1">
              <span class="unit-price-sold">Sold Out</span>
            </div>



            <div class="item__notifications" *ngIf="item.inventory.availableToSell>0">
              <div class="add-notification"
              (click)="$event.stopPropagation()">
                <span class="_icon"
                      [class.active]="item.notificationInfoObj.moreInventoryInitialValue || item.notificationInfoObj.priceDropInitialValue">
                  <svg (click)="showGuestLogin()"
                  (mouseleave)="topService.notificationTooltipHovered = false"
                  (mouseenter)="userService.isGuest?'':openNotificationTooltip($event)" height="24" width="24">
                    <use xlink:href="#icon-add-notification"></use>
                  </svg>
                </span>
              </div>
            </div>
            <div
            [class.padding-adj]='minimizeDetails'
            [class.hide]='!item.added && item.inventory.availableToSell<1'
                 class="item-qty wrapper" (click)="$event.stopPropagation()">
              <div *ngIf="item.mxq > 0" [ngClass]="{'displayItemMxq': showInputMXQ}" class="tooltip itemMxqTooltip">Maximum Quantity {{ item.mxq }}</div>
              <input type="number"
               [(ngModel)]="item.qty" (mouseenter)="itemMXQTooltip(2)" (mouseleave)="itemMXQTooltip(2)"
               (keyup)="updateItemPrice(item)" appOnlyDigits (blur)="onBlur($event)"
               (click)="selectText($event)"  [disabled]="topService.loading || item.added || !item.added && availableToSellItem<1"
              [class.disabled]="item.added" #qty/>
            </div>
            <div [ngClass]="{'float-right': minimizeDetails}"
                 class="add-to-cart" (click)="$event.stopPropagation()">
              <a *ngIf='!item.added && availableToSellItem>0' (click)="!topService.loading?addToCart():''" class="item-to-cart">
                <svg *ngIf="!item.loading" width="24px" height="24px">
                  <use xlink:href="#icon-add-to-cart"></use>
                </svg>
                <span *ngIf="item.loading" class="cart-loader"></span>
                <span *ngIf="!item.beingEdited && !item.loading">Add to Cart</span>
                <span *ngIf="!item.beingEdited && item.loading">Adding...</span>
                <span *ngIf="item.beingEdited && !item.loading">Update Cart</span>
                <span *ngIf="item.beingEdited && item.loading">Updating...</span>
              </a>
              <a class="add-waitlist" *ngIf="item.inventory.availableToSell<1"
              (click)="submitNotification($event)" (mouseenter)="openWaitlistTooltip($event)"
              (mouseleave)="hideWaitlistTooltip($event)"
              [class.added]="item.notificationInfoObj.waitlist">
                <svg height="24" width="24">
                  <use xlink:href="#icon-add-to-waitlist"></use>
                </svg>
                <span [class.hide]="item.notificationInfoObj.waitlist">Add to Waitlist</span>
                <span [class.hide]="!item.notificationInfoObj.waitlist">Waitlisted</span>
              </a>
              <a *ngIf="item.added  && item.inventory.availableToSell>0" (click)="!topService.loading?editItemQty():''" class="item-edit-qty" [class._delete-spinner]="deleteSpinner">
                <span *ngIf="item.loading || deleteSpinner" class="cart-loader"></span>
                <span *ngIf="!item.loading && !deleteSpinner">Edit QTY</span>
                <span *ngIf="item.loading && !deleteSpinner">Updating...</span>
                <span *ngIf="deleteSpinner">Deleting...</span>
              </a>
              <a *ngIf="item.added  && item.inventory.availableToSell>0 && !deleteSpinner" class="item-remove-from-cart" (click)="removeItemFromCart($event)">
                <svg height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                  <use xlink:href="#icon-remove-item"></use>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
