import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../shared/shared.service';
import { TopService } from '../../shared/services/top.service';
import { Router } from '@angular/router';
import { FooterComponent } from '../footer/footer.component';
@Component({
    selector: 'app-terms-of-use',
    templateUrl: './terms-of-use.component.html',
    styleUrls: ['./terms-of-use.component.scss'],
    standalone: true,
    imports: [FooterComponent]
})
export class TermsOfUseComponent implements OnInit {

  constructor(private sharedService: SharedService,
    private router: Router, public topService: TopService) { }

  ngOnInit() {
    this.sharedService.isStaticHtml = true;
    this.sharedService.isCorpSite = true;
    document.documentElement.scrollTop = 0;
  }

}
