<div class="offers-banner" [class.mobile]="topService.isMobile">
  <div class="offers-banner-content">
    <div class="offers-banner-content__image" *ngIf="topService.isMobile">
      <img src="assets/images/icons/offers/offers-banner-mobile.jpg" width="361" alt="Live Offers Banner">
    </div>
    <div class="offers-banner-content__text">
      <i>
      	<img src="assets/images/icons/offers/live-offers-banner-text-2.png" width="392" alt="Live Offers">
      </i>
      <div class="offers-banner-buttons">
      	<a [routerLink]="['/buyer/live_offers']">GO</a>
      </div>
    </div>
  </div>
</div>