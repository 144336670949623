import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../shared/shared.service';
import { TopService } from '../../shared/services/top.service';
import { Router } from '@angular/router';
import { FooterComponent } from '../footer/footer.component';
@Component({
    selector: 'app-privacy-policy',
    templateUrl: './privacy-policy.component.html',
    styleUrls: ['./privacy-policy.component.scss'],
    standalone: true,
    imports: [FooterComponent]
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(private sharedService: SharedService,
    private router: Router, public topService: TopService) { }

    ngOnInit() {
      this.sharedService.isStaticHtml = true;
      this.sharedService.isCorpSite = true;
      document.documentElement.scrollTop = 0;
    }

}
