import { Injectable, Signal, signal, WritableSignal } from '@angular/core';

import { Subject } from 'rxjs';
import { SharedValues } from './shared-values';
import { SharedSource } from './shared-source';

@Injectable()
export class PubSubService {
  sharedSubject: Subject<SharedValues>;
  private signalsMap = new Map<SharedSource, WritableSignal<SharedValues | null>>();

  constructor() {
    this.sharedSubject = new Subject<SharedValues>();
    Object.values(SharedSource).forEach((source) => {
      this.signalsMap.set(source as SharedSource, signal<SharedValues | null>(null));
    });
  }

  // SIGNAL PUBSUB 
  emit(value: SharedValues) {
    const signalToEmit = this.signalsMap.get(value.name);
    if (signalToEmit) {
      signalToEmit.update(() => ({ name: value.name, data: value.data }));
    }
  }

  clear(source: SharedSource) {
    const signalToClear = this.signalsMap.get(source);
    if (signalToClear) {
      signalToClear.update(() => null);
    }
  }

  getSignal(source: SharedSource): WritableSignal<SharedValues | null> | undefined {
    return this.signalsMap.get(source);
  }
  

}
