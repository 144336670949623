import { Pipe, PipeTransform } from '@angular/core';
import { OutletsService } from '../../buyer/outlets/outlets.service';

@Pipe({
    name: 'manufacturersFilter',
    standalone: true
})
export class ManufacturersFilterPipe implements PipeTransform {
  names;

  constructor(private _outletsService: OutletsService) {}

  transform(value: any[], characters: any, args?: any): any {
    let manufacturersArr = [];
    value.forEach(names => {
      manufacturersArr.push({
        'blackLogoUrl': names.blackLogoUrl,
        'count': names.count,
        'heroImageUrl': names.heroImageUrl,
        'id': names.id,
        'logoUrl': names.logoUrl,
        'name': names.name,
        'selected': names.selected,
        'sequence': names.sequence
      });
      this.names = names;
    });

    // if (!characters) return value;
    if (!characters) {
      characters = 'All';
    }

    if (characters !== 'All') {
      this._outletsService.currentManufacturers =
      manufacturersArr.filter(manufacturers => manufacturers.name.charAt(0).includes(characters));
      return this._outletsService.currentManufacturers;
    } else {
      this._outletsService.currentManufacturers = value;
      return value;
    }



    // if (sortBy===1){
    //   return console.log('Popularity')
    // } else {
    //   return console.log('AtoZ');
    // }

    // return manufacturersArr.filter(manufacturers => { return manufacturers.name.charAt(0).includes(characters) });
  }

}
