import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { count } from 'rxjs/operators';
import * as moment from 'moment-timezone';
import { TopService } from '../services/top.service';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-countdown',
    templateUrl: './countdown.component.html',
    styleUrls: ['./countdown.component.scss'],
    standalone: true,
    imports: [NgIf]
})
export class CountdownComponent implements OnInit {
  @Input() dates: any;
  @Input() isFromOffers: boolean = false;
  @Output() ended: EventEmitter<any> = new EventEmitter<any>();
  id = null;
  expired = false;

  constructor(public topService: TopService) { }

  ngOnInit() {
    this.id = this.dates.id;
    this.auctionCountdown();
  }

  auctionCountdown() {
    const start = new Date(this.dates.start);
    const end = new Date(this.dates.end);
    if (start > this.now()) {
      this.countdown(new Date(this.dates.start), new Date(this.dates.end));
    } else if (end > this.now()) {
      this.countdown(new Date(this.dates.end));
    } else {
      this.expired = true;
    }
  }

  countdown(date1, date2 = null) {
    const iosMobile = /iPad|iPhone|iPod/.test(navigator.userAgent) && this.topService.isMobile;
    const countDownDate = date1.getTime();
    const x = setInterval( () => {
      const now = this.now().getTime();
      const distance = countDownDate - now;

      // const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      // const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const hours = Math.floor(distance / 1000 / 60 / 60);
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      const elementClass = 'countdown' + this.id;
      const elements = Array.from(document.getElementsByClassName(elementClass)); // because in auction slider we have cloned auctions

      elements.forEach(element => {
        if (element) {
          // element.innerHTML = days + 'd  ' + ' ' + that.format(hours) + ':' + that.format(minutes) + ':' + that.format(seconds);
          element.innerHTML = this.format(hours) + ':' + this.format(minutes) + ':' + this.format(seconds);
        }

        if (distance <= 0 && !this.expired) {
          clearInterval(x);
          this.expired = true;
          this.ended.emit(true);
          // if (date2) {
          //   that.countdown(date2);
          // } else {
          //   document.getElementById('countdown' + that.id).innerHTML = '00:00:00';
          // }
        }
      });
    }, 1000);
  }

  format(value: number) {
    if (value.toString().length === 1) {
      return '0' + value;
    } else { return value; }
  }

  now() {return new Date(moment().tz('America/New_York').format('YYYY-MM-DDTHH:mm:ss'));}

}


