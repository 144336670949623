
  <form class="offers-filter__wrap" [formGroup]="filtersForm">
    <div class="offers-filter">
      <div class="offers-filter-top">
        <div class="offers-filter__header">
          <i>
            <svg height="28" viewBox="0 0 28 28" width="28" xmlns="http://www.w3.org/2000/svg">
              <path d="m14 2.33333333c6.4164773 0 11.6666667 5.25008383 11.6666667 11.66664257 0 6.416891-5.2501231 11.6666908-11.6666951 11.6666908-2.1743371 0-4.34867425-.6362487-6.20454546-1.8029319-.1590625-.1061646-.37139205-.1590622-.58335228-.1590622-.10616477 0-.21196022 0-.318125.0528967l-4.56061553 1.3789744 1.43192235-4.8787778c.05289678-.2652267.05289678-.5304534-.10616477-.7953014-.84857008-1.6971555-1.32575758-3.553307-1.32575758-5.4624886 0-6.41684283 5.2500947-11.66664257 11.66666667-11.66664257zm-5.18518519 9.72222227c-1.06957466 0-1.94444444.8749217-1.94444444 1.9444444 0 1.0695744.87492187 1.9444448 1.94444444 1.9444448 1.06957467.0006774 1.94444449-.8749225 1.94444449-1.9444448 0-1.0695748-.87492191-1.9444444-1.94444449-1.9444444zm5.30303029 0c-1.0695746 0-1.9444444.8749217-1.9444444 1.9444444 0 1.0695744.8749218 1.9444448 1.9444444 1.9444448 1.0695747.0006774 1.9444445-.8749225 1.9444445-1.9444448 0-1.0695748-.8749219-1.9444444-1.9444445-1.9444444zm5.3030303 0c-1.0695746 0-1.9444444.8749217-1.9444444 1.9444444 0 1.0695744.8749218 1.9444448 1.9444444 1.9444448 1.0695747.0006774 1.9444445-.8749225 1.9444445-1.9444448 0-1.0695748-.8749219-1.9444444-1.9444445-1.9444444z" fill="#fff"/>
            </svg>
          </i>
          <h3>Live Offers</h3>
          <span>{{ liveOffersCounterSignal() }}</span>
        </div>
        <div 
          class="offers-filter__search"
          (clickOutside)="clearSearchInput()"
        >
          <div class="offers-filter__search-group">
            <span 
              class="offers-filter__search__btn"
              (click)="searchOffers($event)"
            >
              <img width="24" height="24" src="assets/images/icons/auctions/filter-search.svg">
            </span>
            <input 
              class="offers-filter__search__input" 
              type="search" 
              autocomplete="off"
              name="offers-search" 
              placeholder="Search Live Offers"
              formControlName="searchQuery"
              (keydown.enter)="searchOffers($event)"
            >
            <span 
              *ngIf="filtersForm.get('searchQuery')?.value?.length > 0"
              class="offers-filter__search__btn __close"
              (click)="clearSearchInput(true)"
            >
              <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <g id="delete/white" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <polygon id="Shape" fill="#FFFFFF" fill-rule="nonzero"
                    points="19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12">
                  </polygon>
                </g>
              </svg>
            </span>
          </div>
        </div>
      </div>
      <div class="offers-filter-bottom">
        <div class="offers-filter-buttons">
           <button 
              class="offers-filter-button" 
              [class.active]="selectedStateSignal() === LiveOfferStateEnum.All"
              [disabled]="offerCountersSignal()?.all === 0"
              (click)="selectState(LiveOfferStateEnum.All)">
              All 
              <span>{{ offerCountersSignal()?.all }}</span>
            </button>
            
            <button 
              class="offers-filter-button" 
              [class.active]="selectedStateSignal() === LiveOfferStateEnum.Live"
              [disabled]="offerCountersSignal()?.live === 0"
              (click)="selectState(LiveOfferStateEnum.Live)">
              Live 
              <span>{{ offerCountersSignal()?.live }}</span>
            </button>
            
            <button 
              class="offers-filter-button" 
              [class.active]="selectedStateSignal() === LiveOfferStateEnum.Counteroffers"
              [disabled]="offerCountersSignal()?.counteroffers === 0"
              (click)="selectState(LiveOfferStateEnum.Counteroffers)">
              Counteroffers 
              <span>{{ offerCountersSignal()?.counteroffers }}</span>
            </button>
            
            <button 
              class="offers-filter-button" 
              [class.active]="selectedStateSignal() === LiveOfferStateEnum.Accepted"
              [disabled]="offerCountersSignal()?.accepted === 0"
              (click)="selectState(LiveOfferStateEnum.Accepted)">
              Accepted 
              <span>{{ offerCountersSignal()?.accepted }}</span>
            </button>
            
            <button 
              class="offers-filter-button" 
              [class.active]="selectedStateSignal() === LiveOfferStateEnum.Canceled"
              [disabled]="offerCountersSignal()?.canceled === 0"
              (click)="selectState(LiveOfferStateEnum.Canceled)">
              Canceled 
              <span>{{ offerCountersSignal()?.canceled }}</span>
            </button>
            <button 
              class="offers-filter-button" 
              [class.active]="selectedStateSignal() === LiveOfferStateEnum.Expired"
              [disabled]="offerCountersSignal()?.expired === 0"
              (click)="selectState(LiveOfferStateEnum.Expired)">
              Expired 
              <span>{{ offerCountersSignal()?.expired }}</span>
            </button>
        </div>
        <div class="offers-filter-bottom__right">
          <div class="offers-filter-sort">
            <label class="offers-filter-checkbox">
              <span class="offers-filter-checkbox__label">Sort by SKU</span>
              <input 
                type="checkbox" 
                formControlName="sortBySKU"
              >
              <i class="offers-filter-checkbox__icon"></i>
            </label>
          </div>
          <div class="offers-filter-dropdown">
            <app-dropdown 
              [items]="sorting"
              [fieldToShow]="'name'" 
              [fieldToSave]="'value'"
              [isFromBuyerPortal]="true" 
              [counterFieldForDropdownOption]="'count'"
              [defaultTitle]="'Select Sorting'" 
              darkTheme="true"
              formControlName="sortingCriteria"
            >
            </app-dropdown>
          </div>
        </div>
      </div>
    </div>
  </form>