import * as models from './model';

export interface Category {
    id: number;
    name: string;
    count: number;
    subCategories: models.SubCategory[];
    isOpen: boolean;
    isShown: boolean;
}
