import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as modelsU from '../../shared/interfaces/model';
import * as models from '../interfaces/model';
import { CartService } from '../cart/cart.service';
import { FormsModule } from '@angular/forms';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'app-address',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, NgClass, FormsModule]
})
export class AddressComponent implements OnInit {
  @Input()  address: modelsU.IAddress;
  @Input() addressType: string;
  @Input() addressTitle: string;
  @Input() currentAddress: string;
  @Input() addresses:  modelsU.IAddress[];
  @Output() selectedAddress = new EventEmitter<modelsU.IAddressEmitter>();
  showPopup: boolean;
  animateOut: boolean;
  addressCode: string;
  dropdownTitle: string;

  constructor(private cartService: CartService) { }

  ngOnInit() {
    this.addressCode = this.currentAddress;
    if (this.addressType === 'shipping') {
        this.dropdownTitle = 'Ship to Address';
    }
    if (this.addressType === 'billing') {
      this.dropdownTitle = 'Bill to Address';
  }
  }

  closeModal(): void {
     this.addressCode = this.address.addressCode;
    this.animateOut = true;
    setTimeout(() => {
      this.showPopup = false;
      this.animateOut = false;
    }, 400);
  }
  selectAddress(): void {
    const myAddress = this.addresses.find(address => address.addressCode === this.currentAddress);
    const mySelectedAddress: modelsU.IAddressEmitter = {
      addressType: this.addressType,
      addressCode: myAddress.addressCode
    };
    this.selectedAddress.emit(mySelectedAddress); 
    // this.address = myAddress;
    this.addressCode = this.currentAddress;
    this.closeModal();
    // this.checkForTerritoryRestrictedItems(this.cartService.cartList, myAddress.country)
  }

  // checkForTerritoryRestrictedItems(items: models.ICartItem[], country: string) {
  //   this.cartService.checkForTerritoryRestrictedItems(items, country);
  // }
}
