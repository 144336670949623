import { Component, OnInit } from '@angular/core';

import { TopService } from '../../services/top.service';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-card-banner',
    templateUrl: './card-banner.component.html',
    styleUrls: ['./card-banner.component.scss'],
    standalone: true,
    imports: [RouterLink]
})
export class CardBannerComponent implements OnInit {

  constructor(public topService: TopService) { }

  ngOnInit() {
  }

}
