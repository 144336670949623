import { Component, EventEmitter, inject, Input, Output, signal } from '@angular/core';
import { BuyerOffersService } from '../buyer-offers.service';
import { LiveOfferCounters, LiveOfferStateEnum } from '../../../shared/interfaces/ILiveOffer';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-offers-breadcrumbs-mobile',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './offers-breadcrumbs-mobile.component.html',
  styleUrl: '../../catalog/items-breadcrumbs/items-breadcrumbs-mobile/items-breadcrumbs-mobile.component.scss', 
})
export class OffersBreadcrumbsMobileComponent  {
  @Input() set counters(counters: LiveOfferCounters) {
    this.offerCountersSignal.set(counters);
  }
  @Output() stateChange = new EventEmitter<LiveOfferStateEnum>();
  
  private buyerOffersService = inject(BuyerOffersService);

  offerCountersSignal = signal<LiveOfferCounters>({
    all: 0,
    live: 0,
    counteroffers: 0,
    accepted: 0,
    canceled: 0,
    expired: 0
  });
  LiveOfferStateEnum = LiveOfferStateEnum;
  selectedStateSignal = this.buyerOffersService.selectedStateSignal;

  selectState(state: LiveOfferStateEnum): void { 
    if (!this.offerCountersSignal()[state]) {
      return;
    }

    this.buyerOffersService.selectedStateSignal.set(state);
    this.stateChange.emit(state);
  }

  capitalize(value: string): string {
    return value ? value.charAt(0).toUpperCase() + value.slice(1) : value;
  }
}
