import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ChangeDetectionStrategy  } from '@angular/core';
import { NotificationCenterVendorService, TabsEnum } from '../notification-center-vendor.service';
import { UtilityService } from '../../../../core/utility.service';
import { Subscription } from 'rxjs';
import { ChangeDetectorRef } from '@angular/core';

import { NotificationTypesEnum } from '../../../../shared/interfaces/INotificationDetail';
import { INotificationVendor } from '../../../interface/INotificationVendor';
import { CustomCurrencyPipe } from '../../../../shared/pipes/currency.pipe';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { FormsModule } from '@angular/forms';
import { NgIf, NgClass, DatePipe } from '@angular/common';

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, FormsModule, NgClass, NgClickOutsideDirective, DatePipe, CustomCurrencyPipe]
})
export class NotificationComponent implements OnInit, OnDestroy {
  @Input() notification: INotificationVendor;
  @Input() isSelected:boolean = false;
  @Output() openNotificationPopup = new EventEmitter<any>();
  @Input() isFromDashboard: boolean;
  @Output() updateTab = new EventEmitter<TabsEnum>();
  @Output() selected = new EventEmitter<boolean>();


  activeTab: number;
  tabsEnum = TabsEnum;
  notificationDetails;
  copied = 'Copy';
  isConfirmVisible = false;
  isConfirmVisibleAnimate = false;

  subscriptions: Subscription[] = [];

  constructor(private _notifCenterSvc: NotificationCenterVendorService,
              private utilityService: UtilityService,
              private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {

    this.subscriptions.push(
      this._notifCenterSvc.tabSubject$()
        .subscribe(tab => { this.activeTab = tab; })
    );

    this.mapNotifictionDetails();
  }

  openPopup(): void {
    if (this.activeTab !== this.tabsEnum.archive) {
      this.openNotificationPopup.next(this.notification);
    }
  }

  showColumn(tab: TabsEnum) {
    if (this.activeTab === TabsEnum.allNotif) {return true; }

    if (this.activeTab === TabsEnum.archive) {
      return tab !== TabsEnum.archive;
    }

    return this.activeTab === tab || tab === TabsEnum.archive;
  }

  mapNotifictionDetails() {
    // Mapping here notif details as objects so it's easier to use in the view
    const mappedNotDetail = { priceDrop: {}, moreInventory: {}, waitlist: {} };

    this.notificationDetails = this.notification.notificationDetails.map(i => {
      switch (i.notificationType) {
        case NotificationTypesEnum.priceDrop:
          mappedNotDetail.priceDrop = { seenCount: i.seenCount, newCount: i.newCount };
          break;
        case NotificationTypesEnum.waitlist:
          mappedNotDetail.waitlist = { seenCount: i.seenCount, newCount: i.newCount };
          break;
        case NotificationTypesEnum.moreInventory:
          mappedNotDetail.moreInventory = { seenCount: i.seenCount, newCount: i.newCount };
          break;
      }
    });
    this.notificationDetails = mappedNotDetail;
    this.notification.mappedNotDetails = mappedNotDetail;
  }

  copyText(event: any, val: string): void {
    event.stopPropagation();
    event.preventDefault();
    this.utilityService.copyTextToClipboard(val);
    this.copied = 'Copied!';
  }

  copyState(): void {
    this.copied = 'Copy';
  }

  showConfirm(event) {
    this.isConfirmVisible = true;
    this.isConfirmVisibleAnimate = true;
    event.stopPropagation();
  }

  hideConfirm(event?) {
    this.isConfirmVisibleAnimate = false;
    setTimeout(() => {
      this.isConfirmVisible = false;
    }, 200);
    if (event) {
      event.stopPropagation();
    }
  }

  onTooltipClick(event) {
    event.stopPropagation();
  }

  archiveNotification(event) {
    let ids = new Array<string>(this.notification.vendorItem.id);
    this._notifCenterSvc.archiveNotifications(ids).subscribe(
      (response) => {
        this.hideConfirm();
        if (response) { this.updateTab.emit(); }
      },
      (error) => {
        console.error('An error occurred. The notification can not be archived.', error);
      }
    );
    event.stopPropagation();
  }

  onSelect() {
    this.notification.isSelected = !this.notification.isSelected;
    this.selected.emit(this.notification.isSelected);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
  markForCheck(){
    this.changeDetectorRef.markForCheck();
  }
}
