import { Component, OnInit, ViewChild } from '@angular/core';
import { ItemsService } from '../../services/items.service';
import { TopService } from '../../services/top.service';
import { SessionService } from '../../../services/session.service';
import { Router } from '@angular/router';
import { OutletsService } from '../../../buyer/outlets/outlets.service';
import { UserService } from '../../../user/user.service';
import { ItemFilterService } from '../../item-filter/item-filter.service';

@Component({
    selector: 'app-landing-page-slider',
    templateUrl: './landing-page-slider.component.html',
    styleUrls: ['./landing-page-slider.component.scss'],
    standalone: true
})
export class LandingPageSliderComponent implements OnInit {

  constructor(private itemsService: ItemsService,
    private router: Router,
    public topService: TopService,
    private sessionService: SessionService,
    public outletsService: OutletsService,
    public userService: UserService,
    private itemFilterService: ItemFilterService) { }

  ngOnInit() {}

  onOutletItemClick(outletName: string): void {
    if ( this.topService.loading ) { return; }

    if (this.itemFilterService.allManufacturers ) {
      const guestUrl = (this.userService.isGuest) ? '/guest' : '';
      // redirect to outlet page
      if (outletName === 'all') {
        this.router.navigate([`${guestUrl}/outlets`]);
      } else {
        const selected = this.itemFilterService.allManufacturers.find(x => x.name.toLowerCase() === outletName.toLowerCase());
        if (selected) {
          this.outletsService.manufacturersData(selected);
          this.router.navigate([`${guestUrl}/outlets`, outletName.toLowerCase()]);
        }
      }
    } else {
      this.topService.loading = true;
      this.itemsService.getManufactures()
      .subscribe(data => {
        this.itemFilterService.allManufacturers = data;
        this.topService.loading = false;
        this.onOutletItemClick(outletName);
      },
      (err) => {
        console.error('Error getting all manufacturers', err);
        this.topService.loading = false;
      });
    }
  }

}
