import {
  Component,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
  AfterViewChecked,
  OnDestroy,
} from "@angular/core";
import { Title } from "@angular/platform-browser";

import { ItemsService } from "../../shared/services/items.service";
import { TopService } from "../../shared/services/top.service";
import { PubSubService } from "../../core/pubsub.service";
import { SharedSource } from "../../core/shared-source";
import { SharedService, nonItemRoutesRgx } from "../../shared/shared.service";
import { SessionService } from "../../services/session.service";
import { Router, ActivatedRoute, UrlTree, NavigationEnd, RouterOutlet } from "@angular/router";
import { NotificationService } from "../../shared/services/notification.service";
import { filter, Subject, Subscription, takeUntil } from "rxjs";
import { UserService } from "../../user/user.service";
import { eConnectionType } from "../../shared/services/signalR/connection-type";
import { ConnectionManagerService } from "../../shared/services/signalR/connection-manager.service";
import { environment } from "../../../environments/environment";
import { BottomNavMobileComponent } from "../../shared/bottom-nav-mobile/bottom-nav-mobile.component";
import { MobileNotificationsModalComponent } from "../../user/mobile-notifications-modal/mobile-notifications-modal.component";
import { NgIf } from "@angular/common";

@Component({
    selector: "app-buyer-home",
    templateUrl: "./buyer-home.component.html",
    styleUrls: ["./buyer-home.component.scss"],
    standalone: true,
    imports: [
        NgIf,
        RouterOutlet,
        MobileNotificationsModalComponent,
        BottomNavMobileComponent,
    ],
})
export class BuyerHomeComponent
  implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy
{
  public type = "component";
  loading: false;
  precartOpen = false;
  precartSmallClose = true;
  precartClose = false;
  switchCatalog = true;
  listingView = true;
  // isMobile = false;
  savedView = "excelGridView";
  showMobileCategories = false;
  getCampaignSub: Subscription;
  showPrecart = false;
  mobilePopupReady = false;
  isMaintenanceMode = environment.maintenanceMode;

  subjectSubscription = new Subscription();

  private destroy$ = new Subject();

  constructor(
    private titleService: Title,
    public itemsService: ItemsService,
    public userService: UserService,
    private pubSubService: PubSubService,
    public topService: TopService,
    private sharedService: SharedService,
    private cdr: ChangeDetectorRef,
    private sessionService: SessionService,
    private router: Router,
    private route: ActivatedRoute,
    public notificationService: NotificationService,
    private connManagerService: ConnectionManagerService
  ) {}

  ngOnInit() {
    this.sharedService.isCorpSite = false;
    this.connManagerService.addConnection(eConnectionType.JustSold);

    if (
      this.sessionService.userRole === "SELLER" ||
      this.sessionService.userRole === "ADMIN" ||
      this.sessionService.userRole === "VENDORMGR"
    ) {
      this.userService.resetSessionWrongPortal();
      this.router.navigate([
        "/guest" + this.router.url.replace(/\/buyer|\/guest/, ""),
      ]);
    }

    this.itemsService.isItemsList = !this.router.url.match(nonItemRoutesRgx);

    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter((event) => event instanceof NavigationEnd),
      )
      .subscribe((route: any) => {
        const finalRoute = route?.url?.split("/")[2];
        const listingRoutes = [
          "price_drop",
          "just_launched",
          "todays_deals",
          "just_sold",
          "past_purchases"
        ];
        if (listingRoutes.includes(finalRoute) || route.url.match(/search/) ) {
          this.itemsService.getSelectedNav();
        }
      });

    this.route.url.subscribe((val) => {
      this.itemsService.paginationInfo = null;
      if (this.route.snapshot.queryParams.listings) {
        const queryString = this.route.snapshot.queryParams.listings;
        this.topService.getPriceListMetadata(queryString);
      }
      this.getCurrentNavigation();
    });

    this.sharedService.isLogoutPage = false;
    this.titleService.setTitle("HUBX Portal");
    this.setupPubSub();

    if (this.sessionService.isLoggedIn) {
      this.refreshUserProfile();
    }
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  ngAfterViewInit(): void {
    if (!this.topService.isDone) {
      this.topService.isDone = true;
    }
  }
  isSimpleView(): boolean {
    return !(
      this.itemsService.selectedTopAttribute === "Just Sold" ||
      this.itemsService.selectedTopAttribute === "Past Purchase" ||
      this.itemsService.selectedTopAttribute === "Notification Center"
    );
  }

  getCurrentNavigation() {
    if (
      !this.router.url.match(nonItemRoutesRgx) &&
      this.itemsService.navigationTree
    ) {
      this.itemsService.getSelectedNav();
    }

    if (this.itemsService.currentURL === "home") {
      this.itemsService.adjustSettingsForHomePage();
    }
    const urlTree: UrlTree = this.router.parseUrl(this.router.url);
    const redirectToFromUrl = urlTree.queryParams;
    if (redirectToFromUrl.redirectTo && !this.userService.auth.redirectUrl) {
      this.router.navigate(["home"]);
    }
  }

  refreshUserProfile() {
    this.userService.getLoggedInUserProfile().subscribe((u) => {
      this.userService.userProfile = u;
      this.mobilePopupReady = true;
    });
  }

  setupPubSub(): void {
    this.subjectSubscription.add(
      this.pubSubService.sharedSubject.subscribe((myEvent) => {
        if (myEvent.name === SharedSource.showPreCart) {
          this.showPrecart = myEvent.data;
        }
        if (myEvent.name === SharedSource.refreshItemsAfterAuth) {
          this.itemsService.updatePastPurchaseInItems();
        }
      })
    );
  }

  showMobileCategory(): void {
    if (this.topService.loading) {
      return;
    }
    this.showMobileCategories = !this.showMobileCategories;
  }

  onClickedOutside($event): void {
    this.showMobileCategories = false;
    $event.stopPropagation();
  }

  handleToggle(): void {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    if (width < 1569) {
      this.precartSmallClose = !this.precartSmallClose;
      this.precartClose = this.precartSmallClose;
      if (this.precartSmallClose === true && width > 1197) {
        this.itemsService.hideText = false;
      } else {
        this.itemsService.hideText = true;
      }
    } else {
      this.precartClose = !this.precartClose;
      this.precartSmallClose = this.precartClose;
      this.itemsService.hideText = false;
    }
  }

  closePhonePopup() {
    sessionStorage.setItem("show_phone_popup", "0");
  }

  showPhonePopup() {
    const showPhone = sessionStorage.getItem("show_phone_popup");

    return (
      showPhone === "1" &&
      this.userService.userProfile &&
      !this.userService.userProfile.isVerified &&
      !this.userService.isGuest
    );
  }

  onChangeView(view: string): void {
    this.savedView = view;
  }

  ngOnDestroy() {
    this.destroy$.next("");
    this.destroy$.unsubscribe();
    this.itemsService.isItemsList = false;
    this.subjectSubscription.unsubscribe();
  }
}
