import { ItemsService } from './../../shared/services/items.service';
import { AcceptedLiveOfferDictionary, AcceptedLiveOffers, LiveOfferFilter, LiveOffersCounterResponse, offerBusinessPartner } from './../../shared/interfaces/ILiveOffer';
import { ConfirmationPopupSettings } from './../../shared/interfaces/IConfirmationPopupSettings';
import { computed, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';

import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import * as models from './../../shared/interfaces/model';
import { IItem } from '../interfaces/IItem';
import {
  LiveOffer,
  LiveOfferByIdResponse,
  LiveOfferPayload,
  LiveOfferResponse,
  LiveOfferStateEnum,
  OfferOwnershipPayload,
} from '../../shared/interfaces/ILiveOffer';

@Injectable({
  providedIn: 'root'
})
export class BuyerOffersService {
  buyerOffersPageSize = 25;
  public liveOfferListingSignal: WritableSignal<LiveOfferResponse> = signal({
    pagination: {
      currentPage: 1,
      pageSize: this.buyerOffersPageSize,
      totalCount: 1,
      totalPages: 1,
    },
    values: [],
    counters: {
      all: 0,
      live: 0,
      counteroffers: 0,
      accepted: 0,
      canceled: 0,
      expired: 0
    },
    acceptedInProcess: {}
  });

  public showRestrictedCountryPopupSignal: WritableSignal<boolean> = signal(false);
  public isListLoadingSignal: WritableSignal<boolean> = signal(null);
  public isSubscriptionLoadingSignal: WritableSignal<boolean> = signal(false);
  public isOwnershipLoadingSignal: WritableSignal<boolean> = signal(false);
  public isGlobalLoadingSignal: Signal<boolean> = computed(() => this.isListLoadingSignal() || this.isSubscriptionLoadingSignal() || this.isOwnershipLoadingSignal());
  public selectedOfferSignal: WritableSignal<LiveOffer> = signal(undefined);
  confirmAcceptanceSettings: ConfirmationPopupSettings = {
    show: false,
    bodyText: 'Please note that by accepting this live offer, you are committing to a binding order that cannot be canceled or returned.',
    headerText: `Are you ready<br>to accept this offer?`,
    zoomIn: true,
    zoomOut: true,
    acceptButton: {
      show: true,
      text: 'Accept Offer'
    },
    cancelButton: {
      show: true,
      text: 'Back'
    }
  };
  submittedForAcceptanceSettings: ConfirmationPopupSettings = {
    show: false,
    bodyText: `The order will be added<br>to your Order History list.`,
    headerText: `Offer has been accepted`,
    zoomIn: true,
    zoomOut: true,
    acceptButton: {
      show: true,
      text: 'Got it!'
    }
  };
  liveOfferNotificationEnabledSettings: ConfirmationPopupSettings = {
    show: false,
    bodyText: `We'll notify you via SMS or email<br>about any response to the offer.`,
    headerText: `Notification enabled`,
    zoomIn: true,
    zoomOut: true,
    acceptButton: {
      show: true,
      text: 'Got it!'
    }
  };
  liveOffersCounterSignal: WritableSignal<number> = signal(0);
  selectedStateSignal = signal<LiveOfferStateEnum>(LiveOfferStateEnum.All);

  isMobileFiltersOpen = false;
  public liveOffersFilter: LiveOfferFilter;

  constructor(
    private http: HttpClient,
    private itemsService: ItemsService
  ) {
    this.setDefaultLiveOffersFilter();
  }

  setDefaultLiveOffersFilter(){
    this.liveOffersFilter = {
      sortingCriteria: 0,
      searchQuery: '',
      filter: LiveOfferStateEnum.Live
    };
  }

  offerBusinessPartners(offerId: string): Observable<offerBusinessPartner> {
    const url = `${environment.adminUrl}Offers/${offerId}/businessPartners`;
    return this.http.get<offerBusinessPartner>(url);
  }

  subscribeToBuyersOffers(offerId: string): Observable<models.OfferSubscriptionResponse> {
    const url = `${environment.buyerPortalBaseUrl}offers/${offerId}/subscription`;
    return this.http.post<models.OfferSubscriptionResponse>(url, null);
  }

  unsubscribeToBuyersOffers(offerId: string): Observable<void> {
    const url = `${environment.buyerPortalBaseUrl}offers/${offerId}/subscription`;
    return this.http.delete<void>(url);
  }

  getLiveOffers(payload: LiveOfferPayload): Observable<LiveOfferResponse> {
    const url = `${environment.buyerPortalBaseUrl}offers/live`;
    return this.http.post<LiveOfferResponse>(url, payload).pipe(
      map((response: LiveOfferResponse) => {
        response.values.map(item => {
          this.itemsService.mapNotificationInfo(item.item);
        });
        return response;
      })
    );
  }

  getLiveOfferById(offerId: string): Observable<LiveOfferByIdResponse> {
    const url = `${environment.buyerPortalBaseUrl}offers/live/${offerId}`;
    const payload = {
      acceptedInProcess: this.getAcceptedOffersFromSessionStorage()
    };

    return this.http.post<LiveOfferByIdResponse>(url, payload).pipe(
      map((response: LiveOfferByIdResponse) => {
        response.item = this.itemsService.mapNotificationInfo(response.item);
        return response;
      })
    );
  }

  searchLiveOffers(payload: LiveOfferPayload): Observable<LiveOfferResponse> {
    const url = `${environment.buyerPortalBaseUrl}offers/live/search`;
    return this.http.post<LiveOfferResponse>(url, payload);
  }

  getLiveOfferByIdSignal(offerId: string): Signal<LiveOffer> {
    const url = `${environment.buyerPortalBaseUrl}offers/live/${offerId}`;
    return toSignal(this.http.get<LiveOffer>(url).pipe(
      catchError((error) => {
        console.error('Error fetching live offer', error);
        return throwError(() => error);
      }),
    ), { initialValue: null });
  }

  getLiveOffersCounter(): void {
    const url = `${environment.buyerPortalBaseUrl}offers/live/count`;
    const payload = {
      acceptedInProcess: this.getAcceptedOffersFromSessionStorage()
    };

    this.http.post<LiveOffersCounterResponse>(url, payload).pipe(
      catchError((error) => {
        console.error('Error fetching live offers count', error);
        return throwError(() => error);
      }),
    ).subscribe((response: LiveOffersCounterResponse) => {
      this.replaceOrMergeAcceptedOffersInSessionStorage(response.acceptedInProcess);
      this.liveOffersCounterSignal.set(response.liveOfferCount);
    });
  }

  updateOfferOwnership(offerOwnershipPayload: OfferOwnershipPayload) {
    const url = `${environment.buyerPortalBaseUrl}offers/${offerOwnershipPayload.id}/ownership`;
    return this.http.put<any>(url, offerOwnershipPayload).pipe(
      catchError((error) => {
        console.error('Error updating offer ownership', error);
        return throwError(() => error);
      }),
    );
  }

  // Handle Accepted Offer in Session Storage
  addAcceptedOfferToSessionStorage(newOffer: Record<string, number>): void {
    const acceptedLiveOffers = this.getAcceptedOffersFromSessionStorage(); // Retrieve the current dictionary from session storage
    const [id, version] = Object.entries(newOffer)[0];    // Extract the id and version from the newOffer (only one key-value pair is expected)

    if (!acceptedLiveOffers.hasOwnProperty(id)) { // Add the new element only if it doesn't already exist in the dictionary
      acceptedLiveOffers[id] = version;
    }
    sessionStorage.setItem('accepted_live_offers', JSON.stringify(acceptedLiveOffers));
  }


  getAcceptedOffersFromSessionStorage(): Record<string, number> {
    const rawData = sessionStorage.getItem('accepted_live_offers');
    
    if (!rawData) {
      return {} as Record<string, number>; 
    }
  
    const parsedData = JSON.parse(rawData);      
    if (Array.isArray(parsedData)) {
      const dictionary: Record<string, number> = {};
      parsedData.forEach((offer: AcceptedLiveOffers) => {
        dictionary[offer.id] = offer.version;
      });
  
      sessionStorage.setItem('accepted_live_offers', JSON.stringify(dictionary));
      return dictionary;
    }
  
    return parsedData as Record<string, number>;
  }

  replaceOrMergeAcceptedOffersInSessionStorage(newOffers: Record<string, number>): void {
    const existingOffers = this.getAcceptedOffersFromSessionStorage();

    if (Object.keys(existingOffers).length === 0 || Object.keys(newOffers).length === 0) {
      return;
    }

    for (const id in newOffers) { // Iterate over the elements in the newOffers dictionary (A)
      if (Object.prototype.hasOwnProperty.call(newOffers, id)) {
        const newVersion = newOffers[id];
        const existingVersion = existingOffers[id];

        if (existingVersion !== undefined) { // If the new version is greater, remove the element from B (session storage)
          if (newVersion > existingVersion) {
            delete existingOffers[id];
          }
        }
      }
    }

    sessionStorage.setItem('accepted_live_offers', JSON.stringify(existingOffers));
  }



}