<div class="items-wrap">
  <div class="ps-header-wrapper">

    <div class="ps-header">

      <!-- TOTAL -->
      <div class="ps-header-top">
        <div class="ps-header-heading">
          <img src="assets/images/vendor-menu-icons/clock.svg" width="28" height="28px" alt="Purchase Orders">
          <span>Pending PO</span>
          <span class="__bage">{{pendingPurchaseOrders?.details?.length || 0}}</span>
        </div>

        <!-- SEARCH -->
        <div class="ps-header-search">
          <button (click)="verifyIfSearch({key: 'Enter'})">
            <svg height="24" viewBox="0 0 32 32" width="24" xmlns="http://www.w3.org/2000/svg">
              <path fill="#fff"
                d="m25.84375 22.8029159-4.4166667-4.4727935c.71875-1.2548816 1.09375-2.6503515 1.09375-4.0562354 0-4.56131215-3.7083333-8.273887-8.2604166-8.273887-4.55208337 0-8.2604167 3.71257485-8.2604167 8.273887 0 4.5613122 3.70833333 8.273887 8.2604167 8.273887 1.453125 0 2.890625-.4009372 4.171875-1.1663629l4.3958333 4.4571726c.0989583.0989326.2395833.1614163.3802083.1614163s.28125-.0572768.3802084-.1614163l2.2552083-2.2806561c.2083333-.213486.2083333-.5467326 0-.7550117zm-11.5833333-13.57979692c2.78125 0 5.0416666 2.26503512 5.0416666 5.05076802s-2.2604166 5.050768-5.0416666 5.050768-5.0416667-2.2650351-5.0416667-5.050768 2.2604167-5.05076802 5.0416667-5.05076802z" />
            </svg>
          </button>
          <input type="text" id="search-items-input" placeholder="Search" autocomplete="off"
            [(ngModel)]="pendingSoldFilters.searchString" (keyup)="verifyIfSearch($event)" />
        </div>

        <!-- EXPORT -->
        <div class="ps-header-export">
          <a role="button" class="export-btn" placement="bottom" (click)="exportToCSV()" [style.cursor]="isExportBtnDisabled() ? 'default' : 'pointer'">
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g id="customers" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.800000012">
                    <g id="5.1---pending-sold" transform="translate(-792.000000, -120.000000)" fill="#FFFFFF" fill-rule="nonzero">
                        <g id="Group-2" transform="translate(123.000000, 96.000000)">
                            <g id="button" transform="translate(647.000000, 16.000000)">
                                <g id="Icons/export" transform="translate(22.000000, 8.000000)">
                                    <path d="M13.5317163,4 L13.5317163,17.7488731 L13.524097,17.7488731 L13.524097,20 L12.3812054,20 C9.58747026,19.484868 6.77595682,18.9877656 4,18.4906632 L4,18.4906632 L4,5.48615583 C6.82167248,4.9916291 9.62556665,4.515132 12.4370801,4 L12.4370801,4 L13.5317163,4 Z M19,5.78235673 L19,18.2099163 L14.232872,18.2099163 L14.232872,5.78235673 L19,5.78235673 Z M10.6719474,8.82163554 C10.2554269,8.84395793 9.83975301,8.86971453 9.42492567,8.89890534 C9.11507505,9.65099807 8.76712804,10.4056665 8.51315212,11.1963941 C8.28711355,10.4520283 7.98742196,9.73341919 7.71566772,9.00708307 L7.71566772,9.00708307 L6.50166282,9.07662589 C6.92580261,10.0270444 7.38041951,10.9645847 7.7918605,11.9227302 C7.30676649,12.8499678 6.85214959,13.7926594 6.38229414,14.727624 C6.78357609,14.7430779 7.18485805,14.7662589 7.58614001,14.7662589 C7.8731328,14.0270444 8.22361957,13.3161623 8.47759549,12.5614939 C8.73983017,13.356524 9.0467982,14.1356519 9.39698832,14.8950419 C9.83890642,14.9285254 10.2782848,14.9568577 10.7202029,14.9929169 C10.212251,13.9523503 9.70429919,12.909208 9.19634734,11.878944 C9.69244698,10.8572655 10.1843137,9.8381627 10.6719474,8.82163554 Z"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
            <span>Export Results</span>
          </a>
        </div>

        <!-- REFRESH -->
        <div class="ps-header-refresh">
          <a role="button" class="refresh-btn" placement="bottom" (click)="refresh()">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
              height="24px" viewBox="0 0 24 24" version="1.1">
              <g id="Icons/24-refresh-white" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <path
                  d="M17.9957824,12 C17.9957824,15.3145476 15.3106214,18 11.99759,18 C8.68455849,18 5.99939749,15.3145476 5.99939749,12 C5.99939749,9.06264182 8.13018166,6.57079616 10.9972649,6.09053966 L11.3550341,6.03061082 L11.3550341,3.01846556 L10.8736286,3.07826914 C6.39612752,3.63449588 3,7.4490131 3,12 C3,16.9716573 7.02790562,21 11.99759,21 C16.9672743,21 20.9951799,16.9716573 20.9951799,12 C20.9951799,9.91643623 20.2825826,7.947437 19.0019225,6.36171025 L20.3979517,4.96710806 L20.5036547,4.86520609 C20.5671884,4.80315403 20.6184075,4.75071062 20.6655618,4.69845732 C20.879937,4.46090093 21,4.23687274 21,3.96428571 C21,3.43261944 20.568397,3 20.0358649,3 L14.6802283,3 C14.5357153,3 14.3981138,3.01920102 14.2388318,3.09157803 C13.9178369,3.23743672 13.7160932,3.54249679 13.7160932,3.96428571 L13.7160932,9.32142857 C13.7160932,9.85297561 14.1481135,10.2857143 14.6802283,10.2857143 C15.0020973,10.2857143 15.1980134,10.1712904 15.4787638,9.89273344 L15.711669,9.65335933 L16.8603712,8.5039329 C17.5928151,9.51597732 17.9957824,10.7266282 17.9957824,12 Z"
                  id="Path" fill="#FFFFFF" fill-rule="nonzero" />
              </g>
            </svg>
            <span>Refresh</span>
          </a>
        </div>
      </div>

      <!-- SKUs -->
      <div class="ps-header-box-wrapper">
        <div class="ps-header-box __sold">
          <div class="ps-header-box-left">
            <span class="ps-header-box__title">Pending SKUs Sold</span>
            <span class="ps-header-box__count">{{ pendingPurchaseOrders?.skusSold  || 0 }}</span>
          </div>
          <div class="ps-header-box-icon">
            <img src="../../../assets/images/vendor-icons/dashboard/pending-skus-sold.svg" width="64" height="64" />
          </div>
        </div>

        <!-- UNITS -->
        <div class="ps-header-box __units-sold">
          <div class="ps-header-box-left">
            <span class="ps-header-box__title">Pending Units Sold</span>
            <span class="ps-header-box__count">{{ pendingPurchaseOrders?.unitsSold  || 0}}</span>
          </div>
          <div class="ps-header-box-icon">
            <img src="../../../assets/images/vendor-icons/dashboard/units-sold.svg" width="64" height="64" />
          </div>
        </div>

        <!-- REVENUE -->
        <div class="ps-header-box __revenue">
          <div class="ps-header-box-left">
            <span class="ps-header-box__title">Pending Revenue</span>
            <span
              class="ps-header-box__count">{{ (pendingPurchaseOrders?.revenue  || 0) | currency:'USD':'symbol-narrow' }}</span>
          </div>
          <div class="ps-header-box-icon">
            <img src="../../../assets/images/vendor-icons/dashboard/revenue.svg" width="64" height="64" />
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="items-list" *ngIf="showItemList">

    <!-- ITEMS HEADER -->
    <div class="items-list-header">
      <div class="items-list-header__sorting __button"></div>

      <div class="items-list-header__sorting __date" (click)="sortBy(PpoSortCondition.Date)"
      [ngClass]="{'to-high': currentSort === PpoSortCondition.Date && isAsc,
                  'to-low': currentSort === PpoSortCondition.Date && !isAsc,
                  'default': currentSort !== PpoSortCondition.Date}">
        Date<span></span>
      </div>

      <div class="items-list-header__sorting __part-number" (click)="sortBy(PpoSortCondition.PartNumber)"
      [ngClass]="{'to-high': currentSort === PpoSortCondition.PartNumber && isAsc,
                  'to-low': currentSort === PpoSortCondition.PartNumber && !isAsc,
                  'default': currentSort !== PpoSortCondition.PartNumber}">
        Part Number <span></span>
      </div>

      <div class="items-list-header__sorting" (click)="sortBy(PpoSortCondition.UnitsSold)"
      [ngClass]="{'to-high': currentSort === PpoSortCondition.UnitsSold && isAsc,
                  'to-low': currentSort === PpoSortCondition.UnitsSold && !isAsc,
                  'default': currentSort !== PpoSortCondition.UnitsSold}">
        Units Sold<span></span>
      </div>

      <div class="items-list-header__sorting" (click)="sortBy(PpoSortCondition.Cost)"
      [ngClass]="{'to-high': currentSort === PpoSortCondition.Cost && isAsc,
                  'to-low': currentSort === PpoSortCondition.Cost && !isAsc,
                  'default': currentSort !== PpoSortCondition.Cost}">
        Cost<span></span>
      </div>

      <div class="items-list-header__sorting __revenue" (click)="sortBy(PpoSortCondition.Revenue)"
      [ngClass]="{'to-high': currentSort === PpoSortCondition.Revenue && isAsc,
                  'to-low': currentSort === PpoSortCondition.Revenue && !isAsc,
                  'default': currentSort !== PpoSortCondition.Revenue}">
        Total Revenue <span></span>
      </div>
    </div>

    <!-- ITEMS -->
    <ng-container *ngIf="pendingPurchaseOrders?.details?.length && !loading">
      <app-pending-sold-items class="app-pending-sold-items" (click)="highlightItem(pendingSoldItem)"
        *ngFor="let pendingSoldItem of pendingPurchaseOrders.details" [item]="pendingSoldItem">
      </app-pending-sold-items>
    </ng-container>

    <!-- ITEMS PRELOADER -->
    <app-vendor-items-preloader [source]="'pendingPo'" *ngIf="loading"></app-vendor-items-preloader>
  </div>
  
  <!-- EMPTY -->
  <app-pending-sold-empty  *ngIf="!pendingPurchaseOrders?.details?.length && !loading"></app-pending-sold-empty>
  
</div>


<!-- ITEM DETAILS -->
<div [ngClass]="{'hide': !showDetailedPopUp}" class="add-to-existing-modal detailed-modal"
  *ngIf="selectedVendorItem && showDetailedPopUp">
  <div class="add-to-wrap animated" [class.zoomIn]="showDetailedPopUp" (click)="clickedInside($event)"
    (clickOutside)="closeDetailsModal()" exclude='.comment-wrapper, .item-logo-number, .test-class, .close' [excludeBeforeClick]="true"
    [clickOutsideEvents]="'click'">
    <app-item-detailed [item]="selectedVendorItem" [fg]="selectedVendorFg"
    [dropDowns]="dropDowns" [exwOptions]="allExwOptions"
    [condition]="condition" [warranty]="warranty" [packaging]="packaging" [restrictions]="restrictions"
    [viewType]="vendorService.vendorItemsSavedInfo.viewType" [isPopup]="true"
    [isAll]="isAllVendors" (openCommentModal)="openCommentModal($event)" (singleItemUpdated)="updateSingleVendorItems($event)"
    (onClose)="closeDetailsModal()">
    </app-item-detailed>
  </div>
  <div class="clear"></div>
</div>

<!-- COMMENTS -->
<div [ngClass]="{'hide': !showCommentModal}" *ngIf="showCommentModal">
  <app-item-comment [fg]="selectedVendorFg" (closeModal)="closeItemCommentsModal()"></app-item-comment>
</div>

<div *ngIf="isModalShown && !loader">
  <app-item-success [sucessMsg]="getItemsText()" (close)="hideSuccessAlert()"></app-item-success>
</div>
