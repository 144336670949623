import * as models from './model';
export interface IOrderLine {
    lineNum: number;
    itemCode: string;
    itemName: string;
    description: string;
    exwPoint: string;
    unitPrice: number;
    overwrittenPrice: number;
    quantity: number;
    comments: models.Comment[];
}
