import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as modelsU from '../../../shared/interfaces/model';
import { CartService } from '../../cart/cart.service';
import { FormsModule } from '@angular/forms';
import { NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'app-address-mobile',
    templateUrl: './address-mobile.component.html',
    styleUrls: ['./address-mobile.component.scss'],
    standalone: true,
    imports: [NgFor, NgClass, FormsModule]
})
export class AddressMobileComponent implements OnInit {
  @Input() address: modelsU.IAddress;
  @Input() addressType: string;
  @Input() isAddressOpen: boolean;
  @Input() currentAddress: string;
  @Input() addresses:  modelsU.IAddress[];
  @Output() selectedAddress = new EventEmitter<modelsU.IAddressEmitter>();
  @Output() onClose = new EventEmitter<null>();
  animateOut: boolean;
  addressCode: string;
  dropdownTitle: string;

  constructor(private cartService: CartService) { }

	ngOnInit() {
		document.documentElement.classList.add('is-modal');
		this.addressCode = this.currentAddress;
		if (this.addressType == 'shipping') {
			this.dropdownTitle = 'Ship to Address';
		} else if (this.addressType == 'billing') {
			this.dropdownTitle = 'Bill to Address';
		}
	}

	ngOnDestroy() {
		document.documentElement.classList.remove('is-modal');
	}

	closeModal(): void {
		this.addressCode = this.address.addressCode;
		this.animateOut = true;
		setTimeout(() => {
			this.animateOut = false;
			this.onClose.emit();
		}, 400);
	}
	selectAddress(): void {
		const myAddress = this.addresses.find(address => address.addressCode === this.currentAddress);
		const mySelectedAddress: modelsU.IAddressEmitter = {
		addressType: this.addressType,
		addressCode: myAddress.addressCode
		};
		this.selectedAddress.emit(mySelectedAddress);
		this.addressCode = this.currentAddress;

		this.closeModal();
	}

}
