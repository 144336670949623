import { OfferService } from './../../user/offers/offer.service';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Injectable, ChangeDetectorRef } from '@angular/core';
import { Event as RouterEvents, Router, NavigationEnd, RouterLinkActive, RouterLink } from '@angular/router';
import { Location, NgIf, NgClass, NgFor } from '@angular/common';

import { ItemsService } from '../../shared/services/items.service';
import { CartService } from '../cart/cart.service';
import { AuthService } from '../../auth/auth.service';
import { TopService } from '../../shared/services/top.service';
import { UserService } from '../../user/user.service';
import { OrdersService } from '../order-history/orders/orders.service';
import { PubSubService } from '../../core/pubsub.service';
import { SharedSource } from '../../core/shared-source';
import { GlobalSearchService } from '../../services/search.service';
import { SharedService } from '../../shared/shared.service';
import { SessionService } from '../../services/session.service';
import { ItemFilterService } from '../../shared/item-filter/item-filter.service';

import * as models from '../interfaces/model';
import * as modelsS from '../../shared/interfaces/model';
import { GuestService } from '../../shared/services/guest.service';
import { LeftCategoriesService } from '../../shared/services/left-categories.service';
import { NotificationCenterBuyerService } from '../notification-center/notification-center.service';
import { TopCategoriesFileSaverService } from '../catalog/top-categories/top-categories-file-saver.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { AuctionsService } from '../auctions/auctions.service';
import { OutletsService } from '../outlets/outlets.service';
import { UserFiltersService } from '../user-filters/user-filters.service';
import { Subscription } from 'rxjs';
import { IOffersSummary } from '../interfaces/IOffersSummary';
import { ConfirmationPopupComponent } from '../../shared/confirmation-popup/confirmation-popup.component';
import { DropdownCategoriesComponent } from '../categories/dropdown-categories.component';
import { ErrorModalComponent } from '../../shared/ui-components/error-modal/error-modal.component';
import { UserFiltersTypeaheadComponent } from '../user-filters/user-filters-typeahead/user-filters-typeahead.component';
import { ConfirmModalComponent } from '../../shared/confirm-modal/confirm-modal.component';
import { AddToCartModalComponent } from '../../shared/ui-components/add-to-cart-modal/add-to-cart-modal.component';
import { TopCategoriesComponent } from '../catalog/top-categories/top-categories.component';
import { DropdownComponent } from '../../shared/dropdown/dropdown.component';
import { FormsModule } from '@angular/forms';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { BulkUploadComponent } from '../cart/bulk-upload/bulk-upload.component';

@Component({
    selector: 'app-top',
    templateUrl: './top.component.html',
    styleUrls: ['./top.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        BulkUploadComponent,
        NgClickOutsideDirective,
        NgClass,
        RouterLinkActive,
        RouterLink,
        FormsModule,
        DropdownComponent,
        TopCategoriesComponent,
        AddToCartModalComponent,
        ConfirmModalComponent,
        NgFor,
        UserFiltersTypeaheadComponent,
        ErrorModalComponent,
        DropdownCategoriesComponent,
        ConfirmationPopupComponent,
    ],
})
export class TopComponent implements OnInit, OnDestroy {
  @ViewChild('topSearch') topSearch: ElementRef;
  disableBulkSumitButton: boolean;
  disabledClassApplied: boolean;
  bulkUploadFieldData = '';
  errors: any; // errors from server
  salesCustomers: modelsS.ICustomers;

  selectedCustomer = '';
  user_role = '';
  showAdminNav = false;
  isCustomerSelected = false;
  hideAdminMenu = false;
  bulkapi_response = [];
  showMobileMenu = false;
  animateOut = false;
  bulkUploadInProgress = false;
  bulkUploadHasErrorAndSucess = false;
  gotFirst = 0;
  showloadString = ``;
  disabledClass = '';
  customersLoading = false;
  currentCustomer = '';
  currentUserEmail = '';
  showBulk = false;
  showLoad = false;
  showDelCart = false;
  uploadedContent: string[];
  excelData: string;
  showBulkErr = false;
  loading = false;
  isResponse200 = false;
  searchAlert = false;
  isMobileSorting = false;
  isShowDetailedPopUp = false;
  topErrorModal: models.IBuyerErrorModal = {
    bodyText: '',
    title: 'Server Error',
    isShown: false
  };
  showBulk2 = false;
  clearCartMsg = ['Your shopping cart will be cleared.'];
  date = new Date();
  fileName = 'HUBX-Price-List-' + this.date.toLocaleString() + '.xlsx';

  newNotificationCounter = 0;
  isCategoriesOpened = false;
  subscriptions = new Subscription();

  constructor(
    public router: Router,
    protected itemsService: ItemsService,
    public topService: TopService,
    public cartService: CartService,
    public authService: AuthService,
    public userService: UserService,
    public orderService: OrdersService,
    protected pubSubService: PubSubService,
    protected searchService: GlobalSearchService,
    public sharedService: SharedService,
    protected sessionService: SessionService,
    private location: Location,
    protected guestService: GuestService,
    public outletService: OutletsService,
    private leftCategoriesService: LeftCategoriesService,
    private _notificationSvcBuyer: NotificationCenterBuyerService,
    private topCategoriesFileSaverService: TopCategoriesFileSaverService,
    public auctionService: AuctionsService,
    public itemFilterService: ItemFilterService,
    private userFilterService: UserFiltersService,
    private cdr: ChangeDetectorRef,
    public offersService: OfferService
  ) {
    this.router.events.subscribe((event: RouterEvents) => {
      let topCategoriesUpdateRoutes: string[] = ['/buyer/home', '/outlets', '/buyer/past_purchases', '/notifications', '/cart'];
      if (event instanceof NavigationEnd) {
        if (topCategoriesUpdateRoutes.find((value) => value === event.url))
          this.itemsService.getLeftNavigationsItems().subscribe((res) => {
            this.pubSubService.sharedSubject.next({ name: SharedSource.navigationsDataChanged, data: res });
          });
      }
    })
  }

  ngOnInit() {
    this.getCounters();

    this.cartService.totalUnits();

    if (!this.cartService.cartList || !this.cartService.cartList.length) {
      this.cartService.getCart();
    }
    this.currentUserEmail = this.sessionService.userEmail;
    this.user_role = this.sessionService.userRole;

    if (this.user_role === 'SALES' || this.user_role === 'SUPER') {
      this.getCustomers();
    }

    this.subscribeToPubService();
    this.disableBulkSumitButton = true;
  }

  subscribeToPubService() {
    this.subscriptions.add(
      this.pubSubService.sharedSubject.subscribe(
        (sharedValue) => {
          if (sharedValue.name === SharedSource.mobileSortingPopUpOpen) {
            this.isMobileSorting = sharedValue.data;
          }
          if (sharedValue.name === SharedSource.showDetailedPopUpOpen) {
            this.isShowDetailedPopUp = sharedValue.data;
          }
          if (sharedValue.name === SharedSource.editOrderMode) {
            this.cartService.isEditOrder = sharedValue.data;
          }


          if ([SharedSource.priceDropFromVendor, SharedSource.waitlistFromVendor, SharedSource.moreInventoryFromVendor]
            .includes(sharedValue.name)) {
            this.getCounters();
          } else if (sharedValue.name === SharedSource.qtyOfNewInNotifCenterBuyer) {
            this.newNotificationCounter = sharedValue.data;
          }

          if (sharedValue.name === SharedSource.triggerChangeDetection
            || sharedValue.name === SharedSource.offerSummary
            || sharedValue.name === SharedSource.bpUnseenEventCountUpdated) {
            this.cdr.detectChanges();
          }
        }
      )
    );
  }

  // isSearchEmpty(): boolean {
  //   return this.searchDisabled() || !this.topService.searchString || this.topService.searchString.length === 0;
  // }

  isSearchEmpty(): boolean {
    return this.searchDisabled();
  }

  searchDisabled(): boolean {
    return this.topService.loading || !this.topService.showSearch;
  }

  gotoCart(): void {
    this.cartService.goToCart();
  }

  validateBulkUploadBeforeSubmit(): void {
    if (this.excelData && this.excelData.trim()) {
      if (this.excelData !== '') {
        this.disableBulkSumitButton = false;
        this.showLoad = !this.showLoad;
        this.showDelCart = !this.showDelCart;
      }
    } else {
      this.disableBulkSumitButton = true;
    }
  }

  getUploadedContent(): void {
    this.loading = true;
    this.showAdminNav = false;
    this.showLoad = false;
    this.uploadedContent = this.excelData.split('\n');
    this.bulkapi_response = [];
    this.bulkUploadInProgress = true;
    this.cartService.BulkItemToCart(this.uploadedContent).subscribe(
      (data) => {
        this.excelData = '';
        this.showBulk = !this.showBulk;
        if (data.length) {

          for (let i = 0; i < data.length; i++) {
            const item_obj = { message: data[i] };
            this.bulkapi_response.push(item_obj);
          }
        } else {
          const item_obj = { message: 'Items imported to cart' };
          this.bulkUploadHasErrorAndSucess = true;
          this.topService.loading = false;
          this.showloadString = '';
          this.bulkUploadInProgress = false;
          this.bulkapi_response.push(item_obj);
        }
      },
      (error) => {
        this.loading = false;
        this.showBulk = !this.showBulk;
        this.excelData = '';
        this.errors = error;
        this.bulkUploadInProgress = false;
        if (this.errors.status === 400) {
          this.bulkUploadHasErrorAndSucess = false;
          this.disableContinueButton();
          const item_obj = { message: JSON.stringify(this.errors.error) };
          this.bulkUploadInProgress = false;
          this.bulkapi_response.push(item_obj);
          this.topService.loading = false;
          this.showloadString = '';
        }
      },
      () => {
        this.excelData = '';
        this.showBulk = !this.showBulk;
        this.bulkUploadHasErrorAndSucess = true;
        this.bulkUploadInProgress = false;
        this.isResponse200 = true;
      }
    );
  }

  continueLogicallyAfterButlAttempt(): void {
    // if bulk upload has success and error
    if (this.bulkUploadHasErrorAndSucess) {
      this.showLoad = false;
      this.showBulkErr = !this.showBulkErr;
      this.hideAdminMenu = true;
      this.showBulk = false;
      this.sharedService.cartData = true;
      this.router.navigate(['cart']);
    } else {
      this.showLoad = false;
      this.disableContinueButton();
    }
  }

  disableContinueButton() {
    return this.disabledClassApplied = true;
  }

  checkBulkDataLength(): boolean {
    return !this.bulkUploadFieldData.length;
  }

  getCustomers(): void {

    this.topService.loading = true;
    this.customersLoading = true;
    this.userService.getCustomers(this.user_role).subscribe(
      (data) => {
        data.customers.map((x) => {
          x['bpCodeAndCompanyName'] = `${x.bpCode} - ${x.companyName}`;
          return x;
        });
        this.salesCustomers = data;
        this.customersLoading = false;
        this.topService.loading = false;
        this.userService.bpCode = this.sessionService.userEmail;
        this.currentUserEmail = this.userService.bpCode;
        this.selectedCustomer = this.sessionService.selectedCustomer;

        if (this.selectedCustomer) {
          this.setNewCustomer(this.selectedCustomer,this.sessionService.partnerId, false);
        }
      },
      (err) => {
        this.topService.loading = false;
        this.customersLoading = false;
        this.sharedService.handleBuyerHttpError(err, this.topErrorModal);
      }
    );
  }

  searchItems(value?: string): void {
    this.topService.searchString = value;
    // close search
    this.topService.isSearchActive = false;
    // reset to show whole list next time
    this.userFilterService.filterListByName('');
    this.topService.searchString = this.topService.searchString?.trim();
    if (!this.topService.searchString) {
      this.clearSearch();
    } else {
      this.searchService.searchString();
      this.itemsService.isLandingPage = false;
    }
  }

  isKeyEnter(event: KeyboardEvent, value: string): void {
    this.userFilterService.filterListByName(value);
    if (event.key === 'Enter' && value.length > 0) {
      this.topService.searchString = value;
      this.clearCharactersWhenSearchOrder(value);
      if (value === '') {
        this.clearSearch();
      } else {
        this.itemFilterService.selectedManufacturer = undefined;
        this.searchItems(value);
      }
    }
  }

  clearCharactersWhenSearchOrder(value: string): void {
    if (this.user_role !== 'BUYER' && value && value.substr(0, 1) === '#') {
      this.topService.searchString = '#' + value.substr(1).replace(/\D+/g, '');
    }
  }

  closeDelCart(): void {
    this.animateOut = true;
    setTimeout(() => {
      this.showDelCart = false;
      this.animateOut = false;
    }, 200);
  }

  clearSearch(): void {
    this.topService.searchString = '';
  }

  getCurrentCustomer(): string {
    if (this.userService.currentCustomer) {
      return this.userService.currentCustomer.split(" ")[0];
    }
  }

  chooseCustomer(selected): void {
    if (this.gotFirst === 0) {
      this.gotFirst = 1;
      this.userService.currentCustomer = selected.bpCode;
      this.resetDefault();
      this.setNewCustomer(selected.bpCode, selected.partnerId, true);
      const that = this;
      setTimeout(function () { that.gotFirst = 0; }, 200);
    } else {
      this.gotFirst = 0;
    }
  }

  showPastPurchase() {
    return (this.user_role === 'BUYER'
      || (this.selectedCustomer && (this.user_role === 'SALES' || this.user_role === 'SUPER')));
  }

  setNewCustomer(selectedCustCode: string, selectedCustId: string, resetSession: boolean): void {
    this.sessionService.selectedCustomer = selectedCustCode;
    this.sessionService.partnerId = selectedCustId;
    this.selectedCustomer = selectedCustCode;
    const selected_value = selectedCustCode.split('-');
    const custCode = selected_value[0];
    this.topService.loading = true;

    if (custCode) {
      this.userService.sendSelectedCustomerToAPI(custCode).subscribe(
        () => {
          if (resetSession) {
            this.sessionService.removeSessionItem('selected_order_number');
            this.sessionService.removeSessionItem('selected_doc_number');
            this.cartService.cartList = [];
            this.sessionService.selectedOrderNumber = '';
            this.sessionService.selectedDocNumber = '';
            this.sharedService.editOrderMode = false;
            this.sessionService.isCustomerSelected = 'YES';

            this.pubSubService.sharedSubject.next({ name: SharedSource.changeCustomer, data: selectedCustCode });
          }
          this.topService.isCustomerSelected = true;
          this.topService.loading = true;
          this.showAdminNav = false;
          this.offersService.selectedOffer = this.offersService.getEmptyOfferState();
          this.offersService.getOffersSummary().subscribe(
            (offerSummary: IOffersSummary)=>{
              this.offersService.offersInitialized=false;
              if (this.router.url.includes('offers') && !this.router.url.includes('live_offers')) {
                this.location.replaceState('user/offers');
              }              
              this.pubSubService.sharedSubject.next({ name: SharedSource.sessionSwitchedAndSummaryReady, data: true });
            },
            (err) => { console.log('Error trying to get offers summary: ', err);
          })

          this.userService.getProfile(true).subscribe(
            (profile) => {
              if (!profile.isCreditCardAllowed && this.router.url === '/user/credit_cards') {
                this.router.navigate(['/buyer/home']);
              }
              if (profile.discount !== null) {
                this.userService.discount = profile.discount;
              } else {
                this.userService.discount = 0;
              }
              this.topService.hasOpenOrders = profile.hasOpenOrders;
              this.userService.companyName = profile.companyName;
              this.userService.bpCode = profile.bpCode;
              this.userService.contactEmail = profile.contact.email;
              this.userService.billing = profile.billingAddresses;
              if (profile.billingAddresses.length > 0) {
                this.userService.billingDefault = profile.billingAddresses.find(a => a.isDefault);
                if (!this.userService.billingDefault) {
                  this.userService.billingDefault = profile.billingAddresses[0];
                }
              } else {
                this.userService.resetBilling();
              }

              if (profile.shippingAddresses.length > 0) {
                this.userService.shippingDefault = profile.shippingAddresses.find(a => a.isDefault);
                if (!this.userService.billingDefault) {
                  this.userService.billingDefault = profile.billingAddresses[0];
                }
              } else {
                this.userService.resetShipping();
              }

              this.userService.shipping = profile.shippingAddresses;
              this.userService.terms = profile.terms;
              this.userService.orderBankInfo = profile.beneficiaryBank;
              if (profile.isCreditCardAllowed !== null || profile.isCreditCardAllowed !== undefined) {
                this.userService.isCreditCardAllowed = profile.isCreditCardAllowed;
                // sessionStorage.setItem('is_credit_card_allowed', profile.isCreditCardAllowed.toString());
              }
              this.topService.loading = false;
              this.cartService.getCart();
            },
            (err) => {
              this.topService.loading = false;
              this.sharedService.handleBuyerHttpError(err, this.topErrorModal);
            });
        },
        (err) => {
          this.topService.loading = false;
          this.sharedService.handleBuyerHttpError(err, this.topErrorModal);
        });
    }
  }

  onCancelBulkUpload(): void {
    this.hideAdminMenu = true;
    this.topService.searchString = '';
    this.showLoad = false;
    this.showBulk = false;
  }

  onActivateBulkUpload(): void {
    this.hideAdminMenu = false;
    this.showBulk = this.excelData && this.excelData.trim().length ? true : false;
  }

  notificationCenter() {
    // this.router.navigate(['notifications']);
    this.itemsService.selectedTopAttribute = 'Notification Center';
    this.itemsService.isNotificationCenter = true;
    this.itemsService.isPastPurchase = false;
    this.itemsService.isJustSoldUrl = false;

    // this will turn off the highlight of just sold in top categories to default since it is not chosen
    this.itemsService.notificationService.highlight = false;

    // this is necessary so that no top categories is highlighted when we are on the notification center page
    this.leftCategoriesService.parentNavName = '';
    this.leftCategoriesService.childNavName = '';

    this.topService.searchString = '';
  }

  isNotOnCatalog(): boolean {
    return this.router.url === '/user/orders' || this.router.url === '/user/profile' || this.router.url === '/buyer/cart' ||
      this.router.url.startsWith('/buyer/full-order');
  }

  clickLogoToReload(): void {
    if (this.itemsService.itemsSubscription) {
      this.itemsService.itemsSubscription.unsubscribe();
    }
    this.hideSearch();
    this.sharedService.navId = null;
    this.itemsService.isPastPurchase = false;
    this.itemsService.isJustSoldUrl = false;
    this.itemsService.isLandingPage = true;
    this.itemsService.isNotificationCenter = false;

    this.itemsService.notificationService.highlight = false;

    this.leftCategoriesService.parentNavName = '';
    this.leftCategoriesService.childNavName = '';

    this.clearSearch();

    this.router.navigate([`${this.userService.getUrlByRole()}/home`]);
  }

  onClickedOutside(e: Event): void {
    this.showLoad = false;
    const element = e.srcElement as Element;
    if (element.className && (element.className.toString() === 'select2-search__field'
      || element.className.toString() === 'select2-results__option select2-results__message')) {
      return;
    } else {

    }
  }

  hideAdminNav(e: Event): void {
    this.showAdminNav = false;
  }

  onClickedOutsideMenu(e: Event): void {
    this.showMobileMenu = false;
  }

  showAllCustomersChoosen(event): void {
    this.userService.canSeeAllCustomers = event.currentTarget.checked;
    this.getCustomers();
  }

  resetDefault(): void {
    this.userService.billingDefault = {
      addressCode: '',
      isDefault: false,
      address1: '',
      address2: '',
      city: '',
      state: '',
      country: '',
      zip: ''
    };
    this.userService.shippingDefault = {
      addressCode: '',
      isDefault: false,
      address1: '',
      address2: '',
      city: '',
      state: '',
      country: '',
      zip: ''
    };
  }

  checkForSearchErr(): boolean {
    return this.searchService.showErr;
  }

  closeSearchErr(): void {
    this.searchService.showErr = false;
  }

  showErrMessage(): string {
    if (this.searchService.showErr) {
      return this.searchService.errorMessage;
    }
  }

  showErrTitle(): string {
    if (this.searchService.showErr) {
      return this.searchService.orderDocNumber;
    }
  }

  getSalesText(): string {
    if (this.selectedCustomer && this.userService.bpCode !== this.sessionService.userEmail) {
      return 'Account - ' + this.userService.bpCode;
    } else {
      return this.currentUserEmail;
    }
  }

  isAuthenticated(): boolean {
    return this.authService.isAuthenticated();
  }

  showError(errorModal: models.IBuyerErrorModal): void {
    this.topErrorModal = errorModal;
  }

  hideError(): void {
    this.topErrorModal.isShown = false;
  }

  uploadContent(): void {
    this.showBulkErr = true;
    this.showLoad = false;
    this.closeDelCart();
    this.getUploadedContent();
  }

  displayBulk(flag: boolean): void {
    this.showBulk2 = flag;
  }

  onCloseDelCart(): void {
    this.closeDelCart();
    this.showLoad = !this.showLoad;
    this.onCancelBulkUpload();
  }

  getCounters() {
    this._notificationSvcBuyer.getHeaderCounters().subscribe(
      (data) => {
        this.newNotificationCounter = data.priceDropNewTotal + data.waitListNewTotal + data.moreInventoryNewTotal;
      }
    );
  }

  getPriceList(): void {
    this.topService.getPriceList();
  }

  showSearch(): void {
    if (!this.userFilterService.userFilterList?.length) {
      this.userFilterService.getUserFilters();
    }
    this.topService.isSearchActive = true;
    this.topSearch.nativeElement.value = '';
    setTimeout(() => {
      this.topSearch.nativeElement.focus();
    }, 0);
  }

  clickOutside(event) {
    // clicking the btn to open the search
    if (event && event.target && event.target.alt === 'Search') {
      return;
    }

    this.hideSearch();
  }

  hideSearch(): void {
    this.topService.isSearchActive = false;
  }

  outlets() {
    this.router.navigate([`${this.userService.getUrlByRole(true)}/outlets/`]);
  }

  toggleCategories(isVisible: boolean): void {
    this.isCategoriesOpened = isVisible;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
