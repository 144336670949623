import { SharedSource } from './../../core/shared-source';
import { PubSubService } from './../../core/pubsub.service';
import { element } from 'protractor';
import { Component, OnInit, Input, Output, EventEmitter, forwardRef, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { FullOfferStatusEnum } from '../enums/enum';
import { Router } from '@angular/router';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { NgIf, NgFor } from '@angular/common';


@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DropdownComponent),
            multi: true
        }
    ],
    standalone: true,
    imports: [NgIf, NgClickOutsideDirective, NgFor]
})
export class DropdownComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() items: any[];
  @Input() fieldToShow: string;
  @Input() fieldToSave: string;
  @Input() label: string;
  @Input() disabled: boolean;
  @Output() onSelect = new EventEmitter();
  @Input() defaultTitle: string;
  @Input() enableFilter: boolean;
  @Input() isFromBuyerPortal: boolean;
  @Input() showCostPopup: boolean;
  @Input() hideSelectedValueFromOptions: boolean;
  @Input() showInRed: boolean;
  @Input() muteClick: boolean;
  @Input() counterFieldForDropdownOption: string; // field that will be concatenated at the end of each option of the dropdown. Ex: Option1 (25)
  @Input() titlePrefix = '';
  @Input() darkTheme: boolean;
  @Input() whiteBorderedTheme: boolean;
  @Input() offersSelect: boolean = false;
  @Input() displayCounterInSelectedTitle = false;

  @ViewChild('inputFilter') inputFilter: ElementRef;

  itemsCopy: any[];
  expanded = false;
  selectedTitle = '';
  selectedValue = '';

  sufix = '';
  firstTimeFiltering = true;
  selectedValueBelongToFilteredList = true;
  showNoResultsFound = false;
  subscriptions: Subscription[] = [];

  constructor(private elemR: ElementRef,
    private pubSubService: PubSubService,
    private router: Router) { 
      this.setSubscriptions();
    }

  ngOnInit() {
    this.sufix = this.getSufixForId();
  }

  ngAfterViewInit() {
    if (this.enableFilter) {
      fromEvent(this.inputFilter.nativeElement, 'keyup')
        .pipe(
          filter(Boolean),
          debounceTime(250),
          distinctUntilChanged(),
          tap((text) => {
            this.filter();
          })
        )
        .subscribe();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setSubscriptions() {
    this.subscriptions.push(
      this.pubSubService.sharedSubject.subscribe(
        myEvent => {
          if (myEvent.name === SharedSource.closeAllDropdowns) {
            this.expanded = false;
          } else if (myEvent.name === SharedSource.updateOffersByStatusDropdown && this.router.url.match(/offers/) && this.offersSelect) {
            this.writeValue(myEvent.data as FullOfferStatusEnum)
          }
        })
    );
  }

  toggleDropdown() {
    if (this.disabled || this.muteClick) {
      return;
    }
    this.expanded = !this.expanded;
  }

  onSelectItem(item: any, e?: Event) {
    if (item.dropdownOptionDisabled && e) {
      e.stopPropagation();
      return;
    }
    this.resetFilter();
    if (this.selectedValue === item[this.fieldToSave]) {
      return;
    }

    const oldValue = this.selectedValue;
    this.selectedTitle = item[this.fieldToShow];
    this.selectedValue = item[this.fieldToSave];
    this.emitChanges(); // to make "the 2 way data binding" work

    item.oldValue = oldValue;
    this.onSelect.emit(item);
  }

  getTitle(): string {
    const selected = this.items.find(x => x[this.fieldToSave] === this.selectedValue);
    if (selected) {
      const counterLabel = (this.displayCounterInSelectedTitle) ? ` ${this.getCustomDropdownOption(selected)}` : '';
      this.selectedTitle = selected[this.fieldToShow] + counterLabel;
    } else {
      return this.defaultTitle;
    }
    return this.selectedTitle;
  }

  onChange = (_: any) => { };

  emitChanges() {
    this.onChange(this.selectedValue);
  }

  writeValue(val: any): void {
    this.selectedValue = val;
    const selected = this.items.find(x => x[this.fieldToSave] === this.selectedValue);
    if (selected) {
      this.selectedTitle = selected[this.fieldToShow];
    }
    this.emitChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void { }


  // FILTER
  getSufixForId(): string {
    return Math.random()   // Generate random number, eg: 0.123456
      .toString(36) // Convert  to base-36 : "0.4fzyo82mvyr"
      .slice(-4);   // Cut off last 4 characters : "mvyr"
  }

  stopPropagation(e: Event): void {
    e.stopPropagation();
  }

  filter() {
    if (!this.enableFilter) {
      return;
    }

    if (this.firstTimeFiltering) { // need to be here because array can be empty at the begining "onInit"
      this.itemsCopy = _.cloneDeep(this.items);
      this.firstTimeFiltering = false;
    }
    this.items = _.cloneDeep(this.itemsCopy);
    let filterValue = this.elemR.nativeElement.querySelector(`#ddf_${this.sufix}`).value;

    if (filterValue && filterValue.length) {
      filterValue = filterValue.trim();
    }

    // filtering the list but keeping the selected value in the list to be able to show the selected value in the dropdown
    this.items = this.items.filter(x =>
      x[this.fieldToSave] === this.selectedValue ||
      x[this.fieldToShow].toLowerCase().includes(filterValue.toLowerCase()));

    // show the current selected value just in case belongs to the filtered list
    const filteredList = this.itemsCopy.filter(x => x[this.fieldToShow].toLowerCase().includes(filterValue.toLowerCase()));
    const selected = filteredList.find(x => x[this.fieldToSave] === this.selectedValue);
    this.selectedValueBelongToFilteredList = selected ? true : false;

    // Show no results found
    if (!this.selectedValue) {
      this.showNoResultsFound = !this.items.length ? true : false;
    } else {
      if (this.selectedValueBelongToFilteredList) {
        this.showNoResultsFound = !this.items.length ? true : false;
      } else {
        this.showNoResultsFound = !(this.items.length - 1) ? true : false;
      }
    }
  }


  onKeyupEnter(e) {
    if (!e.target.value || !this.enableFilter) {
      return;
    }
    if ((!this.selectedValue && this.items.length) || (this.selectedValue && this.selectedValueBelongToFilteredList)) {
      this.onSelectItem(this.items[0]);
      this.expanded = false;
    }

    if (this.selectedValue && !this.selectedValueBelongToFilteredList) {
      const filteredListWithoutTheSelected = this.items.filter(x => x[this.fieldToSave] !== this.selectedValue);
      if (filteredListWithoutTheSelected.length) {
        this.onSelectItem(filteredListWithoutTheSelected[0]);
        this.expanded = false;
      }
    }
  }

  onKeydownEsc(e) {
    if (!this.enableFilter) {
      return;
    }
    this.resetFilter();
    this.expanded = false;
  }

  resetFilter() {
    if (!this.enableFilter) {
      return;
    }
    const filterInput = this.elemR.nativeElement.querySelector(`#ddf_${this.sufix}`);
    if (filterInput.value) {
      this.showNoResultsFound = false;
      filterInput.value = '';
      this.selectedValueBelongToFilteredList = true;
      this.items = _.cloneDeep(this.itemsCopy);
    }
  }

  setFocusToFilter() {
    this.resetFilter();
    if (!this.expanded && this.enableFilter) {
      setTimeout(() => { this.elemR.nativeElement.querySelector(`#ddf_${this.sufix}`).focus(); });
    }
  }

  onClickOutside() {
    this.expanded = false;
    this.resetFilter();
  }

  getCustomDropdownOption(item): string {
    if (!this.counterFieldForDropdownOption || !item[this.counterFieldForDropdownOption] || item[this.counterFieldForDropdownOption] === null) {
      return '';
    }
    return `(${item[this.counterFieldForDropdownOption]})`
  }

}
