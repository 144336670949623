import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StaticRoutingModule } from './static-routing.module';
import { AboutUsComponent } from './about-us/about-us.component';
import { SharedModule } from '../shared/shared.module';
import { FaqComponent } from './faq/faq.component';
import { FooterComponent } from './footer/footer.component';
import { CustomerApplicationComponent } from './customer-application/customer-application.component';
import { VendorApplicationComponent } from './vendor-application/vendor-application.component';
import { ContactSupportComponent } from './contact-support/contact-support.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { FormsModule } from '@angular/forms';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { StaticHtmlComponent } from './static-html/static-html.component';


@NgModule({
    imports: [
        CommonModule,
        StaticRoutingModule,
        SharedModule,
        FormsModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        AboutUsComponent,
        FaqComponent,
        FooterComponent,
        CustomerApplicationComponent,
        VendorApplicationComponent,
        ContactSupportComponent,
        TermsOfUseComponent,
        PrivacyPolicyComponent,
        ThankYouComponent,
        StaticHtmlComponent
    ]
})
export class StaticModule { }
