<div class="comment-wrapper">
  <div class="comment animated" [class.zoomIn]="showInfoModal" (clickOutside)="closeCommentModal()"
    [clickOutsideEvents]="'click'" [exclude]="'.close, .item-icon-btn'" [excludeBeforeClick]="true">
    <div class="comment-header">
      <h3>More Info</h3>
    </div>
    <div class="comment-body">
      <form [formGroup]="newCommentsFG">
        <div class="comment-container" formArrayName="commentsArray">
          <ng-container *ngFor="let comment of comments; let i = index" [ngStyle]="i!==0 && {'margin-top': '30px'}"
            [formGroupName]="i">
            <div class="comment-input">
              <label for="caption">Caption</label>
              <input type="text" formControlName="caption" id="caption">
            </div>
            <div class="comment-input">
              <label for="comment">Comment</label>
              <textarea rows="4" formControlName="comment" id="comment"></textarea>
            </div>
          </ng-container>
        </div>
      </form>
    </div>
    <div class="comment-footer">
      <button type="button" class="comment-footer__button cancel" (click)="closeCommentModal()">Cancel</button>
      <button type="button" class="comment-footer__button save" (click)="patchComment()">Save</button>
    </div>
  </div>
</div>