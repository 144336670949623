import { Component, OnInit, Input, EventEmitter, Output, HostListener } from '@angular/core';
import { Router } from '@angular/router';

import { VendorService } from '../../../vendor.service';
import { IPo } from '../../../interface/IPo';
import { IVendorEditShipmentPO } from '../../../interface/IVendorEditShipmentPo';
import { NgClass, TitleCasePipe, CurrencyPipe, DatePipe } from '@angular/common';

@Component({
    selector: 'app-po-line',
    templateUrl: './po-line.component.html',
    styleUrls: ['./po-line.component.scss'],
    standalone: true,
    imports: [NgClass, TitleCasePipe, CurrencyPipe, DatePipe]
})
export class PoLineComponent implements OnInit {
  @Input() poLine: IPo;
  @Output() showModal = new EventEmitter<IVendorEditShipmentPO>();
  @Input() highlight: boolean;

  constructor() { }

  ngOnInit() { }

  showTracking(event) {
    event.stopPropagation();
    this.showModal.emit();
  }

  getTrackingButtonTitle(): string {
    if (this.poLine.orderStatus === 'open') {
      if (this.shippingInformationEmpty()) {
        return 'Add Tracking';
      } else {
        return this.poLine.isOnHover ? 'Edit Shipped' : 'Shipped';
      }
    } else {
      return this.poLine.isOnHover ? 'Edit Shipped' : 'Shipped';
    }
  }

  shippingInformationEmpty(): boolean {
    if (!this.poLine.shippingInformation.trackingNumber
      || !this.poLine.shippingInformation.carrier
      || !this.poLine.shippingInformation.eta
      || !this.poLine.shippingInformation.shippingDate
    ) {
      return true;
    }
    return false;
  }

}
