import { Component, OnInit, Input } from '@angular/core';
import { IDashboardProductSelling } from '../../interface/IDashboardProductSelling';
import { DashboardService } from '../dashboard.service';

@Component({
    selector: 'app-top-sales',
    templateUrl: './top-sales.component.html',
    styleUrls: ['./top-sales.component.scss'],
    standalone: true
})
export class TopSalesComponent implements OnInit {
  @Input() topSellingProducts: IDashboardProductSelling[];

  constructor(private dashboardService: DashboardService) { }

  ngOnInit() {
  }
}
