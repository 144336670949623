import { switchMap } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { Component, OnInit, AfterContentInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { OrdersService } from './orders.service';
import { CartService } from '../../cart/cart.service';
import { TopService } from '../../../shared/services/top.service';
import { UserService } from '../../../user/user.service';
import { IOrder } from '../../interfaces/model';
import { SharedService } from '../../../shared/shared.service';
import { SharedSource } from '../../../core/shared-source';
import { PubSubService } from '../../../core/pubsub.service';
import { SessionService } from '../../../services/session.service';
import * as models from '../../interfaces/model';
import { OrderStatusEnum } from '../../enums/orderStatusEnum';
import { CustomCurrencyPipe } from '../../../shared/pipes/currency.pipe';
import { ErrorModalComponent } from '../../../shared/ui-components/error-modal/error-modal.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgIf, NgFor, NgClass, DatePipe } from '@angular/common';

@Component({
    selector: 'app-orders',
    templateUrl: './orders.component.html',
    styleUrls: ['./orders.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, NgClass, PaginationModule, ErrorModalComponent, DatePipe, CustomCurrencyPipe]
})
export class OrdersComponent implements OnInit, AfterContentInit, OnDestroy {
  upDateOrderConfirmation: boolean;
  orders: models.Order[] = [];
  showModal = false;
  editOrderPopUpMessage: string;
  cancelOrderPopUpMessage: string;
  cancelError = false;
  showCancelModal = false;
  animateOut = false;
  animateIn = false;
  user_role: string;
  orderLoaded = false;
  docEntry = -1;
  showChargeOrder = false;
  showChargedOrder = false;
  chargingOrder: IOrder = null;
  sharedSubcription: Subscription;
  showEmpty = false;
  pagination = {
    currentPage: 1,
    pageSize: 25,
    totalCount: 0,
    totalPages: 5
  }
  paginationRequest$ = new Subject();
  OrderStatusEnum = OrderStatusEnum;
  salesOrderStatusArray = [];
  
  defaultStatusColor = '#E7E7E7';
  statusColorMap: { [key in models.SalesOrderStatus]: { color: string; description: string } } = {
    [models.SalesOrderStatus.PendingPayment]: { color: '#EB3E43', description: 'Pending payment' },
    [models.SalesOrderStatus.PaymentApproved]: { color: '#F37428', description: 'Payment Approved' },
    [models.SalesOrderStatus.ProcessingTransit]: { color: '#FBA50F', description: 'Processing Transit' },
    [models.SalesOrderStatus.ProcessingStock]: { color: '#E9C30C', description: 'Processing Stock' },
    [models.SalesOrderStatus.Processing]: { color: '#B4CC28', description: 'Processing' },
    [models.SalesOrderStatus.Ready]: { color: '#7ED342', description: 'Ready' },
    [models.SalesOrderStatus.Shipped]: { color: '#47A645', description: 'Shipped' }
  };

  constructor(
    private router: Router,
    public ordersService: OrdersService,
    public topService: TopService,
    private sharedService: SharedService,
    private userService: UserService,
    private cartService: CartService,
    private pubSubService: PubSubService,
    public sessionService: SessionService
  ) {
    const enumLength = Object.keys(models.SalesOrderStatus).length / 2; 
    this.salesOrderStatusArray = Array.from({ length: enumLength }, (_, i) => i);
   }

  ngOnInit(): void {
    this.prepareToLoadOrderHistory();
    this.sharedService.isLogoutPage = false;
    this.sharedSubcription = this.pubSubService.sharedSubject.subscribe(
      mySharedValues => {

        if (mySharedValues.name === SharedSource.changeCustomer) {
          this.ordersService.orderLoaded2 = false;
          this.user_role = this.sessionService.userRole;
          this.loadOrderHistory();
        }
      },
      error => {
      }
    );

    this.user_role = this.sessionService.userRole;
    if (this.user_role === 'BUYER' || (['SALES', 'SUPER'].includes(this.user_role)
      && this.sessionService.isCustomerSelected === 'YES')) {
      this.loadOrderHistory();
    } else {
      this.showEmpty = true;
    }
  }

  ngAfterContentInit() {
    this.topService.showSearch = true;
    this.topService.isCatalog = false;
  }

  ngOnDestroy(): void {
    this.sharedSubcription.unsubscribe();
    this.paginationRequest$.unsubscribe();
  }

  pageChanged(event) {
    const queryString = '?PageNumber='+ event.page +'&PageSize='+ event.itemsPerPage;
    this.loadOrderHistory(queryString);
  }

  prepareToLoadOrderHistory() {
    this.paginationRequest$.pipe(
      switchMap((query: string) => this.ordersService.getOrderList(query))
    )
      .subscribe((data: models.OrderListResponse) => {
        if (data) {
          this.pagination = {
            currentPage: data.pagination.currentPage, 
            pageSize: data.pagination.pageSize,
            totalCount: data.pagination.totalCount,
            totalPages: data.pagination.totalPages,
          };
          this.pagination.totalPages = 5;
        }

        if (!data || data.values.length === 0) {
          this.sharedService.editOrderMode = false;
          this.sharedService.noOrders = true;
        }
        this.orders = [];
        this.orderLoaded = true;
        if (data && data.values && data.values.length > 0) {
          this.ordersService.orders = Object.assign([], data.values);
          this.orders = Object.assign([], data.values);
          this.sharedService.noOrders = false;
        }

        this.showEmpty = true;
        this.topService.loading = false;
        if (this.showCancelModal) {
          this.closeCancelConfirm();
        }
      },
        error => {
          this.topService.loading = false;
          this.ordersService.orderLoaded2 = true;
          this.orders = [];
          if (error.status === 400) {
            this.cartService.erroronline = true;
          }
          this.sharedService.handleBuyerHttpError(error, this.ordersService.ordersErrorModal);
        });
  }

  loadOrderHistory(queryStr?: string): void {
    this.showEmpty = false;
    this.topService.loading = true;

    this.paginationRequest$.next(queryStr);
  }

  gotoShopping(): void {
    this.router.navigate(['buyer/home']);
  }


  openPdf(event: Event, order: models.IOrder): void {
    this.topService.loading = true;
    this.userService.getProfile().subscribe(profile => {
      this.userService.billing = profile.billingAddresses;
      this.userService.shipping = profile.shippingAddresses;
      this.userService.companyName = profile.companyName;
      this.userService.bpCode = profile.bpCode;
      this.userService.orderBankInfo = profile.beneficiaryBank;
      for (let i = 0; i < this.userService.billing.length; i++) {
        if (this.userService.billing[i].isDefault) {
          if (this.userService.billing[i].address2 === null) {
            this.userService.billing[i].address2 = '';
          }
          if (this.userService.billing[i].country === null) {
            this.userService.billing[i].country = '';
          }
        }
      }

      this.ordersService.getOrderDetails(order.docEntry.toString()).subscribe(
        (orderData) => {
          this.ordersService.orderChosen = orderData;
          this.ordersService.generateOrderPdf();
          this.topService.loading = false;
        },
        (err) => {
          this.topService.loading = false;
          this.sharedService.handleBuyerHttpError(err, this.ordersService.ordersErrorModal);
        });

      event.stopPropagation();
    },
      error => {
        this.topService.loading = false;
        this.sharedService.handleBuyerHttpError(error, this.ordersService.ordersErrorModal);
      });
  }

  closeModal(): void {
    this.animateIn = false;
    this.animateOut = true;
    setTimeout(() => {
      this.showModal = false;
      this.animateOut = false;
    }, 400);
  }

  openModal(): void {
    this.showModal = true;
    this.animateIn = true;
  }

  cancelConfirm(order: IOrder): void {
    this.docEntry = order.docEntry;
    this.showCancelModal = true;
    this.animateIn = true;
    this.animateOut = false;
    this.cancelError = false;
    event.stopPropagation();
  }

  cancelOrderYes(): void {
    this.topService.loading = true;
    this.ordersService.cancelOrder(this.docEntry).subscribe(
      () => {
        this.loadOrderHistory();
        // this.closeCancelConfirm();
      },
      (err) => {
        this.loadOrderHistory();
        this.cancelError = true;
      }
    );
  }

  closeCancelConfirm(): void {
    this.animateIn = false;
    this.animateOut = true;
    setTimeout(() => {
      this.showCancelModal = false;
      this.animateOut = false;
    }, 400);
  }

  popUpTextMessages(): string {
    if (this.showModal) {
      return this.editOrderPopUpMessage = 'To edit an order, your shopping cart will be cleared.';
    } else if (this.showCancelModal) {
      if (this.cancelError) {
        return this.cancelOrderPopUpMessage = 'You cannot cancel this order';
      } else {
        return this.cancelOrderPopUpMessage = 'Are you sure you want to CANCEL this order?';
      }
    }
  }

  uncLockMe(): void {
    this.sessionService.removeSessionItem('selected_order_number');
    this.sessionService.removeSessionItem('selected_doc_number');
    this.cartService.cartList = [];
    this.ordersService.orderLoaded2 = false;
    this.cartService.deleteCart().subscribe(
      () => {
        this.cartService.comment = '';
        this.sharedService.editOrderMode = false;
        this.loadOrderHistory();
      },
      (err) => {
        this.topService.loading = false;
        this.sharedService.handleBuyerHttpError(err, this.ordersService.ordersErrorModal);
      });
  }

  convertItemsToExportedJson(): any[] {
    const resultJson: any[] = [];
    this.ordersService.orders.forEach(order => {
      const date = new Date(order.orderDate);
      resultJson.push({
        'Order Entry': order.docEntry,
        'Order Number': order.docNumber,
        'Date': date.getDay() + '/' + date.getMonth() + '/' + date.getFullYear(),
        'Status': order.orderStatus,
        'Discount Amt': order.orderDiscountAmount ? '$' + order.orderDiscountAmount : 'N/A',
        'Discount %': order.orderDiscountPercent ? order.orderDiscountPercent + '%' : 'N/A',
        'Total': order.orderTotal ? '$' + order.orderTotal : 'N/A'
      });
    });

    return resultJson;
  }

  splitOrder(order: models.IOrder): void {
    this.topService.loading = true;
    this.ordersService.getOrderDetails(order.docEntry.toString()).subscribe(
      (orderData) => {
        this.topService.loading = false;
        this.ordersService.orderChosen = orderData;
        this.router.navigate(['/user/split', order.docEntry]);
      },
      (err) => {
        this.topService.loading = false;
        this.sharedService.handleBuyerHttpError(err, this.ordersService.ordersErrorModal);
      }
    );
    event.stopPropagation();
  }

  updateOrderConfirmDialog(order): void {
    this.ordersService.selected_doc_number = order.docNumber;
    this.ordersService.selected_order_number = order.docEntry;
    this.ordersService.handleFirstOrderLoadRequired = true;
    this.openModal();
    this.sessionService.selectedOrderNumber = this.ordersService.selected_order_number.toString();
    this.sessionService.selectedDocNumber = this.ordersService.selected_doc_number.toString();
    this.upDateOrderConfirmation = true;
    event.stopPropagation();
  }

  updateOrder(): void {
    this.closeModal();
    this.topService.loading = true;
    this.ordersService.updateOrder(this.ordersService.selected_order_number).subscribe(
      () => {
        this.sharedService.editOrderMode = true;
        this.router.navigate(['cart'], { queryParams: { updatingOrder: 'Y' } });
      },
      (err) => {
        this.topService.loading = false;
        this.sharedService.handleBuyerHttpError(err, this.ordersService.ordersErrorModal);
      }
    );
  }

  showError(errorModal: models.IBuyerErrorModal): void {
    this.ordersService.ordersErrorModal = errorModal;
  }

  hideError(): void {
    this.ordersService.ordersErrorModal.isShown = false;
  }

  showChargeModal(order: IOrder, event = null) {
    this.chargingOrder = order;
    this.showChargeOrder = true;
    event.stopPropagation();
  }

  chargeOrder(): void {
    if (this.user_role === 'SUPER') {
      this.topService.loading = true;
      this.ordersService.chargeOrder(this.chargingOrder.payment.id, this.chargingOrder.orderTotal).subscribe(
        (res) => {
          this.topService.loading = false;
          this.showChargeOrder = false;
          if (res.error) {
            const modalData = {
              bodyText: res.error.message,
              isShown: true,
            };
            this.showError(modalData);
            return;
          }
          this.showChargedOrder = true;
          const orderIndex = this.orders.indexOf(this.orders.find((e) => e.docEntry === this.chargingOrder.docEntry));
          this.orders[orderIndex].payment.status = 'Applied';
          this.ordersService.orders[orderIndex].payment.status = 'Applied';
        }
      );
    }
  }

  closeChargedModal() {
    this.chargingOrder = null;
    this.showChargedOrder = false;
  }

}
