import { Component, OnInit } from '@angular/core';
import { GuestTopComponent } from '../guest-top.component';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgClickOutsideDirective } from 'ng-click-outside2';

@Component({
    selector: 'app-guest-top-mobile',
    templateUrl: './guest-top-mobile.component.html',
    styleUrls: ['./guest-top-mobile.component.scss'],
    standalone: true,
    imports: [NgClickOutsideDirective, FormsModule, RouterLinkActive, RouterLink]
})
export class GuestTopMobileComponent extends GuestTopComponent implements OnInit {
  isClearBtnActive = false;

  clearSearchMobile(): void {
    this.topService.searchString = '';
    this.isShowClearBtn();
  }

  isShowClearBtn(value?: string): void {
    if (value && value.length > 0) {
      this.isClearBtnActive = true;
      this.topService.searchString = value;
    } else {
      this.isClearBtnActive = false;
    }
  }

  onSearchClicked() {
    if (this.topService.isSearchActive) {
      this.searchItems(this.topService.searchString);
      this.isShowClearBtn();
    } else {
      this.showSearch();
      this.isShowClearBtn();
    }
  }

}
