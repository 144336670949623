import { TopService } from './../../../shared/services/top.service';
import { PubSubService } from './../../../core/pubsub.service';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  LiveOfferCounters,
  LiveOfferFilter,
  LiveOfferStateEnum,
  SortingCriteriaEnum,
} from './../../../shared/interfaces/ILiveOffer';
import {
  Component,
  effect,
  EventEmitter,
  HostListener,
  inject,
  Input,
  model,
  OnDestroy,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { DropdownComponent } from './../../../shared/dropdown/dropdown.component';
import * as models from '../../../buyer/interfaces/model';
import { debounceTime, filter, pipe, Subject, takeUntil, tap } from 'rxjs';
import { BuyerOffersService } from '../buyer-offers.service';
import { CommonModule } from '@angular/common';
import { NgClickOutsideDirective } from 'ng-click-outside2';

@Component({
  selector: 'app-offers-filter',
  standalone: true,
  imports: [DropdownComponent, ReactiveFormsModule, CommonModule, NgClickOutsideDirective],
  templateUrl: './offers-filter.component.html',
  styleUrl: './offers-filter.component.scss',
})
export class OffersFilterComponent implements OnInit, OnDestroy {
  @Input() set counters(counters: LiveOfferCounters) {
    this.offerCountersSignal.set(counters);
  }
  resetFilters = model(false);

  @Output() stateChange = new EventEmitter<LiveOfferStateEnum>();
  @Output() filtersChange = new EventEmitter<LiveOfferFilter>();
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    this.sortBySKUIsHidden = this.innerWidth > 1270 ? false : true;

    if (this.sortBySKUIsHidden) {
      if (this.filtersForm.get('sortBySKU').value && this.filtersForm.get('sortingCriteria').value !== '6') {
        this.filtersForm.get('sortingCriteria')?.setValue('6')
      }
    } else {
      if (this.filtersForm.get('sortBySKU').value && this.filtersForm.get('sortingCriteria').value) {
        this.filtersForm.get('sortingCriteria')?.reset()
      }
    }
  }

  public buyerOffersService = inject(BuyerOffersService);
  private formBuilder = inject(FormBuilder);
  public pubSubService = inject(PubSubService);
  private sortBySKUIsHidden = false;
  private innerWidth = 0;
  private searched = false;
  private searchLoaded = false;

  private destroy$ = new Subject<void>();
  LiveOfferStateEnum = LiveOfferStateEnum;
  selectedStateSignal = this.buyerOffersService.selectedStateSignal;
  protected offerCountersSignal = signal<LiveOfferCounters>({
    all: 0,
    live: 0,
    counteroffers: 0,
    accepted: 0,
    canceled: 0,
    expired: 0
  });

  liveOffersCounterSignal = this.buyerOffersService.liveOffersCounterSignal;

  sorting: models.AttributeValue[];
  filtersForm: FormGroup;

  constructor(public topService: TopService) {
    effect(() => {
      if (this.resetFilters()) {
        this.clearFilters();
        this.resetFilters.set(false);
      }
    }, {
      allowSignalWrites: true
    })
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.sortBySKUIsHidden = this.innerWidth > 1270 ? false : true;
    this.sorting = this.generateSortingArray();
    this.filtersForm = this.generateFiltersForm();
    this.listenFiltersChange();
    this.searchLoaded = false;
  }

  selectState(state: LiveOfferStateEnum): void {
    if (!this.offerCountersSignal()[state]) {
      return;
    }
    this.stateChange.emit(state);
  }

  searchOffers(e: any) {
    e.preventDefault();

    this.searched = true;
    this.searchLoaded = true;

    this.buyerOffersService.selectedStateSignal.set(LiveOfferStateEnum.All);
    this.emitFiltersChange();
  }

  clearSearchInput(focusOnSearch = false) {
    if ((this.filtersForm.get('searchQuery')?.value && !this.searched) || focusOnSearch) {
      this.filtersForm.get('searchQuery')?.setValue('');
      if (focusOnSearch) {
        const inputElement = document.querySelector('input[name="offers-search"]') as HTMLInputElement;
        inputElement?.focus();
      }
    }
  }

  private clearFilters() {
    this.filtersForm.reset();
    this.emitFiltersChange();
  }

  private generateFiltersForm() {
    return this.formBuilder.group({
      sortBySKU: [false],
      sortingCriteria: [0],
      searchQuery: [''],
    });
  }

  private generateSortingArray() {
    const sortingCriteria = Object.keys(SortingCriteriaEnum)
      .filter((key) => isNaN(Number(key)))
      .map((key, index) => {
        return {
          value: index.toString(),
          name: SortingCriteriaEnum[key as keyof typeof SortingCriteriaEnum],
        };
      });

    return sortingCriteria;
  }

  private listenFiltersChange() {
    const commonPipe = () => pipe(
      takeUntil(this.destroy$),
      filter((value) => value !== null),
    );

    this.filtersForm.get('sortBySKU')?.valueChanges
      .pipe(commonPipe())
      .subscribe(() => {
        !this.sortBySKUIsHidden && this.filtersForm.get('sortingCriteria')?.reset(null, { emitEvent: false, onlySelf: true });
        this.emitFiltersChange();
      });

    this.filtersForm.get('sortingCriteria')?.valueChanges
      .pipe(commonPipe())
      .subscribe((value) => {
        if (this.sortBySKUIsHidden && value === '6') {
          this.filtersForm.get('sortBySKU')?.setValue(true);
          return;
        }
        this.filtersForm.get('sortBySKU')?.reset(null, { emitEvent: false, onlySelf: true });
        this.emitFiltersChange();
      });

    this.filtersForm.get('searchQuery')?.valueChanges
      .pipe(
        commonPipe()
      )
      .subscribe((value: string) => {
        if (value.length > 0) {
          this.searched = false;
        } else {
          if (this.searchLoaded) {
            this.emitFiltersChange();
          }
          this.searchLoaded = false;
        }
      })
  }

  private emitFiltersChange() {
    const searchQuery = this.filtersForm.get('searchQuery')?.value;
    const sortBySKU = this.filtersForm.get('sortBySKU')?.value;
    const sortingCriteria = this.filtersForm.get('sortingCriteria')?.value;

    const filters: LiveOfferFilter = {
      ...(searchQuery ? { searchQuery } : {}),
      ...(sortingCriteria ? { sortingCriteria: Number(sortingCriteria) } : {}),
    };

    if (sortBySKU) {
      filters.sortingCriteria = 6
    } else if (!sortBySKU && !sortingCriteria) {
      filters.sortingCriteria = 0
    }

    this.filtersChange.emit(filters);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    if (!this.topService.isMobile) {
      this.buyerOffersService.selectedStateSignal.set(LiveOfferStateEnum.Live);
    }
  }
}
