import { Component, OnInit } from "@angular/core";
import { TopVendorComponent } from "../top-vendor.component";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { NgIf } from "@angular/common";
import { RouterLinkActive, RouterLink } from "@angular/router";

@Component({
    selector: "app-top-vendor-mobile",
    templateUrl: "./top-vendor-mobile.component.html",
    styleUrls: ["./top-vendor-mobile.component.scss"],
    standalone: true,
    imports: [
        RouterLinkActive,
        RouterLink,
        NgIf,
    ],
})
export class TopVendorMobileComponent extends TopVendorComponent implements OnInit {
  
  navToggle: boolean = false;
  unseenEventsCount$: Observable<number>;

  ngOnInit(): void {

  }

  toggleNav(e:Event) {
    if (e.isTrusted) {
      this.navToggle = !this.navToggle;
      if (this.navToggle) {
        document.documentElement.classList.add('is-modal');
      } else {
        document.documentElement.classList.remove('is-modal');
      }
    } else {
      return;
    }
  }

  closeNav() {
    this.navToggle = false;
    document.documentElement.classList.remove('is-modal');
  }

}
