<div class="filter-wrap _today-deals height-crop" (swipedown)="close()">
	<div class="filter-wrap-header">
		<div class="filter-top">
			<app-offers-breadcrumbs-mobile [counters]="buyerOffersService.liveOfferListingSignal().counters">
			</app-offers-breadcrumbs-mobile>
		</div>
		<i class="filter-wrap-header__close" (click)="close()">
			<img src="assets/images/icons/mobile/menu/m-close-white.svg" width="24" height="24" alt="close">
		</i>
	</div>

	
	<form>
		<div class="filter-wrap-footer">
			<div class="filter-wrap-footer-label">
				<span>Offers</span>
			</div>
			<div class="filter-wrap-footer__top">
				<div class="filter-btm-item">
					<button 
		              class="filter-button" 
					  [class.active]="localSelectedState === LiveOfferStateEnum.All"
					  [disabled]="offerCountersSignal()?.all === 0"
					  (click)="selectStateLocally(LiveOfferStateEnum.All, $event)">
		              All 
		              <span>{{ offerCountersSignal()?.all }}</span>
		            </button>
				</div>
				<div class="filter-btm-item">
					<button 
		              class="filter-button" 
		              [class.active]="localSelectedState === LiveOfferStateEnum.Live"
              		  [disabled]="offerCountersSignal()?.live === 0"
              		  (click)="selectStateLocally(LiveOfferStateEnum.Live, $event)">
		              Live 
		              <span>{{ offerCountersSignal()?.live}}</span>
		            </button>
				</div>
				<div class="filter-btm-item">
					<button 
		              class="filter-button" 
		              [class.active]="localSelectedState === LiveOfferStateEnum.Counteroffers"
              		  [disabled]="offerCountersSignal()?.counteroffers === 0"
                      (click)="selectStateLocally(LiveOfferStateEnum.Counteroffers, $event)">
		              Counteroffers
		              <span>{{ offerCountersSignal()?.counteroffers }}</span>
		            </button>
				</div>
				<div class="filter-btm-item">
					<button 
		              class="filter-button" 
		              [class.active]="localSelectedState === LiveOfferStateEnum.Accepted"
             		  [disabled]="offerCountersSignal()?.accepted === 0"
                      (click)="selectStateLocally(LiveOfferStateEnum.Accepted, $event)">
		              Accepted
		              <span>{{ offerCountersSignal()?.accepted }}</span>
		            </button>
				</div>
				<div class="filter-btm-item">
					<button 
		              class="filter-button" 
		              [class.active]="localSelectedState === LiveOfferStateEnum.Canceled"
              		  [disabled]="offerCountersSignal()?.canceled === 0"
                      (click)="selectStateLocally(LiveOfferStateEnum.Canceled, $event)">
		              Canceled
		              <span>{{ offerCountersSignal()?.canceled }}</span>
		            </button>
				</div>
				<div class="filter-btm-item">
					<button 
		              class="filter-button" 
		              [class.active]="localSelectedState === LiveOfferStateEnum.Expired"
             		  [disabled]="offerCountersSignal()?.expired === 0"
                      (click)="selectStateLocally(LiveOfferStateEnum.Expired, $event)">
		              Expired
		              <span>{{ offerCountersSignal()?.expired }}</span>
		            </button>
				</div>
			</div>

			
			<div class="filter-wrap-footer-label">
				<span>Filters</span>
			</div>
			<div class="filter-wrap-footer__top">
				<div class="filter-btm-item">
					<label class="filter-checkbox">
						<span class="filter-checkbox__label">Sort by SKU</span>
						<input type="checkbox" [(ngModel)]="sortBySKU" (change)="onCheckboxChange()" name="sortBySKU" >
						<i class="filter-checkbox__icon"></i>
					</label>
				</div>
				<div class="filter-btm-item">
					<select class="filter-select" [disabled]="false"  (change)="onSelectChange()"  [(ngModel)]="selectedValue"  name="sortOptions" >
						<option [ngValue]="null">Select Sorting</option> 
						<option 
						*ngFor="let option of options" 
						[ngValue]="option.value" 
						[ngClass]="{'selected-option': selectedValue === option.value}">
						{{ option.label }}
					  </option>
					</select>
				</div>
			</div>
			<div class="filter-wrap-footer__btm">
				<button type="button" class="filter-btn-clear" (click)="reset()">Clear All</button>
				<button type="button" class="filter-btn-apply" (click)="close()">
					<img src="assets/images/icons/mobile/24-check-white.svg" alt="check">
				</button>
			</div>
		</div>
	</form>
</div>
