import {Component, OnInit, ViewChild} from '@angular/core';
import { CarouselComponent, CarouselModule } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-notification-center-slider',
    templateUrl: './notification-center-slider.component.html',
    styleUrls: ['./notification-center-slider.component.scss'],
    standalone: true,
    imports: [CarouselModule]
})
export class NotificationCenterSliderComponent implements OnInit {

  private _timestamp_mousewheel = 0;

  @ViewChild('owlSlider', { static: true }) owlSlider: CarouselComponent;

  constructor() {
  }

  ngOnInit() {
  }

  onSliderMousewheel(event: WheelEvent): void {
    event.preventDefault();
    const date = new Date();
    if ((date.getTime() - this._timestamp_mousewheel) > 700) {
      this._timestamp_mousewheel = date.getTime();
      if (event.deltaY > 0.5) {
        // this.owlSlider.next([400]);
        this.owlSlider.next();
      } else if (event.deltaY < -0.5) {
        // this.owlSlider.previous([400]);
        this.owlSlider.prev();
      }
    }

  }

}
