import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-detailed-item-preloader',
    templateUrl: './detailed-item-preloader.component.html',
    styleUrls: ['./detailed-item-preloader.component.scss'],
    standalone: true
})
export class DetailedItemPreloaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
