import { Component, OnInit } from '@angular/core';
import { TopService } from '../../services/top.service';
import { BiddeskBannerService } from './biddesk-banner.service';
import { BiddeskPopupComponent } from './biddesk-popup/biddesk-popup.component';
import { NgIf } from '@angular/common';


@Component({
    selector: 'app-biddesk-banner',
    templateUrl: './biddesk-banner.component.html',
    styleUrls: ['./biddesk-banner.component.scss'],
    standalone: true,
    imports: [NgIf, BiddeskPopupComponent]
})
export class BiddeskBannerComponent implements OnInit {

  
  constructor(public topService: TopService,
    public biddeskBannerService: BiddeskBannerService) { }

  ngOnInit(): void {
  }









}

