<div class="notification-center-updates">
  <h2 class="notification-center-updates__title">Updates</h2>
  <div class="notification-center-updates-slider">
    <owl-carousel-o
      [options]="{items: 1, dots: false, nav: true, margin: 0, autoWidth: true, smartSpeed: 400}"
      #owlSlider (wheel)="onSliderMousewheel($event)"
    >
      <div carouselSlide class="notification-center-updates-slider-item _notification-center">
        <span class="notification-center-updates-slider-item__date">03/03/2019</span>
        <h3 class="notification-center-updates-slider-item__title">Notification Center</h3>
        <p class="notification-center-updates-slider-item__desc">How it helps to make purchases.</p>
      </div>
      <div carouselSlide class="notification-center-updates-slider-item _grading-guide">
        <span class="notification-center-updates-slider-item__date">03/03/2019</span>
        <h3 class="notification-center-updates-slider-item__title">Grading Guide</h3>
        <p class="notification-center-updates-slider-item__desc">for iPhones</p>
      </div>
      <div carouselSlide class="notification-center-updates-slider-item _crystal-box">
        <span class="notification-center-updates-slider-item__date">03/03/2019</span>
        <h3 class="notification-center-updates-slider-item__title">Crystal Boxe</h3>
        <p class="notification-center-updates-slider-item__desc">Highlights each phone you buy!</p>
      </div>
      <div carouselSlide class="notification-center-updates-slider-item _just-launched">
        <span class="notification-center-updates-slider-item__date">03/03/2019</span>
        <h3 class="notification-center-updates-slider-item__title">Just Launched</h3>
        <p class="notification-center-updates-slider-item__desc">Mobile Phones</p>
      </div>
      <div carouselSlide class="notification-center-updates-slider-item _boxes-1">
        <span class="notification-center-updates-slider-item__date">03/03/2019</span>
        <h3 class="notification-center-updates-slider-item__title">Boxes 1</h3>
        <p class="notification-center-updates-slider-item__desc">Mobile Phones</p>
      </div>
      <div carouselSlide class="notification-center-updates-slider-item _boxes-2">
        <span class="notification-center-updates-slider-item__date">03/03/2019</span>
        <h3 class="notification-center-updates-slider-item__title">Boxes 2</h3>
        <p class="notification-center-updates-slider-item__desc">Mobile Phones</p>
      </div>
      <div carouselSlide class="notification-center-updates-slider-item _boxes-3">
        <span class="notification-center-updates-slider-item__date">03/03/2019</span>
        <h3 class="notification-center-updates-slider-item__title">Boxes 3</h3>
        <p class="notification-center-updates-slider-item__desc">Mobile Phones</p>
      </div>
    </owl-carousel-o>
  </div>
</div>
