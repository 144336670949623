import { Component, OnInit } from '@angular/core';
import { PastPurchaseTitleComponent } from '../past-purchase-title.component';
import { NgIf, CurrencyPipe, DatePipe } from '@angular/common';
@Component({
    selector: 'app-past-purchase-title-mobile',
    templateUrl: './past-purchase-title-mobile.component.html',
    styleUrls: ['./past-purchase-title-mobile.component.scss'],
    standalone: true,
    imports: [NgIf, CurrencyPipe, DatePipe]
})
export class PastPurchaseTitleMobileComponent extends PastPurchaseTitleComponent implements OnInit {}
