import { Component, Input, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';

import { VendorService } from '../vendor.service';
import { ILookupMain } from '../interface/ILookupMain';
import { IVendorItemAttributes } from '../interface/IVendorItemAttributes';
import { IVendorItemInfo } from '../interface/IVendorItemInfo';
import { IVendorStaticInfo } from '../interface/IVendorStaticInfo';
import { IVendorDropDownData } from '../interface/IvendorDropDownData';
import { Globals } from '../shared/globals';
import { IVendorItemPrice } from '../interface/IVendorItemPrice';
import { environment } from '../../../environments/environment';
import { StatusPipe } from '../shared/custom-pipes/vendor-status.pipe';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { OnlyDigitsDirective } from '../../shared/directives/only-digits.directive';
import { FormsModule } from '@angular/forms';
import { NgIf, NgClass, NgFor, DatePipe } from '@angular/common';

@Component({
    selector: 'app-edit-item-detailed',
    templateUrl: './edit-item-detailed.component.html',
    styleUrls: ['./edit-item-detailed.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass, FormsModule, NgFor, OnlyDigitsDirective, TooltipModule, DatePipe, StatusPipe]
})
export class EditItemDetailedComponent implements OnInit {
  @Input() itemId: string;
  @Input() isAll: boolean;
  @Input() item: IVendorItemInfo;
  @Input() dropDowns: IVendorStaticInfo;
  @Input() condition: ILookupMain = {};
  @Input() warranty: ILookupMain = {};
  @Input() packaging: ILookupMain = {};
  @Input() restrictions: ILookupMain = {};
  @Output() updateItem = new EventEmitter<IVendorItemInfo>();
  @Output() deleteItem = new EventEmitter();
  @Output() undoChange = new EventEmitter<IVendorItemInfo>();
  @Output() invalidDataEmitter = new EventEmitter<null>();
  @Output() updateSingleItemEmitter = new EventEmitter<null>();
  condVal: IVendorItemAttributes = {};
  warrantyVal: IVendorItemAttributes = {};
  packagingVal: IVendorItemAttributes = {};
  restrictionsVal: IVendorItemAttributes = {};
  statusVal: number;
  businessStatusDropdown: IVendorDropDownData[] = [];
  skuDropDown: IVendorDropDownData[] = [];
  loader = false;
  environment = environment;

  constructor(public vendorService: VendorService) {
  }

  ngOnInit() {
    this.setUpItemData();
  }

  /**
   * Retrieves item's info from server when this gets called from pending PO.
   */
  setUpItemData(): void {
    if (!this.item) {
      this.getItemData();
    } else {
      this.setDropdownsValues();
    }
  }

  /**
   * Gets the item's data from the server and if in pending po page, it checks if the item exists or not.
   */
  getItemData(): void {
    // get all item's information
    this.vendorService.getVendorItemById([this.itemId]).subscribe(
      (data) => {
        if (data['items'].length === 0) {
          this.invalidDataEmitter.emit();
          return;
        }
        if (this.doesItemExist()) {
          this.item = this.vendorService.pendingSoldUpdatedItems.filter(item => item.id === this.itemId)[0];
          this.vendorService.editedItemCopy = Globals.jsonStringifyParser(data['items'][0]);
        } else {
          this.item = data['items'][0];
          this.vendorService.editedItemCopy = Globals.jsonStringifyParser(data['items'][0]);
        }

        this.setDropdownsValues();
      },
      (err) => {
      }
    );
  }

  doesItemExist(): boolean {
    return this.vendorService.pendingSoldUpdatedItems.filter(item => item.id === this.itemId).length > 0;
  }

  /**
   * Sets up the item's dropdown values in the detailed version as well as the status dropdowns and the item's prices.
   */
  setDropdownsValues(): void {
    if (this.item.attributes.filter(condition => condition.label === 'Condition').length === 1) {
      this.condVal = this.item.attributes.filter(condition => condition.label === 'Condition')[0];
    }

    if (this.item.attributes.filter(condition => condition.label === 'Warranty').length === 1) {
      this.warrantyVal = this.item.attributes.filter(condition => condition.label === 'Warranty')[0];
    }

    if (this.item.attributes.filter(condition => condition.label === 'Packaging').length === 1) {
      this.packagingVal = this.item.attributes.filter(condition => condition.label === 'Packaging')[0];
    }

    if (this.item.attributes.filter(condition => condition.label === 'Restrictions').length === 1) {
      this.restrictionsVal = this.item.attributes.filter(condition => condition.label === 'Restrictions')[0];
    } else {
      this.restrictionsVal = { code: '01037EFFB7' };
    }

    if (this.item.attributes.filter(condition => condition.label === 'Restrictions').length === 1) {
      this.restrictionsVal = this.item.attributes.filter(condition => condition.label === 'Restrictions')[0];
    } else {
      this.restrictionsVal = { code: '01037EFFB7' };
    }
    if (this.vendorService.isUser(['SELLER'])) {
          this.skuDropDown = this.dropDowns.skuStatus
              .filter(status => status.text !== 'All'   && status.text !== 'Killed'  && status.text !== 'Inactive'  && status.text !== 'Out of Stock' );
    } else  {
          this.skuDropDown = this.dropDowns.skuStatus.filter(status => status.text !== 'All'  && status.text !== 'Out of Stock');
    }

    this.businessStatusDropdown = this.dropDowns.businessStatus.filter(status => status.text !== 'All');
    this.item.prices = this.item.prices.slice(0, 3);
    this.item.prices = this.removeZeroDqs();
  }

  undoItemUpdates(e: Event): void {
    e.stopPropagation();
    this.item.updatedNow = false;
    this.undoChange.emit(this.item);
  }

  /**
   * Removes the 0 Discount quantities from the item so we don't show them on the screen
   */
  removeZeroDqs(): IVendorItemPrice[] {
    let prices: IVendorItemPrice[] = [];
    this.item.prices.forEach(price => {
      prices.push({fromQty: price.fromQty ? price.fromQty : null, unitPrice: price.unitPrice ? price.unitPrice : null});
    });

    return prices;
  }

  itemCodeUpdated(): void {
    this.item.errors && this.item.errors.errors && this.item.errors.errors.length ? this.item.showError =
      false : this.item.showError = true;
    this.updateItem.emit(this.item);
  }

  priceUpdated(): void {
    this.item.errors && this.item.errors.errors && this.item.errors.errors.length ? this.item.showError =
      false : this.item.showError = true;

    if (!this.item.unitPrice) {
      this.item.unitPrice = 0;
    }

    this.updateItem.emit(this.item);
  }

  descriptionUpdated(event): void {
    this.item.errors && this.item.errors.errors && this.item.errors.errors.length ? this.item.showError =
      false : this.item.showError = true;

    this.updateItem.emit(this.item);
  }

  costUpdated(): void {
    this.item.errors && this.item.errors.errors && this.item.errors.errors.length ? this.item.showError =
      false : this.item.showError = true;

    if (!this.item.cost) {
      this.item.cost = 0;
    }

    this.item.cost = Number(this.item.cost);

    this.updateItem.emit(this.item);
  }

  isFieldDisabled(): boolean {
    return this.item.seller.name === 'Laptop Plaza' || this.item.seller.name === 'HUBX';
  }

  inventoryUpdated(): void {
    this.item.errors && this.item.errors.errors && this.item.errors.errors.length ? this.item.showError =
      false : this.item.showError = true;

    if (!this.item.inventoryInfo.currentInventory.availableToSell) {
      this.item.inventoryInfo.currentInventory.availableToSell = 0;
    }

    if (this.item.inventoryInfo.currentInventory.availableToSell > 0 &&  this.item.status.skuStatus === 'Out of Stock') {
      this.item.status.skuStatus  =  this.item.status.previousSkuStatus;
    }
    this.updateItem.emit(this.item);
  }

  // TODO
  onStatusChange(event: any): void {
    this.item.errors && this.item.errors.errors && this.item.errors.errors.length ? this.item.showError =
      false : this.item.showError = true;

    this.updateItem.emit(this.item);
  }

  businessStatusUpdated(): void {
    this.item.errors && this.item.errors.errors && this.item.errors.errors.length ? this.item.showError =
      false : this.item.showError = true;

    this.updateItem.emit(this.item);
  }

  delete(item: IVendorItemInfo): void {
    this.deleteItem.emit(item.id);
  }

  updateSingleItem(event: Event): void {
    if (this.item.updatedNow) {
      this.item.updatedNow = false;
      this.updateSingleItemEmitter.emit();
    }
  }

  isPromoActive(item: IVendorItemInfo, promoLabel: string): boolean {
    return item.attributes.filter(attr => attr.label === promoLabel).length > 0;
  }

  getStatusValue(text: string): number {
    return 0;
    // return this.dropDowns.vendorStatus.filter(item => item.text === text)[0].value;
  }

  updateAttributeSelected(attributeLabel: string): void {
    switch (attributeLabel) {
      case 'Condition': {
        this.updateAttributeObject(this.condVal, this.condition);
        break;
      }
      case 'Warranty': {
        this.updateAttributeObject(this.warrantyVal, this.warranty);
        break;
      }
      case 'Packaging': {
        this.updateAttributeObject(this.packagingVal, this.packaging);
        break;
      }
      case 'Restrictions': {
        this.updateAttributeObject(this.restrictionsVal, this.restrictions);
        break;
      }
      default: {
        // console.log('Error: unknown attribute name');
      }
    }
  }

  // attributeLookUpList parameter is the attribute's Object that contains that attribute's ID, Name(label), and all of its options
  updateAttributeObject(attribute: IVendorItemAttributes, attributeLookUpList: ILookupMain): void {
    for (let i = 0; i < this.item.attributes.length; i++) {
      if (this.item.attributes[i].label === attribute.label) {
        this.item.attributes[i] = attribute;
        return;
      }
    }

    // if the above didnt return that means the attribute doesn't exist so it needs to
    // be pushed in to the attribute array
    this.item.attributes.push({
      code: attribute.code,
      label: attributeLookUpList.name,
      value: attributeLookUpList.values.filter(val => val.code === attribute.code)[0].value,
      iconUri: attribute.iconUri,
      description: attribute.description,
      attrId: attributeLookUpList.id
    });
  }
}
