import { Component, OnInit, Input } from '@angular/core';
import { IItem } from '../interfaces/IItem';
import { IItemAttribute } from '../interfaces/model';
import { CurrentDealPipe } from '../../shared/pipes/current-deal.pipe';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'app-item-attributes-mobile',
    templateUrl: './item-attributes-mobile.component.html',
    styleUrls: ['./item-attributes-mobile.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf, CurrentDealPipe]
})
export class ItemAttributesMobileComponent implements OnInit {
  @Input() item: IItem;
  @Input() hideText: boolean;
  @Input() moreThanOnHand: boolean;
  @Input() isCart: boolean;
  @Input() openMobileInfo: string;

  constructor() { }

  ngOnInit() {
  }

  getVisibleAttributes(): IItemAttribute[] {
    return this.item.attributes.filter(a => !a.hide);
  }

  isSingleIconShown(attr: IItemAttribute): boolean {
    return attr.label === 'Warranty' || attr.label === 'Packaging' || attr.label === 'Condition' || attr.label === 'Restrictions';
  }
}
