import { Injectable } from '@angular/core';
import { VendorService } from '../vendor.service';
import { IVendorItemInfo } from '../interface/IVendorItemInfo';
import { AbstractControl, ValidationErrors, FormGroup, UntypedFormArray, FormBuilder, Validators } from '@angular/forms';
import { ItemErrorCodesEnum } from '../enums/item-error-codes';
import { INameId } from '../interface/INameId';
import { IVendorItemAttributes } from '../interface/IVendorItemAttributes';
import { IVendorItemComment } from '../interface/IVendorItemComment';
import { IVendorItemPrice } from '../interface/IVendorItemPrice';
import { ICostReductionRule } from '../interface/ICostReductionRule';
import { ListingSkuStatusNamesEnum, ListingSkuStatusValuesEnum } from '../enums/listing-sku-status';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ItemService {
  statusesByRole = [];
  restrictionsFilters: INameId[] = [
    {id: 'yes', name: 'Yes'},
    {id: 'no', name: 'No'}
  ];
  costReductionRule: ICostReductionRule[];
  vendorManagerMap: Map<string, string>;

  constructor(
    private vendorService: VendorService,
    public fb: FormBuilder,
    private http: HttpClient) {
      this.getCostReductionRules();
    }

  generateItemFormGroup(i: IVendorItemInfo ) {
    return this.fb.group(
      {
        id: i.id,
        reactivationThreshold: i.reactivationThreshold,
        initialCost: i.initialCost || i.cost,
        vendorItemCode: {value: i.vendorItemCode, disabled: true},
        status_skuStatus : i.status.skuStatus,
        description: {value: i.description, disabled: (this.vendorService.isSeller)},
        exwPointValue: i.exwPointValue,
        inventory_availableToSell: i.inventoryInfo.currentInventory.availableToSell,
        eta: i.leadTimeDays,
        prices: this.getPricesArrayControls(i.prices),
        moq: {value: i.moq, disabled: (this.vendorService.isSeller)},
        mxq: {value: i.mxq, disabled: (this.vendorService.isSeller)}, 
        cost: [i.cost, [Validators.required]],
        unitPrice: i.unitPrice,
        // for item detail
        hubxItemCode: i.hubxItemCode,
        condition: {
          value: (i.mappedAttributes.condition) ? i.mappedAttributes.condition.code : '',
          disabled: this.vendorService.isSeller
        },
        warranty: {
          value: (i.mappedAttributes.warranty) ? i.mappedAttributes.warranty.code : '',
          disabled: this.vendorService.isSeller
        },
        packaging: {
          value: (i.mappedAttributes.packaging) ? i.mappedAttributes.packaging.code : '',
          disabled: this.vendorService.isSeller
        },
        restrictions: {
          value: (i.mappedAttributes.restrictions) ? i.mappedAttributes.restrictions.code : '',
          disabled: this.vendorService.isSeller
        },
        restrictionDescription: {
          value: (i.mappedAttributes.restrictions) ? i.mappedAttributes.restrictions.description : '',
          disabled: this.vendorService.isSeller
        },
        // comments
        comments: this.getCommentsArrayControls(i.comments),
        // attributes
        attributes: this.getAttrArrayControls(i.attributes),
        reactivationCheckBox: false
      });
  }

  getPricesArrayControls(prices: IVendorItemPrice[]) {
    const resArray: UntypedFormArray = this.fb.array([]);

    for (let i = 0; i < 3; i++) {
      resArray.push(this.fb.group({
        fromQty: {value: (prices[i]) ? prices[i].fromQty : null, disabled: (this.vendorService.isSeller)},
        unitPrice: {value: (prices[i]) ? prices[i].unitPrice : null, disabled: (this.vendorService.isSeller)}
      }));
    }

    return resArray;
  }

  getCommentsArrayControls(comments: IVendorItemComment[]) {
    const resArray: UntypedFormArray = this.fb.array([]);

    for (let i = 0; i < (comments.length || 1); i++) {
      resArray.push(this.fb.group({
        caption: { value: (comments[i]) ? comments[i].caption : 'Special Notes', disabled: true },
        comment: { value: (comments[i]) ? comments[i].comment : '', disabled: this.vendorService.isSeller }
      }));
    }

    return resArray;
  }

  getAttrArrayControls(attributes: IVendorItemAttributes[]) {
    const resArray: UntypedFormArray = this.fb.array([]);

    attributes.forEach(a => {
      resArray.push(this.fb.group({
        attrId: {value: a.attrId},
        code: {value: a.code},
        description: {value: a.description},
        iconUri: {value: a.iconUri},
        label: {value: a.label},
        value: {value: a.value},
      }));
    });

    return resArray;
  }

  validateForm(form: FormGroup): ValidationErrors {
    const itemForm = form.getRawValue();
    itemForm.cost = Number(itemForm.cost);
    itemForm.moq = itemForm.moq !== '' ? Number(itemForm.moq) : null;
    itemForm.mxq = itemForm.mxq !== '' ? Number(itemForm.mxq) : null;

    // validating cost
    if (!itemForm.cost) {
      return { errorMsg: 'Cost cannot be zero', errorCode: ItemErrorCodesEnum.zeroCostError };
    }

    if ( this.vendorService.isSeller && itemForm.cost) {
      if (itemForm.cost > itemForm.initialCost) {
        return { errorMsg: `Cost must be less than ${itemForm.initialCost}`, errorCode: ItemErrorCodesEnum.invalidCost };
      }

      if (itemForm.reactivationThreshold &&
        [ListingSkuStatusValuesEnum.removed, ListingSkuStatusValuesEnum.all].includes(this.vendorService.search.filters.skuStatus) &&
        itemForm.cost > itemForm.reactivationThreshold &&
        itemForm.status_skuStatus === ListingSkuStatusNamesEnum.active) {
        return { errorMsg: `In order to reactivate, cost must be less than ${itemForm.reactivationThreshold}`,
        errorCode: ItemErrorCodesEnum.invalidCost };
      }

    }

    // if the control is disabled the value wont be available, on form.value.unitPrice
    // so we get it like this
    const itemUnitPrice = form.controls['unitPrice'].value;
    //validate unitPrice
    if(this.vendorService.isAdmin || this.vendorService.isVendorManager)
    {
      if(!itemUnitPrice || itemUnitPrice <= 0){
        return { errorMsg: 'Price cannot be zero', errorCode: ItemErrorCodesEnum.zeroPriceError };
      }
      if(itemUnitPrice < itemForm.cost){
        return { errorMsg: 'Price cannot be less than Cost',errorCode: ItemErrorCodesEnum.invalidUnitPrice};
      }
    }

    // validating mxq
    if ((this.vendorService.isAdmin || this.vendorService.isVendorManager) && ( (itemForm.mxq && itemForm.mxq > 0) &&
        (itemForm.moq && itemForm.moq > 0) &&
        (itemForm.moq > itemForm.mxq) )) {
      return { errorMsg: 'MOQ Cannot be greater than MXQ.', errorCode: ItemErrorCodesEnum.invalidMinQty };
    }

    // validating prices
    const prices = itemForm.prices;
    if ( prices && (this.vendorService.isAdmin || this.vendorService.isVendorManager)) {


      let error;
      prices.some((priceRule, i) => {
        priceRule = {
          fromQty: Number(priceRule.fromQty),
          unitPrice: Number(priceRule.unitPrice)
        };
        if (priceRule.fromQty && priceRule.fromQty < 2) {
          error = {
            errorMsg: `'From Qty' needs to be greater than 1.`,
            errorCode: ItemErrorCodesEnum.invalidQtyForDiscount,
            controlName: i
          };
        }
        if ((priceRule.fromQty && priceRule.fromQty > 0 && !priceRule.unitPrice) ||
          (priceRule.unitPrice && priceRule.unitPrice > 0 && !priceRule.fromQty)) {
          error = {
            errorMsg: `'From Qty' and 'Unit Price' fields are required to set up a discount.`,
            errorCode: ItemErrorCodesEnum.invalidDiscount,
            controlName: i
          };
        }
        if (priceRule.unitPrice >= itemUnitPrice) {
          error = {
            errorMsg: 'Discount price can not exceed or equal to the price.',
            errorCode: ItemErrorCodesEnum.invalidDiscountVsPrice,
            controlName: i
          };
        }
        return error;
      });

      if (error) {
        return error;
      }
    }

    return null;
  }

  mapItem(item: IVendorItemInfo ) {
    item.prices = item.prices.filter(p => p.fromQty > 1);
    const length = item.prices.length >= 3 ? 3 : item.prices.length;
    item.prices = item.prices.slice(0, length);
    if (!item.moq) { item.moq = 1; }

    // converting attributes to object form so it's easier to access in template
    item.mappedAttributes = {};
    if (item.attributes.filter(condition => condition.label === 'Condition').length === 1) {
      item.mappedAttributes.condition = item.attributes.filter(condition => condition.label === 'Condition')[0];
    }
    if (item.attributes.filter(condition => condition.label === 'Warranty').length === 1) {
      item.mappedAttributes.warranty = item.attributes.filter(condition => condition.label === 'Warranty')[0];
    }
    if (item.attributes.filter(condition => condition.label === 'Packaging').length === 1) {
      item.mappedAttributes.packaging = item.attributes.filter(condition => condition.label === 'Packaging')[0];
    }
    if (item.attributes.filter(condition => condition.label === 'Restrictions').length === 1) {
      item.mappedAttributes.restrictions = item.attributes.filter(condition => condition.label === 'Restrictions')[0];
    } else {
      item.mappedAttributes.restrictions = { code: null, description: '' };
    }
    return item;
  }

  hasTwoDecimals(event, value) {
    if (value && value.toString().indexOf('.') !== -1) {
      const arr = value.toString().split('.');
      if (arr.length > 1 && arr[1].length === 2) {
        if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105)) {
          event.preventDefault();
        }
      }
    }
  }

  getCostReductionRules() {
    if (!this.costReductionRule?.length) {
      this.http.get(environment.vendorPortalBaseUrl + 'VendorItem/reductionRules' )
      .subscribe((redRules) => {
        this.costReductionRule = redRules as ICostReductionRule[] || [];
      })
    }
  }

  getVendorManagerDiccionary() {
    this.vendorManagerMap = new Map();
    this.http.get(environment.adminBaseUrl + 'businessPartners/manager')
    .subscribe((diccionary) => {
      if (diccionary) {
        this.vendorManagerMap = new Map(Object.entries(diccionary));
      }
    });
  }

}
