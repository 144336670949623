import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-po-item-preloader',
    templateUrl: './po-item-preloader.component.html',
    styleUrls: ['./po-item-preloader.component.scss'],
    standalone: true
})
export class PoItemPreloaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
