import { Component, OnInit, Input, Output, EventEmitter, HostListener, ChangeDetectionStrategy } from '@angular/core';
import * as models from '../../buyer/interfaces/model';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-waitlist-tooltip',
    templateUrl: './waitlist-tooltip.component.html',
    styleUrls: ['./waitlist-tooltip.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf]
})
export class WaitlistTooltipComponent implements OnInit {
  @Input() item: models.IItem;
  @Input() showWaitlistTooltip;
  @Input() tooltipPosition:Event;
  @Output() onTooltipClose = new EventEmitter<null>();

  buyerHome: HTMLElement;
  leftTooltip = 0;
  topTooltip = 0;

   constructor() { }

  ngOnInit() {
  	this.calculatePosition();
  }

  calculatePosition() {
  	if (!this.tooltipPosition) {
  		return;
  	}
  	const btn = (this.tooltipPosition.target as HTMLElement).getBoundingClientRect();
  	const left = btn.left;
  	const top = btn.top;
    if (btn.width === 123) {
      this.leftTooltip = left - 192;
      this.topTooltip = top - 157;
    } else {
      this.leftTooltip = left - 153;
      this.topTooltip = top - 154;
    }
  }

  hideNotification(): void {
    this.onTooltipClose.emit();
  }

  @HostListener('window:mousewheel', ['$event'])
  scroll(event: MouseEvent) {
    this.hideNotification();
  }

}
