import { Component, OnInit } from '@angular/core';
import { NgFor } from '@angular/common';

@Component({
    selector: 'app-pending-sold-empty',
    templateUrl: './pending-sold-empty.component.html',
    styleUrls: ['./pending-sold-empty.component.scss'],
    standalone: true,
    imports: [NgFor]
})
export class PendingSoldEmptyComponent implements OnInit {
	items = [];

  constructor() { }

  ngOnInit() {
  	this.items.length = 8;
  }

}
