import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'manufacturerSortby',
    standalone: true
})
export class ManufacturerSortbyPipe implements PipeTransform {
  manufacturers;

  transform(value: any, sortBy: any, args?: any): any {

    if (sortBy.text==='Sort by Popularity') {
      return value;
    } else if (sortBy.text==='Sort by A-Z') {
      return value.reverse();
    }
  }

}
