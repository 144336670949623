<div class="slider-wrap" [class.mobile]="topService.isMobile">
  <ng-container *ngIf="auctionService.itemsReady && auctionService?.auctionItems?.length">
    <div class="slider-wrap__hint">
      <span>3</span>
      <span>2</span>
      <span>1</span>
    </div>
    <div class="slider-wrap-top">
      <h2 class="slider-wrap-top__title">
        <span class="slider-wrap-top__auction-status">LIVE</span>
        Auctions
      </h2>
      <a class="slider-wrap-link" [routerLink]="[getAuctionsUrl()]" routerLinkActive="active">
        Go!
      </a>
    </div>
  </ng-container>

  <div class="slider-wrap-empty" *ngIf="auctionService.itemsReady && !auctionService?.auctionItems?.length">
    <div class="slider-wrap-empty-left">
      <div class="slider-wrap-empty-left__img">
        <img src="../../../../assets/images/icons/auctions/no-live-auctions-home@2x.png" width="101" height="100" alt="auctions empty">
      </div>
      <div class="slider-wrap-empty-left__txt">
        <h4>No Live Auctions</h4>
        <p>Check back soon!</p>
      </div>
    </div>
    <a class="slider-wrap-link" [routerLink]="[getAuctionsUrl()]" routerLinkActive="active">Auctions</a>
  </div>


  <owl-carousel-o [options]="carouselOptions" id="auctions-slider" [class.mobile]="topService.isMobile"
    class="auctions-slider-wrap" *ngIf="auctionService.itemsReady && auctionService?.auctionItems?.length && showCarousel" [class._one-item]="auctionService?.auctionItems?.length === 1">
    <ng-template carouselSlide [width]="topService.isMobile? 320 : 858" *ngFor="let item of auctionService.auctionItems" >
      <div class="auction-item">
        <div class="auction-item__img" (click)="goToAuctionDetail(item.ID)" id="{{'img-'+item.ID}}">
          <ng-container *ngFor="let image of item.MediaResponse.Original" >
            <img *ngIf="image.DefaultImage === true" [src]="image.PhysicalURI" alt="Image">
          </ng-container>
        </div>

        <div class="auction-item__info" (click)="goToAuctionDetail(item.ID)" id="{{'img-'+item.ID}}">
          <div class="auction-item__info-top">
            <div class="auction-item-desc">

              <div *ngIf="item.ActionCount && item.HasBid && item.ReserveMet">
                <div *ngIf="item.Status === 'Active' && sessionService.userRole !== 'SUPER'">
                  <span *ngIf="item.IsWinning" class="auction-item-desc__bid _win"  id="{{'isWinning-'+item.ID}}">You are winning</span>
                  <span *ngIf="!item.IsWinning" class="auction-item-desc__bid _lost" id="{{'isWinning-'+item.ID}}">You have been outbid</span>
                </div>
                <div *ngIf="item.Status === 'Successful'">
                  <span *ngIf="item.IsWinning" class="auction-item-desc__bid _win" id="{{'isWinning-'+item.ID}}">You won</span>
                  <span *ngIf="!item.IsWinning" class="auction-item-desc__bid _lost" id="{{'isWinning-'+item.ID}}">You lost</span>
                </div>
              </div>

              <div class="auction-item-msg">
                <p *ngIf="item?.MaxBid">Your Maximum Bid Is: {{item.MaxBid}}</p>
                <p style="color: red" id="{{'notifMsg-'+item.ID}}" >{{item?.NotificationMessages}}</p>
              </div>

              <h3 class="auction-item-desc__title"  id="{{'currentQuantity-'+item.ID}}">{{item.Title}}</h3>
              <div class="auction-item-desc__txt">
                <p [innerHTML]="descriptionSanitizer(item.Description)"></p>
              </div>
            </div>
          </div>
          <div class="auction-item__info-btm">
            <div class="auction-item-remaining-row">
              <div class="auction-item-remaining _timer">
                <span class="auction-item-remaining__label">Remaining</span>
                <span class="auction-item-remaining__value">
                  <app-countdown [dates]="{start: item?.StartDTTM, end:item?.EndDTTM, id: item?.ID }" *ngIf="item">
                   </app-countdown>
                </span>
              </div>
              <div class="auction-item-remaining">
                <!-- <span class="auction-item-remaining__label">&nbsp;</span> -->
                <span class="auction-item-remaining__label" *ngIf="item?.ReserveMet === false">&nbsp;</span>
                <span class="auction-item-remaining__label __met" [ngClass]="topService.isMobile ? 'mobile' : 'web'"
                  *ngIf="item?.ReserveMet === true || item?.ReserveMet === null">
                  Reserve Price Met
                </span>
                <span class="auction-item-remaining__value" id="{{'currentPrice-'+item.ID}}">{{ item.CurrentPrice | CustomCurrency}}</span>
              </div>
              <div class="auction-item-remaining _bid">
                <span class="auction-item-remaining__label">Bid(s)</span>
                <span class="auction-item-remaining__value" id="{{'actionCount-'+item.ID}}">{{ item.ActionCount }}</span>
              </div>
            </div>
            <div class="auction-item-actions-btns">
              <!-- live auctions -->
              <button class="btn-watch {{'watch-'+item.ID}}" (click)="addToWatchlist(item, $event)" id="{{'watch-'+item.ID}}" [class._active]="item.IsWatching"
              [ngClass]="{'disabled-div': sessionService.userRole === 'SUPER'}">
                <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="m12 6.4c-6.561 0-10 4.832-10 5.6 0 .766 3.439 5.6 10 5.6 6.56 0 10-4.834 10-5.6 0-.768-3.44-5.6-10-5.6zm0 9.907c-2.455 0-4.445-1.928-4.445-4.307s1.99-4.309 4.445-4.309 4.444 1.93 4.444 4.309-1.989 4.307-4.444 4.307zm0-4.307c-.407-.447.663-2.154 0-2.154-1.228 0-2.223.965-2.223 2.154s.995 2.154 2.223 2.154c1.227 0 2.223-.965 2.223-2.154 0-.547-1.877.379-2.223 0z"
                    fill="#fff" />
                </svg>
              </button>
              <button type="button" class="btn-go">
                GO
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </owl-carousel-o>

</div>

<app-auction-confirm *ngIf="showBidConfirm" [auctionItem]="currentItem" (confirmation)="bidConfirm($event)" [isMobile]="topService.isMobile"></app-auction-confirm>
