import { Component, OnInit } from '@angular/core';
import { IMyBidsLeftNavTotals } from '../../../../buyer/interfaces/IMyBidsLeftNavTotals';
import { AuctionsService } from '../../auctions.service';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
    selector: 'app-my-bids-left-nav',
    templateUrl: './my-bids-left-nav.component.html',
    styleUrls: ['./my-bids-left-nav.component.scss'],
    standalone: true,
    imports: [RouterLink, RouterLinkActive]
})
export class MyBidsLeftNavComponent implements OnInit {
  constructor(public auctionsService: AuctionsService) { }

  ngOnInit() {}

}
