import { FormBuilder, FormGroup, Validators, AbstractControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { TopService } from '../../../shared/services/top.service';

@Component({
    selector: 'app-credit-card-terms',
    templateUrl: './credit-card-terms.component.html',
    styleUrls: ['./credit-card-terms.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule]
})
export class CreditCardTermsComponent implements OnInit, OnDestroy {
  @Output() agreementEmit = new EventEmitter();
  @Output() close = new EventEmitter();
  formAgreement: FormGroup;
  formErrors;

  constructor(public topService: TopService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    if (this.topService.isMobile) {
      document.documentElement.classList.add('gg-modal','is-modal');
    }
    this.formAgreement = this.formBuilder.group({
      firstName: ['', [Validators.required, this.validateFirstNameIsEmpty.bind(this) ]],
      lastName: ['', [Validators.required, this.validateLastNameIsEmpty.bind(this) ]],
      agreement: [null, [Validators.required, this.validateAgree.bind(this)]]
    });

    this.formErrors = this.getDefaultFormErrors();
  }

  validateForm() {
    for (const field in this.formErrors) {
      if (!this.formErrors.hasOwnProperty(field)) {
        continue;
      }
      this.formErrors[field] = {};
      const control = this.formAgreement.get(field);
      if (control && !control.valid) {
        this.formErrors[field] = control.errors;
      }
    }
  }

  validateAgree(control: AbstractControl) {
    if (!control.value) {
      return { validAgree: false };
    }
    return null;
  }

  validateFirstNameIsEmpty(control: AbstractControl) {
    const field = control.value.trim();
    if (!field.length) {
      return { validFirstNameLength: false };
    }
    return null;
  }

  validateLastNameIsEmpty(control: AbstractControl) {
    const field = control.value.trim();
    if (!field.length) {
      return { validLastNameLength: false };
    }
    return null;
  }

  getDefaultFormErrors() {
    return {
      firstName: {},
      lastName: {},
      agreement: {}
    };
  }

  onClose() {
    this.close.emit();
  }

  onAccept(): void {
    if (this.formAgreement.valid && this.formAgreement.value.agreement) {
      this.agreementEmit.emit(this.formAgreement.value);
    } else {
      this.validateForm();
      this.formAgreement.valueChanges.subscribe(() => {
        this.validateForm();
      });
    }
  }

  ngOnDestroy() {
    if (this.topService.isMobile) {
      document.documentElement.classList.remove('gg-modal','is-modal');
    }
  }

}
