import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { TopService } from '../../shared/services/top.service';
import { ItemInventoryResponse } from '../interfaces/ItemInventoryResponse';
import { UserService } from '../../user/user.service';
import { SharedService } from '../../shared/shared.service';
import { SessionService } from '../../services/session.service';
import { AuthService } from '../../auth/auth.service';
import * as models from '../interfaces/model';
import { map } from 'rxjs/operators';
import { IItem } from '../interfaces/model';
import { NotificationTypesEnum } from '../../shared/interfaces/INotificationDetail';
import { ICartCheckout } from '../interfaces/ICartCheckout';
import { UtilityService } from '../../../app/core/utility.service';
import { PaymentTypeEnum } from '../enums/PaymentTypeEnum';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';

@Injectable()
export class CartService {
  private _sendMail = false;
  restapiverb = 'cart';
  comment = '';
  erroronline = false;
  cartList: models.ICartItem[] = [];
  order: models.IOrderResponse = { id: '' };
  shippingAddressCode = '';
  billingAddressCode = '';
  itemAdded = false;
  cartId: string;
  terms: string;
  paymentPercent: number;
  isCartItems: boolean;
  cartChanged = new EventEmitter<null>();
  orderCommentsLength = environment.orderCommentsLength;
  isEditing = false;
  isPurchaseInProgress = false;
  topErrorModal: models.IBuyerErrorModal = {
    bodyText: '',
    title: 'Server Error',
    isShown: false
  };
  isEditOrder = false;
  isCreditCardDeclined = false;
  agreementInfo: any;
  isShownAddedModal = false;
  lastQuantity: number;
  paymentType: PaymentTypeEnum;
  restrictedItemIds: string[] = [];


  constructor(private http: HttpClient,
    private sharedService: SharedService,
    public topService: TopService,
    public userService: UserService,
    public sessionService: SessionService,
    public authService: AuthService,
    private utils: UtilityService,
    private router: Router) { }

  set sendMail(val) {
    this._sendMail = val;
  }
  get sendMail() {
    return this._sendMail;
  }

  rx(cart: models.ICartHeader): models.ICartItem[] {
    if (cart && cart.details) {
      if (cart.externalRef) {
        this.sessionService.selectedOrderNumber = cart.externalRef.toString();
        this.sharedService.editOrderMode = true;
      }

      this.paymentPercent = cart.paymentPercent;
      this.cartId = cart.cartId;
      this.sessionService.newCartId = cart.cartId;
      this.isCartItems = cart.details ? cart.details.length > 0 : false;
      this.erroronline = cart.details.some(l => l.statusCode === 'Failed');

      cart.details.forEach(cartItem => {
        this.mapNotificationInfo(cartItem.item);
      });

      return cart.details;
    } else {
      return [];
    }
  }

  mapNotificationInfo(item: IItem) {
    item.notificationInfoObj = {
      priceDrop: false,
      moreInventory: false,
      waitlist: false,
      priceDropInitialValue: false,
      moreInventoryInitialValue: false,
      waitlistInitialValue: false
    };

    if (item.notificationInfo) {
      if (item.notificationInfo.includes(NotificationTypesEnum.priceDrop)) {
        item.notificationInfoObj.priceDrop = true;
        item.notificationInfoObj.priceDropInitialValue = true;
      }
      if (item.notificationInfo.includes(NotificationTypesEnum.moreInventory)) {
        item.notificationInfoObj.moreInventory = true;
        item.notificationInfoObj.moreInventoryInitialValue = true;
      }
      if (item.notificationInfo.includes(NotificationTypesEnum.waitlist)) {
        item.notificationInfoObj.waitlist = true;
        item.notificationInfoObj.waitlistInitialValue = true;
      }
    }
  }

  handleQuantityUpdate(cartItem: models.ICartItem): number {
    return this.calculatePrice(cartItem.quantity, cartItem.basePrice, cartItem.item.prices);
  }

  calculatePrice(qty: number, unitPrice: number, qtyDiscount: models.IPrice[]): number {
    const myClonedArray = Object.assign([], qtyDiscount);
    if (!qty || qty === 0 || qty === 1) {
      if (unitPrice && unitPrice > 0) {
        return unitPrice;
      } else {
        const price = myClonedArray.find(p => p.fromQty === 1);
        return price ? price.unitPrice : 0;
      }
    } else {
      const qtyPrice = myClonedArray.sort((p, q) => q.fromQty - p.fromQty).find(p => p.fromQty <= qty);
      return qtyPrice ? qtyPrice.unitPrice : unitPrice;
    }
  }

  checkForTerritoryRestrictedItems(items: models.ICartItem[], country: string) {
    const restrictedItems = items.filter((item: any) => item.item.restrictedCountries.includes(country));
    this.restrictedItemIds = [];

    if (restrictedItems) {
      this.cartList.forEach((item: models.ICartItem) => {

        const checkForRestricted = restrictedItems.includes(item);
        if (checkForRestricted) {
          this.restrictedItemIds.push(item.id);
        }
      })
    }
  }

  validateLine(cartItem: models.ICartItem): boolean {
    const price = cartItem.overwrittenPrice ? cartItem.overwrittenPrice : cartItem.unitPrice;

    if (cartItem.quantity === 0 || (cartItem.quantity === null)) {
      this.setError(cartItem, '0 quantity is not allowed');
      return false;
    }

    if (cartItem.item.moq && (cartItem.quantity - cartItem.reservedQty) > 0
        && (cartItem.quantity - cartItem.reservedQty) < cartItem.item.moq ) {
      this.setError(cartItem, `Quantity is below minimum order quantity, please adjust the quantity accordingly.`);
      return false;
    }

    if (cartItem.item.mxq && (cartItem.quantity - cartItem.reservedQty) > 0 && cartItem.item.mxq !== 0 && (cartItem.quantity - cartItem.reservedQty) > cartItem.item.mxq) {
      this.setError(cartItem, `This item has a maximum order quantity of ${cartItem.item.mxq} unit(s), please adjust the quantity accordingly.`);
      return false;
    }
    
    if (cartItem.quantity - cartItem.reservedQty >
      (cartItem.item.inventory.availableToSell >= 0 ? cartItem.item.inventory.availableToSell : 0)) {
      this.setError(cartItem, 'Not enough inventory');
      return false;
    }

    if (cartItem.quantity < cartItem.reservedQty && this.sessionService.userRole === 'SALES') {
      this.setError(cartItem, 'Quantity cannot be reduced');
      return false;
    }

    if (this.sessionService.userRole === 'SALES') {

      let minPrice = 0;
      minPrice = this.calculatePrice(cartItem.quantity, cartItem.basePrice, cartItem.item.prices)
        * ((100 - this.userService.SalesDiscount) / 100);
      if (price < minPrice) {
        this.setError(cartItem, 'Price too low');
        return false;
      }
    }

    return true;
  }

  setError(cartItem: models.ICartItem, errorMessage: string): void {
    this.erroronline = true;

    cartItem.statusCode = 'Failed';
    cartItem.statusMessage = errorMessage;
  }

  deleteCart(): Observable<Object> {
    const cartId = this.cartId;
    this.topService.loading = true;
    return this.http.delete(environment.buyerPortalBaseUrl + 'cart/deleteCart/' + cartId);
  }

  deleteFromCart(lineSequence: number): Observable<models.ICartItem[]> {
    this.itemAdded = false;
    this.restapiverb = 'cart';
    const url = environment.buyerPortalBaseUrl + this.restapiverb + '/' + lineSequence;
    return this.http.delete<models.ICartHeader>(url).pipe(map(res => this.rx(res)));
  }

  getItemFromCartItem(cartItem: models.ICartItem): Observable<any> {
    this.restapiverb = 'items/values';
    const url = environment.buyerPortalBaseUrl + this.restapiverb;
    return this.http.post<any>(url, { searchQuery: cartItem.itemCode });
  }

  checkValid(): void {
    for (let i = 0; i < this.cartList.length; i++) {
      if (this.cartList[i].statusCode !== 'Ok') {
        this.erroronline = true;
        return;
      }

      this.cartList[i].item.added = true;
    }

    this.erroronline = false;
  }

  getItemInventory(itemId: string): Observable<ItemInventoryResponse> {
    const restapiverb = 'cart/inventory';
    const url = environment.buyerPortalBaseUrl + restapiverb + '/' + itemId;
    return this.http.get<ItemInventoryResponse>(url);
  }

  updateCartAvailQty(lineSequence: number, quantity: number): void {
    const line = this.cartList.find(l => l.lineSequence === lineSequence);
    if (!line) {
      return;
    }
    line.item.inventory.availableToSell = quantity;
  }

  pushUpdatedOrder(updatedOrder: ICartCheckout): Observable<models.IOrderResponse> {
    this.restapiverb = 'cart/checkout';
    const url = environment.buyerPortalBaseUrl + this.restapiverb;
    return this.http.put<models.IOrderResponse>(url, updatedOrder);
  }

  placeOrder(cartCheckout: ICartCheckout): Observable<models.IOrderResponse> {
    window.scrollTo(0, 0);
    this.restapiverb = 'cart/checkout/';
    const url = environment.buyerPortalBaseUrl + this.restapiverb;
    return this.http.post<models.IOrderResponse | any>(url, cartCheckout).pipe(
      map(res => {
        if (res.payment.error) {
          this.isCreditCardDeclined = (res.payment.error.code === 'card_declined');
        }
        if (!res.error) {
          res.orderDetails.forEach(orderLine => {
            orderLine.description = orderLine.description ? orderLine.description : orderLine.itemName;
          });
          this.sharedService.noOrders = false;
        }
        return res;
      })
    );
  }

  addToCart(id: string, qty: number): Observable<models.ICartItem[]> {
    this.restapiverb = 'cart';
    this.itemAdded = true;
    const item = { id: id, qty: qty };
    const url = environment.buyerPortalBaseUrl + this.restapiverb;
    return this.http.post<models.ICartHeader>(url, item).pipe(map(res => this.rx(res)));
  }

  BulkItemToCart(items: any): Observable<any> {
    this.restapiverb = 'cart/bulkUpload';
    const url = environment.buyerPortalBaseUrl + this.restapiverb;
    return this.http.post<models.BulkimportCartHeader>(url, items).pipe(
      map(res => {
        if (res.cart.cartId) {
          this.cartId = res.cart.cartId;
          this.sessionService.newCartId = this.cartId;
          this.cartList = res.cart.details;
          this.getCart();
          this.checkForTerritoryRestrictedItems(this.cartList, this.userService.defaultBillingCountry);
        }
        return res.errors;
      })
    );
  }

  getcartItems(): Observable<models.ICartItem[]> {
    window.scrollTo(0, 0);
    this.restapiverb = 'cart';
    const url = environment.buyerPortalBaseUrl + this.restapiverb;
    return this.http.get<models.ICartHeader>(url).pipe(map(res => this.rx(res)));
  }

  updateCart(lineSequence: number, qty: number, overwrittenPrice: number): Observable<models.ICartItem[]> {
    let item = {};
    this.restapiverb = 'cart';
    if (this.sessionService.userRole === 'BUYER') {
      item = { lineSequence: lineSequence, qty: qty };
    } else {
      item = { lineSequence: lineSequence, qty: qty, overwrittenPrice: overwrittenPrice };
    }

    const url = environment.buyerPortalBaseUrl + this.restapiverb;
    return this.http.put<models.ICartHeader>(url, item).pipe(map(res => this.rx(res)));
  }

  updateCartItemData(item: models.IItem): void {
    const cartList = this.cartList;
    for (let i = 0; i < cartList.length; i++) {
      if (cartList[i].item.id === item.id) {
        cartList[i].unitPrice = item.baseUnitPrice;
        cartList[i].item.unitPrice = item.baseUnitPrice;
        cartList[i].item.qty = item.qty;
        cartList[i].item.previousQuantityAdded = item.previousQuantityAdded;
        item.reservedQty = cartList[i].reservedQty;
        return;
      }
    }
  }

  updateItemLogo(): void {
    this.cartList.forEach(cl => {
      if (cl.item.manufacturerLogoUrl !== '' && cl.item.manufacturerLogoUrl != null) {
        cl.item.manufacturerLogoUrl = environment.imageBaseUrl + cl.item.manufacturerLogoUrl;
      } else {
        cl.item.manufacturerLogoUrl = environment.imageBaseUrl + environment.imageDefaultLogoUrl;
      }
    });
  }

  getTotalOrderPrice(): number {
    let sum = this.cartList.map(row => row.unitPrice * (row.quantity - row.reservedQty))
      .reduce((result, total) => result + total);
    sum = sum * (100 - this.userService.discount) / 100;

    return sum;
  }

  convertItemsToExportedJson(): any[] {
    const resultJson: any[] = [];
    this.cartList.forEach(cartItem => {
      resultJson.push({
        'Code': cartItem.itemCode,
        'Description': cartItem.item.description,
        'Exw': cartItem.item.exwPoint,
        'Available': cartItem.item.inventory.availableToSell,
        'Quantity': cartItem.quantity,
        'Price': '$' + cartItem.unitPrice,
        'Total Price': '$' + (cartItem.quantity * cartItem.unitPrice)
      });
    });

    return resultJson;
  }

  resetCart(): void {
    this.cartList = [];
    this.comment = '';
    this.erroronline = false;
    this.order = { id: '' };
    this.shippingAddressCode = '';
    this.billingAddressCode = '';
    this.itemAdded = false;
    this.cartId = null;
    this.terms = '';
    this.isCartItems = false;
  }

  getCart(): void {
    // Establish role and make sure is authenticated
    if (!this.sessionService.userRole) {
      this.sessionService.userRole = this.sessionService.userRole;
    }

    if ((this.sessionService.userRole !== 'GUEST'
      && (this.sessionService.userRole === 'BUYER'
        || (['SALES', 'SUPER'].includes(this.sessionService.userRole) && this.sessionService.isCustomerSelected === 'YES')))
      && this.authService.isAuthenticated()) {
      this.getcartItems().subscribe(
        (data) => {
          this.topService.loading = false;
          if (data) {
            this.cartList = data;
            this.checkValid();
            this.updateItemLogo();
            this.sharedService.cartData = true;
          }
        },
        (err) => {
          this.sharedService.handleBuyerHttpError(err, this.topErrorModal);
        }
      );
    }
  }

  goToCart() {
    if (this.sessionService.userRole === 'SUPER' && this.sessionService._is_customer_selected === null) {
    } else {
      this.sharedService.isLogoutPage = false;
      if (this.topService.loading) { return; }
      this.isEditOrder ?
        this.router.navigate(['/cart'], { queryParams: { updatingOrder: 'Y' } }) : this.router.navigate(['/cart']);
    }
  }

  totalUnits(): number {
    let quantity = 0;

    if (!this.isShownAddedModal) {
      for (let i = 0; i < this.cartList.length; i++) {
        quantity = quantity + this.cartList[i].quantity;
        this.lastQuantity = quantity;
      }
      return quantity;
    } else {
      return this.lastQuantity;
    }
  }

  toPascalCase(s: string): string | void {
    if (s) {
      return this.utils.toTitleCase(s);
    }
  }

  showAddedModal() {
    this.isShownAddedModal = true;
    this.cartList.length <= 0 ? this.lastQuantity = null : '';

    setTimeout(() => {
        this.isShownAddedModal = false;
    }, 1400);
  }

  exportToCSV(): void {

    const items: any[] = this.convertItemsToExportedJson();

    const csv = Papa.unparse(items);

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    saveAs(blob, 'hubx.csv');
  }

}
