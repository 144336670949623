import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'timeToday',
    standalone: true
})
export class TimeTodayPipe implements PipeTransform {

  transform(value: string): any {
    const today = new Date();
    const theDate = new Date(value);
    if ( today.getFullYear() === theDate.getFullYear()
      && today.getMonth() === theDate.getMonth()
      && today.getDate() === theDate.getDate())
      return formatDate(theDate, 'h:mm a', 'en');

    return formatDate(theDate,'MM.dd.yyyy','en');
  }

}
