<div *ngIf="openItemDetails">
	<div class="modal">
		<div class="modal-wrap animated" [class.zoomOut]="animateOut" [class.zoomIn]="openItemDetails">
			<!-- <div class="modal-header">
				<button type="button" class="close" aria-label="Close" (click)="closeModal()"></button>
			</div> -->
			<div class="modal-content" (clickOutside)="onClickedOutside($event)" [clickOutsideEvents]="'click'" [exclude]="'.item, .modal-header, .modal-footer'" [excludeBeforeClick]="true">
				<div class="description">
					<div class="left-part">

						<div class="item__logo">
							<div *ngIf="item.manufacturerLogoUrl" [ngStyle]="{'background-image': 'url(' + item.manufacturerLogoUrl + ')'}"></div>
							<div *ngIf="!item.manufacturerLogoUrl" style="background-image: url('http://cdn.hubx.com/assets/images/item-placeholder.svg');">
							</div>
						</div>

						<div class="price align-self-center">
							{{item.baseUnitPrice | CustomCurrency}}
						</div>
						<div class="price mobile-moq align-self-center" *ngIf="item.moq>1">
							MOQ: {{item.moq}}
						</div>
					</div>
					<div class="right-part">
						<div class="desc">
							{{item.description}}&nbsp; - &nbsp;
							<span class="copy-number" (mouseover)="copyState()" (click)=copyText(item.partNumber)>{{item.partNumber}}
									<span ngModel="copied" ngDefaultControl>{{ copied }}</span>
							</span>
							<span>&nbsp; - &nbsp;
								<span *ngFor="let attribute of item.attributes">
									{{attribute.label === 'Condition' ? attribute.value : ''}}
								</span></span>

							<div (click)="openGradingGuide($event)" *ngIf="item.gradingGuideUrl" class="grading-mobile">
								View Grading Guide
							</div>
						</div>
					</div>
				</div>

				<div class="moq-row">
					<div class="item-moq">
						<div *ngFor="let price of item.prices">
							<div class="item__count--wrapper">
								<div class="moq-wrap">
									<span class="price-qty">{{price.fromQty}}+</span>
									<span class="unit-price">{{price.unitPrice | CustomCurrency}}</span>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="item-attributes-detail-modal">
					<button type="button" class="close" aria-label="Close" (click)="closeModal()"></button>
					<div *ngFor="let attribute of getVisibleAttributes()">
						<div *ngIf="attribute.value">
							<img [ngClass]="{'flipped-img': attribute.label === 'Just Launched',
	              'restr': attribute.label === 'Restrictions', 'p-drop': attribute.label === 'Price Drop'}"
							 width="24px" height="24px" [src]="getAttributeUri(attribute.label)">
						</div>
						<div *ngIf="attribute.value">
							<span class="label">{{attribute.label | currentDeal}}</span>
						</div>
						<div *ngIf="attribute.value !== 'Yes' && attribute.value !== 'No'">
							<span class="value">{{attribute.value}}</span>
						</div>
						<div *ngIf="attribute.label === 'Restrictions' && attribute.value === 'Yes'">
							<span class="value">{{attribute.description}} </span>
						</div>
					</div>
					<div *ngIf="item.leadTimeDays">
						<div>
							<img src="assets/images/new-item-details/eta.svg" alt="ETA" width="24" height="24" />
						</div>
						<div>
							<span class="label">ETA</span>
						</div>btn btn-primary step-two-login
						<div>
							<span class="value">{{ item.leadTimeDays }} Days</span>
						</div>
					</div>
					<div *ngIf="item.comments[0]">
						<div>
							<img src="assets/images/icons/info-white.svg" alt="more-info" width="24" height="24">
						</div>
						<div>
							<span class="label">More Info</span>
						</div>
						<div>
							<span class="value">{{ item.comments[0].comment }}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">

				<div class="available">
					<span class="unit-avail">Available: <br>
						{{ item.inventory.availableToSell>0?getNumberOfItemsAvailable(item):"Sold Out" }}
					</span>
					<!--
            Sergey: lets discuss tomorrow

          <span *ngIf="!itemsService.showInTransitTitle(item, user_role) && !itemsService.showInStockTitle(item, user_role)" class="unit-price-avail">Available:
            {{itemsService.showInStockValue(item, user_role)}}
          </span>
          <span *ngIf="itemsService.showInStockTitle(item, user_role)">
              In Stock:
              <span *ngIf="!checkMoreThanOnHand()">{{ itemsService.showInStockValue(item, user_role) }}</span>
            </span>
            <span *ngIf="itemsService.showInTransitTitle(item, user_role)">
                In Transit:
                <span> {{itemsService.showInTransitValue(item, user_role)}}</span>
              </span> -->
				</div>

				<ng-container *ngIf="sessionService.userRole !== 'GUEST'">
					<div class="item-qty" [class.hide]='!item.added && item.inventory.availableToSell<1'>
						<input type="number" [(ngModel)]="item.qty" (keyup)="updateItemPrice(item)" appOnlyDigits [disabled]="topService.loading || item.added || !item.added && item.inventory.availableToSell<1"
						 [class.disabled]="item.added" #qty [ngClass]="{'item-disabled': item.added || item.inventory.availableToSell<1}" />
					</div>
					<div *ngIf='user_role==="BUYER" || (user_role!=="BUYER" && customerIsSelectedFlag())'>
						<a *ngIf='!item.added && item.inventory.availableToSell>0' (click)="!topService.loading?addToCartBuyer():''"
						 class="item-to-cart">
							<svg *ngIf="!loading" width="28px" height="28px" viewBox="0 0 28 23" version="1.1" xmlns="http://www.w3.org/2000/svg"
							 xmlns:xlink="http://www.w3.org/1999/xlink">
								<g id="UI-Kit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
									<g id="add-to-cart" fill-rule="nonzero">
										<path d="M21,4 L20.349795,6.13178125 L18.8204409,6.13178125 L15.8016246,16.16 L4.21811963,16.16 L0,6.05183945 L15.1377483,6.05183945 L12.7901031,13.9780153 L14.3265479,13.9780153 L17.3285301,4 L21,4 Z M12.0799764,17.44 C11.3977189,17.44 10.8446823,18.0130512 10.8446823,18.72 C10.8446823,19.4269488 11.3977189,20 12.0799764,20 C12.762234,20 13.3152705,19.4269488 13.3152705,18.72 C13.3152705,18.0130512 12.762188,17.44 12.0799764,17.44 Z M7.75629904,17.44 C7.07406685,17.44 6.52105086,18.0130512 6.52105086,18.72 C6.52105086,19.4269488 7.07406685,20 7.75629904,20 C8.43857718,20 8.9916391,19.4269488 8.9916391,18.72 C8.9916391,18.0130512 8.43857718,17.44 7.75629904,17.44 Z"
										 id="shopping-cart-icon" transform="translate(10.500000, 12.000000) scale(-1, 1) translate(-10.500000, -12.000000) "></path>
									</g>
								</g>
							</svg>
							<span *ngIf="loading" class="cart-loader"></span>
							<span *ngIf="!item.beingEdited&&!loading">Add to Cart</span>
							<span *ngIf="!item.beingEdited&&loading">Adding...</span>
							<span *ngIf="item.beingEdited&&!loading">Update Cart</span>
							<span *ngIf="item.beingEdited&&loading">Updating...</span>
						</a>

						<div class="add-waitlist" *ngIf='item.inventory.availableToSell<1' (click)="!topService.loading?submitNotification(true):''"
			              [class.added]="item.notificationInfoObj.waitlist">
			                <img src="assets/images/icons/waitlist-excel.svg" width="24" height="24" alt="Waitlist">
			                <span *ngIf="!item.notificationInfoObj.waitlist">Add to Waitlist</span>
			                <span *ngIf="item.notificationInfoObj.waitlist">Waitlisted</span>
			            </div>

						<a *ngIf="item.added && item.inventory.availableToSell>0" (click)="!topService.loading?editItemQty():''" class="item-edit-qty popup">
							<svg *ngIf="!loading" width="24px" height="24px" viewBox="0 0 28 23" version="1.1" xmlns="http://www.w3.org/2000/svg"
							 xmlns:xlink="http://www.w3.org/1999/xlink">
								<g id="UI-Kit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
									<g id="edit-qty" fill-rule="nonzero">
										<path d="M21,4 L20.349795,6.13178125 L18.8204409,6.13178125 L15.8016246,16.16 L4.21811963,16.16 L0,6.05183945 L15.1377483,6.05183945 L12.7901031,13.9780153 L14.3265479,13.9780153 L17.3285301,4 L21,4 Z M12.0799764,17.44 C11.3977189,17.44 10.8446823,18.0130512 10.8446823,18.72 C10.8446823,19.4269488 11.3977189,20 12.0799764,20 C12.762234,20 13.3152705,19.4269488 13.3152705,18.72 C13.3152705,18.0130512 12.762188,17.44 12.0799764,17.44 Z M7.75629904,17.44 C7.07406685,17.44 6.52105086,18.0130512 6.52105086,18.72 C6.52105086,19.4269488 7.07406685,20 7.75629904,20 C8.43857718,20 8.9916391,19.4269488 8.9916391,18.72 C8.9916391,18.0130512 8.43857718,17.44 7.75629904,17.44 Z"
										 id="shopping-cart-icon" transform="translate(10.500000, 12.000000) scale(-1, 1) translate(-10.500000, -12.000000) "></path>
									</g>
								</g>
							</svg>
							<span *ngIf="loading" class="cart-loader"></span>
							<span *ngIf="!loading">Edit QTY</span>
							<span *ngIf="loading">Updating...</span>
						</a>
					</div>
				</ng-container>

				<ng-container *ngIf="sessionService.userRole === 'GUEST'">
					<div class="item-qty">
						<input type="number" appOnlyDigits value="0" />
					</div>
					<div class="add-to-cart">
						<a class="item-to-cart" (click)="addToCartGuest()">
							<svg width="28px" height="28px" viewBox="0 0 28 23" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
								<g id="UI-Kit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
									<g id="add-to-cart" fill-rule="nonzero">
										<path d="M21,4 L20.349795,6.13178125 L18.8204409,6.13178125 L15.8016246,16.16 L4.21811963,16.16 L0,6.05183945 L15.1377483,6.05183945 L12.7901031,13.9780153 L14.3265479,13.9780153 L17.3285301,4 L21,4 Z M12.0799764,17.44 C11.3977189,17.44 10.8446823,18.0130512 10.8446823,18.72 C10.8446823,19.4269488 11.3977189,20 12.0799764,20 C12.762234,20 13.3152705,19.4269488 13.3152705,18.72 C13.3152705,18.0130512 12.762188,17.44 12.0799764,17.44 Z M7.75629904,17.44 C7.07406685,17.44 6.52105086,18.0130512 6.52105086,18.72 C6.52105086,19.4269488 7.07406685,20 7.75629904,20 C8.43857718,20 8.9916391,19.4269488 8.9916391,18.72 C8.9916391,18.0130512 8.43857718,17.44 7.75629904,17.44 Z"
										 id="shopping-cart-icon" transform="translate(10.500000, 12.000000) scale(-1, 1) translate(-10.500000, -12.000000) "></path>
									</g>
								</g>
							</svg>
							<span *ngIf="loading" class="cart-loader"></span>
							<span>Add to Cart</span>
						</a>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
</div>
