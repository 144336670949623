import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { Subscription } from 'rxjs';
import { SessionService } from '../../../services/session.service';
import { GuestService } from '../../../shared/services/guest.service';
import { TopService } from '../../../shared/services/top.service';
import { SharedService } from '../../../shared/shared.service';
import { UserService } from '../../../user/user.service';
import { IDropdown } from '../../../vendor/interface/IDropdown';
import { SortAuctionsTypesEnum } from '../../enums/sortAuctionsTypesEnum';
import { StatusFilterEnum } from '../../enums/statusFilterEnum';
import { IAuctionsHeadersTotals } from '../../interfaces/IAuctionsHeadersTotal';
import { ISortOptionsAuctions } from '../../interfaces/ISortOptionsAuctions';
import { AuctionsService } from '../auctions.service';
import { DropdownComponent } from '../../../shared/dropdown/dropdown.component';
import { NgIf } from '@angular/common';


@Component({
    selector: 'app-auction-header',
    templateUrl: './auction-header.component.html',
    styleUrls: ['./auction-header.component.scss'],
    standalone: true,
    imports: [RouterLink, RouterLinkActive, NgIf, FormsModule, ReactiveFormsModule, DropdownComponent]
})
export class AuctionHeaderComponent implements OnInit, OnDestroy {
  headerTotals: IAuctionsHeadersTotals;
  statusAuctionsEnum = StatusFilterEnum;
  subscription: Subscription;
  isAsc = false;
  headerTotalIsZero = false;
  searchControl = new FormControl('');

  headerTotal = 0;
  guestUrl = '';
  selectedSortOption;

  constructor(
    public sessionService: SessionService,
    public auctionsService: AuctionsService,
    public sharedService: SharedService,
    public router: Router,
    public userService: UserService,
    private guestService: GuestService,
    public topService: TopService,
  ) { }

  ngOnInit() {
    this.guestUrl = this.userService.isGuest ? '/guest' : '';
    this.auctionsService.isGridView = false;
    this.getTotals();
    this.auctionsService.currentAuctionStatusFilter = StatusFilterEnum.active;
    this.getDefaultTitleForSorting();
  }

  getTotals() {
    // this.auctionsService.getHeaderTotals(this.setHeaderTotalToDisplay, this);
    this.auctionsService.getHeaderTotals();
  }

  onSortOptionSelected(sortOpt: ISortOptionsAuctions) {
    this.auctionsService.selectedSortOption = sortOpt;
    this.auctionsService.setSearchPayload({ sortOption: +sortOpt.Value });
  }

  selectQuickFilter(status: StatusFilterEnum) {
    this.auctionsService.currentAuctionStatusFilter = status;
    // update sort dropdown options
    switch (status) {
      case StatusFilterEnum.comingSoon:
        this.auctionsService.sortType = SortAuctionsTypesEnum.BrowseOptionsComingSoon;
        break;
      case StatusFilterEnum.completed:
        this.auctionsService.sortType = SortAuctionsTypesEnum.BrowseOptionsCompleted;
        break;
      default:
        // all and active
        this.auctionsService.sortType = SortAuctionsTypesEnum.BrowseOptions;
    }
    this.router.navigate([`${this.guestUrl}/auctions`]);
    this.auctionsService.setSearchPayload({ statusFilter: +status });
    this.getTotals();
  }

  searchAuctionItems() {
    this.router.navigate([this.getAuctionsUrl()]);
    this.auctionsService.setSearchPayload({ fullTextQuery: this.searchControl.value });
  }

  resetSearch() {
    this.searchControl.setValue('');
    this.searchAuctionItems();
  }

  searchOnEnter(event: KeyboardEvent) {
    if (event.code === 'Enter') { this.searchAuctionItems(); }
  }

  setHeaderTotalToDisplay(scope) {
    if (scope.auctionsService.headerTotals
      && scope.auctionsService.titleText === scope.auctionsService.LiveAuctionTitleText) {
      scope.headerTotal = scope.auctionsService.headerTotals.LiveTotal;
    } else if (scope.auctionsService.myBidsTotals) {
      scope.headerTotal = scope.auctionsService.myBidsTotals.AllTotal;
    }
    scope.headerTotalIsZero = scope.headerTotal === 0;
  }

  getAuctionsUrl() {
    if (this.userService.isGuest) {
      return '/guest/auctions';
    } else {
      return '/auctions';
    }
  }



  ngOnDestroy() {
    this.auctionsService.resetSearchPayload();
    if (this.subscription) { this.subscription.unsubscribe(); }
  }

  goToWatching() {
    if (this.userService.isGuest) {
      this.userService.guestPop();
    } else {
      this.auctionsService.resetSearchPayload();
      this.router.navigate([`${this.guestUrl}/auctions/my_bids/watching`]);
    }
  }

  goToMyBids() {
    if (this.userService.isGuest) {
      this.userService.guestPop();
    } else {
      this.auctionsService.resetSearchPayload();
      this.router.navigate([`${this.guestUrl}/auctions/my_bids`]);
    }
  }

  getDefaultTitleForSorting(): string {
    this.selectedSortOption = this.auctionsService.selectedSortOption ? this.auctionsService.selectedSortOption.Text : 'Select Sorting';
    return this.selectedSortOption;
  }

}
