import { Component, OnInit, Input, Output, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { EventEmitter } from '@angular/core';
import { PubSubService } from '../../../core/pubsub.service';
import { SharedSource } from '../../../core/shared-source';
import { Subscription } from 'rxjs';
import { SharedService } from '../../shared.service';
import { NgIf, NgFor } from '@angular/common';
import { NgClickOutsideDirective } from 'ng-click-outside2';

@Component({
    selector: 'app-custom-select',
    templateUrl: './custom-select.component.html',
    styleUrls: ['./custom-select.component.scss'],
    standalone: true,
    imports: [NgClickOutsideDirective, NgIf, NgFor]
})
export class CustomSelectComponent implements OnInit, OnDestroy {
  private _selectedItem: any;
  private _subscription: Subscription = new Subscription();
  private _data: any[] = [];
  isActive = false;

  @Input() label: string = 'All';
  @Input() disabled = false;
  @Input() fkey = 'id';
  @Input() fvalue = 'name';
  @Input() minWidth = '154px';
  @Input() count: string;
  @Input() addAll = true;
  @Input() allowEdit = false;
  @Input() allowAdd = false;
  @Input() addLabel = '+ Add';
  @Input() group: string;
  @Input() borderRadius = '0px';
  @Input() height = '40px';
  @Input() iconSrc: string;
  @Input() injectClass: string;
  @Input() set data(val) {
    this._data = val;
  }

  get data() {
    return this._data;
  }

  // You can update value on component and will be reflected on the select
  @Input() set selectedItem(i: any) {
    this._selectedItem = i;
  }

  get selectedItem() {
    return this._selectedItem;
  }

  @Output() onItemSelected = new EventEmitter();
  @Output() editTriggered = new EventEmitter();
  @Output() createTriggered = new EventEmitter();

  get addDefaultOpt() {
    return this.addAll && !this.data?.find(item => item[this.fvalue] === this.allOption[this.fvalue]);
  }

  constructor(private fb: FormBuilder,
    private pubSubService: PubSubService,
    public sharedService: SharedService) { }

  ngOnInit() {
    if (!this.selectedItem && this.data?.length) { this.selectedItem = this.data[0]; }
    this.subscribeToLegacyDDEvents();
  }

  get allOption() {
    const allObj = {};
      allObj[this.fkey] = null;
      allObj[this.fvalue] = this.label;
      return allObj;
  }

  toggleDropdown(event: MouseEvent) {
    // event.stopPropagation();
    this.isActive = !this.isActive;
  }

  closePicker(event: MouseEvent) {
    event.stopPropagation();
    this.isActive = false;
  }

  selectionChanged(item: any) {
    this.isActive = false;
    this._selectedItem = item;
    this.onItemSelected.emit(item);
  }

  subscribeToLegacyDDEvents () {
    this._subscription.add(
      this.pubSubService.sharedSubject.subscribe(dropdownEvent => {
        if (dropdownEvent.name === SharedSource.dropdownDirective) {
          if ( dropdownEvent.data.action === 'resetAll') {
            if (( this.group && this.group === dropdownEvent.data.group ) || !this.group ) {
              this.selectedItem = undefined;
            }
          }
        }
      })
    );
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
}
