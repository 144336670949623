import { Component, OnInit, OnDestroy, Pipe, PipeTransform, ViewChild, AfterViewChecked } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuctionsService } from '../auctions.service';
import { IAuctionItem } from '../../interfaces/iauction-item';
import * as moment from 'moment';
import { IAuctionWatch } from '../../interfaces/IAuctionWatch';
import { TopService } from '../../../shared/services/top.service';
import { IAuctionBid } from '../../interfaces/IAuctionBid';
import { IAuctionBidRequest } from '../../interfaces/IBidHistoryRequest';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as _ from 'lodash';
import { SessionService } from '../../../services/session.service';
import { UserService } from '../../../user/user.service';
import { GuestService } from '../../../shared/services/guest.service';
import { AuthService } from '../../../auth/auth.service';
import { StaticService } from '../../../static/static.service';
import { CarouselComponent, CarouselModule } from 'ngx-owl-carousel-o';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CustomCurrencyPipe } from '../../../shared/pipes/currency.pipe';
import { AuctionConfirmComponent } from '../../../shared/auction-confirm/auction-confirm.component';
import { AuctionBidHistoryComponent } from '../bid-history/auction-bid-history.component';
import { AuctionBidNotifComponent } from '../../../shared/auction-bid-notification/auction-bid-notif.component';
import { OnlyDigitsDirective } from '../../../shared/directives/only-digits.directive';
import { FormsModule } from '@angular/forms';
import { CountdownComponent } from '../../../shared/countdown/countdown.component';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'app-auction-details',
    templateUrl: './auction-details.component.html',
    styleUrls: ['./auction-details.component.scss'],
    standalone: true,
    imports: [NgIf, CarouselModule, NgFor, NgClass, CountdownComponent, FormsModule, OnlyDigitsDirective, AuctionBidNotifComponent, AuctionBidHistoryComponent, AuctionConfirmComponent, CustomCurrencyPipe]
})

export class AuctionDetailsComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('owlCarousel') owlCarousel: CarouselComponent;

  activeRouterSub: Subscription;
  moment = moment;
  htmlDescription: SafeHtml;
  carouselOptions: OwlOptions = {
    items: 1,
    dots: false,
    nav: true,
    loop: false,
    autoWidth: true,
    navText: ['<', '>'],
  };
  sliderImagesCounter = {
    current: 1
  };
  showQuickBidConfirm = false;
  showManualBidConfirm = false;
  isShowFaq = false;
  isSuperUser = false;
  showCarousel = false;

  isCarouselResized = false;

  constructor(
    private sessionService: SessionService,
    public auctionsService: AuctionsService,
    public topService: TopService,
    private activeRouter: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private userService: UserService,
    public guestService: GuestService,
    public authService: AuthService,
    public staticService: StaticService) {
  }

  ngOnInit() {
    this.auctionsService.isDetailsPage = true;
    this.topService.loading = true;
    this.auctionsService.isDetailsPage = true;
    this.isSuperUser = this.sessionService.userRole === 'SUPER';
    this.topService.showFilterBtn = true;
    this.activeRouterSub = this.activeRouter.params.subscribe((params) => {
      const id = params['id'];
      this.auctionsService.registerInterestingObjects([id]);
      this.auctionsService.getAuctionItem(id, { fn: this.getAuctionItemAsync, scope: this });
    });
  }

  getAuctionItemAsync(scope) {
    scope.topService.loading = false;
    scope.htmlDescription = scope.sanitizer.bypassSecurityTrustHtml(scope.auctionsService.currentAuctionItem.Description);
    setTimeout(() => {
      scope.showCarousel = true;
    }, 0);
  }

  ngAfterViewChecked() {
    if (!this.isCarouselResized && this.owlCarousel) {
      // this.owlCarousel.refresh();
      this.isCarouselResized = true;
    }
  }

  ngOnDestroy() {
    this.activeRouterSub.unsubscribe();
    this.auctionsService.currentAuctionItem = null;
    this.auctionsService.bidAmount = null;
    this.topService.showFilterBtn = false;
    this.auctionsService.isDetailsPage = false;
  }

  downloadSpecifications() {
    if (this.auctionsService.currentAuctionItem.MediaResponse.Pdf[0].PhysicalURI) {
      window.open(this.auctionsService.currentAuctionItem.MediaResponse.Pdf[0].PhysicalURI, '_blank');
    }
  }

  onSliderChanged(event): void {
    this.sliderImagesCounter.current = event.startPosition + 1;
  }

  quickBid() {
    this.showQuickBidConfirm = true;
  }

  quickBidConfirm(confirmed) {
    if (confirmed) {
      this.auctionsService.quickBid(this.auctionsService.currentAuctionItem);
    }
    this.showQuickBidConfirm = false;
  }

  manualBid() {
    if (this.userService.isGuest) {
      this.userService.guestPop();
    } else {
      this.showManualBidConfirm = true;
    }
  }


  manualBidConfirm(confirmed) {
    if (confirmed) {
      this.auctionsService.manualBid(this.auctionsService.currentAuctionItem, this.auctionsService.bidAmount);
    }
    this.showManualBidConfirm = false;
  }

  showFaq() {
    this.isShowFaq = !this.isShowFaq;
  }

  gotIt(confirmed, item: IAuctionItem) {
    if (confirmed) {
      item.NotificationMessages = null;
    }
  }

  countdownEnd(event, id, currentStatus) {
    location.reload();
  }

  won() {
    if (this.auctionsService.currentAuctionItem && this.auctionsService.currentAuctionItem.ActionCount &&
      this.auctionsService.currentAuctionItem.Status === 'Successful' && this.auctionsService.currentAuctionItem.IsWinning) {
      return true;
    } else {
      return false;
    }
  }

}



