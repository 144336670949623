import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { SharedService } from '../../../shared/shared.service';
import { Router } from '@angular/router';
import { PubSubService } from '../../../core/pubsub.service';
import { Subscription } from 'rxjs';
import { SharedSource } from '../../../core/shared-source';
import { CartService } from '../cart.service';
import { TopService } from '../../../shared/services/top.service';

import * as models from '../../interfaces/model';
import { CustomCurrencyPipe } from '../../../shared/pipes/currency.pipe';
import { ErrorModalComponent } from '../../../shared/ui-components/error-modal/error-modal.component';
import { CreditCardTermsComponent } from '../../credit-cards/credit-card-terms/credit-card-terms.component';
import { FormsModule } from '@angular/forms';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'app-cart-bottom',
    templateUrl: './cart-bottom.component.html',
    styleUrls: ['./cart-bottom.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, NgClass, CreditCardTermsComponent, ErrorModalComponent, CustomCurrencyPipe]
})
export class CartBottomComponent implements OnInit, OnDestroy {
  @Input() discount: number;
  @Input() editedOrderShippingCost: number;
  @Input() userRole: string;
  @Input() selectedOrderNumber: number;
  @Input() comments: string;
  // @Input() comment: string;
  @Input() commentsLength: string;
  @Input() isCart: boolean;
  @Input() totalAmount: number;
  @Input() processingFee: number;
  @Input() findAllErrors: boolean;
  @Input() loading: boolean;
  @Input() showModal: boolean;
  @Input() completed: boolean;
  @Input() cartList: models.ICartItem[];
  @Input() hasOpenOrders: boolean;
  @Input() addToExistingRejected: boolean;
  @Input() credit: boolean;
  @Input() errorCollection: Map<string, string>;
  @Output() showModalEmitter = new EventEmitter();
  @Output() refreshEmitter = new EventEmitter();
  @Output() gotoShoppingEmitter = new EventEmitter();
  @Output() cancelEmitter = new EventEmitter();
  @Output() placeNewEmitter = new EventEmitter();
  // @Output() commentEmitter = new EventEmitter<string>();
  @Output() rejectAddtoExistingEmitter = new EventEmitter();
  @Output() editOrderShippingCostEmitter = new EventEmitter<number>();

  isCommentsShown:boolean = false;
  isTooltipShown:boolean = false;
  subscription: Subscription;
  showTerms:boolean = false;
  errorModal: models.IBuyerErrorModal = {
    bodyText: '',
    title: 'Server Error',
    isShown: false
  };
  errorFromEmail = false;

  constructor(
    private router: Router,
    public pubSubService: PubSubService,
    private sharedService: SharedService,
    public cartService: CartService,
    public topService: TopService,
    private _router: Router) { }

  ngOnInit() {
  }

  refresh(): void {
    this.refreshEmitter.emit();
  }

  cancel(): void {
    if ( this.cartService.isPurchaseInProgress ) { return; }
    this.cancelEmitter.emit();
  }

  showError(errorModal: models.IBuyerErrorModal): void {
    this.errorModal = errorModal;
  }

  placeNew(): void {
    if (this.cartService.paymentType) {
      this.editOrderShippingCostEmitter.emit(this.editedOrderShippingCost);
      this.placeNewEmitter.emit();
    }
  }

  gotoShopping(): void {
    this.gotoShoppingEmitter.emit();
  }

  reject(): void {
    this.rejectAddtoExistingEmitter.emit();
  }

  onShowModal(): void {
    this.showModalEmitter.emit();
  }

  // onCommentChange(commnet: string): void {
  //   this.commentEmitter.emit(commnet);
  // }

  isCartEmptyAndCompleted(): boolean {
    return this.cartList.length === 0 || this.completed;
  }

  /* Show change billing or shipping address pencil*/
  showAddToExistingOrderBtn(): boolean {
    return !this.selectedOrderNumber && this.hasOpenOrders && !this.addToExistingRejected && !this.completed
    && !this.sharedService.noOrders;
  }

  toggleComments() {
    if ( this.cartService.isPurchaseInProgress ) { return; }
    this.isCommentsShown = !this.isCommentsShown;
  }

  showProcessingTitle(active:boolean) {
    this.isTooltipShown = active;
  }

  ngOnDestroy() {
    this.cartService.comment = '';
  }

  hideError(): void {
    this.errorModal.isShown = false;
    if (this.errorFromEmail) {
      this.router.navigate(['users/orders']);
    }
  }

}

