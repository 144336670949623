import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { AuctionsService } from '../auctions.service';
import { TopService } from '../../../shared/services/top.service';
import { Router } from '@angular/router';
import { IAuctionWatch } from '../../interfaces/IAuctionWatch';
import { IAuctionItem } from '../../interfaces/model';
import { EventEmitter } from 'events';
import * as _ from 'lodash';
import { SessionService } from '../../../services/session.service';
import { GuestService } from '../../../shared/services/guest.service';
import { AuthService } from '../../../auth/auth.service';
import { StaticService } from '../../../static/static.service';
import { UserService } from '../../../user/user.service';
import { CustomCurrencyPipe } from '../../../shared/pipes/currency.pipe';
import { AuctionConfirmComponent } from '../../../shared/auction-confirm/auction-confirm.component';
import { AuctionBidNotifComponent } from '../../../shared/auction-bid-notification/auction-bid-notif.component';
import { CountdownComponent } from '../../../shared/countdown/countdown.component';
import { NgIf, NgFor, NgClass } from '@angular/common';


@Component({
    selector: 'app-auction-items',
    templateUrl: './auction-items.component.html',
    styleUrls: ['./auction-items.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgIf, NgFor, NgClass, CountdownComponent, AuctionBidNotifComponent, AuctionConfirmComponent, CustomCurrencyPipe]
})
export class AuctionItemsComponent implements OnInit, OnDestroy {
  remainingTime: number;
  getActionItems;
  testImage;

  showBidConfirm = false;
  currentItem: IAuctionItem;
  isSuperUser = false;

  constructor(public auctionService: AuctionsService,
    private sessionService: SessionService,
    public topService: TopService,
    private _router: Router,
    public guestService: GuestService,
    public authService: AuthService,
    public staticService: StaticService,
    public userService: UserService) { }

  ngOnInit() {
    this.isSuperUser = this.sessionService.userRole === 'SUPER';
    this.topService.showFilterBtn = true;
  }

  ngOnDestroy() {
    this.topService.showFilterBtn = false;
  }

  viewDetails(itemID) {
    if (this.userService.isGuest) {
      this._router.navigate(['guest/auctions/details', itemID]);
    } else {
      this._router.navigate(['auctions/details', itemID]);
    }
  }

  quickBid(item: IAuctionItem) {
    event.stopPropagation();
    this.currentItem = item;
    this.showBidConfirm = true;
  }

  bidConfirm(confirmed) {
    if (confirmed) {
      this.auctionService.quickBid(this.currentItem);
    }
    this.showBidConfirm = false;
  }

  addToWatchlist(item: IAuctionItem) {
    if (event && !event.isTrusted) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    if (this.userService.isGuest) {
      this.userService.guestPop();
    } else {
      this.auctionService.addToWatchlist(item);
    }
    event.stopPropagation();
  }

  gotIt(confirmed, item: IAuctionItem) {
    if (confirmed) {
      item.NotificationMessages = null;
    }
  }

  countdownEnd(event, id, currentStatus) {
    _.remove(this.auctionService.auctionItems, function (n) {
      return n.ID === id;
    });
    if (currentStatus === 'Preview') {
      this.auctionService.headerTotals.CommingSoonTotal--;
      this.auctionService.headerTotals.LiveTotal++;
    } else if (currentStatus === 'Active') {
      this.auctionService.headerTotals.CompletedTotal++;
      this.auctionService.headerTotals.LiveTotal--;
    }
  }

  truncateDescription(description: string) {
    description = description.replace(/<[^>]+>/g, '');
    return '<p>' + description + '</p>';
  }
}
