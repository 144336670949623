import { Directive, Renderer2, Input, Output, EventEmitter, OnInit, ElementRef, HostListener } from '@angular/core';

import { IDropdown } from '../../vendor/interface/IDropdown';
import { IDropdownGroup } from '../../vendor/interface/IDropdownGroup';
import { PubSubService } from '../../core/pubsub.service';
import { SharedSource } from '../../core/shared-source';

@Directive({
    selector: '[appDropDown]',
    standalone: true
})
export class DropdownDirective implements OnInit {
  private _selectedValueTitle;
  @Input() title: string;
  @Input() notActive: boolean;
  @Input() addAll = false;
  @Input() data: any[] = [];
  @Input() fvalue: string;
  @Input() slength: number;
  @Input()  set selectedValueTitle(val) {
    const selectedValue = this.data.find(d => d[this.fvalue] === val);
    if (this._selectedValueTitle) {
      // value changed
      this.resetToDefaultTitle(selectedValue);
    }
    this._selectedValueTitle = val;
  }

  get selectedValueTitle() {
    return this._selectedValueTitle;
  }

  @Input() dropdownId: string;
  @Input() dropdownName: string;
  @Input() group: string;
  @Input() count: string;
  @Input() sort?: boolean;
  @Output() selectedItem = new EventEmitter();
  div: any = this.renderer.createElement('div');
  text1 = this.renderer.createText('All');
  span = this.renderer.createElement('span');
  button = null;
  text = null;
  span2 = null;
  selectedTitle = null;
  flag = false;
  lastValue: string;

  constructor(private elRef: ElementRef, private renderer: Renderer2, private pubSubService: PubSubService) { }

  ngOnInit(): void {
    this.renderDropdown();
    this.pubSubService.sharedSubject.subscribe(dropdownEvent => {
      if (dropdownEvent.name === SharedSource.dropdownDirective) {
        if (dropdownEvent.data['group'] === this.group && dropdownEvent.data['action'] === 'setSelectedValue') {
          this.setSelectedValue(dropdownEvent.data['name']);
          return;
        }

        if (dropdownEvent.data['group'] === this.group && dropdownEvent.data['dependentId'] === this.dropdownId) {
          this.data = dropdownEvent.data['values'];
          // this.notActive = dropdownEvent.data['notActive'];
          this.resetAllValues();
        } else if (dropdownEvent.data['group'] === this.group && dropdownEvent.data['action'] === 'resetStatusPO'
          && dropdownEvent.data['dropdownId'] === this.dropdownId) {
          this.resetAllValues();
        } else if (dropdownEvent.data['group'] === this.group && dropdownEvent.data['action'] === 'closeAll') {
          if (this.flag) {
            this.closeDropdown();
            // this.renderer.destroyNode(this.div);
            this.renderer.removeClass(this.elRef.nativeElement.children[0], 'active');
            this.renderer.removeChild(this.elRef.nativeElement, this.div);
            this.flag = !this.flag;
          }
        } else if (dropdownEvent.data['group'] === this.group && dropdownEvent.data['action'] === 'resetAll') {
          this.resetAllValues();
        } else if (dropdownEvent.data['group'] === this.group && dropdownEvent.data['action'] === 'resetAllButVendor'
         && this.dropdownName === 'vendor' ) {
          this.selectedValueTitle = 'HUBX';
          this.renderer.removeChild(this.elRef.nativeElement, this.button);
          this.renderer.removeChild(this.elRef.nativeElement, this.div);
          this.renderer.removeChild(this.elRef.nativeElement, this.text);
          this.renderer.removeChild(this.elRef.nativeElement, this.text1);
          this.renderer.removeChild(this.elRef.nativeElement, this.span);
          this.renderer.removeChild(this.elRef.nativeElement, this.span2);
          this.renderer.removeChild(this.elRef.nativeElement, this.selectedTitle);
          this.lastValue = '';
          this.renderDropdown();
        } else if (dropdownEvent.data['group'] === this.group && dropdownEvent.data['action'] === 'resetAllButVendor'
        && this.dropdownName !== 'vendor' ) {
          this.resetAllValues();
       } else if ((dropdownEvent.data['group'] === this.group || dropdownEvent.data['dropdownId'] === this.dropdownId)
       && dropdownEvent.data['action'] === 'resetToDefaultTitle') {

        if ( dropdownEvent.data.values && dropdownEvent.data.values.length ) {
          this.resetToDefaultTitle(dropdownEvent.data.values[0]);
          return;
        }
          const selectedValue = this.data.find(d => d[this.fvalue] === this.selectedValueTitle);
          this.resetToDefaultTitle(selectedValue);
       }
      }
    });
  }

  resetAllValues(): void {
    this.selectedValueTitle = 'All';
    this.renderer.removeChild(this.elRef.nativeElement, this.button);
    this.renderer.removeChild(this.elRef.nativeElement, this.div);
    this.renderer.removeChild(this.elRef.nativeElement, this.text);
    this.renderer.removeChild(this.elRef.nativeElement, this.text1);
    this.renderer.removeChild(this.elRef.nativeElement, this.span);
    this.renderer.removeChild(this.elRef.nativeElement, this.span2);
    this.renderer.removeChild(this.elRef.nativeElement, this.selectedTitle);
    this.lastValue = '';
    this.renderDropdown();
  }

  setSelectedValue(value: string): void {
    this.selectedValueTitle = value;
    this.renderer.removeChild(this.elRef.nativeElement, this.button);
    this.renderer.removeChild(this.elRef.nativeElement, this.div);
    this.renderer.removeChild(this.elRef.nativeElement, this.text);
    this.renderer.removeChild(this.elRef.nativeElement, this.text1);
    this.renderer.removeChild(this.elRef.nativeElement, this.span);
    this.renderer.removeChild(this.elRef.nativeElement, this.span2);
    this.renderer.removeChild(this.elRef.nativeElement, this.selectedTitle);
    this.lastValue = '';
    this.renderDropdown();
  }

  renderDropdown(): void {
    this.button = this.renderer.createElement('button');
    this.text = this.renderer.createText(this.title);
    this.span2 = this.renderer.createElement('span2');
    this.renderer.addClass(this.span, 'items-filter-select-name');
    this.renderer.addClass(this.span2, 'items-filter-select-value');
    this.selectedTitle = this.renderer.createText(this.selectedValueTitle.substring(0, this.slength));

    this.span = this.renderer.createElement('span');
    this.renderer.appendChild(this.span, this.selectedTitle);
    this.renderer.addClass(this.button, 'btn');
    this.renderer.addClass(this.button, 'dropdown-toggle');
    this.renderer.appendChild(this.button, this.text);
    this.renderer.appendChild(this.button, this.span);
    this.renderer.appendChild(this.elRef.nativeElement, this.button);

    // push dropdown to localstorage if doesn't exist
    this.checkIfDropdownExistAndCreate();
    // turn off all dropdowns before updating/ creating them
    this.resetLocalStorageDropdowns();

    if (this.data) {
      this.data = this.data.filter(d => d[this.fvalue] !== 'All');
      if (this.sort) {
        this.data.sort((a, b) => a[this.fvalue].localeCompare(b[this.fvalue]));
      }
    }

    if (!this.notActive) {
      this.pushData(this.div, this.elRef.nativeElement);
    } else {
      this.renderer.addClass(this.button, 'disableMe');
    }
  }

  pushData(div: any, el: any) {
    if (this.addAll && this.data.length > 0) {
      if (this.data.filter(d => d[this.fvalue] === 'All').length === 0) {
        if (this.dropdownId === 'statusDropdown') {
          this.data.unshift(
            {
              id: '0', text: 'All'
            });
        } else {
          this.data.unshift(
            {
              id: '0', name: 'All'
            });
        }
      }
    }

    this.data.forEach(d => {
      if (d[this.fvalue] && d[this.fvalue] !== '') {
        const href = this.renderer.createElement('a');
        this.renderer.addClass(href, 'dropdown-item');
        if (d[this.fvalue] === this.lastValue || (d[this.fvalue] === this.selectedValueTitle && !this.lastValue)) {
          this.renderer.addClass(href, 'active');
        }

        let counter = '';
        if (d[this.count]){
          counter = ' (' + d[this.count] + ')';
        } else {
          counter = '';
        }

        const text2 = this.renderer.createText(d[this.fvalue] + counter);
        this.renderer.appendChild(href, text2);
        this.renderer.addClass(div, 'dropdown-menu');

        const dropdownMenu = this.elRef.nativeElement.querySelector('.dropdown-menu');
        if (this.data.length > 6) {
          this.renderer.setStyle(div, 'height', '20em');
          this.renderer.setStyle(div, 'overflow-y', 'scroll');
        }

        this.renderer.addClass(div, 'show');

        this.renderer.listen(href, 'click', (event) => {
          this.lastValue = d[this.fvalue];
          this.renderer.setProperty(this.span, 'innerText', d[this.fvalue].substring(0, this.slength));
          this.renderer.addClass(href, 'active');
          this.renderer.removeChild(this.elRef.nativeElement, this.div);
          this.renderer.removeClass(this.elRef.nativeElement.children[0], 'active');
          this.flag = !this.flag;
          this.selectedItem.emit(d);
          this.closeDropdown();
          event.stopPropagation();
        });
        this.renderer.appendChild(div, href);
      }
    });
  }

  @HostListener('click') toggleDropDown(eventDate: Event) {
    if (!this.notActive) {
      if (this.flag) {
        this.closeDropdown();
        // this.renderer.destroyNode(this.div);
        this.renderer.removeChild(this.elRef.nativeElement, this.div);
        this.renderer.removeClass(this.elRef.nativeElement.children[0], 'active');
      } else {
        this.div = this.renderer.createElement('div');
        this.pushData(this.div, this.elRef.nativeElement);
        this.renderer.addClass(this.elRef.nativeElement.children[0], 'active');
        this.renderer.appendChild(this.elRef.nativeElement, this.div);
        this.selectDropdown();
      }
      this.flag = !this.flag;
    }
  }

  createDropdownObject(): IDropdown {
    return {
      dropdownId: this.dropdownId,
      name: this.dropdownName,
      selected: false
    };
  }

  getGroupDropdownGroup(): IDropdownGroup {
    return JSON.parse(localStorage.getItem(this.group));
  }

  setGroupDropdownGroup(dropdownGroup: IDropdownGroup): void {
    localStorage.setItem(this.group, JSON.stringify(dropdownGroup));
  }

  resetLocalStorageDropdowns(): void {
    const dropdownGroup: IDropdownGroup = JSON.parse(localStorage.getItem(this.group));
    dropdownGroup.currentDropdownSelectedId = '';
    dropdownGroup.dropdowns.filter(dropdown => dropdown.selected = false);

    this.setGroupDropdownGroup(dropdownGroup);
  }

  checkIfDropdownExistAndCreate(): void {
    if (!localStorage.getItem(this.group)) {
      localStorage.setItem(this.group, JSON.stringify({ currentDropdownSelectedId: '', dropdowns: [] }));
    }

    const dropdownGroup: IDropdownGroup = JSON.parse(localStorage.getItem(this.group));

    if (!dropdownGroup.dropdowns.filter(dropdown => dropdown.dropdownId === this.dropdownId).length) {
      dropdownGroup.dropdowns.push(this.createDropdownObject());
      localStorage.setItem(this.group, JSON.stringify(dropdownGroup));
    }
  }

  closeDropdown(): void {
    const dropdownGroup = this.getGroupDropdownGroup();
    dropdownGroup.dropdowns.filter(dropdown => dropdown.name === this.dropdownName)[0].selected = false;
    dropdownGroup.currentDropdownSelectedId = '';
    this.setGroupDropdownGroup(dropdownGroup);
    this.renderer.removeClass(this.button, 'active');
  }

  selectDropdown(): void {
    const dropdownGroup = this.getGroupDropdownGroup();
    const dropdownChosen = dropdownGroup.dropdowns.filter(dropdown => dropdown.name === this.dropdownName)[0];

    if (dropdownGroup.dropdowns.filter(dropdown => dropdown.selected).length) {
      dropdownGroup.dropdowns.filter(dropdown => dropdown.dropdownId === dropdownGroup.currentDropdownSelectedId)[0].selected = false;
      const element: HTMLElement = document.getElementById(dropdownGroup.currentDropdownSelectedId);
      element.click();
    }

    dropdownChosen.selected = true;
    dropdownGroup.currentDropdownSelectedId = dropdownChosen.dropdownId;
    this.setGroupDropdownGroup(dropdownGroup);
  }

  resetToDefaultTitle(val) {
    if (!val) { return; }
    this.lastValue = val[this.fvalue];
    this.renderer.setProperty(this.span, 'innerText', val[this.fvalue].substring(0, this.slength));
  }
}
