import { Component, OnInit, ChangeDetectorRef, OnDestroy, AfterViewChecked, QueryList, ViewChildren } from '@angular/core';
import { ItemsService } from '../../../shared/services/items.service';
import { TopService } from '../../../shared/services/top.service';
import { SharedService } from '../../../shared/shared.service';
import { PubSubService } from '../../../core/pubsub.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { Subscription } from 'rxjs';
import { SharedSource } from '../../../core/shared-source';
import { IBuyerErrorModal, IItem } from '../../interfaces/model';
import { IPastPurchaseRequest } from '../../interfaces/IPastPurchaseRequest';
import { IJustSoldQuickFilter } from '../../../shared/interfaces/IJustSoldQuickFilter';
import { environment } from '../../../../environments/environment';
import { IItemManufacturerFilter } from '../../interfaces/IItemManufacturerFilter';
import { IMultipleQuickSortFilters } from '../../interfaces/IMultipleQuickSortFilters';
import { StateService } from '../../../shared/services/state.service';
import { ItemFilterService } from '../../../shared/item-filter/item-filter.service';
import { ListingViewItemComponent } from '../../../shared/listing-view-item/listing-view-item.component';
import { ListingViewItemMobileComponent } from '../../../shared/listing-view-item/listing-view-item-mobile/listing-view-item-mobile.component';
import { SessionService } from '../../../services/session.service';
import * as uuid from 'uuid';;
import { OfferService } from '../../../user/offers/offer.service';
import { filter } from 'rxjs/operators';
import { MakeOfferComponent } from '../../../shared/item-detail-desktop/make-offer/make-offer.component';
import { WaitlistTooltipComponent } from '../../../shared/waitlist-tooltip/waitlist-tooltip.component';
import { RestrictionNoticeComponent } from '../../../shared/ui-components/restriction-notice/restriction-notice.component';
import { NotificationTooltipComponent } from '../../notifications/notification-tooltip/notification-tooltip.component';
import { GradingGuide2Component } from '../../../shared/grading-guide2/grading-guide2.component';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { ItemDetailDesktopComponent } from '../../../shared/item-detail-desktop/item-detail-desktop.component';
import { ErrorModalComponent } from '../../../shared/ui-components/error-modal/error-modal.component';
import { ItemsPreloaderMobileComponent } from '../../../shared/ui-components/items-preloader/items-preloader-mobile/items-preloader-mobile.component';
import { ItemsBreadcrumbsMobileComponent } from '../../catalog/items-breadcrumbs/items-breadcrumbs-mobile/items-breadcrumbs-mobile.component';
import { ItemsPreloaderComponent } from '../../../shared/ui-components/items-preloader/items-preloader.component';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { NothingFoundBuyerComponent } from '../../nothing-found-buyer/nothing-found-buyer.component';
import { NothingFoundPurchasesComponent } from './nothing-found-purchases/nothing-found-purchases.component';
import { ItemFilterComponent } from '../../../shared/item-filter/item-filter.component';
import { NgIf, NgFor, CurrencyPipe, DatePipe } from '@angular/common';

@Component({
    selector: 'app-past-purchase-items',
    templateUrl: './past-purchase-items.component.html',
    styleUrls: ['./past-purchase-items.component.scss'],
    standalone: true,
    imports: [NgIf, ItemFilterComponent, NothingFoundPurchasesComponent, NothingFoundBuyerComponent, VirtualScrollerModule, NgFor, ListingViewItemComponent, ItemsPreloaderComponent, ItemsBreadcrumbsMobileComponent, ListingViewItemMobileComponent, ItemsPreloaderMobileComponent, ErrorModalComponent, ItemDetailDesktopComponent, NgClickOutsideDirective, GradingGuide2Component, NotificationTooltipComponent, RestrictionNoticeComponent, WaitlistTooltipComponent, MakeOfferComponent, CurrencyPipe, DatePipe]
})
export class PastPurchaseItemsComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChildren(ListingViewItemComponent) listItemComponents: QueryList<ListingViewItemComponent>;
  @ViewChildren(ListingViewItemMobileComponent) listItemMobileComponents: QueryList<ListingViewItemMobileComponent>;
  purchases = true;
  loading = false;
  selectedItem: IItem;
  showDetailedPopUp = false;
  manufacturers: IJustSoldQuickFilter[] = [];
  // conditions: IJustSoldQuickFilter[] = [];
  pubServiceSubscription: Subscription;
  itemChangedSubscription: Subscription;
  isMobile = false;
  itemListErrorModal: IBuyerErrorModal = {
    bodyText: '',
    title: '',
    isShown: false
  };
  throttle = 200;
  scrollDistance = 1;
  pageNumber = 1;
  itemsCount = 25;
  pastPurhcasePayload: IPastPurchaseRequest = {};
  pastPurchaseValues: IItem[];
  totalCount = 0;
  isFirstLoad = true;
  manufacturerSelected = 'All Manufacturers';
  // conditionSelected = 'All Conditions';
  showNotificationTooltip = false;
  notificationItem: IItem = {};
  tooltipPosition:Event;
  showWaitlistTooltip = false;
  waitlistItem: IItem = {};
  waitlistTooltipPosition: Event;
  blockPreloader = false;
  showRestrictedCountryPopup = false;
  selectedItemOffer: IItem;

  constructor(
    public itemsService: ItemsService,
    public stateService: StateService,
    public topService: TopService,
    private _itemFilterService: ItemFilterService,
    public sharedService: SharedService,
    private pubSubService: PubSubService,
    private _sessionService: SessionService,
    private cdr: ChangeDetectorRef,
    private notificationService: NotificationService,
    public offerService: OfferService
  ) { }

  ngOnInit() {
    this.sharedService.navId = null;
    this.itemsService.isPastPurchase = true;
    this.itemsService.isLandingPage = false;
    this.itemsService.selectedTopAttribute = 'Past Purchases';
    this._itemFilterService.selectedZeroLevel = { navName: this.itemsService.selectedTopAttribute };

    // for mobile
    this.topService.showFilterBtn = true;

    if ( !this._sessionService.userRole || this._sessionService.userRole === 'GUEST' ||
         (this._sessionService.isCustomerSelected !== 'YES' &&
         ['SALES','SUPER'].includes(this._sessionService.userRole))) {
      this.pastPurchaseValues = [];
      this.itemsService.itemsReady = true;
      return;
    }

    this.pubServiceSubscription = this.pubSubService.sharedSubject.subscribe(
      mySharedValues => {
        if (mySharedValues.name === SharedSource.mobileSize) {
          this.isMobile = mySharedValues.data;
          this.itemsService.hideText = this.isMobile;
        }
        if (mySharedValues.name === SharedSource.changeCustomer) {
          if (!this.isFirstLoad) {
            this.getPastPurchases(true);
          }
        }
        if (mySharedValues.name === SharedSource.pastPurchaseSearch) {
          this.searchPastPurchase();
        }
        if (mySharedValues.name === SharedSource.manufacturerChangedInFilter) {
          const manuId = mySharedValues.data;
          this.changeQuickFilter(manuId);
        }
        if (mySharedValues.name === SharedSource.sortChangedInFilter) {
          const sortObj = mySharedValues.data;
          this.sortItems(sortObj);
        }
        if (mySharedValues.name === SharedSource.itemRestrictedForCountry) {
          this.showRestrictedCountryPopup = true;
          this.selectedItem = mySharedValues.data;
        }
      });

    this.isMobile = this.topService.isMobile;

    if (this.topService.pastPurchasesSearchString !== '') {
      this.searchPastPurchase();
    } else {
      // this.itemsService.initializeConditionsDataForQuickSort(this.conditions);
      this.itemsService.initializeManufacturerDataForQuickSort(this.manufacturers);
      this.getPastPurchases(true);
    }

    this.itemChangedSubscription = this.offerService.itemChanged$
    .pipe(
      filter(itemChanged => !!itemChanged)
    )
    .subscribe(itemChanged => {
      if (this.pastPurchaseValues && this.pastPurchaseValues.length) {
        this.pastPurchaseValues = this.pastPurchaseValues.map(pastPurchase => {
          if (pastPurchase.id === itemChanged.id) {
            pastPurchase.offerStatus = itemChanged.offerStatus;
          }
          return pastPurchase;
        });
        this.cdr.detectChanges();
      }
    });
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  addToCart(event) {
    this.itemsService.closeExwPopup(event);

    // gridView is the oposite of expected,
    // if true the listingViewComponent is the one showing
    if ( !this.topService.isMobile ){
      const listingItemCmp = this.listItemComponents.find(lItem => {
       return this.itemsService.selectedItemExwPopup &&
       lItem.item.id === this.itemsService.selectedItemExwPopup.id;
      });

      if ( listingItemCmp ) {
        listingItemCmp.addToCart(true);
      }
     } else {
      const listingItemMobileCmp = this.listItemMobileComponents.find(lItem => {
       return this.itemsService.selectedItemExwPopup &&
       lItem.item.id === this.itemsService.selectedItemExwPopup.id;
      });

      if ( listingItemMobileCmp ) {
        listingItemMobileCmp.addToCart(true);
      }

    }
  }

  ngOnDestroy() {
    this.itemsService.isPastPurchase = false;
    if (this.pubServiceSubscription) {
      this.pubServiceSubscription.unsubscribe();
    }
    this.topService.pastPurchasesSearchString = '';
    this.itemsService.selectedItemExwPopup = undefined;
    // for mobile
    this.topService.showFilterBtn = false;
    this.selectedItem = undefined;
    if (this.itemChangedSubscription) {
      this.itemChangedSubscription.unsubscribe();
    }
  }

  searchPastPurchase(): void {
    this.blockPreloader = false;
    this.pastPurhcasePayload.searchQuery = this.topService.pastPurchasesSearchString;
    this.manufacturerSelected = 'All Manufacturers';
    // this.conditionSelected = 'All Conditions';
    this.getPastPurchases(true, false);
  }

  isKeyEnter(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.searchPastPurchase();
    }
  }

  sortItems(sortObj) {
    this.blockPreloader = false;
    this.pastPurhcasePayload.orderBy = { orderByName: '', sortDirection: '' };
//     if (sortBy === 'inventory') {
//       this.pastPurhcasePayload.orderBy.orderByName = sortBy;
//       this.pastPurhcasePayload.orderBy.sortDirection = this.itemsService.pastPurchaseQtySort ? 'asc' : 'desc';
//       this.getPastPurchases(true, true);
//     }
//
//     if (sortBy === 'price') {
//       this.pastPurhcasePayload.orderBy.orderByName = sortBy;
//       this.pastPurhcasePayload.orderBy.sortDirection = this.itemsService.pastPurchasePriceSort ? 'asc' : 'desc';
//       this.getPastPurchases(true, true);
//     }
    if (sortObj.sortBy === 'purchasedate') {
      this.pastPurhcasePayload.orderBy.orderByName = sortObj.sortBy;
      this.pastPurhcasePayload.orderBy.sortDirection = sortObj.isAsc ? 'asc' : 'desc';
      this.getPastPurchases(true, true);
    }
  }

  getPastPurchases(isFirstPage?: boolean, isFilterChanged?: boolean): void {
    // this.loading = true;
    this.topService.loading = true;
    this.itemsService.itemsReady = false;

    if (isFirstPage) {
      this.pageNumber = 1;
    }

    if (!isFilterChanged) {
      this.pastPurhcasePayload.quickFilters = null;
    }

    this.pastPurhcasePayload.pagination = { pageNumber: this.pageNumber, pageSize: 25 };

    this.itemsService.getPastPurchases(this.pastPurhcasePayload).subscribe(
      (data) => {
        this.itemsService.selectedItemExwPopup = undefined;
        this.clearQuickFilters();
        this.setManufacturers(data.filters.quickFilters);
        // this.setConditions(data.filters.quickFilters);
        this.totalCount = data.pagination.totalCount;
        this.sharedService.pastPurchasesTotal = this.totalCount;

        if (this.pastPurchaseValues && this.pastPurchaseValues.length && !isFirstPage) {
          this.pastPurchaseValues = this.pastPurchaseValues.concat(data.values);
        } else {
          this.pastPurchaseValues = data.values;
        }

        this.pastPurchaseValues.map(item=>{
          item.highlightId = uuid.v4();
        })

        this.isFirstLoad = false;
        this.notificationService.updateItemLogo(this.pastPurchaseValues);
        // this.loading = false;
        this.topService.loading = false;
        this.itemsService.itemsReady = true;
      },
      (err) => {
        // error handler
        // this.loading = false;
        this.topService.loading = false;
        this.itemsService.itemsReady = true;
        this.itemListErrorModal.title = 'Past Purchases';
        if(err.status==401){
          this.itemListErrorModal.bodyText = 'You must sign in again.';
          this.itemListErrorModal.isShown=true;
        } else{
          this.itemListErrorModal.bodyText = typeof(err.error) == 'string' ? err.error: err.error.message;
          this.sharedService.handleBuyerHttpError(err, this.itemListErrorModal,true);
        }
      }
    );
  }

  clearQuickFilters(): void {
    if (this.manufacturers.length) {
      this.manufacturers = this.manufacturers.filter(manu => manu.name === this.itemsService.pastPurchasesManuSelected);
    }
  }

  changeQuickFilter(quickFilters: IItemManufacturerFilter): void {
    this.blockPreloader = false;
    this.pastPurhcasePayload.quickFilters = [];
    this.clearSearchPayload();
//     if (quickFilters.condition) {
//       this.pastPurhcasePayload.quickFilters.push({ id: quickFilters.condition, type: 'condition' });
//     }
    if (quickFilters.manufacturer) {
      this.pastPurhcasePayload.quickFilters.push({ id: quickFilters.manufacturer, type: 'manufacturer' });
    }

    this.getPastPurchases(true, true);
  }

  clearSearchPayload(): void {
    this.topService.pastPurchasesSearchString = '';
    this.pastPurhcasePayload.searchQuery = this.topService.pastPurchasesSearchString;
  }

  getMultiFilterItems(quickFilters: IMultipleQuickSortFilters): void {
    this.blockPreloader = false;
    this.pastPurhcasePayload.orderBy = { orderByName: '', sortDirection: '' };
    this.pastPurhcasePayload.quickFilters = [];
    this.clearSearchPayload();
    quickFilters.filters.forEach(qf => {
//       if (qf.condition) {
//           this.pastPurhcasePayload.quickFilters.push({ id: qf.condition, type: 'condition' });
//         }
      if (qf.manufacturer) {
        this.pastPurhcasePayload.quickFilters.push({ id: qf.manufacturer, type: 'manufacturer' });
      }
    });
//     if (quickFilters.sortByPrice) {
//       this.pastPurhcasePayload.orderBy.orderByName = 'price';
//       this.pastPurhcasePayload.orderBy.sortDirection = this.itemsService.pastPurchasePriceSort ? 'asc' : 'desc';
//     }
    if (quickFilters.sortByPurchaseDate) {
      this.pastPurhcasePayload.orderBy.orderByName = 'purchasedate';
      this.pastPurhcasePayload.orderBy.sortDirection = this.itemsService.pastPurchaseDateSort ? 'asc' : 'desc';
    }
//     if (quickFilters.sortByQty) {
//         this.pastPurhcasePayload.orderBy.orderByName = 'inventory';
//        this.pastPurhcasePayload.orderBy.sortDirection = this.itemsService.pastPurchaseQtySort ? 'asc' : 'desc';
//       }

    this.getPastPurchases(true, true);
  }

  setManufacturers(quickFilters: IJustSoldQuickFilter[]): void {

    quickFilters.forEach(qf => {
      if (qf.type === 0 && !this.manufacturers.filter(manu => manu.name === qf.name).length) {
        this.manufacturers.push(qf);
      }
    });

    if (this.manufacturers[0] && this.manufacturers[0].name !== 'All Manufacturers') {
      const newTopElement = { count: 0, logoUrl: '', selected: false, id: '', name: 'All Manufacturers' };
      this.manufacturers.unshift(newTopElement);
    }

    this._itemFilterService.pastPurchasesManufacturers = this.manufacturers;
  }
  // setConditions(quickFilters: IJustSoldQuickFilter[]): void {
  //   quickFilters.forEach(qf => {
  //     if (qf.type === 1 && !this.conditions.filter(cond => cond.name === qf.name).length) {
  //       this.conditions.push(qf);
  //     }
  //   });

  //   if (this.conditions[0] && this.conditions[0].name !== 'All Conditions') {
  //     const newTopElement = { count: 0, logoUrl: '', selected: false, id: '', name: 'All Conditions' };
  //     this.conditions.unshift(newTopElement);
  //   }
  // }

  hideError(): void {
    this.itemListErrorModal.isShown = false;
  }

  hideDetailedPopUp(): void {
    this.showDetailedPopUp = false;
    this.sharedService.isMobileSortingPopup = false;
    this.selectedItem = { id: '' };
  }

  openitemDetailedMode(item: IItem): void {
    this.selectedItem = item;
    this.showDetailedPopUp = true;
    this.sharedService.isMobileSortingPopup = true;
  }

  openGradingGuide(item: IItem): void {
    this.selectedItem = item;
    this.sharedService.gradingGuideHtmlPath =
      environment.imageBaseUrl + item.gradingGuideUrl + 'index6.html';
    this.sharedService.isGradingGuideOpen = true;
  }

  showError(errorModal: IBuyerErrorModal): void {
    this.itemListErrorModal = errorModal;
  }

  alertConfirmBtn(): void {
    event.preventDefault();
    event.stopPropagation();
    this.sharedService.isGradingGuideOpen = false;
    if (!this.showDetailedPopUp) {
      this.selectedItem = { id: '' };
    }
  }

  onScrollDown(event) {
    this.blockPreloader = true;
    const endPerPage = this.pageNumber * this.pastPurhcasePayload.pagination.pageSize;
    if (event.endIndex < endPerPage - 1) {
      return;
    }
    this.pageNumber += 1;
    this.getPastPurchases(false, true);
  }

  showNotification(tooltip): void {
    this.showNotificationTooltip = false;
    setTimeout(() => {
        this.notificationItem = tooltip.item;
        this.tooltipPosition = tooltip.event;
        this.showNotificationTooltip = true;
    }, 100);
  }

  hideNotificationTooltip(): void {
    this.showNotificationTooltip = false;
  }

  showWaitlist(tooltip): void {
    if (tooltip.isShowWaitlist) {
      this.waitlistItem = tooltip.item;
      this.waitlistTooltipPosition = tooltip.event;
      this.showWaitlistTooltip = true;
    } else {
      this.showWaitlistTooltip = false;
    }
  }

  hideWaitlistTooltip(): void {
    this.showWaitlistTooltip = false;
  }

  refresh() {
    location.reload();
  }

  closeRestrictedCountryPopup(){
    this.showRestrictedCountryPopup = false;

    if (!this.showDetailedPopUp) {
      this.selectedItem = { id: '' };
    }
  }

  trackByFn(index: number, item: IItem){
    return item.highlightId;
  }

  showOfferpopup(item: IItem): void {
    if (this._sessionService.userRole !== 'GUEST') {
      this.selectedItemOffer = item;
    }
  }

  hideOfferpopup(): void {
    this.selectedItemOffer = undefined;
  }
}
