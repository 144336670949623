import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-mini-progress-chart',
    templateUrl: './mini-progress-chart.component.html',
    styleUrls: ['./mini-progress-chart.component.scss'],
    standalone: true
})
export class MiniProgressChartComponent implements OnInit {
  private readonly _startingZeroPercentage = 600;
  private readonly _increasePercentageStep = 1.47;
  strokeDashOffset = 600;
  @Input() percentage: number;
  @Input() color: string;
  @Input() count: number;

  constructor() { }

  ngOnInit() {
    this.transformPercentageToSVG();
  }

  transformPercentageToSVG() {
    this.strokeDashOffset = this._startingZeroPercentage - (this.percentage * this._increasePercentageStep);
  }

}
