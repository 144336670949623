import { Component, OnInit, AfterContentInit, OnDestroy } from '@angular/core';
import { UserService } from '../user.service';
import { AuthService } from '../../auth/auth.service';
import { TopService } from '../../shared/services/top.service';
import { CartService } from '../../buyer/cart/cart.service';
import { SessionService } from '../../services/session.service';
import { VendorService } from '../../vendor/vendor.service';
import { GuestService } from '../../shared/services/guest.service';
import { SharedService } from '../../shared/shared.service';
import { ItemsService } from '../../shared/services/items.service';
import { environment } from '../../../environments/environment';
import { ResetPasswordService } from '../../shared/services/reset-password.service';
import { PubSubService } from '../../core/pubsub.service';
import { SharedSource } from '../../core/shared-source';
import { Subscription } from 'rxjs';
import { UserFiltersService } from '../../buyer/user-filters/user-filters.service';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss'],
    standalone: true,
    imports: [NgIf]
})
export class UserProfileComponent implements OnInit, AfterContentInit, OnDestroy {
  fullAddress = '';
  resetAlert = false;
  subscription: Subscription;

  constructor(
    public userService: UserService,
    public auth: AuthService,
    public topService: TopService,
    public pwdService: ResetPasswordService,
    private cartService: CartService,
    public sessionService: SessionService,
    private vendorService: VendorService,
    private pubSubService: PubSubService,
    public sharedService: SharedService,
    private itemsService: ItemsService,
    private userFiltersService: UserFiltersService
  ) { }

  ngOnInit() {
    this.sharedService.isLogoutPage = true;

    if (
      (!this.userService.contactEmail && this.sessionService.userRole !== 'SUPER' ) ||
      (this.sessionService.userRole === 'SUPER' && !this.userService.currentCustomer &&
      this.sessionService.selectedCustomer)) {
      // if reload, the user may be empty
      this.getUser();
    }
    if (this.sessionService.userRole &&
      !['ADMIN', 'SELLER', 'VENDORMGR'].includes(this.sessionService.userRole)
      ) {
      this.topService.showSearch = true;

    } else {
      if (sessionStorage.getItem('selected_customer')) {
        this.userService.contactEmail = this.sessionService.userEmail;
        this.userService.bpCode = sessionStorage.getItem('selected_customer');
        this.userService.companyName = this.sessionService.companyName;
      } else {
        this.getUser();
      }
    }

    this.subscription = this.pubSubService.sharedSubject.subscribe(event => {
      if (event.name === SharedSource.changeCustomerVendor) {
        const {bpCode, companyName} = event.data;
        this.userService.bpCode = bpCode;
        this.userService.companyName = companyName;
      }
    });
  }

  ngAfterContentInit() {
    this.topService.isCatalog = false;
  }

  getUser(): void {
    this.topService.loading = true;
    this.userService.getProfile()
    .subscribe(
      profile => {
      this.userService.billing = profile.billingAddresses;
      this.userService.shipping = profile.shippingAddresses;
      this.userService.companyName = profile.companyName;
      this.userService.bpCode = profile.bpCode;
      this.userService.discount = profile.discount;
      this.userService.contactEmail = profile.contact.email;
      this.userService.orderBankInfo = profile.beneficiaryBank;
      const billing = this.userService.billing.find(c => c.isDefault);

      if (billing) {
        this.fullAddress = `${billing.address1}
        ${!billing.address2 ? '' : billing.address2}
        ${billing.city}, ${billing.state}
        ${!billing.country ? '' : billing.country} ${billing.zip}`;
      }
      if (profile.isCreditCardAllowed !== null || profile.isCreditCardAllowed !== undefined) {
        this.userService.isCreditCardAllowed = profile.isCreditCardAllowed;
      }

      this.topService.loading = false;
    },
    error => {
      console.error('Error getting user profile ', error);
      this.topService.loading = false;
    });
  }

  changePassword(): void {
    // this.pwdService.resetPassword(this.userService.contactEmail, environment.auth0_realm);
    this.pwdService.resetPassword(this.userService.contactEmail, environment.hubx_auth_realm);
    this.resetAlert = true;
  }

  logout(): void {
    this.itemsService.selectedTopAttribute = '';
    this.itemsService.isLandingPage = true;
    this.itemsService.isJustSoldUrl = false;
    this.userFiltersService.clearUserFilterList();
    this.cartService.resetCart();
    this.userService.logout();
  }

  ngOnDestroy() {
    this.sharedService.isLogoutPage = false;
    this.subscription.unsubscribe();
  }
}
