import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { VendorService } from '../../vendor.service';
import { IPo } from '../../interface/IPo';
import { PageErrorService } from '../../../page-error/page-error.service';
import { IPoTrackingInformationRequest } from '../../interface/IPoTrackingTrackingInformationRequest';
import { IErrorModal } from '../../interface/IErrorModal';
import { SharedService } from '../../../shared/shared.service';
import { Subscription } from 'rxjs';
import { Globals } from '../../shared/globals';
import { SharedSource } from '../../../core/shared-source';
import { PubSubService } from '../../../core/pubsub.service';

import * as _ from 'lodash';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe, NgIf, NgFor, CurrencyPipe } from '@angular/common';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@Component({
    selector: 'app-po-full-details',
    templateUrl: './po-full-details.component.html',
    styleUrls: ['./po-full-details.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, BsDatepickerModule, NgFor, CurrencyPipe, DatePipe]
})
export class PoFullDetailsComponent implements OnInit, OnDestroy {
  po: IPo;
  poCopy: IPo;
  subscriptions: Subscription[] = [];
  editTrackingEnabled = false;
  isFromEdit = false;
  form: FormGroup;
  formErrors: any;
  trackingInformationRequest: IPoTrackingInformationRequest = {
    vendorInvoiceNo: null,
    shippingInformation: {}
  };
  defaultShippingAddressList: string[] = [];
  shippingAddressList: string[] = [];
  billingAddressList: string[] = [];
  hasTrackingInfo = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private vendorService: VendorService,
    public date: DatePipe
  ) { }

  ngOnInit() {
    this.vendorService.isFullDetailsPage = true;
    this.subscriptions.push(
      this.activatedRoute.params.subscribe((params) => {
        this.getPurchaseOrder(params['id']);
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.vendorService.isFullDetailsPage = false;
    this.isFromEdit = false;
  }

  getPurchaseOrder(id: number): void {
    this.subscriptions.push(
      this.vendorService.getPOId(id).subscribe(
        data => {
          this.po = data;
          this.poCopy = _.cloneDeep(this.po);
          if (this.po.orderStatus === 'open') {
            this.editTracking();
            this.isFromEdit = false;
          }
          this.setUpAddresses();
          this.setUpPoShippingInfo();
        },
        err => {
          console.error('Error getting purchase order: ', err);
        }
      )
    );
  }

  setUpAddresses() {
    this.defaultShippingAddressList = this.vendorService.defaultShippingAddress.split('\r');
    if (this.po.shippingAddress) {
      this.shippingAddressList = this.po.shippingAddress.split('\r');
    }
    if (this.po.billingAddress) {
      this.billingAddressList = this.po.billingAddress.split('\r');
    }
  }

  setUpPoShippingInfo(): void {
    const todaysDate = new Date();
    if (!this.po.shippingInformation) {
      this.po.shippingInformation = {};
    }
    if (!this.po.shippingInformation.shippingDate) {
      this.po.shippingInformation.shippingDate = todaysDate; // todaysDate;
    } else {
      this.po.shippingInformation.shippingDate = new Date(this.po.shippingInformation.shippingDate);
    }

    if (!this.po.shippingInformation.eta) {
      this.po.shippingInformation.eta = ''; // todaysDate;
    } else {
      this.po.shippingInformation.eta = new Date(this.po.shippingInformation.eta);
    }
  }

  editTracking() {
    this.editTrackingEnabled = true;
    this.isFromEdit = true;
    this.formErrors = this.vendorService.getDefaultTrackingFormErrors();
    this.form = this.vendorService.createTrackingForm(this.po);
    this.vendorService.prepareTrackingToUpdate(this.po, this.form);
    this.vendorService.setSubscriptions(this.formErrors, this.form);
  }

  cancelTracking(): void {
    this.po = _.cloneDeep(this.poCopy);
    this.form.setValue({
      invoice: this.po.vendorInvoiceNo,
      shipmentDate: this.date.transform(this.po.shippingInformation.shippingDate, 'MM.dd.yyyy'),
      shipmentType: this.po.shippingInformation.transportationMode,
      carrierName: this.po.shippingInformation.carrier,
      bol: this.po.shippingInformation.billOfLadingNumber,
      etaDate: this.date.transform(this.po.shippingInformation.eta, 'MM.dd.yyyy'),
      trackingNbr: this.po.shippingInformation.trackingNumber,
      notes: this.po.shippingInformation.comments
    });
    this.isFromEdit ? this.editTrackingEnabled = false : this.editTrackingEnabled = true;
  }

  saveTracking(): void {
    if (this.form.valid) {
      this.vendorService.prepareTrackingToSave(this.form, this.po, this.trackingInformationRequest);
      this.subscriptions.push(
        this.vendorService.addTrackingToPurchaseOrder(this.trackingInformationRequest, this.po.docEntry).subscribe(
          data => {
            this.poCopy = _.cloneDeep(this.po);
            this.editTrackingEnabled = true;
          },
          err => {
            console.error('Error saving the tracking: ', err);
          }
        )
      );
    } else {
      this.vendorService.validateTrackingForm(this.formErrors, this.form);
    }
  }

  goBackToPOList(): void {
    this.router.navigate(['vendor/purchase-orders']);
  }
}
