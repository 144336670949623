import { Component, OnInit } from '@angular/core';
import { BonusOfferFullComponent } from '../bonus-offer-full/bonus-offer-full.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-bonus-offer',
    templateUrl: './bonus-offer.component.html',
    styleUrls: ['./bonus-offer.component.scss'],
    standalone: true,
    imports: [NgIf, BonusOfferFullComponent]
})
export class BonusOfferComponent implements OnInit {
  isShowBonusFullOffer = false;

  constructor() { }

  ngOnInit() {
  }

  showBonusFullOffer():void{
  	this.isShowBonusFullOffer = true;
  }
  hideBonusFullOffer():void{
    this.isShowBonusFullOffer = false;
  }

}
