import { Component, OnInit, Output, EventEmitter, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserService } from '../user.service';
import { PubSubService } from '../../core/pubsub.service';
import { SharedService } from '../../shared/shared.service';
import { TopService } from '../../shared/services/top.service';
import { ICountry } from '../../shared/interfaces/ICountry';
import { IMobileNotification } from '../../shared/interfaces/IMobileNotification';
import { MobileMsgOptionsEnum } from '../../../app/shared/interfaces/UserProfile';
import { ErrorModalComponent } from '../../shared/ui-components/error-modal/error-modal.component';
import { NgxMaskModule } from 'ngx-mask';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-mobile-notifications-modal',
    templateUrl: './mobile-notifications-modal.component.html',
    styleUrls: ['./mobile-notifications-modal.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgClickOutsideDirective, NgFor, NgxMaskModule, ErrorModalComponent]
})
export class MobileNotificationsModalComponent implements OnInit, OnDestroy {
  @ViewChild('n1') n1: ElementRef;
  @ViewChild('n2') n2: ElementRef;
  @ViewChild('n3') n3: ElementRef;
  @ViewChild('n4') n4: ElementRef;

  @Output() closeModal: EventEmitter<boolean> = new EventEmitter();
   isConfirm = false;
   notifEnabled;
   phoneIsVerified = false;
   verificationCode = '';
   showError = false;
   isSMSAllowed = true;
   showSMSPopup = false;
   isDropdownToggled = false;
   subscription: Subscription;
   payload: IMobileNotification;
   loading = false;

   countries: ICountry[];
   selectedCountry: ICountry;

   fgPhoneNumb: FormGroup = new FormGroup({
     msgType: new FormControl(null, Validators.required),
     phoneNumb: new FormControl(null, [Validators.required]),
   });

   fgConfirm: FormGroup = new FormGroup({
     n1: new FormControl(null, [Validators.required, Validators.pattern(/\d{1}/)]),
     n2: new FormControl(null, [Validators.required, Validators.pattern(/\d{1}/)]),
     n3: new FormControl(null, [Validators.required, Validators.pattern(/\d{1}/)]),
     n4: new FormControl(null, [Validators.required, Validators.pattern(/\d{1}/)]),
   });

   constructor(
     public userService: UserService,
     public pubSubService: PubSubService,
     public sharedService: SharedService,
     public topService: TopService
     ) { }

   ngOnInit() {
     if (this.userService.userProfile ) {
      this.patchPhoneNumberForm();
     } else {
      this.userService.getLoggedInUserProfile()
      .subscribe( u => {
          this.userService.userProfile = u;
          this.patchPhoneNumberForm();
      });
     }

     this.autofocusConfirmationForm();
     this.verifyCodeOnValueChanges();
     
     this.userService.getCountriesCodes().subscribe(data => {
       this.countries = data;
       this.selectUserCountryCode();
       this.resetSMSRestrictionOnValueChanges();
      });

      if (this.topService.isMobile) {
        document.documentElement.classList.add('is-modal');
      }
   }

   patchPhoneNumberForm() {
    const msgOpt = (this.userService.userProfile.messageOptions) ?
    this.userService.userProfile.messageOptions : 1;
     this.fgPhoneNumb.get('msgType').patchValue(msgOpt);

     if ( this.userService.userProfile.phoneNumber ) {
       this.fgPhoneNumb.get('phoneNumb').patchValue(this.userService.userProfile.phoneNumber);
     }
   }

   autofocusConfirmationForm() {
    this.fgConfirm.get('n1').valueChanges
    .subscribe(v => {
      if (v !== null && !!v.match(/\d{1}/) && this.n2 ) {
        this.n2.nativeElement.focus();
      }
    });
    this.fgConfirm.get('n2').valueChanges
    .subscribe(v => {
      if (v !== null && !!v.match(/\d{1}/) && this.n3 ) {
        this.n3.nativeElement.focus();
      }
    });
    this.fgConfirm.get('n3').valueChanges
    .subscribe(v => {
      if (v !== null && !!v.match(/\d{1}/) && this.n4 ) {
        this.n4.nativeElement.focus();
      }
    });
  }

  verifyCodeOnValueChanges() {
    this.fgConfirm.get('n4').valueChanges
    .subscribe(v => {
      if (v !== null && !!v.match(/\d{1}/) ) {
        this.confirmPhoneNumber();
      }
    });
  }
  resetSMSRestrictionOnValueChanges(){
    this.fgPhoneNumb.get('msgType').valueChanges
    .subscribe(newValue => {
      if(this.isSelectedCountryNotAllowedSMS() && newValue === MobileMsgOptionsEnum.sms){
        this.showSMSPopup = true;
        this.isSMSAllowed = false;
      }else{
        this.isSMSAllowed = true;
      }

    });
  }

  onSmsPopupClose(){
    this.showSMSPopup = false;
    this.fgPhoneNumb.get('msgType').patchValue(MobileMsgOptionsEnum.whatsApp);
  }


   selectUserCountryCode() {
    let userCountry;

    if ( this.countries && this.userService.userProfile && this.userService.userProfile.countryId ) {
      userCountry = this.countries.find(c => c.id === this.userService.userProfile.countryId);
    }

    if (!userCountry) {
      userCountry = this.countries[0];
    }

    this.selectCountry(userCountry);
   }

   savePhoneNumber() {
     if (!this.fgPhoneNumb.valid || !this.selectedCountry || this.loading) {
      return;
    }
     
     const formValue = this.fgPhoneNumb.value;
     if (this.isSelectedCountryNotAllowedSMS() && 
      formValue && formValue.msgType === MobileMsgOptionsEnum.sms) {
        this.showSMSPopup = true;
        this.isSMSAllowed = false;
        return;
    }
     this.topService.loading = true;

     this.payload = this.userService.getDefaultPayloadPhoneRequest();

     this.payload.phoneNumber = formValue.phoneNumb;
     this.payload.countryCode = `+${this.selectedCountry.code}`;
     this.payload.countryId = this.selectedCountry.id;
     this.payload.messageOptions = formValue.msgType;

     // if not enabled user wont receive verification code
     this.payload.isMobileEnabled = true;

     // in case user didnt confirm first time
     this.payload.isResendCode = true;

     this.loading = true;
     this.userService.addPhoneNumber(this.payload)
     .subscribe(
       res => {
         if (res && res.verificationCode) {
           // console.log(' Verification code: ', res.verificationCode);
           this.verificationCode = res.verificationCode;
           this.clearConfirmationForm();
           this.isConfirm = true;
         }
         this.refreshUserProfile();
         this.loading = false;
     },
     err => {
       console.error('Error saving user phone number', err);
       this.loading = false;
      });
   }

   resendCodeModal() {
     this.topService.loading = true;
     this.payload = this.userService.getDefaultPayloadPhoneRequest();
     this.payload.isMobileEnabled = true;
     this.payload.isResendCode = true;

     this.userService.addPhoneNumber(this.payload)
     .subscribe(
       res => {
         if (this.isConfirm && res && res.verificationCode) {
           this.verificationCode = res.verificationCode;
         }
         this.topService.loading = false;
     },
     err => {
       console.error('Error toggling notifications ', err);
       this.topService.loading = false;
     });
   }

   refreshUserProfile() {
    // this.topService.loading = true;
     this.userService.getLoggedInUserProfile()
     .subscribe( u => {
       this.userService.userProfile = u;
      //  this.topService.loading = false;
       });
   }

   confirmPhoneNumber() {
     const formValue = this.fgConfirm.value;
     this.topService.loading = true;
     let enteredCode = '';
     // tslint:disable-next-line: forin
     for ( const n in formValue) {
       enteredCode = enteredCode.concat(this.fgConfirm.get(n).value);
     }

     this.payload = this.userService.getDefaultPayloadPhoneRequest();

     // set it to true here so user can receive the text on their phones
     this.payload.isMobileEnabled = true;
     this.payload.verificationCode = enteredCode;

     this.userService.verifyPhoneNumber(this.payload)
     .subscribe(
       res => {
       if (res.isVerified) {
        //  this.refreshUserProfile();
         this.isConfirm = false;
         this.phoneIsVerified = true;
         this.showError = false;
       } else {
         this.clearConfirmationForm();
         this.showError = true;
       }
       this.topService.loading = false;
     },
     err => {
       console.error('Error verifying phone number: ', err);
       this.topService.loading = false;
     });
   }

   clearConfirmationForm() {
     const resetVals = {
       n1: null,
       n2: null,
       n3: null,
       n4: null,
     };
     this.showError = false;
     this.fgConfirm.setValue(resetVals);
      if ( this.n1 ) {
        this.n1.nativeElement.focus();
      }
   }

   resendVerificationCode() {
     this.showError = false;
     this.clearConfirmationForm();
     this.resendCodeModal();
   }

   selectCountry(country: ICountry) {
     if (!country) { return; }
     this.selectedCountry = country;
     this.isDropdownToggled = false;
     this.isSMSAllowed = true;

    if(this.isSelectedCountryNotAllowedSMS() &&
       this.fgPhoneNumb.get('msgType').value === MobileMsgOptionsEnum.sms) {
      this.fgPhoneNumb.get('msgType').patchValue(MobileMsgOptionsEnum.whatsApp);
    }
   }

   toggleDropdown(): void {
     this.isDropdownToggled = !this.isDropdownToggled;
   }

   hideDropdown($event): void {
     this.isDropdownToggled = false;
     $event.stopPropagation();
   }

   ngOnDestroy() {
     if (this.topService.isMobile) {
        document.documentElement.classList.remove('is-modal');
     }
     this.refreshUserProfile();
   }

   hoverBtn($event,isBtnOnHover):void{
    if ($event.target.classList.contains('disabled')) { return; }
    if (isBtnOnHover && $event.target.querySelector('path')) {
      $event.target.querySelector('path').setAttribute('d','M17.6199048,1.3243759 C7.07037232,2.98624006 0,11.243368 0,22 C0,32.756632 7.07037232,41.01376 17.6199048,42.6756241 C28.8294425,44.4414586 137.170558,44.4414586 148.380095,42.6756241 C158.929628,41.01376 166,32.756632 166,22 C166,11.243368 158.929628,2.98624006 148.380095,1.3243759 C137.170558,-0.441458634 28.8294425,-0.441458634 17.6199048,1.3243759 Z');
    } else if (!isBtnOnHover && $event.target.querySelector('path')){
      $event.target.querySelector('path').setAttribute('d','M21.046729,2 C9.97522627,2 1,10.954305 1,22 C1,33.045695 9.97522627,42 21.046729,42 C21.046729,42 144.953271,42 144.953271,42 C156.024773,42 165,33.045695 165,22 C165,10.954305 156.024773,2 144.953271,2 C144.953271,2 21.046729,2 21.046729,2 Z');
    } else {
      return;
    }
  }

  isSelectedCountryNotAllowedSMS(): boolean{
    return this.countries.some(c => !c.allowSMS && c.id === this.selectedCountry.id);
  }
}
