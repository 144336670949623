import { Component, OnInit, Input, ChangeDetectionStrategy  } from '@angular/core';
import { ItemsService } from '../../../shared/services/items.service';
import { FormsModule } from '@angular/forms';
import { NgIf, CurrencyPipe, DatePipe } from '@angular/common';

@Component({
    selector: 'app-item-dashboard',
    templateUrl: './item-dashboard.component.html',
    styleUrls: ['./item-dashboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, FormsModule, CurrencyPipe, DatePipe]
})
export class ItemDashboardComponent implements OnInit {

  @Input() item;

  constructor( public itemsService: ItemsService
    ) { }

  ngOnInit() {
  }

}
