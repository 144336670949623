<div class="restriction-modal">
  <div class="restriction-modal-dialog">
    <div class="restriction-modal-content" (clickOutside)="close()"
    [exclude]="'.restrictions-select, .restrictions-select-right__btn'" [clickOutsideEvents]="'click'" [excludeBeforeClick]="true">
      <div class="restriction-modal-content__header">Restrictions</div>
      <div class="restriction-modal-content__body">
        <!-- <form [formGroup]="fg"> -->
          <div class="restriction-form-textarea">
            <textarea placeholder="Example: No US Sales" [formControl]="newRestriction"></textarea>
          </div>
          <div class="restriction-prev-copy" *ngIf="currentRestriction">
            <div class="restriction-prev-copy__left">
              <span>Your previous copy</span>
              <p> {{ currentRestriction }}</p>
            </div>
            <span class="restriction-prev-copy__btn" (click)="useOldRestriction()">Use It</span>
          </div>
        <!-- </form> -->
      </div>
      <div class="restriction-modal-content__footer">
        <button type="button" class="restriction-form-button cancel" (click)="close()">Cancel</button>
        <button type="button" class="restriction-form-button save" [disabled]="!newRestriction.value" (click)="patchRestriction()">Save</button>
      </div>
    </div>
  </div>
</div>
