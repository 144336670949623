import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuctionsService } from '../auctions.service';
import { Router, RouterLinkActive, RouterLink } from '@angular/router';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { TopService } from '../../../shared/services/top.service';
import { IAuctionItem } from '../../interfaces/iauction-item';
import { Subscription } from 'rxjs';
import { PubSubService } from '../../../core/pubsub.service';
import { SharedSource } from '../../../core/shared-source';
import { SessionService } from '../../../services/session.service';
import { UserService } from '../../../user/user.service';
import { StatusFilterEnum } from '../../enums/statusFilterEnum';
import { GuestService } from '../../../shared/services/guest.service';
import { CustomCurrencyPipe } from '../../../shared/pipes/currency.pipe';
import { AuctionConfirmComponent } from '../../../shared/auction-confirm/auction-confirm.component';
import { CountdownComponent } from '../../../shared/countdown/countdown.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'app-auction-slider',
    templateUrl: './auction-slider.component.html',
    styleUrls: ['./auction-slider.component.scss'],
    standalone: true,
    imports: [NgIf, RouterLinkActive, RouterLink, CarouselModule, NgFor, CountdownComponent, NgClass, AuctionConfirmComponent, CustomCurrencyPipe]
})
export class AuctionSliderComponent implements OnInit, OnDestroy {
  carouselOptions = {
    dots: true,
    nav: true,
    loop: true,
    center: true,
    autoWidth: true,
    mouseDrag: false,
    lazyLoad: true,
    responsive: {
          0: {
            items: 1
          }
        }
    //autoWidth: true
  };

  showBidConfirm = false;
  currentItem: IAuctionItem;
  pubServiceSubscription: Subscription;
  showCarousel = false;

  constructor(public auctionService: AuctionsService,
    private sessionService: SessionService,
    private _router: Router,
    private sanitizer: DomSanitizer,
    private pubSubService: PubSubService,
    public topService: TopService,
    public userService: UserService,
    private guestService: GuestService) { }

  ngOnInit() {
    this.pubServiceSubscription = this.pubSubService.sharedSubject.subscribe(
      myEvent => {
        if (myEvent.name === SharedSource.updateAuctionItem) {
          this.updateAuctionItem(myEvent.data);
        } else if (myEvent.name === SharedSource.updateAuctionNotifMsg) {
          this.updateAuctionNotifMsg(myEvent.data);
        } else if (myEvent.name === SharedSource.auctionItems) {
          this.getAuctionItemsAsync(this);
        }
      });
      setTimeout(() => {
        this.auctionService.setSearchPayload({ statusFilter: StatusFilterEnum.active });
      }, 0);
    
  }

  ngOnDestroy() {
    if (this.pubServiceSubscription) {
      this.pubServiceSubscription.unsubscribe();
    }
  }

  addToWatchlist(item: IAuctionItem, event: MouseEvent) {
    if (event && !event.isTrusted) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    if (this.userService.isGuest) {
      this.userService.guestPop();
      return;
    }
    const callbackObj = {
      scope: this,
      fn: this.addToWatchlistAsync
    };
    this.auctionService.addToWatchlist(item, callbackObj);
    if (event) { event.stopPropagation(); }
  }

  addToWatchlistAsync(scope, item: IAuctionItem) {
    const elements = Array.from(document.getElementsByClassName('watch-' + item.ID));
    elements.forEach(element => {
      if (item.IsWatching) {
        element.setAttribute('class', 'btn-watch _active' + ' watch-' + item.ID);
      } else {
        element.setAttribute('class', 'btn-watch' + ' watch-' + item.ID);
      }
    });
  }

  descriptionSanitizer(description: string): SafeHtml {
    const htmlDescription: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(description);
    return htmlDescription;
  }

  goToAuctionDetail(auctionId: number) {
    const urlPerUser = (this.userService.isGuest) ? '/guest/' : '';
    this._router.navigate([`${urlPerUser}auctions/details`, auctionId]);
  }

  getAuctionsUrl() {
    const urlPerUser = (this.userService.isGuest) ? '/guest' : '';
    return `${urlPerUser}/auctions`;
  }

  quickBid(item: IAuctionItem) {
    event.stopPropagation();
    this.currentItem = item;
    this.showBidConfirm = true;
  }

  bidConfirm(confirmed) {
    if (confirmed) {
      this.auctionService.quickBid(this.currentItem);
    }
    this.showBidConfirm = false;
  }

  getAuctionItemsAsync(scope) {
    let avoidInfoClickEvent = false;

    setTimeout(() => {
      scope.showCarousel = true;
    }, 0);

    setTimeout(() => {
      $('.owl-stage .cloned').on('click', '.auction-item__img', function (event) {
        const itemId = +String(event.currentTarget.id).split('-')[1];
        scope.goToAuctionDetail(itemId);
      });

      $('.owl-stage .cloned').on('click', '.auction-item__info', function (event) {
        if (!avoidInfoClickEvent) {
          const itemId = +String(event.currentTarget.id).split('-')[1];
          scope.goToAuctionDetail(itemId);
        } else {
          avoidInfoClickEvent = false;
        }
      });

      $('.owl-stage .cloned .btn-watch').on('click', function (event) {
        avoidInfoClickEvent = true;
        const itemId = +String(event.currentTarget.id).split('-')[1];
        const item: IAuctionItem = scope.getAuctionItemById(itemId);
        scope.addToWatchlist(item);
      });

      // necessary to apply styles to the inner html, if ViewEncapsulation.None is used then carousel breaks
      $('.owl-stage .cloned .auction-item-desc__txt p').each(function (event) {
        this.setAttribute('style', 'font-size: 14px; line-height: 21px; color: rgba(#1B1B1B, .9); margin: 0; max-height: 105px; overflow: hidden;');
      });
    }, 0);
  }

  getAuctionItemById(itemId: number): IAuctionItem {
    return this.auctionService.auctionItems.find(x => x.ID === itemId);
  }

  updateAuctionItem(item: IAuctionItem) {
    let element;
    // quick bid amount
    element = document.getElementById('quickBidAmount-' + item.ID);
    if (item.ActionCount) {
      element.innerHTML = item.CurrentPrice + item.Increment;
    } else {
      element.innerHTML = item.CurrentPrice;
    }

    // bids qty
    element = document.getElementById('actionCount-' + item.ID);
    element.innerHTML = item.ActionCount;

    //  price
    element = document.getElementById('currentPrice-' + item.ID);
    element.innerHTML = '$' + item.CurrentPrice;

    // qty
    element = document.getElementById('currentQuantity-' + item.ID);
    element.innerHTML = item.CurrentQuantity + 'Units';

    // isWinning
    element = document.getElementById('isWinning-' + item.ID);
    if (item.Status === 'Active' && item.IsWinning) {
      element.innerHTML = 'You are winning';
      element.setAttribute('class', 'auction-item-desc__bid _win');
    } else if (item.Status === 'Active' && !item.IsWinning) {
      element.innerHTML = 'You have been outbid';
      element.setAttribute('class', 'auction-item-desc__bid _lost');
    } else if (item.Status === 'Successful' && item.IsWinning) {
      element.innerHTML = 'You won';
      element.setAttribute('class', 'auction-item-desc__bid _win');
    } else if (item.Status === 'Successful' && !item.IsWinning) {
      element.innerHTML = 'You lost';
      element.setAttribute('class', 'auction-item-desc__bid _lost');
    }
  }

  updateAuctionNotifMsg(item: IAuctionItem) {
    const element = document.getElementById('notifMsg-' + item.ID);
    element.innerHTML = item.NotificationMessages;
  }

  updateUrl() {
    if (this.userService.isGuest) {
      return '/guest/auctions';
    } else {
      return '/auctions';
    }
  }


}
