import { Component, OnInit, Input } from '@angular/core';
import { AuctionsService } from '../auctions.service';
import { TopService } from '../../../shared/services/top.service';
import * as moment from 'moment';
import { CustomCurrencyPipe } from '../../../shared/pipes/currency.pipe';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'app-auction-bid-history',
    templateUrl: './auction-bid-history.component.html',
    styleUrls: ['./auction-bid-history.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, NgClass, CustomCurrencyPipe]
})
export class AuctionBidHistoryComponent implements OnInit {
  @Input() auctionItemId: number;
  moment = moment;

  constructor(public auctionsService: AuctionsService, public topService: TopService) {
  }

  ngOnInit() {
    this.getBidHistory();
  }

  getBidHistory() {
    this.auctionsService.getBidHistory();
  }


}
