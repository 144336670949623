import * as models from './model';
import { IPastPurchaseInfo } from './IPastPurchaseInfo';
import { NotificationTypesEnum } from '../../shared/interfaces/INotificationDetail';
import { OfferStatusEnum } from '../enums/offerStatusEnum';

enum AttrDataType {
    tag = 1,
    range = 2,
    multiple = 3
}
export interface IItem {
    id?: string;
    highlightId?: string;
    vendorItemId?: string;
    itemCode?: string;
    partNumber?: string;
    manufacturer?: string;
    manufacturerLogoUrl?: string;
    description?: string;
    exwPoint?: string;
    mpn?: string;
    moq?: number;
    mxq?: number;
    inventory?: models.IInventory;
    prices?: models.IPrice[];
    attributes?: models.IItemAttribute[];
    links?: models.ILink[];
    overwrittenPrice?: number;
    unitPrice?: number;
    qty?: number;
    previousQuantityAdded?: number;
    added?: boolean;
    beingEdited?: boolean;
    inCart?: boolean;
    comments?: models.Comment[];
    reservedQty?: number;
    leadTimeDays?: number;
    baseUnitPrice?: number;
    gradingGuideUrl?: string;
    quantitySold?: number;
    priceSold?: number;
    prevPrice?: number;
    pastPurchaseInfo?: IPastPurchaseInfo;
    notificationInfo?: NotificationTypesEnum[];
    restrictedCountries?: string[];
    notificationInfoObj?: {
        priceDrop: boolean,
        moreInventory: boolean,
        waitlist: boolean,
        priceDropInitialValue: boolean,
        moreInventoryInitialValue: boolean,
        waitlistInitialValue: boolean
    };
    notificationDetails?: INotificationDetailBuyer[];
    lastNotificationDate?: string;
    loading?: boolean;
    offerStatus?: number;
    hasPendingOffers?: boolean;
}

export interface INotificationDetailBuyer {
    isNew: boolean;
    notificationType: NotificationTypesEnum;
    notificationStatus: eNotificationStatusBuyer;
    notificationValue: any;
    timeStamp: string;
}

export enum eNotificationStatusBuyer {
    waiting,
    fulfilled,
    rejected
}
