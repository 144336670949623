import { Component, OnInit, OnDestroy } from '@angular/core';
import { NotificationCenterVendorService, TabsEnum } from '../notification-center-vendor.service';
import { Subscription } from 'rxjs';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-notification-helper',
    templateUrl: './notification-helper.component.html',
    styleUrls: ['./notification-helper.component.scss'],
    standalone: true,
    imports: [NgIf]
})
export class NotificationHelperComponent implements OnInit, OnDestroy {
  isArchive = false;
  text = 'Notifications';
  subscriptions: Subscription[] = [];

  constructor(private _notifCenterSvc: NotificationCenterVendorService) { }

  ngOnInit() {
    this.subscriptions.push(
        this._notifCenterSvc.tabSubject$()
        .subscribe(tab => {
          this.isArchive = (tab === TabsEnum.archive);
          this.text = (this.isArchive) ? 'Archives' : 'Notifications';
        })
    );
  }

ngOnDestroy() {
  this.subscriptions.forEach(s => s.unsubscribe());
}

}
