import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { formatDate } from '@angular/common';

@Pipe({
    name: 'utcToNowOffers',
    standalone: true
})
export class UtcToNowOffersPipe implements PipeTransform {

  transform(utcDate: string, fromOfferList: boolean): any {
    const lastEventLocalDatetime = moment.utc(utcDate).local();
    
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'days').startOf('day');
    const sevenDaysAgo = yesterday.clone().subtract(7, 'days');

    if (fromOfferList) {
      if (lastEventLocalDatetime.isSame(today, 'day')) {
        return lastEventLocalDatetime.format('LT');
      } else if (lastEventLocalDatetime.isSame(yesterday, 'day')) {
        return 'Yesterday';
      } else if (lastEventLocalDatetime.isAfter(sevenDaysAgo)) {
        return lastEventLocalDatetime.format('dddd');
      } else {
        return  formatDate(lastEventLocalDatetime.format(), 'MM/dd/yy', 'en');
      }
    }

    return lastEventLocalDatetime.format('LT');
  }

}
