import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TopService } from '../../shared/services/top.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: true
})
export class FooterComponent implements OnInit {
  currentYear  = new Date().getFullYear();

  constructor(private router: Router, public topService: TopService) { }

  ngOnInit() {
  }
  navigateTo(url: string): void {
    this.router.navigate([url]);
  }
}
