import { Component, OnInit, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { SessionService } from '../../services/session.service';
import { UserService } from '../../user/user.service';
import { GuestService } from '../../shared/services/guest.service';
import { Router } from '@angular/router';
import { SharedService } from '../../shared/shared.service';
import { IBuyerErrorModal } from '../../buyer/interfaces/IBuyerErrorModal';
import { CartService } from '../../buyer/cart/cart.service';
import { environment } from '../../../environments/environment';
import { ConnectionManagerService } from '../../shared/services/signalR/connection-manager.service';
import { eConnectionType } from '../../shared/services/signalR/connection-type';
import { ItemsService } from '../../shared/services/items.service';
import { MainTopComponent } from '../main-top.component';
import { ErrorModalComponent } from '../../shared/ui-components/error-modal/error-modal.component';
import { GuestTopMobileComponent } from '../../buyer/top/guest-top/guest-top-mobile/guest-top-mobile.component';
import { TopVendorMobileComponent } from '../../vendor/top-vendor/top-vendor-mobile/top-vendor-mobile.component';
import { TopMobileComponent } from '../../buyer/top/top-mobile/top-mobile.component';
import { GuestTopFullComponent } from '../../buyer/top/guest-top-full/guest-top-full.component';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'app-main-top-mobile',
    templateUrl: './main-top-mobile.component.html',
    styleUrls: ['./main-top-mobile.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass, GuestTopFullComponent, TopMobileComponent, TopVendorMobileComponent, GuestTopMobileComponent, ErrorModalComponent]
})
export class MainTopMobileComponent extends MainTopComponent implements OnInit, AfterViewChecked {

}
