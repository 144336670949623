import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { UserService } from '../user.service';
import { FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MobileMsgOptionsEnum } from '../../../app/shared/interfaces/UserProfile';
import { SharedService } from '../../shared/shared.service';
import { Subscription } from 'rxjs';
import { PubSubService } from '../../core/pubsub.service';
import { SharedSource } from '../../core/shared-source';
import { SessionService } from './../../services/session.service';
import { TopService } from '../../shared/services/top.service';
import { ICountry } from '../../shared/interfaces/ICountry';
import { IMobileNotification } from '../../shared/interfaces/IMobileNotification';
import { ErrorModalComponent } from '../../shared/ui-components/error-modal/error-modal.component';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { NgxMaskModule } from 'ngx-mask';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-mobile-notifications',
    templateUrl: './mobile-notifications.component.html',
    styleUrls: ['./mobile-notifications.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgxMaskModule, NgClickOutsideDirective, NgFor, ErrorModalComponent]
})
export class MobileNotificationsComponent implements OnInit {
 @ViewChild('n1') n1: ElementRef;
 @ViewChild('n2') n2: ElementRef;
 @ViewChild('n3') n3: ElementRef;
 @ViewChild('n4') n4: ElementRef;

  private _isEdit = false;
  private _isConfirm = false;
  notifEnabled;
  phoneIsVerified = false;
  isSMSAllowed = true;
  showSMSPopup = false;
  verificationCode = '';
  showError = false;
  isDropdownToggled = false;
  isVendor: boolean;
  subscription: Subscription;
  payload: IMobileNotification;

  countries: ICountry[];
  selectedCountry: ICountry;


  fgToggle: FormGroup = new FormGroup({
    notifToggle: new FormControl()
  });

  fgPhoneNumb: FormGroup = new FormGroup({
    msgType: new FormControl(null, Validators.required),
    phoneNumb: new FormControl(null, Validators.required),
  });

  fgConfirm: FormGroup = new FormGroup({
    n1: new FormControl(null, [Validators.required, Validators.pattern(/\d{1}/)]),
    n2: new FormControl(null, [Validators.required, Validators.pattern(/\d{1}/)]),
    n3: new FormControl(null, [Validators.required, Validators.pattern(/\d{1}/)]),
    n4: new FormControl(null, [Validators.required, Validators.pattern(/\d{1}/)]),
  });

  constructor(
    public userService: UserService,
    public pubSubService: PubSubService,
    public sharedService: SharedService,
    public topService: TopService,
    private _sessionService: SessionService) { }

  set isEdit(val) {
    this._isEdit = val;
    this.disableToggle(val || this.isConfirm);
  }

  get isEdit() {
    return this._isEdit;
  }

  set isConfirm(val) {
    this._isConfirm = val;
    this.disableToggle(val || this.isEdit);
  }

  get isConfirm() {
    return this._isConfirm;
  }

  ngOnInit() {
    this.topService.loading = true;

    // Always get profile cuz they might've disabled
    // notifications thru their phone directly
    this.userService.getLoggedInUserProfile()
    .subscribe( u => {
        this.userService.userProfile = u;
        this.setToggleValue();
        if ( !this.userService.userProfile.phoneNumber ) {
          this.editPhoneNumb();
        }
        this.topService.loading = false;
      },
      (err) => {
        this.topService.loading = false;
        console.error('Error getting userprofile ', err);
      });

    this.fgToggle.get('notifToggle').valueChanges
    .subscribe(
      v => {
        if (this.userService && v !== this.userService.userProfile.isMobileEnabled &&
            !this.isConfirm && !this.isEdit) {
            // call enpoint
            this.updateNotificationToggleOrResendCode(false);
        }
      }
    );

    this.autofocusConfirmationForm();
    this.verifyCodeOnValueChanges();

    this.userService.getCountriesCodes().subscribe(data => {
      this.countries = data;
      this.selectUserCountryCode();
      this.resetSMSRestrictionOnValueChanges();
    });

    this.isVendor = ['SELLER', 'VENDORMGR', 'ADMIN'].includes(this._sessionService.userRole);
  }

  onSmsPopupClose(){
    this.showSMSPopup = false;
    this.fgPhoneNumb.get('msgType').patchValue(MobileMsgOptionsEnum.whatsApp);
  }

  verifyCodeOnValueChanges() {

    this.fgConfirm.get('n4').valueChanges
    .subscribe(v => {
      if (v !== null && !!v.match(/\d{1}/) ) {
        this.confirmPhoneNumber();
      }
    });
  }

  resetSMSRestrictionOnValueChanges(){
    this.fgPhoneNumb.get('msgType').valueChanges
    .subscribe(newValue => {
      if(this.isSelectedCountryNotAllowedSMS() && newValue === MobileMsgOptionsEnum.sms){
        this.showSMSPopup = true;
        this.isSMSAllowed = false;
      }else{
        this.isSMSAllowed = true;
      }

    });
  }

  autofocusConfirmationForm() {
    this.fgConfirm.get('n1').valueChanges
    .subscribe(v => {
      if (v !== null && !!v.match(/\d{1}/) && this.n2 ) {
        this.n2.nativeElement.focus();
      }
    });
    this.fgConfirm.get('n2').valueChanges
    .subscribe(v => {
      if (v !== null && !!v.match(/\d{1}/) && this.n3 ) {
        this.n3.nativeElement.focus();
      }
    });
    this.fgConfirm.get('n3').valueChanges
    .subscribe(v => {
      if (v !== null && !!v.match(/\d{1}/) && this.n4 ) {
        this.n4.nativeElement.focus();
      }
    });
  }

  selectUserCountryCode() {
    let userCountry;

    if ( this.countries && this.userService.userProfile && this.userService.userProfile.countryId ) {
      userCountry = this.countries.find(c => c.id === this.userService.userProfile.countryId);
    }

    if (!userCountry) {
      userCountry = this.countries[0];
    }
    this.selectCountry(userCountry);
  }

  disableToggle( disable ) {
    const toggleControl = this.fgToggle.get('notifToggle');
    const opts = { emitEvent: false };
    if (toggleControl) {
      (disable) ? toggleControl.disable(opts) : toggleControl.enable(opts);
    }
  }

  setToggleValue() {
    const toggleControl = this.fgToggle.get('notifToggle');
    this.notifEnabled = (this.userService.userProfile) ? this.userService.userProfile.isMobileEnabled : false;
    if (toggleControl) {
      toggleControl.patchValue(this.notifEnabled);
    }
  }

  getPhoneNumberAndMsgOptionsText() {
    let formattedPhone = '';
    if ( this.userService.userProfile ) {
     formattedPhone = formattedPhone.concat(this.userService.userProfile.countryCode ? this.userService.userProfile.countryCode : '');
     formattedPhone = formattedPhone.concat(this.userService.userProfile.phoneNumber ? ' ' + this.userService.userProfile.phoneNumber : '');
      switch (this.userService.userProfile.messageOptions) {
        case MobileMsgOptionsEnum.sms : formattedPhone.concat(' ( SMS )'); break;
        case MobileMsgOptionsEnum.whatsApp : formattedPhone.concat(' ( WhatsApp )'); break;
      }
    }
    return formattedPhone;
  }

  editPhoneNumb() {
    if (!this.selectedCountry && this.countries) {
      this.selectUserCountryCode();
    }
    const msgOpt = (this.userService.userProfile.messageOptions) ?
    this.userService.userProfile.messageOptions : 1;

    this.fgPhoneNumb.get('msgType').patchValue(msgOpt);
    this.fgPhoneNumb.get('phoneNumb').patchValue(this.userService.userProfile.phoneNumber);
    this.isEdit = true;
  }

  savePhoneNumber() {
    if (!this.fgPhoneNumb.valid ||
      this.topService.loading ||
      !this.selectedCountry ||
      (!this.isThereAChange() && this.userService.userProfile.isVerified)) {
      return;
    }

    const formValue = this.fgPhoneNumb.value;
    if (this.isSelectedCountryNotAllowedSMS() && 
        formValue && formValue.msgType === MobileMsgOptionsEnum.sms) {
        this.showSMSPopup = true;
        this.isSMSAllowed = false;
        return;
    }
    this.topService.loading = true;

    this.payload = this.userService.getDefaultPayloadPhoneRequest();

    this.payload.phoneNumber = formValue.phoneNumb;
    this.payload.countryCode = `+${this.selectedCountry.code}`;
    this.payload.countryId = this.selectedCountry.id;
    this.payload.messageOptions = formValue.msgType;

    // set it to true here so user can receive the text on their phones
    this.payload.isMobileEnabled = true;

    // in case user didnt confirm first time
    this.payload.isResendCode = true;

    this.userService.addPhoneNumber(this.payload)
    .subscribe(
      res => {
        if (res && res.verificationCode) {
          this.verificationCode = res.verificationCode;
          // is user edit 2 times in a row the form may have previous values
          this.clearConfirmationForm();
          this.isConfirm = true;
        }
        this.refreshUserProfile();
        this.isEdit = false;
        this.topService.loading = false;
    },
    err => {
      console.error('Error saving user phone number', err);
      this.topService.loading = false;
    });
  }

  updateNotificationToggleOrResendCode(isResendCode: boolean) {
    // if the user is editing and changes the toggle
    this.isEdit = false;
    this.topService.loading = true;

    this.payload = this.userService.getDefaultPayloadPhoneRequest();
    const toggleControl = this.fgToggle.get('notifToggle');
    this.payload.isMobileEnabled = (isResendCode) ? true : (toggleControl ? toggleControl.value : false);
    this.payload.isResendCode = isResendCode;

    this.userService.addPhoneNumber(this.payload)
    .subscribe(
      res => {
        if (!this.isEdit && !this.isConfirm) {
          // so it doesnt refresh the form if user is editing
          this.refreshUserProfile();
        } else if (this.isConfirm && res && res.verificationCode) {
          this.verificationCode = res.verificationCode;
        }
        this.topService.loading = false;
    },
    err => {
      console.error('Error toggling notifications ', err);
      this.topService.loading = false;
    });
  }

  refreshUserProfile() {
    this.topService.loading = true;
    this.userService.getLoggedInUserProfile()
    .subscribe( u => {
      this.userService.userProfile = u;
      this.setToggleValue();
      this.topService.loading = false;
      });
  }

  confirmPhoneNumber() {
    const formValue = this.fgConfirm.value;
    this.topService.loading = true;
    let enteredCode = '';
    // tslint:disable-next-line: forin
    for ( const n in formValue) {
      enteredCode = enteredCode.concat(this.fgConfirm.get(n).value);
    }

    this.payload = this.userService.getDefaultPayloadPhoneRequest();
    this.payload.verificationCode = enteredCode;

    this.userService.verifyPhoneNumber(this.payload)
    .subscribe(
      res => {
      if (res.isVerified) {
        this.isConfirm = false;
        this.phoneIsVerified = true;
        this.showError = false;
        this.refreshUserProfile();
      } else {
        this.clearConfirmationForm();
        this.showError = true;
      }
      this.topService.loading = false;
    },
    err => {
      console.log('Error verifying phone number: ', err);
      this.topService.loading = false;
    });
  }

  clearConfirmationForm() {
    const resetVals = {
      n1: null,
      n2: null,
      n3: null,
      n4: null,
    };
    this.showError = false;
    this.fgConfirm.setValue(resetVals);
    if ( this.n1 ) {
      this.n1.nativeElement.focus();
    }
  }

  resendVerificationCode() {
    this.showError = false;
    this.clearConfirmationForm();
    this.updateNotificationToggleOrResendCode(true);
  }

  selectCountry(country: ICountry) {
    if (!country) { return; }
    this.selectedCountry = country;
    this.isDropdownToggled = false;
    this.isSMSAllowed = true;

    if(this.isSelectedCountryNotAllowedSMS() &&
       this.fgPhoneNumb.get('msgType').value === MobileMsgOptionsEnum.sms) {
      this.fgPhoneNumb.get('msgType').patchValue(MobileMsgOptionsEnum.whatsApp);
    }
  }

  isThereAChange() {
    return (this.userService.userProfile) &&
    (
      this.userService.userProfile.countryCode !== `+${this.selectedCountry.code}` ||
      this.userService.userProfile.phoneNumber !== this.fgPhoneNumb.get('phoneNumb').value ||
      this.userService.userProfile.messageOptions !== this.fgPhoneNumb.get('msgType').value
    );
  }

  toggleDropdown(): void {
    this.isDropdownToggled = !this.isDropdownToggled;
  }

  hideDropdown($event):void{
    $event.stopPropagation();
    this.isDropdownToggled = false;
  }

  isSelectedCountryNotAllowedSMS(): boolean{
    return this.countries.some(c => !c.allowSMS && c.id === this.selectedCountry.id);
  }
  // hoverBtn($event,isBtnOnHover):void{
  //   if ($event.target.classList.contains('disabled')) { return; }
  //   if (isBtnOnHover && $event.target.querySelector('path')) {
  //     $event.target.querySelector('path').setAttribute('d','M17.6199048,1.3243759 C7.07037232,2.98624006 0,11.243368 0,22 C0,32.756632 7.07037232,41.01376 17.6199048,42.6756241 C28.8294425,44.4414586 137.170558,44.4414586 148.380095,42.6756241 C158.929628,41.01376 166,32.756632 166,22 C166,11.243368 158.929628,2.98624006 148.380095,1.3243759 C137.170558,-0.441458634 28.8294425,-0.441458634 17.6199048,1.3243759 Z');
  //   } else if (!isBtnOnHover && $event.target.querySelector('path')){
  //     $event.target.querySelector('path').setAttribute('d','M21.046729,2 C9.97522627,2 1,10.954305 1,22 C1,33.045695 9.97522627,42 21.046729,42 C21.046729,42 144.953271,42 144.953271,42 C156.024773,42 165,33.045695 165,22 C165,10.954305 156.024773,2 144.953271,2 C144.953271,2 21.046729,2 21.046729,2 Z');
  //   } else {
  //     return;
  //   }
  // }

}
