export enum OfferErrorCodes {
    
    //General
    General_PreconditionFailed = 1,

    //CreateOffer
    CreateOffer_ItemPriceChanged = 100,
    CreateOffer_ItemAvailabilityChanged,
    CreateOffer_ItemStatusChanged,
    CreateOffer_NoTokensAvailable,
    CreateOffer_ItemAlreadyBided,
    CreateOffer_OrderCreationNotAllowed,

    //Accept
    AcceptOffer_ItemPriceChanged = 200,
    AcceptOffer_ItemAvailabilityChanged,
    AcceptOffer_InvalidStatus,
    AcceptOffer_InvalidPreviousEventType,
    AcceptOffer_OfferEnabledInvalid,
    AcceptOffer_ItemStatusChanged,
    AcceptOffer_OrderCreationNotAllowed_Buyer,
    AcceptOffer_OrderCreationNotAllowed_Vendor,

    //Counter
    CounterOffer_ExistAndPending = 300,
    CounterOffer_PreviousEventValid,
    CounterOffer_IsFinalResult,
    CounterOffer_PriceInvalid,
    CounterOffer_OfferEnabledInvalid,
    CounterOffer_OfferInvalidQuantity,
    CounterOffer_ItemAvailabilityChanged,
    CounterOffer_ItemPriceChanged,
    CounterOffer_ItemStatusChanged,
    CounterOffer_OrderCreationNotAllowed_Buyer,
    CounterOffer_OrderCreationNotAllowed_Vendor,

    //Reject
    RejectOffer_NotPending = 400,
    RejectOffer_WrongCustomer,
    RejectOffer_WrongVendor,
    RejectOffer_LastEventRoleSameAsIncoming,
    RejectOffer_EitherSideDisabled,
    RejectOffer_ItemAvailabilityChanged,
    RejectOffer_ItemPriceChanged,

    //Cancel
    CancelOffer_NotPending = 500,
    CancelOffer_WrongCustomer,
    CancelOffer_WrongVendor,
    CancelOffer_EitherSideDisabled,
    CancelOffer_OfferVersionMismatch,

    //Update
    UpdateOffer_ExistAndPending = 600,
    UpdateOffer_PreviousEventInValid,
    UpdaterOffer_IsFinalResult,
    UpdateOffer_PriceInvalid,
    UpdateOffer_OfferEnabledInvalid,
    UpdateOffer_OfferInvalidQuantity,
    UpdateOffer_ItemAvailabilityChanged,
    UpdateOffer_ItemPriceChanged,
    UpdateOffer_ItemStatusChanged,
    UpdateOffer_OrderCreationNotAllowed_Buyer,
    UpdateOffer_OrderCreationNotAllowed_Vendor,
    UpdateOffer_WrongCustomer,
    UpdateOffer_WrongVendor,
    UpdateOffer_NoModifications,

    //Ownership
    UpdateOfferOwnership_NotPending = 700,
    UpdateOfferOwnership_VersionMismatch,
    UpdateOfferOwnership_AlreadyOwned,
    UpdateOfferOwnership_ItemPriceChanged,
    UpdateOfferOwnership_ItemAvailabilityChanged,
    UpdateOfferOwnership_InvalidPreviousEventType,
    UpdateOfferOwnership_OrderCreationNotAllowed
}