import { OrderStatusEnum } from '../enums/orderStatusEnum';
import * as models from './model';
import { DecimalPipe } from '@angular/common';
export interface IOrder {
  docEntry?: number;
  docNumber?: number;
  orderDate?: Date;
  dueDate?: Date;
  orderStatus?: string | OrderStatusEnum;
  orderDiscountAmount?: number;
  orderDiscountPercent?: number;
  orderDetails?: models.IOrderLine[];
  orderTotal?: number;
  customerNo?: string;
  refNo?: string;
  salesEmp?: string;
  comments?: string;
  email?: string;
  contactInfo?: string;
  billingAddress?: string;
  billingAddressCode?: string;
  shippingAddress?: string;
  shippingAddressCode?: string;
  salesEmployee?: string;
  contactName?: string;
  contactEmail?: string;
  tax?: number;
  shipping?: number;
  terms?: string;
  subtotal?: number;
  isCancelled?: boolean;
  externalId?: string;
  beingUpdated?: boolean;
  customerRef?: string;
  updateByUser?: string;
  updatedByName?: string;
  canCancelUpdate?: string;
  cardCode?: string;
  cardName?: string;
  shippingCost?: number;
  payment?: {
    id: string,
    fee: number,
    status: string,
    authorization: string,
    charge: string;
    orderDate: string;
    chargeDate: string;
    amount: number;
    cardName: string;
    error: any;
    creditCardId?: string;
    firstName: string;
    lastName: string;
    ipAddress: string;
    cardLast4Digits: string;
    cardType: string;
    agreementDate: string;
  };
  isLocked?: boolean;
}
