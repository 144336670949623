import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { PageErrorService } from './page-error.service';
import { environment } from '../../environments/environment';
import { Subscription } from 'rxjs';
import { UserService } from '../user/user.service';
import { ItemsService } from '../shared/services/items.service';
import { TopService } from '../shared/services/top.service';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-page-error',
    templateUrl: './page-error.component.html',
    styleUrls: ['./page-error.component.scss'],
    standalone: true,
    imports: [NgIf]
})
export class PageErrorComponent implements OnInit, OnDestroy {
  portal = '';
  errorMessage = '';
  isProduction = false;
  routeParamsSubscription: Subscription;

  constructor(
    private pageErrorService: PageErrorService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private itemsService: ItemsService,
    public topService: TopService) {
  }

  ngOnInit() {
    this.processRouterParams();
    this.errorMessage = this.pageErrorService.errorMessage;
    this.isProduction = environment.production;
    this.topService.trendingNavVisible = true;
    this.topService.hideMenusOnError = true;
  }

  processRouterParams(): void {
    this.routeParamsSubscription = this.route.params.subscribe(routeParams => {
      if (routeParams['portal']) {
        this.portal = routeParams['portal'];
      }
    });
  }

  navigateHome(): void {
      if (this.portal === 'vendor') {
        this.router.navigate(['/vendor/dashboard']);
      } else {
        if (this.userService.isGuest) {
          this.router.navigate(['/guest/home']);
        } else {
          this.router.navigate(['/buyer/home']);
        }
      }
  }

  ngOnDestroy() {
    this.topService.trendingNavVisible = false;
    this.topService.hideMenusOnError = false;
  }
}
