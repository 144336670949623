import { DecimalPipe, NgIf, NgFor, CurrencyPipe } from '@angular/common';
import { ChartComponent } from './../chart/chart.component';
import { Component, OnInit, ViewChild, AfterViewInit, HostListener, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { DashboardService } from '../dashboard.service';
import { IDashboardSaleActivity } from '../../interface/IDashboardSaleActivity';
import { IDashboardProductSelling } from '../../interface/IDashboardProductSelling';
import { VendorService } from '../../vendor.service';
import { IVendor } from '../../interface/IVendor';
import { BsDaterangepickerDirective, BsDaterangepickerInlineDirective, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { IDashboardVendorRequest } from '../../interface/IDashboardVendorRequest';
import { SharedService } from '../../../shared/shared.service';
import { IDropdown } from '../../interface/IDropdown';
import { IErrorModal } from '../../interface/IErrorModal';
import { IOpenPurchaseOrder } from '../../interface/IOpenPurchaseOrder';
import { environment } from '../../../../environments/environment';
import { Subscription } from 'rxjs';
import { PubSubService } from '../../../core/pubsub.service';
import { SharedSource } from '../../../core/shared-source';
import * as m from 'moment';
import * as moment from 'moment-timezone';
import { NotificationService } from '../../../shared/services/notification.service';
import { IJustSoldPayload } from '../../../shared/interfaces/IJustSoldPayload';
import { IItem } from '../../../buyer/interfaces/IItem';
import { IOrderCounters } from '../../interface/IOrderCounters';
import { DaterangesQuickOptsEnum } from '../../enums/dateranges-quick-opts';
import { ListingDetailsComponent } from '../listing-details/listing-details.component';
import { ListingSkuStatusNamesEnum } from '../../enums/listing-sku-status';
import { NotificationCenterVendorComponent } from '../../notifications/notification-center-vendor/notification-center-vendor.component';
import { Router, NavigationExtras, ActivatedRoute, RouterLink } from '@angular/router';
import { IChartSerie, IPerformanceChart, IPerformanceChartResponse } from '../../interface/IPerformanceChart';
import { EPerformanceChartSerieName } from '../../enums/performance-chart-serie';
import { ErrorModalComponent } from '../../../shared/ui-components/error-modal/error-modal.component';
import { VendorItemsPreloaderComponent } from '../../../shared/ui-components/vendor-items-preloader/vendor-items-preloader.component';
import { DashboardJustSoldPreloaderComponent } from '../../../shared/ui-components/vendor-items-preloader/dashboard-just-sold-preloader/dashboard-just-sold-preloader.component';
import { ItemDashboardComponent } from '../../products/item-dashboard/item-dashboard.component';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { MiniProgressChartComponent } from '../../shared/ui-components/mini-progress-chart/mini-progress-chart.component';
import { ChartComponent as ChartComponent_1 } from '../chart/chart.component';

@Component({
    selector: 'app-main-dashboard',
    templateUrl: './main-dashboard.component.html',
    styleUrls: ['./main-dashboard.component.scss'],
    providers: [DecimalPipe],
    standalone: true,
    imports: [NgIf, RouterLink, BsDatepickerModule, ChartComponent_1, ListingDetailsComponent, NgFor, MiniProgressChartComponent, VirtualScrollerModule, ItemDashboardComponent, DashboardJustSoldPreloaderComponent, VendorItemsPreloaderComponent, NotificationCenterVendorComponent, ErrorModalComponent, CurrencyPipe]
})
export class MainDashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('dashboardDp') datepicker: BsDaterangepickerDirective;
  @ViewChild('inlineDatePicker') inlineDatePicker: BsDaterangepickerInlineDirective;
  @ViewChild('listingDetail', { static: true }) listingDetailsChart: ListingDetailsComponent;
  @ViewChild('dashboardNotifications') dashboardNotifications: NotificationCenterVendorComponent;
  @ViewChild(NotificationCenterVendorComponent) notificationCenter: NotificationCenterVendorComponent;

  bsConfig: Partial<BsDatepickerConfig>;
  isDatePickerOpen = false;
  countersLoading = false;
  daterangesEnum = DaterangesQuickOptsEnum;
  quickDateRangeSelected: DaterangesQuickOptsEnum;
  lastQuickDateRangeSelected: DaterangesQuickOptsEnum;
  performanceChartData: IPerformanceChart | any = { series: [], categories: [] };
  revenue: number = 0;
  unitsSold: number = 0;
  loadingPerformanceChartData = false;
  loadingTodayPerformanceChartData = false;

  salesActivity: IDashboardSaleActivity[] = [];
  products: IDashboardProductSelling[] = [];
  topSellingProducts: IDashboardProductSelling[] = [];
  openPurchaseOrders: IOpenPurchaseOrder;
  dashboardVendorRequest: IDashboardVendorRequest = {};
  errorModal: IErrorModal = { isError400: false, errorMessage400: '' };
  dateRangeValues: Date[];
  lastDateRangeValues: Date[] = [];
  dashboardDataSubscription: Subscription;
  vendorDataSubscription: Subscription;
  justSoldLoading = false;
  justSoldScrollLoading = false;

  orderCounters: IOrderCounters;
  justSoldPayload: IJustSoldPayload = {
    pagination: {
      pageNumber: 1,
      pageSize: 25
    }
  };
  justSoldItems: IItem[];
  subscriptions: Subscription[] = [];
  bypassCustomerVendorChange = true; // this is to avoid duplicated request due to top-vendor.component initialization

  chartSeriesEmpty: IChartSerie[] =
    [{
      name: EPerformanceChartSerieName.Units,
      data: []
    },
    {
      name: EPerformanceChartSerieName.Revenue,
      data: []
    }];
  totalUnits = 0;
  totalRevenue = 0;
  isPerformanceChartEmpty = true;

  constructor(
    public dashboardService: DashboardService,
    public sharedService: SharedService,
    private cdr: ChangeDetectorRef,
    private pubSubService: PubSubService,
    public vendorService: VendorService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private decimalPipe: DecimalPipe
  ) {
    this.setDefaultOrderCounters();
  }

  ngOnInit() {
    this.initDates();
    this.getAllDashboardData();

    this.subscriptions.push(
      this.pubSubService.sharedSubject.subscribe(data => {
        if (data.name === SharedSource.changeCustomerVendor) {
          this.listingDetailsChart.getChartData();
          if (this.vendorService.isVendorManager && this.bypassCustomerVendorChange) {
            this.bypassCustomerVendorChange = false;
          } else {
            this.justSoldItems = [];
            this.getAllDashboardData();
          }
        }
      })
    );
  }

  ngAfterViewInit() {
    this.bsConfig = Object.assign({}, { containerClass: 'theme-blue', showWeekNumbers: false });
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  // Perfomance Chart
  initDates(): void {
    this.dateRangeValues = [m().subtract(7, 'days').startOf('day').toDate(), m().endOf('day').toDate()];
    this.lastDateRangeValues = [this.dateRangeValues[0], this.dateRangeValues[1]];
    this.quickDateRangeSelected = DaterangesQuickOptsEnum.last7days;
    this.lastQuickDateRangeSelected = DaterangesQuickOptsEnum.last7days;

    this.dashboardVendorRequest.startDate = moment(this.dateRangeValues[0]).format();
    this.dashboardVendorRequest.endDate = moment(this.dateRangeValues[1]).format();
  }

  clickedInsideDatePicker(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
  }

  showDaterangePicker(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this.isDatePickerOpen = true;
  }

  roundNumber(value: number): number {
    const roundedString = this.decimalPipe.transform(value, '1.0-0');
    return roundedString ? parseFloat(roundedString.replace(/,/g, '')) : NaN;
  }

  getTodayPerfomanceData(): void {
    this.loadingTodayPerformanceChartData = true;
    this.subscriptions.push(
      this.dashboardService.getPerformanceChartData(
        moment().startOf('day').format(),
        moment().endOf('day').format()).subscribe(
          (data: IPerformanceChartResponse[]) => {
            this.loadingTodayPerformanceChartData = false;
            this.revenue = 0;
            this.unitsSold = 0;
            if (data.length) {
              this.revenue = this.roundNumber(data[0].revenue);
              this.unitsSold = this.roundNumber(data[0].units);
            }
          },
          (err) => {
            this.loadingTodayPerformanceChartData = false;
            console.log('Error getting today performance chart data');
          }
        )
    );
  }

  getPerformanceChartData(): void {
    this.loadingPerformanceChartData = true;
    this.isDatePickerOpen = false;
    this.performanceChartData.series = this.chartSeriesEmpty;
    this.isPerformanceChartEmpty = true;

    this.subscriptions.push(
      this.dashboardService.getPerformanceChartData(
        moment(this.dashboardVendorRequest.startDate).startOf('day').format(),
        moment(this.dashboardVendorRequest.endDate).endOf('day').format()).subscribe(
          (data: IPerformanceChartResponse[]) => {
            var format = this.getFormatType(this.dashboardVendorRequest.startDate, this.dashboardVendorRequest.endDate);
            var categories: string[] = [];
            const units = {
              name: EPerformanceChartSerieName.Units,
              data: []
            } as IChartSerie;
            const revenue = {
              name: EPerformanceChartSerieName.Revenue,
              data: []
            } as IChartSerie;

            this.totalUnits = 0;
            this.totalRevenue = 0;
            var from = moment(this.dashboardVendorRequest.startDate);
            const to = moment(this.dashboardVendorRequest.endDate);

            if (data.length) {
              while (from.isSameOrBefore(to)) {
                categories.push(from.format(format));

                var dayData = data.find(x => moment(x.date).isSame(moment(from)));
                if (dayData) {
                  units.data.push(this.roundNumber(dayData.units));
                  revenue.data.push(this.roundNumber(dayData.revenue));
                  this.totalUnits += this.roundNumber(dayData.units);
                  this.totalRevenue += this.roundNumber(dayData.revenue);
                } else {
                  units.data.push(0);
                  revenue.data.push(0);
                }

                from.add(1, 'day');
              }
            }

            if (!data.length) {
              var from = moment(this.dashboardVendorRequest.startDate);
              const to = moment(this.dashboardVendorRequest.endDate);
              while (from.isSameOrBefore(to)) {
                categories.push(from.format(format));
                units.data.push(0);
                revenue.data.push(0);
                from.add(1, 'day');
              }
            }

            this.unitsSold = this.totalUnits;
            this.revenue = this.totalRevenue;

            this.performanceChartData = {
              series: [units, revenue],
              categories: categories
            } as IPerformanceChart;

            this.lastQuickDateRangeSelected = this.quickDateRangeSelected;
            this.lastDateRangeValues = [this.dateRangeValues[0], this.dateRangeValues[1]];

            this.isPerformanceChartEmpty = !this.totalUnits && !this.totalRevenue;
            this.loadingPerformanceChartData = false;
          },
          (err) => {
            this.loadingPerformanceChartData = false;
            console.log('Error getting performance chart data');
          }
        )
    );
  }

  closeDatepicker() {
    this.quickDateRangeSelected = this.lastQuickDateRangeSelected;
    this.dateRangeValues = [this.lastDateRangeValues[0], this.lastDateRangeValues[1]];
    this.dashboardVendorRequest.startDate = moment(this.dateRangeValues[0]).format();
    this.dashboardVendorRequest.endDate = moment(this.dateRangeValues[1]).format();
    // this.datepicker.bsValueChange.next(this.dateRangeValues);
    this.isDatePickerOpen = false;
  }

  getFormatType(fromDate: string, toDate: string): string {
    const start = moment(fromDate);
    const end = moment(toDate);

    if (start.isSame(end, 'month') && start.isSame(end, 'year')) {
      return 'D';
    } else if (start.isSame(end, 'year')) {
      return 'MMM D';
    } else {
      return 'MMM D, yy';
    }
  }

  changeDateRanges(event: Event, days: number, range: DaterangesQuickOptsEnum): void {
    event.stopPropagation();
    event.preventDefault();
    this.quickDateRangeSelected = range;

    switch (range) {
      case DaterangesQuickOptsEnum.thisMonth:
        this.dateRangeValues = [m().startOf('month').toDate(), m().endOf('month').toDate()];
        break;
      case DaterangesQuickOptsEnum.lastMonth:
        this.dateRangeValues = [m().subtract(1, 'months').startOf('month').toDate(), m().subtract(1, 'months').endOf('month').toDate()];
        break;
      case DaterangesQuickOptsEnum.customRange:
        break;
      case DaterangesQuickOptsEnum.yesterday:
        const yesterday = m().subtract(days, 'days').startOf('day');
        this.dateRangeValues = [yesterday.toDate(), m(yesterday).endOf('day').toDate()];
        break;
      default:
        this.dateRangeValues = [m().subtract(days, 'days').startOf('day').toDate(), m().endOf('day').toDate()];
    }

    this.dashboardVendorRequest.startDate = moment(this.dateRangeValues[0]).format();
    this.dashboardVendorRequest.endDate = moment(this.dateRangeValues[1]).format();
  }

  @HostListener('document:click', ['$event']) clickedOutside($event): void {
    // debugger;
    // const dropdownData: IDropdown = { group: 'dashboard', action: 'closeAll' };
    // this.sharedService.setDropdown(dropdownData);
    // if (this.datepicker && this.datepicker.isOpen) {
    //   this.dateRangeValues = [this.lastDateRangeValues[0], this.lastDateRangeValues[1]];
    //   this.datepicker.bsValueChange.next(this.dateRangeValues);
    // }
  }

  onDateValueChange(event): void {
    if (event && event.length > 1) {
      if (event[0] && event[1]) {
        // user selected a date manually
        if (this.dateRangeValues[0] !== event[0] || this.dateRangeValues[1] !== event[1]) {
          this.quickDateRangeSelected = DaterangesQuickOptsEnum.customRange;;
        }

        this.dateRangeValues[0] = event[0];
        this.dateRangeValues[1] = event[1];

        this.dashboardVendorRequest.startDate = moment(event[0]).format();
        this.dashboardVendorRequest.endDate = moment(event[1]).format();

        // once new date range is chosen, update lastDateRangeValues variable
        // this.lastDateRangeValues = [this.dateRangeValues[0], this.dateRangeValues[1]];

        this.datepicker.bsValueChange.next(this.dateRangeValues); /** update input */
      }

    }
  }

  // Just Sold
  getJustSoldItems(isFirstPage?: boolean): void {
    if (isFirstPage) {
      this.justSoldPayload.pagination.pageNumber = 1;
    }
    this.subscriptions.push(
      this.notificationService.getJustSoldItems(this.justSoldPayload).subscribe(
        (data) => {
          if (this.justSoldItems && this.justSoldItems.length && !isFirstPage) {
            this.justSoldItems = this.justSoldItems.concat(data.values);
          } else {
            this.justSoldItems = data.values;
          }
          this.notificationService.updateItemLogo(this.justSoldItems);
          this.justSoldLoading = false;
          this.justSoldScrollLoading = false;
        },
        (err) => {
          this.justSoldLoading = false;
          this.justSoldScrollLoading = false;
          console.log('Error: ' + err);
        }
      )
    );
  }

  onScrollDownJustSold(event): void {
    const endPerPage = this.justSoldPayload.pagination.pageNumber * this.justSoldPayload.pagination.pageSize;
    if (event.endIndex < endPerPage - 1) {
      return;
    }
    this.justSoldPayload.pagination.pageNumber++;
    this.justSoldScrollLoading = true;
    this.getJustSoldItems(false);
  }

  // Orders
  getOrderCounters(): void {
    this.countersLoading = true;
    this.subscriptions.push(
      this.dashboardService.getOrderCounters().subscribe(
        (data) => {
          this.orderCounters = data;
          this.countersLoading = false;
        },
        (err) => {
          console.log('Error: ' + err.message);
          this.setDefaultOrderCounters();
          this.countersLoading = false;
        }
      )
    );
  }

  setDefaultOrderCounters(): void {
    this.orderCounters = {
      missingTrackingTotal: 0,
      pendingPOAmount: 0,
      pendingPOTotal: 0,
      openOrdersTotal: 0,
      openOrdersAmount: 0
    };
  }

  // Listing Details
  getPercentageProgress(count: number) {
    if (!this.dashboardService.totalListing || !this.dashboardService.totalListing.count) { return 0; }
    const percentage = (count / this.dashboardService.totalListing.count) * 100;
    // business dont want to round less than 1 values
    return (percentage < 1) ? 0 : Math.round(percentage);
  }

  getColorByStatusName(name: ListingSkuStatusNamesEnum) {
    switch (name) {
      case ListingSkuStatusNamesEnum.active: return '#80CB5E';
      case ListingSkuStatusNamesEnum.archived: return '#B4B4B4';
      case ListingSkuStatusNamesEnum.inactive: return '#1A1A1A';
      case ListingSkuStatusNamesEnum.paused: return '#F5C854';
      case ListingSkuStatusNamesEnum.removed: return '#4687D6';
      case ListingSkuStatusNamesEnum.soldOut: return '#EB3B44';
    }
  }


  getAllDashboardData(): void {
    this.justSoldLoading = true;
    this.getJustSoldItems(true);
    if (!this.vendorService.isVendorManager || this.vendorService.isImpersonationReady) {
      this.getOrderCounters();
    }
    this.getPerformanceChartData();
  }

  filterDashboardDataByVendor(vendorSelected: IVendor): void {
    this.dashboardVendorRequest.vendorId = vendorSelected.id;
  }

  hideErrorAlert(): void {
    this.errorModal = { isError400: false, errorMessage400: '' };
  }

  goTo(url) {
    this.router.navigate([url], { relativeTo: this.route.parent });
  }

}
