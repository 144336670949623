import { Component, OnInit, Output, Input, EventEmitter, OnDestroy } from '@angular/core';
import { TopService } from '../../../shared/services/top.service';
import { INotificationCenterTab } from '../../interfaces/INotificationCenterTab';

import { SharedSource } from '../../../core/shared-source';
import { PubSubService } from '../../../core/pubsub.service';
import { Subscription } from 'rxjs';
import { NotificationCenterBuyerService } from '../notification-center.service';
import { SharedService } from '../../../shared/shared.service';
import { NotificationFiltersEnum, NotificationSortingDirection } from '../../../shared/interfaces/INotificationDetail';
import { SessionService } from '../../../services/session.service';
import { UserService } from '../../../user/user.service';
import { NotificationCenterComponent } from '../notification-center.component';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'app-notification-center-header',
    templateUrl: './notification-center-header.component.html',
    styleUrls: ['./notification-center-header.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass]
})
export class NotificationCenterHeaderComponent implements OnInit, OnDestroy {
  NotificationFiltersEnum = NotificationFiltersEnum;
  NotificationSortingDirection = NotificationSortingDirection;
  pubServiceSubscription: Subscription;
  counter = {
    all: 0,
    dropPrice: 0,
    waitlist: 0,
    moreInventory: 0
  };
  darkenActive = false;
  endsWith = 0;

  @Input() allSelected: boolean;
  @Input() indeterminate: boolean;
  @Output() selectAll: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() exportNotifications: EventEmitter<any> = new EventEmitter<any>();
  @Output() resetNotifications: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() manufacturerFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() search: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private _pubSubService: PubSubService,
    private _sharedService: SharedService,
    private _userService: UserService,
    private _sessionService: SessionService,
    public notificationSvcBuyer: NotificationCenterBuyerService,
    public topService: TopService
  ) {

  }

  ngOnInit() {
    this.notificationSvcBuyer.sortDirection = NotificationSortingDirection.desc;
    this.getCounters();
    this.pubServiceSubscription = this._pubSubService.sharedSubject.subscribe(myEvent => {
      if ([SharedSource.priceDropFromVendor, SharedSource.waitlistFromVendor, SharedSource.moreInventoryFromVendor]
        .includes(myEvent.name)) {
          this.getCounters();
        }
    });
  }


  onTabClicked(tab: NotificationFiltersEnum) {
    this.resetNotifications.emit(true);
    this.darkenActive = false;
    this.notificationSvcBuyer.activeTab = tab;
    if ( this._sessionService.userRole === 'GUEST' ) { return; }

    this.notificationSvcBuyer.sortDirection =
      (this.notificationSvcBuyer.sortDirection === NotificationSortingDirection.desc && tab === this.endsWith) ?
      NotificationSortingDirection.asc :
      NotificationSortingDirection.desc;

    this.endsWith = tab;
    this._sharedService['handleBuyerNotificationTabs'] = this.notificationSvcBuyer.activeTab;
  }

  onTabHovered (event) {
    if (!event.target.classList.contains('active')) {
      this.darkenActive = true;
    }
  }

  onTabMouseLeave () {
    this.darkenActive = false;
  }

  ngOnDestroy() {
    this.notificationSvcBuyer.activeTab = NotificationFiltersEnum.all;
    this.notificationSvcBuyer.sortDirection = NotificationSortingDirection.desc;
    if (this.pubServiceSubscription) {
      this.pubServiceSubscription.unsubscribe();
    }
  }

  getCounters() {
    this.notificationSvcBuyer.getHeaderCounters().subscribe(
      (data) => {
        this.counter.dropPrice = data.priceDropNewTotal;
        this.counter.waitlist = data.waitListNewTotal;
        this.counter.moreInventory = data.moreInventoryNewTotal;
        this.counter.all = data.priceDropNewTotal + data.waitListNewTotal + data.moreInventoryNewTotal;

        this._sharedService['setQtyOfNewInNotifCenterBuyer'] = this.counter.all;
      }
    );
  }

  selectUnselectAll(event) {
    this.selectAll.emit(event.target.checked)
  }
}

