import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { CartService } from '../../cart/cart.service';
import { Router } from '@angular/router';
import { TopService } from '../../../shared/services/top.service';
import { SharedService } from '../../../shared/shared.service';
import * as models from '../../interfaces/model';
import { ConfirmModalComponent } from '../../../shared/confirm-modal/confirm-modal.component';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgClickOutsideDirective } from 'ng-click-outside2';

@Component({
    selector: 'app-bulk-upload',
    templateUrl: './bulk-upload.component.html',
    styleUrls: ['./bulk-upload.component.scss'],
    standalone: true,
    imports: [NgClickOutsideDirective, FormsModule, NgIf, ConfirmModalComponent]
})
export class BulkUploadComponent implements OnInit {
  @ViewChild('focus', { static: true }) bulkUploadText: ElementRef;
  @Output() showBulk: EventEmitter<any> = new EventEmitter<any>();
  uploadedContent: string[];
  bulkUploadFieldData = '';
  showConfirmMsg = false;
  confirmMsg = ['Your shopping cart will be cleared.'];
  showErrorMsg = false;
  errorMsg = [];
  hideTMask = false;
  showBulkErr = false;
  loading = false;
  showLoad = false;
  bulkUploadHasErrorAndSucess = false;
  redirectFlag = false;

  constructor(private cartService: CartService,
              private router: Router,
              public topService: TopService,
              private sharedService: SharedService) { }

  ngOnInit() {}

  getUploadedContent(confirmed: boolean): void {
    this.showBulkErr = true;
    this.topService.loading = true;
    this.showConfirmMsg = false;
    this.errorMsg = [];
    if (confirmed) {
      this.uploadedContent = this.bulkUploadFieldData.split('\n');
      this.cartService.BulkItemToCart(this.uploadedContent).subscribe(
        (data) => {
          this.bulkUploadFieldData = '';
          if (data.length) {
            this.errorMsg.push(data);
          } else {
            this.bulkUploadHasErrorAndSucess = true;
            this.errorMsg = ['Items imported to cart'];
            this.showConfirmMsg = false;
            this.redirectFlag = true;
            this.topService.loading = false;
          }
        },
        (error) => {
          this.bulkUploadFieldData = '';
          if (error.status === 400) {
            this.bulkUploadHasErrorAndSucess = false;
            this.errorMsg.push(error.error ? error.error : "Something went wrong.");
            this.showErrorMsg = true;
            this.topService.loading = false;
          }
        },
        () => {
          this.redirectFlag = true;
          this.bulkUploadHasErrorAndSucess = true;
          this.topService.loading = false;
          this.bulkUploadFieldData = '';
        }
      );
    } else {
      this.topService.loading = false;
    }
  }

  continueLogicallyAfterButlAttempt(): void {
    // if bulk upload has success and error
    if (this.bulkUploadHasErrorAndSucess) {
      this.showLoad = false;
      this.showBulkErr = !this.showBulkErr;
      this.sharedService.cartData = true;
      this.router.navigate(['cart']);
      this.close();
    } else {
      this.showLoad = false;
    }
    this.close();
  }

  close() {
    this.showBulk.emit(false);
  }

  hideTextMask(event): void {
    event.stopPropagation();
    event.preventDefault();
    this.hideTMask = true;
    this.bulkUploadText.nativeElement.focus();
  }


}
