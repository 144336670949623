import { Component, OnInit } from '@angular/core';
import { NgForm, FormGroup, FormControl, FormsModule } from '@angular/forms';

import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { UserService } from '../../../user/user.service';
import { ItemsService } from '../../../shared/services/items.service';
import { SharedService } from '../../../shared/shared.service';
import { SessionService } from '../../../services/session.service';
import { DEFAULT_URL } from '../../../shared/static';
import { PubSubService } from '../../../core/pubsub.service';
import { SharedSource } from '../../../core/shared-source';
import { GuestService } from '../../../shared/services/guest.service';
import { TopService } from '../../../shared/services/top.service';
import { ForgotPasswordComponent } from '../../../user/forgot-password/forgot-password.component';
import { NgIf, NgClass } from '@angular/common';


@Component({
    selector: 'app-guest-login',
    templateUrl: './guest-login.component.html',
    styleUrls: ['./guest-login.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass, ForgotPasswordComponent, FormsModule]
})
export class GuestLoginComponent implements OnInit {
  email: string;
  password: string;
  firstTry: boolean;
  showForgot = false;
  fromForgot = false;
  loginData = {
    email: '',
    password: ''
  };

  constructor(
    private titleService: Title,
    private router: Router,
    public userService: UserService,
    private pubSubService: PubSubService,
    public itemsService: ItemsService,
    public sharedService: SharedService,
    public sessionService: SessionService,
    private guestService: GuestService,
    public topService: TopService) { }

  ngOnInit() {
    this.userService.loginSuccess = false;
    this.userService.loginFail = false;
    this.userService.auth.completedLoad = false;

    // DOnt think we need this condition at all
    if (this.userService.auth.isAuthenticated()) {
      this.userService.auth.completedLoad = true;
      if (this.sessionService.userRole === 'SELLER' || this.sessionService.userRole === 'ADMIN' || this.sessionService._user_role === 'VENDORMGR') {
        this.router.navigate(['/vendor/dashboard']);
      } else if (this.sessionService.userRole === 'BUYER' ||
      this.sessionService.userRole === 'SALES' ||
      this.sessionService.userRole === 'SUPER') {
          this.router.navigate(['/buyer/home']);
      } else if (this.sessionService.userRole === 'GUEST') {
        this.router.navigate(['/guest/home']);
      }
    }

    this.titleService.setTitle('HUBX Portal');
    this.itemsService.itemsRequest = this.itemsService.getEmptyRequestPayload();
  }

  login(event: MouseEvent): void {
    // event.preventDefault();
    // event.stopPropagation();

    if(this.userService.loading == true){ return; }

    this.pubSubService.sharedSubject.subscribe(
      mySharedValues => {
        if (mySharedValues.name === SharedSource.LoggedInInternal) {
          this.userService.loading = false;
        }
        if (mySharedValues.name === SharedSource.LoggedInGuest) {
          this.userService.loading = false;
          this.userService.loginFail = !mySharedValues.data;
        }
    });

    this.userService.loading = true;
    if (!this.sessionService._is_notification_called) {
      this.sessionService.isNotificationCalled = 'false';
    }
    this.userService.login(this.loginData.email, this.loginData.password);
  }

  showForgotPwd() {
    this.userService.loginFail = false;
    this.showForgot = true;
  }

  showLogin() {
    this.showForgot = false;
  }

  clearEmail(): void {
    this.loginData.email = '';
  }

  clearPass(): void {
    this.loginData.password = '';
  }


  hideLogin(event) {
    this.showForgot = false;
    if (event === true) {
      this.fromForgot = true;
    }
  }

  focusInput(elem) {
    if (elem) {
        elem.focus();
        elem.value = (elem.type === 'email') ? this.loginData.email : this.loginData.password;
        elem.style = 'font-size: 14px; color: rgb(0, 0, 0);';
    }
  }
}
