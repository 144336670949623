import { OfferCancellationWarningComponent } from './../../../user/offers/offer-cancellation-warning/offer-cancellation-warning.component';
import {
  Component, OnInit, ViewChild, AfterViewInit,
  HostListener, OnDestroy, ElementRef, ChangeDetectorRef, AfterViewChecked,
  ViewChildren,
  QueryList
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm, FormBuilder, UntypedFormArray, Validators, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VendorService } from '../../vendor.service';
import { BsDaterangepickerDirective } from 'ngx-bootstrap/datepicker';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { IDiscountQty } from '../../interface/IDiscountQty';
import { ILookupMain } from '../../interface/ILookupMain';
import { IVendorDropDownData } from '../../interface/IvendorDropDownData';
import { IVendorItemRequest } from '../../interface/IVendorItemRequest';
import { IVendorItemInfo, itemOperationFlagAttr } from '../../interface/IVendorItemInfo';
import { IVendorManufacturer } from '../../interface/IVendorManufacturer';
import { IVendorStaticInfo } from '../../interface/IVendorStaticInfo';
import { IVendorCategory } from '../../interface/IVendorCategory';
import { IVendorSubCategory } from '../../interface/IVendorSubCategory';
import { IVendorPromo } from '../../interface/IVendorPromo';
import { IVendorItemAttributes } from '../../interface/IVendorItemAttributes';
import { IVendorItemAttributeRequest } from '../../interface/IVendorItemAttributeRequest';
import { IVendorItemPrice } from '../../interface/IVendorItemPrice';
import { IVendor } from '../../interface/IVendor';
import { ILoadValidationError } from '../../interface/ILoadValidationError';
import { SharedService } from '../../../shared/shared.service';
import { IDropdown } from '../../interface/IDropdown';
import { IErrorModal } from '../../interface/IErrorModal';
import { IVendorItemCodeRequest } from '../../interface/IVendorItemCodeRequest';
import { environment } from '../../../../environments/environment';
import { IVendorPagination } from '../../interface/IVendorPagination';
import { Globals } from '../../shared/globals';
import { Subscription } from 'rxjs';
import * as $ from 'jquery';
import { PubSubService } from '../../../core/pubsub.service';
import { SharedSource } from '../../../core/shared-source';
import { IVendorStatus } from '../../interface/IVendorStatus';
import * as _ from 'lodash';
import { IVendorItemComment } from '../../interface/IVendorItemComment';
import { ItemService } from '../item.service';
import { ItemDetailedComponent } from '../item-detailed/item-detailed.component';


import { saveAs as importedSaveAs } from 'file-saver';
import { IItemsExportRequest } from '../../interface/IItemsExportRequest';
import { ListingSkuStatusNamesEnum, ListingSkuStatusValuesEnum } from '../../enums/listing-sku-status';
import { IExw } from '../../interface/IExw';
import { INameId } from '../../interface/INameId';
import { LoaderService } from '../../../shared/services/loader.service';
import { OfferService } from '../../../user/offers/offer.service';
import * as models from './../../../shared/interfaces/model';
import * as moment from 'moment-timezone';
import * as enums from '../../../shared/enums/enum';
import { ItemSimpleComponent } from '../item-simple/item-simple.component';
import { OfferCancellationWarningComponent as OfferCancellationWarningComponent_1 } from '../../../user/offers/offer-cancellation-warning/offer-cancellation-warning.component';
import { ItemCommentComponent } from '../item-comment/item-comment.component';
import { ErrorModalComponent } from '../../../shared/ui-components/error-modal/error-modal.component';
import { ItemUpdatingModalComponent } from '../item-updating-modal/item-updating-modal.component';
import { ItemSuccessComponent } from '../item-success/item-success.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ItemsEmptyComponent } from '../items-empty/items-empty.component';
import { VendorItemsPreloaderComponent } from '../../../shared/ui-components/vendor-items-preloader/vendor-items-preloader.component';
import { CustomSelectComponent } from '../../../shared/ui-components/custom-select/custom-select.component';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'app-items',
    templateUrl: './items.component.html',
    styleUrls: ['./items.component.scss'],
    standalone: true,
    imports: [NgIf, NgClickOutsideDirective, FormsModule, NgFor, CustomSelectComponent, NgClass, ItemDetailedComponent, VendorItemsPreloaderComponent, ReactiveFormsModule, ItemSimpleComponent, ItemsEmptyComponent, PaginationModule, ItemSuccessComponent, ItemUpdatingModalComponent, ErrorModalComponent, ItemCommentComponent, OfferCancellationWarningComponent_1]
})
export class ItemsComponent implements OnInit, AfterViewInit, OnDestroy, AfterViewChecked {
  @ViewChild('dp') datepicker: BsDaterangepickerDirective;
  @ViewChild('updatedItemsAlert') autoShownModal: ModalDirective;
  @ViewChildren(ItemSimpleComponent) itemSimpleComponents!: QueryList<ItemSimpleComponent>;
  @ViewChild(OfferCancellationWarningComponent) offerCancelationWarningComponent!: OfferCancellationWarningComponent;

  showCancellationWarning = false;
  isModalShown = false;
  bsConfig: Partial<BsDatepickerConfig>;
  private _items: IVendorItemInfo[];
  statuses: IVendorStatus[] = [];
  viewPortItems: IVendorItemInfo[] = [];
  manufacturers: IVendorManufacturer[];
  pagination: IVendorPagination = {};
  statusSelectedChoice = 'All';
  promos: IVendorPromo[];
  categories: IVendorCategory[];
  dropDowns: IVendorStaticInfo;
  exwPoints: IExw[] = [];
  allExwOptions: IExw[];

  isSearchFocused = false;
  condition: ILookupMain = {};
  warranty: ILookupMain = {};
  packaging: ILookupMain = {};
  restrictions: ILookupMain = {};
  show = false;
  errorsList: string[] = [];
  selectedVendorItem: IVendorItemInfo;
  selectedVendorFg: FormGroup;
  selectedVendorIndex: number;
  categorySelected: IVendorCategory;
  subCategorySelected: IVendorSubCategory;
  loader = false;
  isSortAvailableAsc = false;
  showDetailedPopUp = false;
  isSearched = false;
  totalUpdatedItemsCount = 0;
  pendingForReactivation = 0;
  errorModal: IErrorModal = { isError400: false, errorMessage400: '' };
  defaultStartDate: Date;
  defaultEndDate: Date;
  dateRangeValues: Date[] = [];
  lastDateRangeValues: Date[] = [];
  isModalOpen = false;
  itemsTitle = 'Product Listings';
  paginationMaxSize = 5;
  isShowAllUpdatedItems = false;
  isPaginationDisabled = false;
  isPaginationLoading = false;
  routeParamsSubscription: Subscription;
  nothingFoundMessage = environment.vendorItemsMessage;
  nothingFoundMinorMessage = environment.vendorItemsMinorMessage;
  nothingFoundButtonTxt = environment.vendorItemsButtonText;
  isAllVendors = false;
  dropdownDataSubscription: Subscription;
  itemsDataSubscription: Subscription = new Subscription();
  itemAndStatusDataSubscription: Subscription = new Subscription();
  vendorDataSubscription: Subscription;
  pubSubSubscription: Subscription;
  isUpdatingItems = false;
  isShowExportTooltip = false;
  isAllExport = false;
  isExporting = false;
  isAllRemovedSelected = false;
  exportReq: IItemsExportRequest;
  exportReqOptions: number[];

  showCommentModal = false;
  selectedItem: IVendorItemInfo;
  sellerStatuses = [];
  listingSkuStatusValuesEnum = ListingSkuStatusValuesEnum;
  itemsForm: UntypedFormArray;

  subCategories: IVendorSubCategory[] = [];
  selectedManufacturer: IVendorManufacturer;
  selectedPromotion: IVendorPromo;
  selectedRestriction: INameId;
  selectedExw: IExw;
  totalCountDisplay = 0;
  vendorHomeElem: ElementRef;
  isMoreShown = false;

  pendingOffers: models.ItemWithPendingOffers[] = [];
  isRequestingPendingOffers = false;
  showRefreshMsg = false;
  offersSubscriptions: Subscription[] = [];
  EventTypesEnum = enums.OfferEventTypes;
  isCheckPendingOffersSingleItem = true;
  totalPendingOffers = 0;
  disableReviewOffersButton = true;

  constructor(
    public vendorService: VendorService,
    private elRef: ElementRef,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    public pubSubService: PubSubService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    public itemService: ItemService,
    public fb: FormBuilder,
    public loaderService: LoaderService,
    public offerService: OfferService
  ) { }

  set items(items) {
    this.itemsForm = this.fb.array([]);
    this._items = items.map((item, i) => {
      this.itemService.mapItem(item);
      this.itemsForm.push(this.itemService.generateItemFormGroup(item));
      item.index = i;
      return item;
    });
  }

  get items() {
    return this._items;
  }

  get preSelectedStatus() {
    return this.route.snapshot.paramMap.get('statusName');
  }

  ngOnInit() {
    this.setUpPubSub();
    this.initDates();
    this.getVendorItems();
    this.elRef.nativeElement.ownerDocument.body.style.overflow = 'hidden';
  }

  resetAllDropdowns() {
    this.categorySelected = undefined;
    this.subCategorySelected = undefined;
    this.selectedManufacturer = undefined;
    this.selectedPromotion = undefined;
    this.selectedRestriction = undefined;
    this.selectedExw = undefined;
  }

  ngOnDestroy() {
    if (this.routeParamsSubscription) {
      this.routeParamsSubscription.unsubscribe();
    }
    if (this.pubSubSubscription) {
      this.pubSubSubscription.unsubscribe();
    }

    this.vendorService.search = undefined;
    this.setUpSearchFilters();
    this.clearSearchFilter();
    this.elRef.nativeElement.ownerDocument.body.style.overflow = null;
    this.offersSubscriptions.forEach(s => s.unsubscribe());
  }

  ngAfterViewInit() {
    this.bsConfig = Object.assign({}, { containerClass: 'theme-blue', showWeekNumbers: false });
    this.vendorHomeElem = this.elRef.nativeElement.closest('.vendor-home');
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  setUpPubSub(): void {
    this.pubSubSubscription = this.pubSubService.sharedSubject.subscribe(
      mySharedValues => {
        if (mySharedValues.name == SharedSource.handleNewOfferEventReceived) {
          this.handleNewOfferEventReceived(mySharedValues.data);
        }
        if (mySharedValues.name === SharedSource.vendorComponentUpdated) {
          if (mySharedValues.data === 'ItemsComponent') {
            this.cancelAllHttpCalls();
          }
        }
        if (mySharedValues.name === SharedSource.changeCustomerVendor) {
          this.dropDowns = null;
          this.resetAllDropdowns();
          this.vendorService.search = undefined;
          this.setUpSearchFilters();
          this.clearSearchFilter();
          this.getVendorItems();
        }
      });
  }

  cancelAllHttpCalls(): void {
    if (this.routeParamsSubscription) {
      this.routeParamsSubscription.unsubscribe();
    }

    if (this.dropdownDataSubscription) {
      this.dropdownDataSubscription.unsubscribe();
    }

    if (this.vendorDataSubscription) {
      this.vendorDataSubscription.unsubscribe();
    }

    if (this.itemAndStatusDataSubscription) {
      this.itemAndStatusDataSubscription.unsubscribe();
    }

    if (this.itemsDataSubscription) {
      this.itemsDataSubscription.unsubscribe();
    }
  }

  setUpSearchFilters(): void {
    if (this.vendorService.search) {
      return;
    }
    this.vendorService.search = this.getDefaultFilter();
  }

  getDefaultFilter() {
    return {
      filters: {
        skuStatus: 1
      },
      pagination: {
        pageSize: environment.vendorItemsPageSize,
        pageNumber: 1
      },
      orderBy: 'Inventory',
      sortDirection: 'DESC'
    };
  }

  initDates(): void {
    this.defaultStartDate = new Date();
    this.defaultEndDate = new Date();
  }

  clickedInside($event: Event): void {
    $event.preventDefault();
    $event.stopPropagation();
  }

  scrollTop(): void {
    $('.items-list').scrollTop(0);
  }

  onKeyPressOnSearch(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.search();
    }
  }

  search() {
    this.isSearchFocused = false;
    this.searchItemsByText();
  }

  expandSearch(): void {
    this.isSearchFocused = !this.isSearchFocused;
  }

  sortByAvailable(): void {
    this.vendorService.search.pagination.pageNumber = 1;
    if (this.vendorService.search.sortDirection === 'ASC') {
      this.vendorService.search.sortDirection = 'DESC';
    } else {
      this.vendorService.search.sortDirection = 'ASC';
    }

    this.isPaginationLoading = true;
    this.getVendorItemsData();
  }

  searchItemsByText(): void {
    this.vendorService.search.filters = {};
    this.vendorService.search = {
      searchQuery: this.vendorService.searchString.trim(),
      filters: {
        dateRange: {
          start: this.defaultStartDate.toDateString(),
          end: this.defaultEndDate.toDateString()
        },
        skuStatus: ListingSkuStatusValuesEnum.all,
      },
      pagination: {
        pageSize: environment.vendorItemsPageSize,
        pageNumber: 1
      },
      orderBy: 'Inventory',
      sortDirection: 'DESC'
    };
    // if comming from dashboard
    if (this.preSelectedStatus && this.preSelectedStatus !== 'All') {
      this.router.navigate(['../All'], { relativeTo: this.route });
    }

    if (this.vendorService.isVendorManager && !this.itemService.vendorManagerMap) {
      this.itemService.getVendorManagerDiccionary();
    }
    this.isPaginationLoading = true;
    this.isAllVendors = !!(this.vendorService.search.searchQuery);
    this.getVendorItems();
    this.resetAllDropdowns();

  }

  statusSelected(status: IVendorDropDownData): void {
    this.vendorService.search.filters.skuStatus = status.value;
    this.vendorService.search.pagination.pageNumber = 1;
    this.clearSearchFilter();
    this.isPaginationLoading = true;
    this.getVendorItems();
  }

  selectCategory(category: IVendorCategory): void {
    this.categorySelected = category;
    this.vendorService.search.filters.categoryIds = [];

    if (!this.categorySelected.children) {
      if (this.categorySelected.navId) {
        this.vendorService.search.filters.categoryIds.push(this.categorySelected.categoryId);
      }
    } else {
      this.vendorService.search.filters.categoryIds = this.getSubCategoryIds(this.categorySelected.children);
    }

    this.vendorService.search.pagination.pageNumber = 1;
    this.clearSearchFilter();
    this.isPaginationLoading = true;
    this.getVendorItems();
    this.subCategories = this.categorySelected.children ? this.categorySelected.children : [];
    this.subCategorySelected = undefined;
  }

  selectSubCategory(subCategory: IVendorSubCategory): void {
    this.subCategorySelected = subCategory;
    this.vendorService.search.filters.categoryIds = [];

    if (this.subCategorySelected.navId) {
      this.vendorService.search.filters.categoryIds.push(this.subCategorySelected.categoryId);
    } else {
      this.vendorService.search.filters.categoryIds = this.getSubCategoryIds(this.categorySelected.children);
    }

    this.vendorService.search.pagination.pageNumber = 1;
    this.clearSearchFilter();
    this.isPaginationLoading = true;
    this.getVendorItems();
  }

  /**
   * Method to get all of the categoryIds of the current subcategories
   * Triggers when the user clicks on the All option under the subcategories.
   */
  getSubCategoryIds(subCategories: IVendorSubCategory[]): string[] {
    return subCategories.map((subCat) => subCat.categoryId);
  }

  selectRestriction(restriction: INameId) {
    this.selectedRestriction = restriction;
    if (restriction.id) {
      this.vendorService.search.filters.restrictions = restriction.id;
    } else {
      this.vendorService.search.filters.restrictions = null;
    }

    this.vendorService.search.pagination.pageNumber = 1;
    this.clearSearchFilter();
    this.isPaginationLoading = true;
    this.getVendorItems();
  }

  selectExw(exw: IExw) {
    this.selectedExw = exw;
    this.vendorService.search.filters.exw = exw.value;

    this.vendorService.search.pagination.pageNumber = 1;
    this.clearSearchFilter();
    this.isPaginationLoading = true;
    this.getVendorItems();
  }

  getVendorItemsAndStatusData(clearMapping = false): void {
    // preselected status from dashboard
    if (this.preSelectedStatus && !this.vendorService.searchString) {
      this.selectStatusByName(this.preSelectedStatus);
    }

    this.itemsDataSubscription.unsubscribe();
    this.itemAndStatusDataSubscription.unsubscribe();

    this.itemAndStatusDataSubscription = this.vendorService.getStatusCountAndItems(this.vendorService.search).subscribe(
      (data) => {
        if (data) {
          this.pagination = data[0].pagination;
          this.items = Object.assign([], data[0].items);
          if (clearMapping) {
            this.vendorService.updatedMappedItems.clear();
          }
          this.manufacturers = Object.assign([], data[0].manufacturers);
          this.promos = Object.assign([], data[0].promos);
          this.exwPoints = Object.assign([], data[0].exw);
          this.statuses = data[1];
          let selectedSku = this.statuses.find(s => s.value === this.vendorService.search.filters.skuStatus);

          if (selectedSku && selectedSku.count === 0 && selectedSku.value > 0 && !this.preSelectedStatus) {
            selectedSku = this.statuses.find(s => s.value === 0);
            this.vendorService.search.filters.skuStatus = 0;
            this.vendorService.vendorItemsSavedInfo.statusSelectedChoice = 'All';
            this.getVendorItemsData();
            return;
          }
          this.totalCountDisplay = (selectedSku) ? selectedSku.count : 0;
          this.vendorService.isShowAllUpdatedItems = false;

          if (this.isModalShown) {
            this.resetItemsAfterUpload();
          }

          setTimeout(() => {
            this.isPaginationDisabled = false;
            this.loader = false;
            this.isUpdatingItems = false;
            this.loaderService.setLoaderVendorVisible(false);
            this.isPaginationLoading = false;
          }, 100);
        }
      },
      (err) => {
        this.isUpdatingItems = false;
        this.loader = false;
        this.isPaginationDisabled = false;
        this.sharedService.handleVendorHttpError(err, this.errorModal, this.vendorService.portal);
      }
    );
  }

  promotionSelected(promo: IVendorPromo): void {
    this.selectedPromotion = promo;
    this.vendorService.search.filters.promoId = '';
    if (promo.name !== 'All') {
      this.vendorService.search.filters.promoId = promo.id;
    }

    this.vendorService.search.pagination.pageNumber = 1;
    this.clearSearchFilter();
    this.isPaginationLoading = true;
    this.getVendorItems();

  }

  closeEditItemModal(): void {
    this.items[this.selectedVendorIndex].selected = false;
    this.selectedVendorItem = undefined;
    this.selectedVendorFg = undefined;
    this.showDetailedPopUp = false;
    this.isModalOpen = false;
  }

  manufacturerSelected(manufacturer: IVendorManufacturer): void {
    this.selectedManufacturer = manufacturer;
    this.vendorService.search.filters.manufacturerId = '';
    if (manufacturer.name !== 'All') {
      this.vendorService.search.filters.manufacturerId = manufacturer.id;
    }

    this.vendorService.search.pagination.pageNumber = 1;
    this.clearSearchFilter();
    this.isPaginationLoading = true;
    this.getVendorItems();
  }

  getVendorDropDownData(loadVendors = true): void {
    this.dropdownDataSubscription = this.vendorService.getVendorDropDownData()
      .subscribe(data => {
        this.dropDowns = Object.assign([], data[0]);
        this.itemService.statusesByRole = (this.vendorService.isSeller) ? this.getSellerDefaultStates() : this.getAdminDefaultStates();
        this.condition = this.dropDowns.attributes.filter(condition => condition.name === 'Condition')[0];
        this.warranty = this.dropDowns.attributes.filter(condition => condition.name === 'Warranty')[0];
        this.packaging = this.dropDowns.attributes.filter(condition => condition.name === 'Packaging')[0];
        this.restrictions = this.dropDowns.attributes.filter(condition => condition.name === 'Restrictions')[0];
        this.allExwOptions = data[1];
        this.setUpSearchFilters();

        if (!loadVendors) { return; }
        this.getVendorItemsAndStatusData();
        if (this.vendorService.isUser(['ADMIN', 'SUPER']) && !this.vendorService.vendors.length) {
          this.vendorDataSubscription = this.vendorService.getAllVendors().subscribe(
            vendorsData => {
              this.vendorService.vendors = vendorsData;
            },
            err => {
              this.loader = false;
              this.sharedService.handleVendorHttpError(err, this.errorModal, this.vendorService.portal);
            }
          );
        }
      });
  }

  getVendorItemsData(): void {
    this.loader = true;
    this.loaderService.setLoaderVendorVisible(true);
    this.isPaginationDisabled = true;

    this.itemsDataSubscription.unsubscribe();
    this.itemAndStatusDataSubscription.unsubscribe();

    this.itemsDataSubscription = this.vendorService.getItems(this.vendorService.search).subscribe(data => {
      this.pagination = data.pagination;
      this.items = Object.assign([], data.items);
      this.manufacturers = Object.assign([], data.manufacturers);
      this.promos = Object.assign([], data.promos);
      this.exwPoints = Object.assign([], data.exw);
      this.vendorService.updatedMappedItems.clear();

      this.vendorService.isShowAllUpdatedItems = false;

      if (this.isModalShown) {
        this.resetItemsAfterUpload();
      }
      //If header checkbox toggled before items load on Removed filter
      this.checkAllItemsForReactivation();
      this.isUpdatingItems = false;

      setTimeout(() => {
        this.isUpdatingItems = false;
        this.isPaginationDisabled = false;
        this.loader = false;
        this.loaderService.setLoaderVendorVisible(false);
        this.isPaginationLoading = false;
      }, 100);
    },
      err => {
        this.isUpdatingItems = false;
        this.loader = false;
        this.loaderService.setLoaderVendorVisible(false);
        this.isUpdatingItems = false;
        this.isPaginationDisabled = false;
        this.sharedService.handleVendorHttpError(err, this.errorModal, this.vendorService.portal);
      }
    );
  }

  getVendorItems(wasJustUpdated = false, clearMapping = true): void {
    this.loader = true;
    this.loaderService.setLoaderVendorVisible(true);
    if (!this.dropDowns) {
      this.getVendorDropDownData();
    } else {
      if (!wasJustUpdated && !this.vendorService.searchString) {
        this.vendorService.search.filters.skuStatus = ListingSkuStatusValuesEnum.active;
        this.vendorService.vendorItemsSavedInfo.statusSelectedChoice = 'Active';
      }
      this.getVendorItemsAndStatusData(clearMapping);
    }
  }

  getSellerDefaultStates() {
    if (!this.dropDowns) { return []; }
    return this.dropDowns.skuStatus
      .filter(s =>
        s.text === ListingSkuStatusNamesEnum.removed ||
        s.text === ListingSkuStatusNamesEnum.active ||
        s.text === ListingSkuStatusNamesEnum.paused ||
        s.text === ListingSkuStatusNamesEnum.soldOut);
  }

  getAdminDefaultStates() {
    if (!this.dropDowns) { return []; }
    return this.dropDowns.skuStatus
      .filter(s => s.value !== ListingSkuStatusValuesEnum.all &&
        s.value !== ListingSkuStatusValuesEnum.archived);
  }

  selectStatusAndRetrieveItems(status: IVendorStatus) {
    this.totalCountDisplay = status.count;
    this.vendorService.search.filters.skuStatus = status.value;
    this.vendorService.vendorItemsSavedInfo.statusSelectedChoice = status.name; // TODO: delete this
    this.vendorService.search.pagination.pageNumber = 1;
    this.isPaginationLoading = true;
    this.resetReactivationCheckboxes();
    this.getVendorItemsData();

    if (this.preSelectedStatus) {
      this.router.navigate(['/vendor/items/status/' + status.name]);
    }
    this.hideMore();
  }

  selectStatusByName(statusName: string) {
    const selectedStatus = this.dropDowns.skuStatus.filter(sku => sku.text === statusName)[0];

    if (selectedStatus) {
      this.vendorService.search.filters.skuStatus = selectedStatus.value;
    }
  }

  clearSearchFilter(): void {
    this.vendorService.search.searchQuery = '';
    this.vendorService.searchString = '';
  }

  pageChanged(event: any): void {
    if (this.isPaginationLoading) {
      return;
    }

    this.vendorService.search.pagination.pageNumber = event.page;
    this.vendorService.search.pagination.pageSize = environment.vendorItemsPageSize;
    this.resetReactivationCheckboxes();
    this.getVendorItemsData();
  }

  clearAllUpdatedItems(): void {
    this.resetReactivationCheckboxes();
    // get vendor items from server with current filters in order to reset to the initial values
    this.getVendorItems(true);
  }

  /**
 * Method to change the view of the vendor items
 * Options(viewType) - 'excel', 'detailed'
 * Variable useAnalytics is used to determine if the analytics view should be displayed
 */
  changeView(viewType: string): void {
    this.vendorService.vendorItemsSavedInfo.viewType = viewType;

    // if analytics were on then clear the date range and reload items without the date filter
    if (this.vendorService.vendorItemsSavedInfo.useAnalytics) {
      this.dateRangeValues[0] =
        this.vendorService.search.filters.dateRange ? new Date(this.vendorService.search.filters.dateRange.start) : null;
      this.dateRangeValues[1] =
        this.vendorService.search.filters.dateRange ? new Date(this.vendorService.search.filters.dateRange.end) : null;
      this.vendorService.vendorItemsSavedInfo.useAnalytics = false;
    }
  }


  /**
   * Gets called when user clicks onn item logo on item simple view and opens up the item detailed popup version
   * @param item
   */
  openEditVendorItemInDetailedMode(item: IVendorItemInfo, index: number): void {
    this.selectedVendorItem = item;
    this.selectedVendorIndex = index;
    this.selectedVendorFg = this.itemsForm.controls[index] as FormGroup;
    this.selectedVendorFg.clearValidators();
    this.showDetailedPopUp = true;
  }

  resetItemsAfterUpload(): void {
    this.items.map(item => {
      item.updatedNow = false;
      item.showError = false;
    });
  }

  checkPendingOffersAllItems() {
    if (!this.isRequestingPendingOffers) {
      this.isRequestingPendingOffers = true;
      this.showRefreshMsg = false;
      const payload = {
        items: []
      } as models.ItemChangeWillAffectOfferRequest;
      this.vendorService.updatedMappedItems.forEach((object, key) => {
        this.setUpdateOperationFieldHandler(object.updateItem.id);
        let itemData = this.vendorService.updatedMappedItems.get(object.updateItem.id).itemRequest;
        payload.items.push(itemData as IVendorItemRequest);
      });

      const localTimeInUTC = moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      this.offerService.getPendingOffers(payload).subscribe(response => {
        this.isRequestingPendingOffers = false;
        this.pendingOffers = response.map(offer => ({
          ...offer,
          sellerId: this.vendorService.updatedMappedItems.get(offer.vendorItemId).updateItem.seller.id,
          manufacturerLogoUrl: this.vendorService.updatedMappedItems.get(offer.vendorItemId).updateItem.manufacturerLogoUrl,
          offers: offer.offers.map(o => ({
            ...o,
            status: enums.OfferStatusCancelationWarning.Pending
          })),
          hasPendingOffers: true
        }));

        this.calculateTotalPendigOffers();
        const partnerId = sessionStorage.getItem('partner_id');
        this.disableReviewOffersButton = this.pendingOffers.some(value => value.sellerId !== partnerId);
        this.isCheckPendingOffersSingleItem = false;
        sessionStorage.setItem('cancellationWarningFromDate', localTimeInUTC);
        this.showCancellationWarning = true;
      }, err => {
        console.log('Error on get pending offers', err);
        this.isRequestingPendingOffers = false;
        if (err.status === 404) {
          this.updateAllVendorItems();
        }
      });
    }
  }

  updateAllVendorItems(): void {
    this.loader = true;
    this.isUpdatingItems = true;
    this.resetReactivationCheckboxes();
    this.setUpdateOperationFieldHandler();
    const requestItems = [];

    this.vendorService.updatedMappedItems.forEach((object, key) => {
      requestItems.push(object.itemRequest);
    });

    this.vendorService.updateVendorItems(requestItems).subscribe(
      data => {
        this.isModalShown = true;
        this.getVendorItems(true);
        this.totalUpdatedItemsCount = this.vendorService.updatedMappedItems.size;
        this.vendorService.updatedMappedItems.clear();
      },
      err => {
        // special case of error handling if items updated were returned with a 400 error from server
        this.isUpdatingItems = false;
        this.loader = false;
        err.status === 500 ?
          this.sharedService.handleVendorHttpError(err, this.errorModal, this.vendorService.portal) :
          this.addErrorsToItems(err.error);
      });
  }

  checkPendingOffersSingleItem(item: IVendorItemInfo) {
    if (!this.isRequestingPendingOffers) {
      this.isRequestingPendingOffers = true;
      this.showRefreshMsg = false;

      this.setUpdateOperationFieldHandler(item.id);

      var itemData = this.vendorService.updatedMappedItems.get(item.id).itemRequest
      var payloadItem = itemData as IVendorItemRequest

      const payload = {
        items: [payloadItem]
      } as models.ItemChangeWillAffectOfferRequest;
      const localTimeInUTC = moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      this.offerService.getPendingOffers(payload).subscribe(response => {
        this.isRequestingPendingOffers = false;
        this.pendingOffers = response.map(offer => ({
          ...offer,
          sellerId: item.seller.id,
          manufacturerLogoUrl: item.manufacturerLogoUrl,
          offers: offer.offers.map(o => ({
            ...o,
            status: enums.OfferStatusCancelationWarning.Pending
          })),
          hasPendingOffers: true
        }));
        const partnerId = sessionStorage.getItem('partner_id');
        this.disableReviewOffersButton = this.pendingOffers.some(value => value.sellerId !== partnerId);
        this.calculateTotalPendigOffers();
        this.isCheckPendingOffersSingleItem = true;
        sessionStorage.setItem('cancellationWarningFromDate', localTimeInUTC);
        this.showCancellationWarning = true;
      }, err => {
        console.log('Error on get pending offers', err);
        this.isRequestingPendingOffers = false;
        if (err.status === 404) {
          this.updateSingleVendorItems(item);
        }
      });
    }


  }

  calculateTotalPendigOffers() {
    this.totalPendingOffers = 0;
    this.pendingOffers.forEach(po => {
      po.offers.forEach(offer => {
        if (offer.status === enums.OfferStatusCancelationWarning.Pending) {
          this.totalPendingOffers++;
        }
      });
    });
  }

  updateSingleVendorItems(item: IVendorItemInfo): void {
    this.loader = true;
    this.isUpdatingItems = true;

    this.setUpdateOperationFieldHandler(item.id);
    this.resetReactivationCheckboxes();

    const requestItems = [];
    const itemReq: IVendorItemRequest = this.vendorService.updatedMappedItems.get(item.id).itemRequest;
    requestItems.push(itemReq);

    this.vendorService.updateVendorItems(requestItems).subscribe(
      data => {

        this.isModalShown = true;
        // this can be called from pending po and search will be undefined
        // also no need to get the list for pending po
        if (this.vendorService.search) {
          this.getVendorItems(true, false);
        } else {
          this.loader = false;
          this.isUpdatingItems = false;
        }
        this.totalUpdatedItemsCount = 1;
        this.vendorService.updatedMappedItems.delete(item.id);
      },
      err => {
        // special case of error handling if items updated were returned with a 400 error from server
        this.isUpdatingItems = false;
        this.loader = false;
        err.status === 500 ?
          this.sharedService.handleVendorHttpError(err, this.errorModal, this.vendorService.portal) :
          this.addErrorsToItems(err.error);
      });
  }

  createVendorAttributesRequest(attributes: IVendorItemAttributes[]): IVendorItemAttributeRequest[] {
    const vendorAttributes: IVendorItemAttributeRequest[] = [];

    attributes.forEach(attr => {
      vendorAttributes.push({
        code: attr.code,
        value: attr.value,
        vendorItemAttributeId: attr.attrId,
        name: attr.label,
        description: attr.description
      });
    });

    return vendorAttributes;
  }

  handleNewOfferEventReceived(offerEvent: models.IOfferEventMessage): void {
    const offerIdToCheck = offerEvent.offerId;

    if (offerEvent.eventType === enums.OfferEventTypes.OfferMade) { // if offerMade was recived, then check that item in the offer created is candidate to be updated
      
      const existsInPendings = this.pendingOffers.some(pendingOffer => pendingOffer.offers.some(o => o.id === offerEvent.offerId));
      if (existsInPendings) {
        return;
      }

      this.offersSubscriptions.push(this.offerService.getOfferById(offerEvent.offerId)
        .subscribe(
          (offer: models.Offer) => {
            var exists = false;
              this.vendorService.updatedMappedItems.forEach((object, key) => {
                if (object.itemRequest.id === offer.vendorItemId) {
                  exists = true;
                }
              });
              if (exists) {
                this.offerService.offersCancellationPopupSettings.show = false;
                this.showRefreshMsg = true;
              }
          },
          (err) => { console.log('Error trying to get offer by id ', err); }
        ))
    }

    if (offerEvent.eventType !== enums.OfferEventTypes.OfferMade) {
      const exists = this.pendingOffers.some(pendingOffer =>
        pendingOffer.offers.some(offer => offer.id === offerIdToCheck)
      );
      if (exists) {
        this.offerService.offersCancellationPopupSettings.show = false;
        this.showRefreshMsg = true;
      }
    }
  }
  closeCancellationWarningModal(){
    this.showCancellationWarning=false;
    this.pendingOffers=[];
  }
  refreshCancellationWarningModal() {
    this.showRefreshMsg = false;
    const localTimeInUTC = moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

    const payload = { itemIds: [] } as models.PendingOfferRequest;
    this.vendorService.updatedMappedItems.forEach((object, key) => {
      payload.itemIds.push(object.itemRequest.id);
    });

    this.offerService.loadingOfferCancelationData = true;
    this.offersSubscriptions.push(
      this.offerService.getPendingOffersFromDate(payload).subscribe(
        (response: models.ItemWithPendingOffers[]) => {
          sessionStorage.setItem('cancellationWarningFromDate', localTimeInUTC);
          response = response.map(offer => ({
            ...offer,
            sellerId: this.vendorService.updatedMappedItems.get(offer.vendorItemId).updateItem.seller.id,
            manufacturerLogoUrl: this.vendorService.updatedMappedItems.get(offer.vendorItemId).updateItem.manufacturerLogoUrl
          }));

          response.forEach(updatedItem => {
            // Find the corresponding item in A that matches the updated item in B
            const itemInPendingOffers = this.pendingOffers.find(item =>
              item.vendorItemId === updatedItem.vendorItemId
            );

            if (itemInPendingOffers) {
              // Iterate over the offers of the updated item in B
              updatedItem.offers.forEach(newOrUpdatedOffer => {
                // Find the corresponding offer in the item of A
                const offerInPendingOffers = itemInPendingOffers.offers.find(offer => offer.id === newOrUpdatedOffer.id);

                if (offerInPendingOffers) {
                  // Update the offer values in A with the values from the offer in B
                  offerInPendingOffers.itemPrice = newOrUpdatedOffer.itemPrice;
                  offerInPendingOffers.offerPrice = newOrUpdatedOffer.offerPrice;
                  offerInPendingOffers.units = newOrUpdatedOffer.units;
                  offerInPendingOffers.total = newOrUpdatedOffer.total;
                  offerInPendingOffers.status = newOrUpdatedOffer.status;
                } else { // was an offer made, then we need to add the new offer
                  itemInPendingOffers.offers.push(newOrUpdatedOffer);
                }
              });
              this.verifyItemHasPendingOffers(itemInPendingOffers.vendorItemId);
            } else {
              updatedItem = {
                ...updatedItem,
                sellerId: this.vendorService.updatedMappedItems.get(updatedItem.vendorItemId).updateItem.seller.id,
                manufacturerLogoUrl: this.vendorService.updatedMappedItems.get(updatedItem.vendorItemId).updateItem.manufacturerLogoUrl,
                offers: updatedItem.offers.map(o => ({
                  ...o,
                  status: enums.OfferStatusCancelationWarning.Pending
                })),
                hasPendingOffers: true
              };

              this.pendingOffers.push(updatedItem);
              this.offerCancelationWarningComponent.addItemOffersToFormArray(updatedItem);
              this.offerCancelationWarningComponent.checkForOffersUpdates();
            }
          });

          const partnerId = sessionStorage.getItem('partner_id');
          this.disableReviewOffersButton = this.pendingOffers.some(value => value.sellerId !== partnerId);
          this.calculateTotalPendigOffers();
          this.offerService.loadingOfferCancelationData = false;
        },
        (error) => {
          this.showRefreshMsg = true;
          this.offerService.loadingOfferCancelationData = false;
          console.log(error, ' Error getting cancellation warning data')
        })
    );
  }

  verifyItemHasPendingOffers(itemId: string) {
    const index = this.pendingOffers.findIndex(po => po.vendorItemId === itemId);
    const pendingItemOffers = this.pendingOffers[index];
    var counterOfNoPendingOffers = 0;
    pendingItemOffers.offers.forEach(offer => {
      if (offer.status !== enums.OfferStatusCancelationWarning.Pending) {
        counterOfNoPendingOffers++;
      }
    });
    if (counterOfNoPendingOffers === pendingItemOffers.offers.length) {
      pendingItemOffers.hasPendingOffers = false;
      // ((this.offerCancelationWarningComponent.pendingOffersForm.get('offers'))as FormArray).controls[index].get('selectedOption').setValue(models.PendingOfferAction.KEEP);
    }
  }

  updateAfterCancelationWarning(pendingItemOffersByAction: models.PendingOfferByAction) {
    const pendingItemOffersToKeep: models.ItemWithPendingOffers[] = pendingItemOffersByAction.pendingOffersToKeep;
    const pendingItemOffersToCancel: models.ItemWithPendingOffers[] = pendingItemOffersByAction.pendingOffersToCancel;

    pendingItemOffersToKeep.forEach(itemOffers => {
      for (let [key, value] of this.vendorService.updatedMappedItems.entries()) {
        if (itemOffers.vendorItemId === value.itemRequest.id) {
          this.vendorService.updatedMappedItems.delete(key);
          const itemSimpleComp = this.itemSimpleComponents.find(is => is.item.id === itemOffers.vendorItemId);
          if (itemSimpleComp) {
            itemSimpleComp.undoItemUpdates(null);
          }
        }
      }
    });

    if (this.isCheckPendingOffersSingleItem) {
      if (!pendingItemOffersToCancel.length) {
        return;
      }
      const item: IVendorItemInfo = {
        id: pendingItemOffersToCancel[0].vendorItemId
      }
      this.updateSingleVendorItems(item);
      return;
    }

    if (this.vendorService.updatedMappedItems.size >= 1) {
      this.updateAllVendorItems();
    }
  }

  /**
   * Method to go thru each error and find its corresponding line number in the
   * itemsRequestArray and modify the error attribute in the items array.
   * @param errors - errors array returning from server.
   */
  addErrorsToItems(errors: ILoadValidationError[]): void {

    // set items to updatedItems in order to have the entire list of updated items to be displayed to the user in case of an error
    // filter thru it and only show the ones that resulted in an error
    const updatedItems = [];
    this.vendorService.updatedMappedItems.forEach((value, key) => {
      updatedItems.push(value.updateItem);
    });
    // this.items = updatedItems;
    // this.items = this.vendorService.vendorItemsSavedInfo.updatedItems;
    this.totalUpdatedItemsCount = this.vendorService.updatedMappedItems.size;
    // this.totalUpdatedItemsCount = this.vendorService.vendorItemsSavedInfo.vendorItemsRequest.length;
    if (this.totalUpdatedItemsCount > errors.length) {
      this.isModalShown = true;
      this.totalUpdatedItemsCount = this.totalUpdatedItemsCount - errors.length;
    }
    for (let i = 0; i < errors.length; i++) {
      const tempItems =
        updatedItems.filter(item => item.id === updatedItems[errors[i].lineNumber - 1].id);
      // this.items.filter(item => item.id === this.vendorService.vendorItemsSavedInfo.vendorItemsRequest[errors[i].lineNumber - 1].id);
      tempItems[0].errors = errors[i];
      tempItems[0].showError = true;
    }

    // remove all items that don't have errors and update updateItems to most current list of updatedItems
    this.items = updatedItems.filter(item => item.errors && item.errors.errors);
    updatedItems.filter(item => !item.errors)
      .forEach(i => {
        if (this.vendorService.updatedMappedItems.has(i.id)) {
          this.vendorService.updatedMappedItems.delete(i.id);
        }
      });

    if (this.vendorService.vendorItemsSavedInfo.useAnalytics) {
      this.changeView('excel');
    }
    this.loader = false;
  }


  /**
   * creates vendor prices for the update request.
   * @param prices
   */
  createVendorPricesRequest(prices: IVendorItemPrice[]): IDiscountQty[] {
    const vendorPrices: IDiscountQty[] = [];

    prices.forEach(price => {
      if (price.fromQty && price.unitPrice && price.fromQty !== 0 && price.unitPrice !== 0) {
        vendorPrices.push({
          qty: price.fromQty,
          price: price.unitPrice
        });
      }
    });

    return vendorPrices;
  }

  showSuccessAlert(): void {
    this.isModalShown = true;
  }

  onHidden(): void {
    this.isModalShown = false;
  }

  hideModal(): void {
    this.isModalShown = false;
  }

  getItemsText(): string {
    return (this.totalUpdatedItemsCount === 1) ? '1  item was updated.' :
      `${this.totalUpdatedItemsCount} items were updated.`;
  }

  isItemsEmpty(): boolean {
    return this.items && !this.items.length;
  }

  showAllUpdatedItems(): void {
    this.vendorService.isShowAllUpdatedItems = true;
  }

  hideSuccessAlert(): void {
    this.isModalShown = false;
  }

  hideErrorAlert(): void {
    this.errorModal = { isError400: false, errorMessage400: '' };
  }

  getCurrentUpdatedItemsListings(): string {
    return this.vendorService.vendorItemsSavedInfo.updatedItems.length + ' of ' +
      this.vendorService.vendorItemsSavedInfo.updatedItems.length;
  }

  getTotalListings(): string {
    const start = ((this.pagination.currentPage - 1) * this.pagination.pageSize) + 1;
    const end = this.pagination.currentPage * this.pagination.pageSize;


    // if there is only one page
    if (this.pagination.totalCount < this.pagination.pageSize) {
      return this.pagination.totalCount + ' of ' + this.pagination.totalCount;
    }

    if (this.pagination.currentPage === this.pagination.totalPages) {
      return start.toLocaleString() + '-' + this.pagination.totalCount + ' of ' + this.pagination.totalCount;
    }

    return start.toLocaleString() + '-' + end + ' of ' + this.pagination.totalCount;
  }

  refreshItemsData(): void {
    this.vendorService.searchString = '';
    this.vendorService.search.filters = {};
    this.vendorService.search = {
      searchQuery: '',
      filters: {
        dateRange: {
          start: this.defaultStartDate.toDateString(),
          end: this.defaultEndDate.toDateString()
        },
        skuStatus: 0,
      },
      pagination: {
        pageSize: environment.vendorItemsPageSize,
        pageNumber: 1
      },
      orderBy: 'Inventory',
      sortDirection: 'DESC'
    };
    this.isPaginationLoading = true;
    this.getVendorItems();
    this.resetAllDropdowns();
  }

  setUpdateOperationFieldHandler(mappedItemId?: string): void {
    if (mappedItemId) {
      this.setUpdateOperationField(this.vendorService.updatedMappedItems.get(mappedItemId));
    } else {
      this.vendorService.updatedMappedItems.forEach(value => {
        this.setUpdateOperationField(value);
      });
    }
  }

  setUpdateOperationField(value) {
    let sum = 0;
    if (!_.isEmpty(value.itemRequest.inventory)) {
      sum += itemOperationFlagAttr.inventory;

      value.itemRequest.inventory = {
        itemCode: value.updateItem.vendorItemCode,
        onHand: value.itemRequest.inventory.onHand || value.updateItem.inventoryInfo.currentInventory.onHand,
        onRoute: value.itemRequest.inventory.onRoute || value.updateItem.inventoryInfo.currentInventory.enRoute,
        newInventory: value.itemRequest.inventory.newInventory || value.updateItem.inventoryInfo.currentInventory.availableToSell,
        leadTimeDays: value.itemRequest.inventory.leadTimeDays || value.updateItem.leadTimeDays
      };
    } else {
      delete value.itemRequest.inventory;
    }
    if (!_.isEmpty(value.itemRequest.status)) {
      sum += itemOperationFlagAttr.status;
    } else {
      delete value.itemRequest.status;
    }
    if (!_.isEmpty(value.itemRequest.itemInformation)) {
      sum += itemOperationFlagAttr.information;

      //  complete rest of properties inside
      value.itemRequest.itemInformation = {
        itemCode: value.updateItem.vendorItemCode,
        description: value.itemRequest.itemInformation.description || value.updateItem.description,
        vendorAttributes: value.itemRequest.itemInformation.vendorAttributes ||
          this.createVendorAttributesRequest(value.updateItem.attributes),
        mpn: value.itemRequest.itemInformation.mpn || value.updateItem.mpn,
        comments: value.itemRequest.itemInformation.comments || value.updateItem.comments,
        exw: value.itemRequest.itemInformation.exw || value.updateItem.exwPointValue
      };
    } else {
      delete value.itemRequest.itemInformation;
    }
    if (!_.isEmpty(value.itemRequest.pricing)) {
      sum += itemOperationFlagAttr.price;

      value.itemRequest.pricing = {
        itemCode: value.updateItem.vendorItemCode,
        unitPrice: value.itemRequest.pricing.unitPrice || value.updateItem.unitPrice,
        cost: value.itemRequest.pricing.cost || value.updateItem.cost,
        moq: value.itemRequest.pricing.moq || value.updateItem.moq,
        mxq: value.itemRequest.pricing.mxq || value.updateItem.mxq,
        discountQtys: value.itemRequest.pricing.discountQtys || this.createVendorPricesRequest(value.updateItem.prices)
      };
    } else {
      delete value.itemRequest.pricing;
    }
    value.itemRequest.updateOperations = sum;
  }

  openCommentModal(fgVendor: FormGroup): void {
    // this.selectedItem = item;
    this.selectedVendorFg = fgVendor;
    this.showCommentModal = true;
  }

  closeItemCommentsModal() {
    event.stopPropagation();
    event.preventDefault();
    this.showCommentModal = false;
  }

  onClickedOutside() {
    this.closeEditItemModal();
  }

  triggerExportTooltip(exportReq: NgForm): void {
    exportReq.resetForm();
    this.isAllExport = false;
    this.isShowExportTooltip = !this.isShowExportTooltip;
  }

  triggerExportAll(): void {
    this.isAllExport = !this.isAllExport;
  }

  hideExportTooltip($event): void {
    this.isShowExportTooltip = false;
  }

  getFilteredOptions(exportReq: NgForm) {
    const checkedOptions = Object.keys(exportReq.value).map((x) => Number(x));
    const filteredOptions = checkedOptions.filter(function (key) {
      return exportReq.value[key];
    });
    this.exportReqOptions = filteredOptions;
  }


  isOptionNotSelected(exportReq: NgForm) {
    this.getFilteredOptions(exportReq);
    if (this.exportReqOptions && this.exportReqOptions.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  export(exportReq: NgForm) {
    this.isExporting = true;
    this.getFilteredOptions(exportReq);
    this.exportReq = {
      OptionELId: this.exportReqOptions,
    };

    this.vendorService.exportItemList(this.exportReq).subscribe(data => {
      const fileName = data.headers.get('content-disposition').split(';')[1].split('"')[1];
      importedSaveAs(data.body, fileName);
      this.isShowExportTooltip = false;
      this.isAllExport = false;
      exportReq.resetForm();
      this.isExporting = false;
    },
      (err) => {
        this.isExporting = false;
        this.sharedService.handleVendorHttpError(err, this.errorModal, this.vendorService.portal);
      });
  }

  resetItem(item: IVendorItemInfo, index: number) {
    item.index = index;
    const newFormGroup = this.itemService.generateItemFormGroup(item);
    this.items[index] = item;
    this.itemsForm.controls[index] = newFormGroup;

    if (this.selectedVendorItem) {
      this.selectedVendorItem = item;
      this.selectedVendorItem.selected = true;
      this.selectedVendorFg = newFormGroup;
    }
    this.countPendingForReactivation();
  }

  showMore() {
    this.isMoreShown = true;
  }
  hideMore() {
    this.isMoreShown = false;
  }

  applyCostReductionToAllSelected() {
    this.items.forEach((i, idx) => {
      if (i.reactivationThreshold) {
        const itemFG = this.itemsForm.controls[idx] as FormGroup;
        if (itemFG.controls.reactivationCheckBox.value) {
          itemFG.controls.cost.setValue(i.reactivationThreshold);
        }
      }
    });
    this.resetReactivationCheckboxes();
  }

  checkAllItemsForReactivation($event = undefined) {
    if (this.vendorService?.search?.filters?.skuStatus === this.listingSkuStatusValuesEnum.removed) {
      if ($event)
        this.isAllRemovedSelected = $event.target.checked;
      const itemFG = this.itemsForm.controls as FormGroup[];
      itemFG.forEach(fg => {
        fg.controls.reactivationCheckBox.setValue(this.isAllRemovedSelected);
      });
      this.countPendingForReactivation();
    }
  }

  countPendingForReactivation(checkIfAllSelected = true) {
    if (this.vendorService?.search?.filters?.skuStatus === this.listingSkuStatusValuesEnum.removed) {
      const itemFG = this.itemsForm.controls as FormGroup[];
      this.pendingForReactivation = itemFG.filter(f => f.controls.reactivationCheckBox.value).length;
      if (checkIfAllSelected)
        this.isAllRemovedSelected = this.pendingForReactivation === itemFG.length && itemFG.length > 0;
    }
  }

  resetReactivationCheckboxes() {
    if (this.vendorService?.search?.filters?.skuStatus === this.listingSkuStatusValuesEnum.removed) {
      const itemFG = this.itemsForm.controls as FormGroup[];
      itemFG.forEach(fg => {
        fg.controls.reactivationCheckBox.setValue(false);
      });
      this.isAllRemovedSelected = false;
      this.countPendingForReactivation(false);
    }
  }

}
