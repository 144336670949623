import { Component, OnInit } from '@angular/core';
import { NgFor } from '@angular/common';

@Component({
    selector: 'app-items-empty',
    templateUrl: './items-empty.component.html',
    styleUrls: ['./items-empty.component.scss'],
    standalone: true,
    imports: [NgFor]
})
export class ItemsEmptyComponent implements OnInit {

  items = [];

  constructor() { }

  ngOnInit() {
  	this.items.length = 3;
  }

}
