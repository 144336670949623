import { Component, OnInit, HostListener, ChangeDetectionStrategy } from '@angular/core';
import { TopComponent } from '../top.component';
import { UserFiltersTypeaheadComponent } from '../../user-filters/user-filters-typeahead/user-filters-typeahead.component';
import { NgIf } from '@angular/common';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgClickOutsideDirective } from 'ng-click-outside2';

@Component({
    selector: 'app-top-mobile',
    templateUrl: './top-mobile.component.html',
    styleUrls: ['./top-mobile.component.scss'],
    standalone: true,
    imports: [
        NgClickOutsideDirective,
        FormsModule,
        RouterLinkActive,
        RouterLink,
        NgIf,
        UserFiltersTypeaheadComponent,
    ],
})
export class TopMobileComponent extends TopComponent implements OnInit {
  isClearBtnActive = false;

  clearSearch(): void {
    this.topService.searchString = '';
    this.isShowClearBtn();
  }

  isShowClearBtn(value?: string): void {
    if (value && value.length > 0) {
      this.topService.searchString = value;
      this.isClearBtnActive = true;
    } else {
      this.isClearBtnActive = false;
    }
  }

  onSearchClicked() {
    if (this.topService.isSearchActive) {
      this.searchItems(this.topService.searchString);
      this.isShowClearBtn();
    } else {
      this.showSearch();
      this.isShowClearBtn();
    }
  }
}
