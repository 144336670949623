import { Component, OnInit } from '@angular/core';
import { TopService } from '../../services/top.service';
import { ItemsService } from '../../services/items.service';
import { SharedService } from '../../shared.service';
import { TopFiltersEnum } from '../../../buyer/enums/topFiltersEnum';
import { Router } from '@angular/router';
import { UserService } from '../../../user/user.service';
import { environment } from '../../../../environments/environment';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-landing-page-trending-boxes',
    templateUrl: './landing-page-trending-boxes.component.html',
    styleUrls: ['./landing-page-trending-boxes.component.scss'],
    standalone: true,
    imports: [NgIf]
})
export class LandingPageTrendingBoxesComponent implements OnInit {
  topFiltersEnum = TopFiltersEnum;
  environment = environment;

  constructor(
    public topService: TopService,
    public itemsService: ItemsService,
    public sharedService: SharedService,
    private router: Router,
    private userService: UserService
    ) { }

  ngOnInit() {
  }

  goTo(selectedFilter: TopFiltersEnum | string) {

    if ( selectedFilter === TopFiltersEnum.justSold ) {
      if ( !this.sharedService.justSoldValues.totalLineItems ) { return; }
      this.itemsService.selectJustSold();
      this.router.navigate([`${this.userService.getUrlByRole()}/just_sold`]);
    } else {
      const topfilter = this.topService.getTopFilterByName(selectedFilter);
      if (topfilter.count && topfilter.count > 0) {
        const url = this.topService.getURLByTopFilter(topfilter);
        this.router.navigate([url]);
      }
    }
  }
}
