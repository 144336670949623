import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../shared/shared.service';
import { Router , ActivatedRoute} from '@angular/router';
import { environment } from '../../../environments/environment';
import { UserService } from '../../user/user.service';
import { TopService } from '../../shared/services/top.service';
import { FooterComponent } from '../footer/footer.component';
import { NgIf, NgFor, NgClass } from '@angular/common';
@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, NgClass, FooterComponent]
})
export class FaqComponent implements OnInit {
  customerFaq: any[];
  vendorFaq: any[];
  constructor(private sharedService: SharedService,
              private router: Router,
              private route: ActivatedRoute,
              private userService: UserService,
              private activatedRoute: ActivatedRoute,
              public topService: TopService) { }

  ngOnInit() {
    document.documentElement.scrollTop = 0;
    this.vendorFaq = environment.faq.vendor;
    this.customerFaq = environment.faq.customer;
    this.sharedService.isStaticHtml = true;
    this.sharedService.isCorpSite = true;
    this.activatedRoute.queryParamMap.subscribe(params => {
      if (params.keys.length > 0 ) {
        this.userService.isInIframe = true;
      }

    });


    $(function () {
        var $list = $('.support-dropdown_list'),
            $btn = $('.support-dropdown_btn');
        $list.find('li').on('click', function () {
            var $this = $(this),
                id = $this.data('id');
            $('.support-dropdown_list li').removeClass('active');
            $this.addClass("active");
            $('.questions-col').hide();
            $('.questions-col[data-block="' + id + '"]').show();
            $btn.text($this.text());
            toggleDd()
        });
        function toggleDd() {
            $list.stop(true, true).slideToggle(300);
            $btn.toggleClass('opened');
        }
        $btn.on('click', toggleDd);
    });
  }
  getAnswer(event: MouseEvent, faq: any): void {
    faq.open = !faq.open;
  }

  navigateTo(url: string): void {

    this.router.navigate([url]);
  }
}
