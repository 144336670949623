import { Component, Input, EventEmitter, Output } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import * as models from '../../interfaces/model';
import { CartService } from '../cart.service';
import { CustomCurrencyPipe } from '../../../shared/pipes/currency.pipe';
import { NgIf, NgFor, DatePipe } from '@angular/common';

@Component({
    selector: 'app-add-to-existing',
    templateUrl: './add-to-existing.component.html',
    styleUrls: ['./add-to-existing.component.scss'],
    animations: [
        trigger('openOrdersDetailsTrigger', [
            state('hide', style({
                height: '0px',
                overflow: 'hidden'
            })),
            state('show', style({
                height: '*'
            })),
            transition('show => hide', animate('200ms ease-out')),
            transition('hide => show', animate('200ms ease-in'))
        ])
    ],
    standalone: true,
    imports: [NgIf, NgFor, DatePipe, CustomCurrencyPipe]
})
export class AddToExistingComponent {
  @Input() selectedOpenOrder: models.IOrder;
  @Input() openOrdersDetails: boolean;
  @Input() showModal: boolean;
  @Input() isMobile: boolean;
  @Input() loading: boolean;
  @Input() loading2: boolean;
  @Input() openOrders: models.IOrder[] = [];
  @Output() openOrderDetailEmitter = new EventEmitter();
  @Output() closeOrderModalEmitter = new EventEmitter();
  @Output() selectOrderEmitter = new EventEmitter();
  @Output() placeExistingEmitter = new EventEmitter();

  constructor(private cartService: CartService) { }

  openOrderDetail(): void {
    this.openOrderDetailEmitter.emit();
  }

  closeModal(): void {
    if (!this.loading2) {
      this.closeOrderModalEmitter.emit();
    }
  }

  onSelectOrder(order: models.IOrder): void {
    this.selectOrderEmitter.emit(order);
  }

  onPlaceExisting(docEntry: Number): void {
    if (!this.loading2) {
      this.placeExistingEmitter.emit(docEntry);
    }

  }
}
