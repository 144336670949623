import { Component, OnInit } from '@angular/core';
import { NgFor } from '@angular/common';

@Component({
    selector: 'app-po-empty',
    templateUrl: './po-empty.component.html',
    styleUrls: ['./po-empty.component.scss'],
    standalone: true,
    imports: [NgFor]
})
export class PoEmptyComponent implements OnInit {

  items = [];


  constructor() { }

  ngOnInit() {
  	this.items.length = 8;
  }

}
