<div class="notification-center-wrapper" [class.mobile]="topService.isMobile">
	<div class="notification-center-wrapper__header">
		<app-notification-center-header (resetNotifications)="resetNotificationBulkActionState()"
										[indeterminate]="notificationBulkActionState.total > notificationBulkActionState.selected && notificationBulkActionState.selected"
										[allSelected]="notificationBulkActionState.total == notificationBulkActionState.selected && notificationBulkActionState.selected"
										(selectAll)="selectUnselectAll($event)"></app-notification-center-header>
	</div>

	<div class="notification-center-scrollable-wrap"
		[class.hideComponent]="topService.loading || (!topService.loading && notifications && notifications.length>0) || !notifications">
		<app-notification-center-helper></app-notification-center-helper>
	</div>
	<!-- <app-notification-center-slider *ngIf="notifications && notifications.length>0">
	</app-notification-center-slider> -->

	<div *ngIf="topService.loading || !notifications" class="notification-center-scrollable-wrap">
		<app-items-preloader *ngIf="!topService.isMobile" [isFromNotificationCenter]="true"></app-items-preloader>
		<app-items-preloader-mobile *ngIf="topService.isMobile" [isFromNotificationCenter]="true"></app-items-preloader-mobile>
	</div>

	<div class="row align-items-center" *ngIf="showNewNotification">
		<div class="col-12 show-items">
			<a role="button" class="show-new-item" [class.showing-btn]="showNewNotification"
				[class.hiding-btn]="hideMoreBtn && !showNewNotification"
				(click)="getNotifications(true, activeTab); hideMoreBtn=true;">
				<span>
					Show {{ newNotificationQty }} more
				</span>
			</a>
		</div>
	</div>


	<div class="notification-center-scrollable-wrap"
		[class.__bottom-controls]="notificationRequest.pagination.totalCount > notificationRequest.pagination.pageSize" *ngIf="notifications && notifications.length>0"
		[class.__selected-items-info]="notificationBulkActionState.selected"
	>
		<app-notification-center-items-list [items]="notifications" [currentNotFilter]="activeTab" (updatePagination)="updatePagination()" (selected)="onSelect($event)">
		</app-notification-center-items-list>
	</div>


	<div class="notification-center-bottom-controls" *ngIf="notifications && notifications.length && notificationRequest.pagination.totalCount > notificationRequest.pagination.pageSize">
		<ng-container *ngIf="topService.isMobile">
			<pagination *ngIf="notificationRequest.pagination.totalCount > notificationRequest.pagination.pageSize"
          [totalItems]="notificationRequest.pagination.totalCount"
          [ngModel]="notificationRequest.pagination.pageNumber"
          [itemsPerPage]="notificationRequest.pagination.pageSize" (pageChanged)="pageChanged($event)"
          [boundaryLinks]="true"
			    [rotate]="true"
			    [maxSize]="5"
			    [customFirstTemplate]="firstTemplate"
			    [customLastTemplate]="lastTemplate"
			    [customPreviousTemplate]="prevTemplate"
			    [customNextTemplate]="nextTemplate">
      </pagination>

		    <ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
		      <img src="assets/images/icons/pagination/24-forward-black.svg">
		    </ng-template>

		    <ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
		      <img src="assets/images/icons/pagination/24-back-black.svg">
		    </ng-template>

		    <ng-template #lastTemplate let-disabled="disabled" let-currentPage="currentPage">
		      <img src="assets/images/icons/pagination/24-doubleforward-black.svg">
		    </ng-template>

		    <ng-template #firstTemplate let-disabled="disabled" let-currentPage="currentPage">
		      <img src="assets/images/icons/pagination/24-doubleback-black.svg">
		    </ng-template>
		</ng-container>


		<ng-container *ngIf="!topService.isMobile">
			<pagination *ngIf="this.notificationRequest.pagination.totalCount > this.notificationRequest.pagination.pageSize"
				[totalItems]="notificationRequest.pagination.totalCount"
				[ngModel]="notificationRequest.pagination.pageNumber"
				[itemsPerPage]="notificationRequest.pagination.pageSize" (pageChanged)="pageChanged($event)"
				[boundaryLinks]="true"
			    [rotate]="false"
			    [maxSize]="5">
		    </pagination>
		</ng-container>

  	</div>

	<div class="notification-center-selected-items-info" *ngIf="notificationBulkActionState.selected">
		<div class="notification-center-selected-items__left">
			<div class="notification-center-selected-items-summary">
				<span>{{notificationBulkActionState.selected}}</span>selected
			</div>
			<button type="button" class="notification-center-selected-items-btn" (click)="selectUnselectAll(false)">Deselect</button>
		</div>
		<div class="notification-center-selected-items__actions">
			<button type="button" class="notification-center-selected-items-btn _has-icon _mobile-text-visible"
				(click)="manageNotifications(eNotificationAction.archive, activeTab)"
				*ngIf="activeTab !== NotificationFiltersEnum.archive">
				<img src="assets/images/notification-center/select-items/archive.svg" width="24" height="24" alt="archive" />
				Archive
			</button>
			<button type="button" class="notification-center-selected-items-btn _has-icon"
				(click)="manageNotifications(eNotificationAction.restore, activeTab)"
				*ngIf="activeTab === NotificationFiltersEnum.archive">
				<img src="assets/images/notification-center/select-items/restore.svg" width="24" height="24" alt="restore" />
				<span>Restore</span>
			</button>
			<button type="button" class="notification-center-selected-items-btn _has-icon"
				(click)="manageNotifications(eNotificationAction.remove, activeTab)"
				*ngIf="activeTab === NotificationFiltersEnum.archive">
				<img src="assets/images/notification-center/select-items/delete.svg" width="24" height="24" alt="delete" />
				<span>Delete</span>
			</button>
		</div>
	</div>

	<!-- for Mobile filtering -->
	<app-notification-center-header-mobile *ngIf="topService.isMobile" [show]="notificationSvcBuyer.showHeaderMobile">
	</app-notification-center-header-mobile>
	<app-bottom-nav-mobile *ngIf="topService.isMobile"></app-bottom-nav-mobile>
</div>
