import { GenericCountdownSourceEnum } from './../../buyer/enums/GenericCountdownSourceEnum';
import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment-timezone';
import { NgIf } from '@angular/common';
import 'moment-timezone/builds/moment-timezone-with-data';

@Component({
    selector: 'app-generic-countdown',
    templateUrl: './generic-countdown.component.html',
    styleUrls: ['./generic-countdown.component.scss'],
    standalone: true,
    imports: [NgIf]
})
export class GenericCountdownComponent implements OnInit, OnDestroy {
  @Input() utcDate: string;
  @Input() source: GenericCountdownSourceEnum = GenericCountdownSourceEnum.counteroffer;
  @Output() onFinish: EventEmitter<any> = new EventEmitter<any>();
  GenericCountdownSourceEnum = GenericCountdownSourceEnum;
  countdownMessage: string = '';
  private intervalId: any;
  expired = false;

  ngOnInit(): void {
    this.updateCountdown();
    this.intervalId = setInterval(() => {
      this.updateCountdown();
    }, 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }

  private updateCountdown(): void {
    // this.utcDate = '2023-10-11T16:49:00.00';
    const utcDate = moment.utc(this.utcDate);
    const userTimeZone = moment.tz.guess();
    const timeDiffSeconds = utcDate.tz(userTimeZone).diff(moment(), 'seconds');

    if (timeDiffSeconds <= 0) {
      if (this.source === GenericCountdownSourceEnum.counteroffer) {
        this.countdownMessage = '00h 00m 00s';
      } else if (this.source === GenericCountdownSourceEnum.liveOfferAcceptCountdown) {
        this.countdownMessage = '';
      }

      if (this.intervalId) {
        clearInterval(this.intervalId);
      }

      if (!this.expired) {
        this.onFinish.emit();
        this.expired = true;
      }
    } else {
      const days = Math.floor(timeDiffSeconds / (60 * 60 * 24));
      const hours = Math.floor((timeDiffSeconds % (60 * 60 * 24)) / (60 * 60));
      const minutes = Math.floor((timeDiffSeconds % (60 * 60)) / 60);
      const seconds = timeDiffSeconds % 60;
      this.countdownMessage = this.format(days, hours, minutes, seconds);
    }
  }

  private formatTwoDigits(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  private format(pDays: number, pHours: number, pMinutes: number, pSeconds: number): string {
    var days = pDays > 0 ? pDays + 'd' : '';
    var hours = (pDays > 0) || (pDays == 0 && pHours > 0) ? this.formatTwoDigits(pHours) + 'h' : '';
    var minutes = (pDays > 0 || pHours > 0) || (pHours == 0 && pMinutes > 0) ? this.formatTwoDigits(pMinutes) + 'm' : '';
    var seconds = this.formatTwoDigits(pSeconds) + 's';
    var result = `${days} ${hours} ${minutes} ${seconds}`;

    switch (this.source) {
      case GenericCountdownSourceEnum.counteroffer:
        result = `${days} ${hours} ${minutes} ${seconds}`;
        break;
      case GenericCountdownSourceEnum.lastOfferEventMsg:
        hours = (pDays > 0) || (pDays == 0 && pHours > 0) ? this.formatTwoDigits(pDays > 0 ? pDays * pHours : pHours) + 'h' : '';
        result = `${hours} ${minutes}`;
        break;
      case GenericCountdownSourceEnum.liveOfferAcceptCountdown:
        if (pDays > 0) {
          result = `${pDays}d`;
        } else if (pHours > 0) {
          result = `${pHours}h`;
        } else if (pMinutes > 0) {
          result = `${pMinutes}m`;
        } else {
          result = `${pSeconds}s`;
        }
        break;
    }

    return result;
  }
}
