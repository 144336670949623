<div class="items-wrapper" #itemsWrapper>
  <div class="items-header-wrapper" [class.select-active]="vendorService?.search?.filters?.skuStatus === listingSkuStatusValuesEnum.removed">

    <div class="items-header-select-all" *ngIf="vendorService?.search?.filters?.skuStatus === listingSkuStatusValuesEnum.removed">
        <label class="items-checkbox">
          <input type="checkbox"  (change)="checkAllItemsForReactivation($event)" [checked]="isAllRemovedSelected">
          <i class="_icon"></i>
        </label>
    </div>
    <div class="items-header">
      <div class="items-header-top">
        <div class="items-header-heading" (click)="refreshItemsData()">
          <img src="assets/images/vendor-menu-icons/product-list.svg" width="28" height="28px" alt="Product Listings">
          <span>Product Listings</span>
          <span class="__bage"> {{ totalCountDisplay }}</span>
        </div>

        <div class="export" (clickOutside)="hideExportTooltip($event)" [excludeBeforeClick]="true">
          <button class="export-btn" type="button"
                  [disabled]="isAllVendors || isExporting"
                  (click)="triggerExportTooltip(exportForm)"
                  [class.active]="isShowExportTooltip && !isAllVendors">
            <i *ngIf="isExporting" class="loader"></i>
            <img *ngIf="!isExporting" src="assets/images/vendor-icons/export.svg">
            <span>Export Listings</span>
          </button>
          <div class="export-tooltip" [class.active]="isShowExportTooltip && !isAllVendors && !isExporting">
            <form #exportForm="ngForm" (ngSubmit)="export(exportForm)" novalidate>
              <div class="export-tooltip__checkbox">
                <input type="checkbox" name="0" (change)="triggerExportAll()" id="export-All" ngModel>
                <label for="export-All"></label>
                <label class="label-text" for="export-All">All</label>
              </div>
              <ng-container *ngFor="let status of statuses; let i = index">
                <div class="export-tooltip__checkbox" *ngIf="status.count>0 && status.name !=='All'"
                  [class.disabled]="isAllExport">
                  <input type="checkbox" name="{{ i }}" id="export-{{ status.name }}" ngModel [disabled]="isAllExport"
                    [checked]="isAllExport">
                  <label for="export-{{ status.name }}"></label>
                  <label class="label-text"
                    for="export-{{ status.name }}">{{ status.name }}</label>
                </div>
              </ng-container>
              <button class="export-tooltip__btn" [disabled]="isOptionNotSelected(exportForm)" type="submit">
                <span>Export</span>
              </button>
            </form>
          </div>
        </div>


      </div>
      <div class="items-header-dropdown-wrapper">

        <div class="items-header-search">
          <button (click)="search()">
            <svg height="24" viewBox="0 0 32 32" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m25.84375 22.8029159-4.4166667-4.4727935c.71875-1.2548816 1.09375-2.6503515 1.09375-4.0562354 0-4.56131215-3.7083333-8.273887-8.2604166-8.273887-4.55208337 0-8.2604167 3.71257485-8.2604167 8.273887 0 4.5613122 3.70833333 8.273887 8.2604167 8.273887 1.453125 0 2.890625-.4009372 4.171875-1.1663629l4.3958333 4.4571726c.0989583.0989326.2395833.1614163.3802083.1614163s.28125-.0572768.3802084-.1614163l2.2552083-2.2806561c.2083333-.213486.2083333-.5467326 0-.7550117zm-11.5833333-13.57979692c2.78125 0 5.0416666 2.26503512 5.0416666 5.05076802s-2.2604166 5.050768-5.0416666 5.050768-5.0416667-2.2650351-5.0416667-5.050768 2.2604167-5.05076802 5.0416667-5.05076802z" fill="#fff"/></svg>
          </button>
          <input type="text" id="search-items-input" placeholder="Search" autocomplete="off"
            [(ngModel)]="vendorService.searchString" (keyup)="onKeyPressOnSearch($event)"
            (click)="expandSearch()"/>
        </div>

        <div class="btn-group">
          <app-custom-select label="All Categories" [data]="dropDowns?.categories" fkey="navId"
          [selectedItem]="categorySelected" (onItemSelected)="selectCategory($event)"></app-custom-select>

          <app-custom-select label="All Sub Categories" [data]="subCategories" fkey="navId"
          [selectedItem]="subCategorySelected" (onItemSelected)="selectSubCategory($event)"></app-custom-select>

          <app-custom-select label="All Manufacturer" [data]="manufacturers"
          [selectedItem]="selectedManufacturer" (onItemSelected)="manufacturerSelected($event)"></app-custom-select>

          <app-custom-select label="All Promotions" [data]="promos"
          [selectedItem]="selectedPromotion" (onItemSelected)="promotionSelected($event)"></app-custom-select>

          <app-custom-select label="All Restrictions" [data]="promos && itemService.restrictionsFilters"
          [selectedItem]="selectedRestriction" (onItemSelected)="selectRestriction($event)" borderRadius="0 8px 8px 0"></app-custom-select>
        </div>
      </div>
      <div class="items-header-filter-wrapper">
        <ul class="items-header-filter-statuses">
            <li *ngFor="let status of statuses"
              [ngClass]="{'active': status.value === vendorService?.search?.filters?.skuStatus}"
              (click)="selectStatusAndRetrieveItems(status)">
              <span>{{ status.name }}</span>
              <span class="__badge">{{ status.count }}</span>
            </li>
            <!-- For small screens -->
            <li class="btn-group __more" (clickOutside)="hideMore()">
              <button class="btn dropdown-toggle"
              (click)="showMore()"
              [class.active]="isMoreShown"
              [class.selected]="vendorService?.search?.filters?.skuStatus>3">
              <span *ngIf="vendorService?.search?.filters?.skuStatus < 4">
                More
              </span>
              <ng-container *ngIf="vendorService?.search?.filters?.skuStatus>3">
                <span>{{statuses[vendorService?.search?.filters?.skuStatus].name}}</span>
                <span class="__badge">{{ statuses[vendorService.search.filters.skuStatus].count }}</span>
              </ng-container>
              </button>
              <div class="dropdown-menu" [class.show]="isMoreShown">
                <ng-container *ngFor="let status of statuses; let i = index">
                  <div *ngIf="i > 3" class="dropdown-item" (click)="selectStatusAndRetrieveItems(status)">
                    <a class="_text">
                      <span>{{ status.name }}</span>
                      <span class="__badge">{{ status.count }}</span>
                    </a>
                  </div>
                </ng-container>
              </div>
            </li>
        </ul>

        <div class="btn-group __exw">
          <app-custom-select label="All EXW" borderRadius="8px" minWidth="140px" fkey='value' fvalue='text'
          [data]="exwPoints" [selectedItem]="selectedExw" (onItemSelected)="selectExw($event)"></app-custom-select>
        </div>

        <button type="button" class="items-header-filter-sort" (click)="sortByAvailable()">
          <i>
            <img src="assets/images/icons/item-filter/sort-high-low.svg" *ngIf="vendorService?.search?.sortDirection === 'DESC'" />
            <img src="assets/images/icons/item-filter/sort-low-high.svg" *ngIf="vendorService?.search?.sortDirection === 'ASC'" />
          </i>
          <span>Available</span>
        </button>

        <div class="btn-group __view" role="group" aria-label="Change view">

          <button type="button" class="btn btn-secondary" (click)="changeView('excel')"
            [ngClass]="{ 'active': vendorService.vendorItemsSavedInfo.viewType === 'excel' && !vendorService.vendorItemsSavedInfo.useAnalytics }">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="14px" height="12px" viewBox="0 0 14 12" version="1.1">
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="3.1---Grid-view" transform="translate(-1559.000000, -238.000000)" fill="#FFFFFF" fill-rule="nonzero">
                        <g id="Group-18-Copy-4" transform="translate(124.000000, 96.000000)">
                            <g id="viewing" transform="translate(1422.000000, 128.000000)">
                                <g id="Icons/view-grid" transform="translate(8.000000, 8.000000)">
                                    <path d="M18.4,13.2 L5.6,13.2 C5.26862915,13.2 5,13.4686292 5,13.8 C5,14.1313708 5.26862915,14.4 5.6,14.4 L18.4,14.4 C18.7313708,14.4 19,14.1313708 19,13.8 C19,13.4686292 18.7313708,13.2 18.4,13.2 Z M18.4,16.8 L5.6,16.8 C5.26862915,16.8 5,17.0686292 5,17.4 C5,17.7313708 5.26862915,18 5.6,18 L18.4,18 C18.7313708,18 19,17.7313708 19,17.4 C19,17.0686292 18.7313708,16.8 18.4,16.8 Z M5.6,10.8 L18.4,10.8 C18.7313708,10.8 19,10.5313708 19,10.2 C19,9.86862915 18.7313708,9.6 18.4,9.6 L5.6,9.6 C5.26862915,9.6 5,9.86862915 5,10.2 C5,10.5313708 5.26862915,10.8 5.6,10.8 Z M5.6,7.2 L18.4,7.2 C18.7313708,7.2 19,6.93137085 19,6.6 C19,6.26862915 18.7313708,6 18.4,6 L5.6,6 C5.26862915,6 5,6.26862915 5,6.6 C5,6.93137085 5.26862915,7.2 5.6,7.2 Z" id="Shape"/>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
          </button>
          <button type="button" class="btn btn-secondary" (click)="changeView('detailed')"
            [ngClass]="{ 'active': vendorService.vendorItemsSavedInfo.viewType === 'detailed' && !vendorService.vendorItemsSavedInfo.useAnalytics }">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="14px" height="12px" viewBox="0 0 14 12" version="1.1">
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="3.1---Grid-view" transform="translate(-1599.000000, -238.000000)" fill="#FFFFFF" fill-rule="nonzero">
                        <g id="Group-18-Copy-4" transform="translate(124.000000, 96.000000)">
                            <g id="viewing" transform="translate(1422.000000, 128.000000)">
                                <g id="Icons/view-listing" transform="translate(48.000000, 8.000000)">
                                    <path d="M6.16666667,18 L6.16666667,15.6 L17.8,15.6 C18.4627417,15.6 19,16.1372583 19,16.8 C19,17.4627417 18.4627417,18 17.8,18 L6.16666667,18 L6.16666667,18 Z M6.16666667,18 C5.52233446,18 5,17.4776655 5,16.8333333 L5,16.7666667 C5,16.1223345 5.52233446,15.6 6.16666667,15.6 C6.81099887,15.6 7.33333333,16.1223345 7.33333333,16.7666667 L7.33333333,16.8333333 C7.33333333,17.4776655 6.81099887,18 6.16666667,18 Z M7.33333333,11.9666667 L7.33333333,12.0333333 C7.33333333,12.6776655 6.81099887,13.2 6.16666667,13.2 C5.52233446,13.2 5,12.6776655 5,12.0333333 L5,11.9666667 C5,11.3223345 5.52233446,10.8 6.16666667,10.8 C6.81099887,10.8 7.33333333,11.3223345 7.33333333,11.9666667 Z M6.16666667,10.8 L17.8,10.8 C18.4627417,10.8 19,11.3372583 19,12 C19,12.6627417 18.4627417,13.2 17.8,13.2 L6.16666667,13.2 L6.16666667,13.2 L6.16666667,10.8 Z M7.33333333,7.16666667 L7.33333333,7.23333333 C7.33333333,7.87766554 6.81099887,8.4 6.16666667,8.4 C5.52233446,8.4 5,7.87766554 5,7.23333333 L5,7.16666667 C5,6.52233446 5.52233446,6 6.16666667,6 C6.81099887,6 7.33333333,6.52233446 7.33333333,7.16666667 Z M6.16666667,6 L17.8,6 C18.4627417,6 19,6.5372583 19,7.2 C19,7.8627417 18.4627417,8.4 17.8,8.4 L6.16666667,8.4 L6.16666667,8.4 L6.16666667,6 Z" id="Combined-Shape"/>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
          </button>
        </div>

      </div>
    </div>
  </div>
  <div class="items-list" [class.__bottom-controls]="!isItemsEmpty()" [class.pending-reactivation]="(vendorService?.search?.filters?.skuStatus === listingSkuStatusValuesEnum.removed && pendingForReactivation > 0) && !isItemsEmpty()" *ngIf="!isItemsEmpty()">

    <!-- detailed -->
    <div *ngIf="vendorService.vendorItemsSavedInfo.viewType === 'detailed' && !loader && items && items.length">
      <ng-container *ngFor="let item of items; let i = index">
          <app-item-detailed *ngIf="!vendorService.isShowAllUpdatedItems ||
          (vendorService.isShowAllUpdatedItems && vendorService.updatedMappedItems.has(item.id))" [item]="item"
           [fg]="itemsForm.controls[i]" [dropDowns]="dropDowns" [condition]="condition" [warranty]="warranty"
            [packaging]="packaging" [restrictions]="restrictions" [exwOptions]="allExwOptions" [viewType]="vendorService.vendorItemsSavedInfo.viewType"
            [isPopup]="false" [isAll]="isAllVendors" (itemChanged)="resetItem($event, i)"
            (openCommentModal)="openCommentModal($event)" (singleItemUpdated)="checkPendingOffersSingleItem($event)"
            (updateReactivationCount)="countPendingForReactivation()">
          </app-item-detailed>
        </ng-container>
    </div>

    <app-vendor-items-preloader [source]="'detailed'" [isCheckbox]="vendorService?.search?.filters?.skuStatus === listingSkuStatusValuesEnum.removed" *ngIf="loader && vendorService.vendorItemsSavedInfo.viewType === 'detailed'"></app-vendor-items-preloader>

    <form *ngIf="itemsForm" [formGroup]="itemsForm">
      <!-- simple -->
      <div *ngIf="vendorService.vendorItemsSavedInfo.viewType === 'excel' && !loader && items.length">
        <ng-container *ngFor="let item of items; let i = index">
          <app-item-simple *ngIf="!vendorService.isShowAllUpdatedItems ||
          (vendorService.isShowAllUpdatedItems && vendorService.updatedMappedItems.has(item.id))" [item]="item"
            [fg]="itemsForm.controls[i]" [dropDowns]="dropDowns"
            (editDetailedMode)="openEditVendorItemInDetailedMode($event, i)" [isPopup]="!!selectedVendorItem"
            [isAll]="isAllVendors" (itemChanged)="resetItem($event, i)" (singleItemUpdated)="checkPendingOffersSingleItem($event)"
            (updateReactivationCount)="countPendingForReactivation()">
          </app-item-simple>
        </ng-container>

      </div>
    </form>
    <app-vendor-items-preloader [source]="'simple'" [isCheckbox]="vendorService?.search?.filters?.skuStatus === listingSkuStatusValuesEnum.removed" *ngIf="loader && vendorService.vendorItemsSavedInfo.viewType === 'excel'"></app-vendor-items-preloader>
  </div>

  <app-items-empty *ngIf="isItemsEmpty() && !loader"> </app-items-empty>

  <div class="items-bottom-controls">
    <div class="selected-items-wrap" *ngIf="vendorService?.search?.filters?.skuStatus === listingSkuStatusValuesEnum.removed && pendingForReactivation > 0">
        <div class="selected-items">
          <div class="selected-items__left">
             <div class="selected-items-summary">
              <div>
                <span>{{pendingForReactivation}}</span>
              </div>
               selected
             </div>
             <button type="button" class="selected-items-btn" (click)="resetReactivationCheckboxes()">Deselect</button>
           </div>

           <div class="selected-items__actions">
             <button type="button" class="selected-items-btn" (click)="applyCostReductionToAllSelected()" >
               Reduce Cost by 2%
             </button>
           </div>
        </div>
      </div>
      <div class="items-bottom-controls-pagination">

        <pagination
          *ngIf="!isPaginationLoading"
          [totalItems]="pagination.totalCount"
          [itemsPerPage]="vendorService.totalItemsPerPage" (pageChanged)="pageChanged($event)"
          [maxSize]="paginationMaxSize" [boundaryLinks]="true">
        </pagination>

        <div class="bottom-btn-wrap">
          <button type="button" class="btn btn-clear clear-button" (click)="clearAllUpdatedItems()"
            *ngIf="vendorService.updatedMappedItems?.size">
            Clear
          </button>
          <button type="button" class="btn btn-update" (click)="checkPendingOffersAllItems()"
            [disabled]="itemsForm?.invalid || !vendorService.updatedMappedItems?.size || loader">
            <i>
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="m6.00421756 12c0 3.3145476 2.685161 6 5.99819244 6 3.3130315 0 5.9981925-2.6854524 5.9981925-6 0-2.93735818-2.1307842-5.42920384-4.9978674-5.90946034l-.3577692-.05992884v-3.01214526l.4814055.05980358c4.4775011.55622674 7.8736286 4.37074396 7.8736286 8.92173086 0 4.9716573-4.0279056 9-8.99759 9-4.96968431 0-8.99758993-4.0283427-8.99758993-9 0-2.08356377.71259734-4.052563 1.99325746-5.63828975l-1.39602921-1.39460219-.10570299-.10190197c-.06353372-.06205206-.11475278-.11449547-.16190711-.16674877-.21437519-.23755639-.33443822-.46158458-.33443822-.73417161 0-.53166627.43160298-.96428571.96413509-.96428571h5.35563658c.14451306 0 .28211456.01920102.44139649.09157803.32099494.14585869.52273864.45091876.52273864.87270768v5.35714286c0 .53154704-.43202033.96428573-.96413513.96428573-.32186896 0-.51778503-.1144239-.7985355-.39298086l-.23290517-.23937411-1.14870224-1.14942643c-.73244391 1.01204442-1.1354112 2.2226953-1.1354112 3.4960671z" fill="#FFFFFF"/></svg>
            </i>
            <span> Update All Listings ({{vendorService.updatedMappedItems?.size}})</span>
          </button>
          <button type="button" class="btn btn-filter" (click)="showAllUpdatedItems()"
            *ngIf="vendorService.updatedMappedItems?.size">
            <span> Unsaved Listings ({{vendorService.updatedMappedItems?.size}})</span>
          </button>
        </div>

      </div>


  </div>

  <div [ngClass]="{'hide': !showDetailedPopUp}" class="add-to-existing-modal detailed-modal"
    *ngIf="selectedVendorItem && showDetailedPopUp">
    <div class="add-to-wrap animated" [class.zoomIn]="showDetailedPopUp" (click)="clickedInside($event)"
      (clickOutside)="onClickedOutside()" exclude='.comment-wrapper, .item-logo-number, .test-class, .close, .cancellation-modal'
      [clickOutsideEvents]="'click'">
      <app-item-detailed [item]="selectedVendorItem" [fg]="selectedVendorFg"
        [dropDowns]="dropDowns" [exwOptions]="allExwOptions"
        [condition]="condition" [warranty]="warranty" [packaging]="packaging" [restrictions]="restrictions"
        [viewType]="vendorService.vendorItemsSavedInfo.viewType" [isPopup]="true"
        [isAll]="isAllVendors" (openCommentModal)="openCommentModal($event)" [fromItemSimple]="true"
        (onClose)="closeEditItemModal()"  (itemChanged)="resetItem($event, selectedVendorIndex)"
        (singleItemUpdated)="checkPendingOffersSingleItem($event)">
      </app-item-detailed>

    </div>
    <div class="clear"></div>
  </div>

  <div *ngIf="isModalShown && !loader">
    <app-item-success [sucessMsg]="getItemsText()" (close)="hideSuccessAlert()"></app-item-success>
  </div>

  <div *ngIf="isUpdatingItems">
    <app-item-updating-modal></app-item-updating-modal>
  </div>

</div>

<app-error-modal [title]="'Server Error'" [isError]="errorModal.isError400" [bodyText]="errorModal.errorMessage400" (onConfirmError)="hideErrorAlert()"></app-error-modal>


<div [ngClass]="{'hide': !showCommentModal}" *ngIf="showCommentModal">
  <app-item-comment [fg]="selectedVendorFg" (closeModal)="closeItemCommentsModal()"></app-item-comment>
</div>

<app-offer-cancellation-warning *ngIf="showCancellationWarning"
  (refreshCancellationWarningModal)="refreshCancellationWarningModal()"
  (closeCancellationWarningModal)="closeCancellationWarningModal()"
  (updateAfterCancelationWarning)="updateAfterCancelationWarning($event)"
  [pendingOffers]="pendingOffers"
  [showRefreshMsg]="showRefreshMsg"
  [totalPendingOffers]="totalPendingOffers"
  [disableReviewOffersButton]="disableReviewOffersButton">
</app-offer-cancellation-warning>
