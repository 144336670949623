import { Component, OnInit, AfterContentChecked, OnDestroy, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Location, NgIf, NgFor, NgClass } from '@angular/common';
import { Router, RouterLinkActive, RouterLink } from '@angular/router';

import { ItemsService } from '../../../shared/services/items.service';
import { TopService } from '../../../shared/services/top.service';

import { PubSubService } from '../../../core/pubsub.service';
import { TopCategoriesFileSaverService } from './top-categories-file-saver.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { SharedSource } from '../../../core/shared-source';
import { Subscription } from 'rxjs';
import { ITopNav } from '../../interfaces/ITopNav';

import * as models from '../../interfaces/model';
import { INav } from '../../interfaces/model';
import { SharedService } from '../../../shared/shared.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { OutletsService } from '../../outlets/outlets.service';
import { ItemFilterService } from '../../../shared/item-filter/item-filter.service';
import { TopFiltersEnum } from '../../../buyer/enums/topFiltersEnum';
import * as _ from 'lodash';
import { SessionService } from '../../../services/session.service';
import { UserService } from '../../../user/user.service';
import { environment } from '../../../../environments/environment';
import { DropdownCategoriesComponent } from '../../categories/dropdown-categories.component';
import { BuyerOffersService } from '../../buyers-offers/buyer-offers.service';

@Component({
  selector: 'app-top-categories',
  templateUrl: './top-categories.component.html',
  styleUrls: ['./top-categories.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    RouterLinkActive,
    RouterLink,
    NgClass,
    DropdownCategoriesComponent,
  ],
})
export class TopCategoriesComponent
  implements OnInit, AfterContentChecked, OnDestroy {
  topFiltersEnum = TopFiltersEnum;
  attributes: models.Attribute[] = [];
  categories: models.Category[] = [];
  topFilters: ITopNav[];
  navigationTree: ITopNav[];
  isCatalog = false;
  date = new Date();
  fileName = 'HUBX-Price-List-' + this.date.toLocaleString() + '.xlsx';
  isEditOrder = false;
  pubServiceSubscription: Subscription;
  justSoldCount: boolean;
  navChilds;
  isCategoriesOpened = false;
  visibleCategoryId = null;
  environment = environment;

  liveOffersCounterSignal = this.buyerOffersService.liveOffersCounterSignal;

  constructor(
    private location: Location,
    private pubSubService: PubSubService,
    private topCategoriesFileSaverService: TopCategoriesFileSaverService,
    private buyerOffersService: BuyerOffersService,
    public itemsService: ItemsService,
    public topService: TopService,
    public notificationService: NotificationService,
    public router: Router,
    public sharedService: SharedService,
    public outletsService: OutletsService,
    public sessionService: SessionService,
    public itemFilterService: ItemFilterService,
    public userService: UserService,
  ) {
    this.buyerOffersService.getLiveOffersCounter();
  }

  ngOnInit() {
    this.pubServiceSubscription = this.pubSubService.sharedSubject.subscribe(
      (myEvent) => {
        if (myEvent.name === SharedSource.editOrderMode) {
          this.isEditOrder = myEvent.data;
        }

        if (myEvent.name === SharedSource.topCategory) {
          this.topFilters = myEvent.data;
        }

        if (myEvent.name === SharedSource.IsJustSoldSelected) {
          if (!myEvent.data) {
            this.notificationService.highlight = false;
          }
        }

        if (myEvent.name === SharedSource.JustSoldValues) {
          this.justSoldCount = true;
          this.notificationService.highlight = true;
          setTimeout(() => {
            if (this.itemsService.selectedTopAttribute !== 'Just Sold') {
              this.justSoldCount = false;
              this.notificationService.highlight = false;
            } else {
              this.justSoldCount = false;
            }
          }, 1000);
        }

        if (myEvent.name === SharedSource.manufacturersDataChanged) {
          if (this.itemFilterService.isOutletsSinglePage) {
            const cleanUrl = this.router.url.replace(/\/guest/, '');
            const currentOutlet = cleanUrl.split('/');
            const outletName = currentOutlet[2].replace(/\_/g, ' ');
            // you are in a top category e.g todays_deals
            if (currentOutlet.length === 4) {
              this.outletsService.topcatNavName = currentOutlet[3];
            }
            this.outletsService.getReactiveOutlet(outletName);
          }
        }
      },
    );

    this.loadTopFilterData();
  }

  ngAfterContentChecked() {
    this.isCatalog = this.topService.isCatalog;
  }

  ngOnDestroy() {
    if (this.pubServiceSubscription) {
      this.pubServiceSubscription.unsubscribe();
    }
  }

  allManufacturerSpecificItems() {
    this.outletsService.topcatNavName = undefined;
    this.itemsService.showFilterComponent = false;
    const transformedName = String(
      this.outletsService.selectedOutlet.name.replace(/\s/g, '_'),
    ).toLowerCase();

    this.router.navigate([
      `${this.userService.getUrlByRole(true)}/outlets/` + transformedName,
    ]);
    this.outletsService.resetEverythingButManufacturers();
    this.itemsService.skipManufacturers = false;
    this.itemFilterService.selectedCondition =
      this.itemsService.conditionAttributeValues.find(
        (c) => c.value === 'All Conditions',
      );
    this.itemsService.paginationInfo = null;
    this.itemsService.setSelectedManufacturer(
      this.outletsService.selectedOutlet.id,
      true,
      true,
    );
  }

  getPriceList(): void {
    this.topService.loading = true;
    this.topCategoriesFileSaverService.downloadPriceList().subscribe((blob) => {
      this.topService.loading = false;
      importedSaveAs(blob, this.fileName);
    });
  }

  // Check if Tp exists before calling
  loadTopFilterData(): void {
    this.topFilters = this.topService.topFilters;
  }

  goBack(): void {
    this.location.back();
  }

  selectJustSold() {
    this.itemsService.selectJustSold();
  }

  closeOutlet() {
    this.router.navigate([`${this.userService.getUrlByRole(true)}/outlets/`]);
  }

  goToEditMode(): void {
    this.isEditOrder
      ? this.router.navigate(['cart'], { queryParams: { updatingOrder: 'Y' } })
      : this.router.navigate(['cart']);
  }

  checkForCartPage(): boolean {
    return this.router.url === '/orders';
  }

  isEditMode(): boolean {
    return !this.isCatalog && this.isEditOrder;
  }

  isTopFilterEqualToNavName(topFilter: INav, navName: string): boolean {
    return topFilter.navName === navName;
  }

  toggleCategories(isVisible: boolean): void {
    this.isCategoriesOpened = isVisible;
  }

}
