import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { UserService } from '../../user/user.service';
import { ItemsService } from '../../shared/services/items.service';
import { SharedService } from '../../shared/shared.service';
import { SessionService } from '../../services/session.service';
import { GuestService } from '../../shared/services/guest.service';
import { DEFAULT_URL } from '../../shared/static';
import { AUTH_CONFIG } from '../../auth/hubx-auth-variables';
import { AuthService } from '../../auth/auth.service';
import { TopService } from '../../shared/services/top.service';
import { Subscription } from 'rxjs';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { FormsModule } from '@angular/forms';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass, FormsModule, ForgotPasswordComponent]
})
export class LoginComponent implements OnInit, OnDestroy {
  emailLogin: string;
  passwordLogin: string;
  firstTry: boolean;
  showReset = false;
  fromForgot = false;

  constructor(
    private titleService: Title,
    private router: Router,
    public userService: UserService,
    private itemsService: ItemsService,
    public sharedService: SharedService,
    public sessionService: SessionService,
    private guestService: GuestService,
    public topService: TopService,
    private authService: AuthService) { }

  ngOnInit() {
    this.userService.auth.completedLoad = false;
    this.userService.loginFail = false;
    this.userService.loginSuccess = false;

    if (this.userService.auth.isAuthenticated()) {
      if (this.sessionService.userRole) {
        if (this.sessionService.userRole === 'SELLER' || this.sessionService.userRole === 'ADMIN' || this.sessionService.userRole === 'VENDORMGR') {
          this.userService.auth.completedLoad = true;
          this.router.navigate(['/vendor/dashboard']);
        }
        if (this.sharedService.isBuyerPortal()) {
          this.userService.resetSessionWrongPortal();
        }
      } else {
        this.router.navigate(['']);
      }
    }

    this.titleService.setTitle('HUBX Portal');
    this.itemsService.itemsRequest = this.itemsService.getEmptyRequestPayload();

    //this.topService.removeMobile();
    if (this.topService.isMobile) {
      document.documentElement.classList.add('is-modal');
    }
  }

  login(email: string, password: string): void {
    if(this.userService.loading == true){ return; }
    this.userService.loading = true;
    this.loginVendor(email, password);
  }

  reset() {
    this.showReset = true;
    this.userService.loginFail = false;
  }

  hideLogin(event) {
    this.showReset = false;
    if (event === true) {
      this.fromForgot = true;
    }
  }

  loginVendor(email: string, password: string): void {
    sessionStorage.clear();
    localStorage.clear();

    this.userService.auth.hubxAuth.login({
      realm: AUTH_CONFIG.realm,
      username: email,
      password: password
    }, (err, authResult) => {
      if (err) {
        this.userService.loading = false;
        this.userService.loginFail = true;
      } else {

        if (authResult && authResult.accessToken) {
          this.userService.auth.setSession(authResult);
          window.location.hash = '';
          this.userService.user_role = this.sessionService.userRole;
          this.sessionService.readyToShowSessionError = true;
          switch (this.userService.user_role) {

            case 'BUYER':
            case 'SALES':
            case 'SUPER': {
              this.userService.loginFail = true;
              this.userService.loading = false;
              this.authService.clearSessionIfNotVendor();
              break;
            }
            case 'SELLER': {
              this.userService.loginSuccess = true;
              this.userService.loginFail = false;
              this.userService.loading = false;
              setTimeout(() => {
                this.sessionService.isLoggedIn = 'true';
                this.userService.fetchUserInfo();
                this.redirectAfterLogin();
              }, 1000);
              break;
            }
            case 'ADMIN':
            case 'VENDORMGR': {
              this.userService.loginFail = false;
              this.sessionService.companyName = email;
              this.userService.contactEmail = email;
              this.userService.topService.isDone = false;
              this.userService.loading = false;
              this.userService.loginSuccess = true;

              setTimeout(() => {
                this.sessionService.isLoggedIn = 'true';
                this.userService.auth.completedLoad = true;
                this.sharedService.isLoggedInInternal = true;
                this.userService.foundBP = true;
                this.redirectAfterLogin();
              }, 1000);
              break;
            }
          }
        }
      }
    });
  }

  clearEmail(): void {
    this.emailLogin = '';
  }

  clearPass(): void {
    this.passwordLogin = '';
  }

  redirectAfterLogin() {
    if (this.authService.redirectUrl) {
      this.router.navigate([this.authService.redirectUrl]);
      this.authService.redirectUrl = '';
    } else {
      if (this.topService.isMobile) {
        this.router.navigate(['/vendor/user/offers']);
      } else {
        this.router.navigate(['/vendor/dashboard']);
      }
    }
  }

  ngOnDestroy() {
    this.userService.loginFail = false;
    this.userService.loginSuccess = false;
    if (this.topService.isMobile) {
      document.documentElement.classList.remove('is-modal');
    }
  }
}
