import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { PubSubService } from '../../core/pubsub.service';
import { SharedSource } from '../../core/shared-source';
import { TopService } from '../services/top.service';
import { CartService } from '../../buyer/cart/cart.service';
import { AuthService } from '../../auth/auth.service';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-version-notifier',
    templateUrl: './version-notifier.component.html',
    styleUrls: ['./version-notifier.component.scss'],
    standalone: true,
    imports: [NgIf]
})
export class VersionNotifierComponent implements OnInit, OnDestroy {
  pubServiceSubscription: Subscription;
  hardReloadInterval = undefined;
  hardReloadMinMs = 60000;
  hardReloadMaxMs = 180000;
  connectionModifier = 10;

  constructor(
    public authService: AuthService,
    public topService: TopService,
    public cartService: CartService,
    private pubSubService: PubSubService
  ) { }

  ngOnInit() {
    if (this.hardReloadInterval) {
      clearInterval(this.hardReloadInterval);
    }

    this.pubServiceSubscription = this.pubSubService.sharedSubject.subscribe(
      myEvent => {
        if (myEvent.name === SharedSource.newVersionAvailable) {
          console.log('NEW VERSION AVAILABLE');
          let connectionCount = 0;
          if(myEvent.data){
            connectionCount = myEvent.data.totalConnections;
          }
          this.topService.newVersionAvailable = true;
          this.hardReloadInterval = setInterval(this.hardReload, this.getHardReloadMS(connectionCount));
        }
      });
  }

  ngOnDestroy() {
    if (this.pubServiceSubscription) {
      this.pubServiceSubscription.unsubscribe();
    }
  }

  hardReload() {
    clearInterval(this.hardReloadInterval);
    location.reload();
  }

  getHardReloadMS(connectionCount: number) {
    //For every 10 connections (connectionModifier), add one minute to max
    const randomModifier = Math.random();
    const increasedMax = Math.floor((connectionCount/this.connectionModifier)) * 60000;
    const maxMs = this.hardReloadMaxMs + increasedMax;
    return  Math.floor(randomModifier * (maxMs - this.hardReloadMinMs)) + this.hardReloadMinMs;
  }
}
