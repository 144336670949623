<div class="modal">
	<div class="modal-wrap animated" [class.zoomOut]="false" [class.zoomIn]="true">
		<div class="modal-content" (clickOutside)="close()" [excludeBeforeClick]="true"
         [exclude]="'.bulk-upload, .form-block, .example, .form-group, .confirm-modal-buttons, .confirm-modal-wrap'"
         [clickOutsideEvents]="'click'">
			<button (click)="close()" type="button" class="close" aria-label="Close" [disabled]="topService.loading"></button>
			<div class="modal-info-wrapper">
				<div class="header-block">
					<svg height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
						<path
							d="m2 6h4.72046135l3.85969135 12.4725191h11.0974675l4.3878011-9.90771979h2.9345787l-5.4232967 12.63520069h-14.89307782l-3.88133526-12.53527344h-1.96631234zm11.4686017 16.8c.8771883 0 1.5882353.716314 1.5882353 1.6s-.711047 1.6-1.5882353 1.6-1.5882352-.716314-1.5882352-1.6.711106-1.6 1.5882352-1.6zm5.5590138 0c.8771557 0 1.5881762.716314 1.5881762 1.6s-.7110205 1.6-1.5881762 1.6c-.8772147 0-1.5882943-.716314-1.5882943-1.6s.7110796-1.6 1.5882943-1.6zm-4.0276155-14.8v-4h3v4h4v3h-4v4h-3v-4h-4v-3z"
							fill="#fff" /></svg>
					<h2>Instructions</h2>
				</div>
				<div class="text-block">
					<p>
						The bulk SKU upload allows you to search multiple SKU’s and add them to your cart instantly.
						There are two
						ways:
					</p>
					<ol>
						<li>
							<p>Just cut and paste the multiple part numbers like this:</p>
							<p>G703GI-WS91K</p>
							<p>GX501GI-XS74</p>
							<p>INS283538SA</p>
						</li>
						<li>
							<p>Or to add inventory paste the part by space, example you want 10 units of part
								INS283538SA.
							</p>
							<p>INS283538SA 10</p>
						</li>
					</ol>
				</div>
			</div>
			<div class="modal-form-wrapper">
				<div class="form">
					<div class="header-block">
						<h2>
							Bulk SKU Upload
						</h2>
					</div>
					<div class="form-block">
						<div class="form-group">
							<textarea (click)="hideTextMask($event)" #focus class="form-control" rows="3"
								[(ngModel)]="bulkUploadFieldData">
							</textarea>
							<div class="example" *ngIf="!hideTMask" (click)="hideTextMask($event)" (contextmenu)="hideTextMask($event)">
								<span>G703GI-WS91K</span>
								<span>GX501GI-XS74</span>
								<span>INS283538SA</span>
								<span>OR</span>
								<span>INS283538SA 10</span>
							</div>
						</div>
						<button (click)="showConfirmMsg = true" class="btn btn-primary" type="submit"
							[disabled]="bulkUploadFieldData.length === 0 || topService.loading">
							<svg *ngIf="!topService.loading" height="24" viewBox="0 0 24 24" width="24"
								xmlns="http://www.w3.org/2000/svg">
								<path
									d="m0 2h4.19596564l3.4308368 11.2252672h1.75593688l-2.68302305-8.91694782h17.30028373l-4.8207082 11.37168062h-13.23829137l-3.45007579-11.2817461h-1.74783319zm10.1943127 15.12c.7797229 0 1.4117647.6446826 1.4117647 1.44s-.6320418 1.44-1.4117647 1.44c-.77972297 0-1.41176474-.6446826-1.41176474-1.44s.63209427-1.44 1.41176474-1.44zm4.9413455 0c.779694 0 1.4117122.6446826 1.4117122 1.44s-.6320182 1.44-1.4117122 1.44c-.7797464 0-1.4118172-.6446826-1.4118172-1.44s.6320708-1.44 1.4118172-1.44z"
									fill="#fff" /></svg>

							<div *ngIf="topService.loading" class="cart-loader bulk-loader"></div>
							<div *ngIf="topService.loading" class="loader"></div>
							<span>Add to Cart</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<app-confirm-modal *ngIf="showConfirmMsg" [message]="confirmMsg" (confirmed)="getUploadedContent($event)">
</app-confirm-modal>
<app-confirm-modal *ngIf="showErrorMsg || bulkUploadHasErrorAndSucess" [message]="errorMsg"
	(confirmed)="redirectFlag ? continueLogicallyAfterButlAttempt() : showErrorMsg = false"></app-confirm-modal>
