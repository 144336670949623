import { Component, OnInit, Input } from '@angular/core';
import { DashboardJustSoldPreloaderComponent } from './dashboard-just-sold-preloader/dashboard-just-sold-preloader.component';
import { DashboardNotificationPreloaderComponent } from './dashboard-notification-preloader/dashboard-notification-preloader.component';
import { PurchaseItemPreloaderComponent } from './purchase-item-preloader/purchase-item-preloader.component';
import { PoItemPreloaderComponent } from './po-item-preloader/po-item-preloader.component';
import { NotificationItemPreloaderComponent } from './notification-item-preloader/notification-item-preloader.component';
import { ListingItemPreloaderComponent } from './listing-item-preloader/listing-item-preloader.component';
import { DetailedItemPreloaderComponent } from './detailed-item-preloader/detailed-item-preloader.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-vendor-items-preloader',
    templateUrl: './vendor-items-preloader.component.html',
    styleUrls: ['./vendor-items-preloader.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, DetailedItemPreloaderComponent, ListingItemPreloaderComponent, NotificationItemPreloaderComponent, PoItemPreloaderComponent, PurchaseItemPreloaderComponent, DashboardNotificationPreloaderComponent, DashboardJustSoldPreloaderComponent]
})
export class VendorItemsPreloaderComponent implements OnInit {
	@Input() source: string;
  @Input() isCheckbox: boolean = false;

  preloaderItemList = Array(4);

  constructor() { }

  ngOnInit(): void {
  	switch (this.source) {
      case 'detailed':
      	this.preloaderItemList.length = 4;
        break;
      case 'simple':
        this.preloaderItemList.length = 5;
        break;
      case 'notifications':
        this.preloaderItemList.length = 5;
        break;
      case 'dashboardNotifications':
        this.preloaderItemList.length = 5;
        break;
      case 'pendingPo':
        this.preloaderItemList.length = 15;
        break;
      case 'purchase':
        this.preloaderItemList.length = 15;
        break;
      default:
      this.preloaderItemList.length = 4;
    }
  }


}
