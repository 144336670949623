import { Component, OnInit } from '@angular/core';
import { TopService } from '../../../shared/services/top.service';
import { NgForm, FormsModule } from '@angular/forms';
import { AuthService } from '../../../auth/auth.service';
import { IHotLeadRequest } from '../../../shared/interfaces/IHotLeadRequest';
import { SharedService } from '../../../shared/shared.service';
import { Router } from '@angular/router';
import { GuestService } from '../../../shared/services/guest.service';
import { UserService } from '../../../user/user.service';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'app-login-popup',
    templateUrl: './login-popup.component.html',
    styleUrls: ['./login-popup.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, NgClass]
})
export class LoginPopupComponent implements OnInit {
  loading = false;
  hotLeadCustomer: IHotLeadRequest = {
    fullName: '',
    mobile: '',
    email: '',
    isCall: false,
    isEmail: false
  };
  zoomeOutModal = false;

  constructor(private guestService: GuestService,
    public authService: AuthService,
    private sharedService: SharedService,
    private router: Router,
    public topService: TopService,
    public userService: UserService) { }

  ngOnInit() {
    this.hotLeadCustomer.fullName = this.userService.leadProfile.fullName;
    this.hotLeadCustomer.email = this.userService.leadProfile.email;
    this.hotLeadCustomer.mobile = this.userService.leadProfile.phoneNumber;
    this.hotLeadCustomer.isCall = this.userService.leadProfile.isCall;
    this.hotLeadCustomer.isEmail = this.userService.leadProfile.isEmail;
  }

  onSubmit(form: NgForm, $event): void {
    this.loading = true;
    event.preventDefault();
    event.stopPropagation();
    this.hotLeadCustomer.fullName = this.userService.leadProfile.fullName;
    this.hotLeadCustomer.email = this.userService.leadProfile.email;
    this.hotLeadCustomer.mobile = this.userService.leadProfile.phoneNumber;
    this.hotLeadCustomer.isCall = this.userService.leadProfile.isCall;
    this.hotLeadCustomer.isEmail = this.userService.leadProfile.isEmail;

    this.guestService.registerHotLeadUser(this.hotLeadCustomer).subscribe(
      (data) => {
        this.closeFirstModal();
        this.loading = false;
      },
      (err) => {
        this.guestService.errorModal.bodyText = err.error ? err.error : 'Error on registrating lead.';
        this.guestService.errorModal.isShown = true;
        this.guestService.errorModal.title = 'Error';
        this.loading = false;
      }
    );
  }

  closeFirstModal() {
    this.userService.loginFirstPopup = false;
    this.userService.loginSecondPopup = true;
  }

  getUserFullName(): string {
    if (this.userService.leadProfile && this.userService.leadProfile.fullName) {
      return this.userService.leadProfile.fullName.substring(0, this.userService.leadProfile.fullName.indexOf(' '));
    } else {
      return 'Guest';
    }
  }

  closeAll() {
    this.zoomeOutModal = true;
    this.userService.leadProfile.isCall = this.hotLeadCustomer.isCall;
    this.userService.leadProfile.isEmail = this.hotLeadCustomer.isEmail;
    setTimeout(() => {
      this.userService.loginFirstPopup = false;
      this.userService.loginSecondPopup = false;
      this.userService.isLogginPopupOpen = false;
      this.zoomeOutModal = false;
    }, 400);
  }

  navigateTo(location: string): void {
    this.sharedService.isStaticHtml = true;
    this.sharedService.isCorpSite = true;

    if (location === 'vendor') {
      this.router.navigate(['/vendor-application']);
    } else if (location === 'customer') {
      this.router.navigate(['/customer-application']);
    }

    this.zoomeOutModal = true;
    this.userService.leadProfile.isCall = this.hotLeadCustomer.isCall;
    this.userService.leadProfile.isEmail = this.hotLeadCustomer.isEmail;
    this.userService.loginFirstPopup = false;
    this.userService.loginSecondPopup = false;
    this.userService.isLogginPopupOpen = false;
    this.zoomeOutModal = false;
  }
}
