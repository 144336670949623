import { Component, Input, EventEmitter, Output } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-error-modal',
    templateUrl: './error-modal.component.html',
    styleUrls: ['./error-modal.component.scss'],
    standalone: true,
    imports: [NgIf]
})
export class ErrorModalComponent  {
  @Input() title: string;
  @Input() bodyText: string;
  @Input() isError: boolean;
  @Output() onConfirmError = new EventEmitter<null>();
  errorAnimateOut = false;

  constructor() { }

  alertConfirmBtn(): void {
    event.stopPropagation();
    this.errorAnimateOut = true;

    setTimeout(() => {
      if (this.isError) {
        this.onConfirmError.emit();
      }
      this.errorAnimateOut = false;
    }, 400);
  }
}
