
import * as models from './model';
export interface ICartHeader {
    version?: number;
    comments?: string;
    details?: models.ICartItem[];
    cartId?: string;
    externalRef?: number;
    shoppingCost?: number;
    paymentPercent?: number;

}
