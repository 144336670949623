import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { NgForm, FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IRegistrationData } from '../../../shared/interfaces/IRegistrationData';
import { environment } from '../../../../environments/environment';
import { setTime } from 'ngx-bootstrap/chronos/utils/date-setters';
import { UserService } from '../../../user/user.service';
import { UserDataEmitter } from '../guest-registration-mobile/guest-registration-mobile.component';
import { Router } from '@angular/router';
import { AUTH_CONFIG } from '../../../auth/hubx-auth-variables';
import { SessionService } from '../../../services/session.service';
import { DEFAULT_URL } from '../../../shared/static';
import { GuestService } from '../../../shared/services/guest.service';
import { TopService } from '../../../shared/services/top.service';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgIf, NgClass } from '@angular/common';


@Component({
    selector: 'app-guest-registration',
    templateUrl: './guest-registration.component.html',
    styleUrls: ['./guest-registration.component.scss'],
    standalone: true,
    imports: [FormsModule, NgIf, NgClass, ReactiveFormsModule, BsDropdownModule]
})
export class GuestRegistrationComponent implements OnInit, OnDestroy {
  public showModalTrigger = false;
  public animateIn = false;
  public animateOut = false;
  public registrationFailed = false;
  public nextStep = false;
  public isShowPassword = false;
  public goneForward = false;
  categories: string[] = [];
  companyType: string[] = [];
  guestRegisterMessage = environment.guestRegisterMessageX.message;
  hidden = false;
  checkboxCheck = false;
  checkboxIsChecked = false;
  checkboxAnimation = false;
  loading = this.userService.loading;

  invalidFeedback = '';

  userData: IRegistrationData = {
    companyName: '',
    companyWeb: '',
    fullName: '',
    email: '',
    emailConfirm: '',
    password: '',
    country: '',
    companyRevenue: '',
    phoneNumber: '',
    companyCategory: '',
    companyType: ''
  };

  @Output() registerDataEmitter = new EventEmitter<UserDataEmitter>();
  phoneForm: FormGroup;

  constructor(
    public topService: TopService,
    private userService: UserService,
    private guestService: GuestService,
    private router: Router,
    private sessionService: SessionService) { }

  ngOnInit() {
    this.phoneForm = new FormGroup({
      phone: new FormControl()
    });
  }

  ngOnDestroy() {
  }

  cancelRegistration(): void {
    this.closeModal();
  }

  register(): void {
    this.registerDataEmitter.emit({ userData: this.userData, isMobile: false });
  }

  closeModal(): void {
    this.animateOut = true;
    this.animateIn = false;
    setTimeout(() => {
      this.showModalTrigger = false;
      this.animateOut = false;
    }, 400);
  }

  showPassword(isShowPassword: boolean): void {
    this.isShowPassword = !isShowPassword;
  }

  onSubmit(form: NgForm, step, $event): void {
    event.preventDefault();
    event.stopPropagation();
    this.loading = true;

    this.createCategoriesRequest();
    this.createCompanyTypeRequest();
    this.checkIfLastNameExists();
    if (step === 1 && this.checkboxCheck) {
      // tslint:disable-next-line:max-line-length
      // ATTEMPT TO LOGIN AS BUYER/SUPER,VENDOR,ADMIN, OR SALES and if it doesn't work then may continue to next api call to check if email exists in guest list.
      this.guestService.getAllTypesEmail(this.userData.email).subscribe(
        (data) => {
          // THIS MEANS THE EMAIL EXISTS AND THE USER CANNOT CONTINUE WITH THIS EMAIL
          // alert('Error: Email already exists, please change it.');
          // but we check user and password to see if this is a returning customer
          this.loginAttempt(this.userData.email, this.userData.password);
        },
        (err) => {
          // THIS MEANS THE EMAIL IS UNIQUE AND DOES NOT EXIST ON THE SYSTEM AND THE USER MAY CONTINUE TO THE NEXT STEP
          this.invalidFeedback = '';
          this.nextStep = true;
          this.loading = false;
          this.goneForward = true;
          return;
        }
      );
    } else if (step === 2) {
      this.register();
      // this.loading = false;
    } else if (!this.checkboxCheck) {
      this.checkboxIsChecked = true;
      this.checkboxAnimation = true;
      this.loading = false;
      setTimeout(() => {
        this.checkboxAnimation = false;
      }, 1400);
      return;
    }
  }

  emailConfirm(): boolean {
    return this.userData.email.toLowerCase() !== this.userData.emailConfirm.toLowerCase();
  }

  checkFullName(): boolean {
    return true;
    // return (this.userData.fullName.split(/\w+/g).length - 1 > 1);
  }

  isFullName(): boolean {
    return this.userData.fullName.split(/\w+/g).length - 1 > 1;
  }

  checkIfLastNameExists(): void {
    if (this.userData.fullName.split(/\w+/g).length - 1 === 1) {
      this.userData.fullName += '   ';
    }
  }

  checkAccept(event: any) {
    if (event.target.checked) {
      this.checkboxCheck = true;
    } else {
      this.checkboxCheck = false;
    }
  }

  companyTypeClick(event: any, ctype: string): void {
    if (event.target.checked) {
      this.companyType.push(ctype);
    } else {
      this.companyType = this.companyType.filter(cate => cate !== ctype);
    }

  }

  recordClick(event: any, category: string): void {
    if (event.target.checked) {
      this.categories.push(category);
    } else {
      this.categories = this.categories.filter(cate => cate !== category);
    }


  }


  createCompanyTypeRequest(): void {
    this.userData.companyType = '';
    if (!this.companyType.length) {
      return;
    }

    for (let i = 0; i < this.companyType.length; i++) {
      if (this.companyType[i + 1] === this.companyType[this.companyType.length]) {
        this.userData.companyType += this.companyType[i];
      } else {
        this.userData.companyType += this.companyType[i] + ';';
      }
    }
  }

  createCategoriesRequest(): void {
    this.userData.companyCategory = '';
    if (!this.categories.length) {
      return;
    }

    for (let i = 0; i < this.categories.length; i++) {
      if (this.categories[i + 1] === this.categories[this.categories.length]) {
        this.userData.companyCategory += this.categories[i];
      } else {
        this.userData.companyCategory += this.categories[i] + ';';
      }
    }
  }

  onClickedOutside(): void {
    this.hidden = true;
  }

  getCategoriesText(): string {
    if (this.categories.length === 1) {
      return this.categories[0];
    }
    if (this.categories.length > 1) {
      return this.categories[0] + '...';
    }
  }
  onKeyUp(e: KeyboardEvent) {

  if ( e.keyCode >= 48 && e.keyCode <= 57 ||
      (e.keyCode === 65 && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.keyCode === 67 && e.ctrlKey === true) || // Allow: Ctrl+C
   //   (e.keyCode === 86 && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.keyCode === 88 && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.keyCode === 65 && e.metaKey === true) || // Allow: Cmd+A (Mac)
      (e.keyCode === 67 && e.metaKey === true) || // Allow: Cmd+C (Mac)
 //     (e.keyCode === 86 && e.metaKey === true) || // Allow: Cmd+V (Mac)
      (e.keyCode === 88 && e.metaKey === true) || // Allow: Cmd+X (Mac)
      (e.keyCode === 32) ||
      (e.keyCode === 43) ||
      (e.keyCode >= 35 && e.keyCode <= 39) // Allow: Home, End, Left, Right
    ) {
    return true;
  }
   return false;
  }
  getCompanyTypeText(): string {
    if (this.companyType.length === 1) {
      return this.companyType[0];
    }
    if (this.companyType.length > 1) {
      return this.companyType[0] + '...';
    }
  }

  loginAttempt(email: string, password): void {
    sessionStorage.clear();
    localStorage.clear();

    // this.userService.auth.auth0.client.login({
    this.userService.auth.hubxAuth.login({
      realm: AUTH_CONFIG.realm,
      username: email,
      password: password
    }, (err, authResult) => {
      if (err) {
        this.loginGuest(email, password);
      } else {
        this.sessionService.isLoggedIn = 'true';
        // this.guestService.isLoggedIn = true;
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.userService.auth.setSession(authResult);
          window.location.hash = '';
          this.userService.user_role = this.sessionService.userRole;

          switch (this.userService.user_role) {
            case 'BUYER': {
              this.userService.fetchUserInfo();
              break;
            }
            case 'SALES': {
              // this.userService.isGuest = false;
              this.sessionService.userEmail = email;
              this.userService.contactEmail = email,
                this.userService.auth.completedLoad = true;
              this.userService.sharedService.isLoggedInInternal = true;
              break;
            }
            case 'SUPER': {
              // this.userService.isGuest = false;
              this.sessionService.userEmail = email;
              this.userService.contactEmail = email,
                this.userService.auth.completedLoad = true;
              this.userService.sharedService.isLoggedInInternal = true;
              break;
            }
            case 'SELLER': {
              this.userService.fetchUserInfo();
              break;
            }
            case 'ADMIN': {
              // this.userService.isGuest = false;
              this.sessionService.companyName = email;
              this.userService.contactEmail = email,
                this.userService.foundBP = true;
              this.userService.topService.isDone = false;
              this.userService.auth.completedLoad = true;
              this.userService.sharedService.isLoggedInInternal = true;
              this.router.navigate(['/vendor/dashboard']);
              break;
            }
          }
        }
      }
    });
  }

  loginGuest(email: string, password: string): void {
    // this.userService.auth.auth0.client.login({
    this.userService.auth.hubxAuth.login({
      realm: environment.hubx_auth_guest_realm,
      username: email,
      password: password
    }, (err, authResult) => {
      if (err) {
        this.invalidFeedback = 'Error: Email already exists, please use another email.';
        this.loading = false;
        this.nextStep = false;
        this.userService.loading = false;
        this.userService.sharedService.isLoggedInGuest = false;
      } else {
        this.userService.auth.setSession(authResult);

        if (!this.userService.leadProfile.fullName) {
          this.guestService.getGuestEmailByEmail(email).subscribe(
            (data) => {
              this.userService.leadProfile = data;
            },
            (error) => {
              console.log('error on login: ' + error);
            }
          );
        }

        this.sessionService.isLoggedIn = 'true';
        // this.guestService.isLoggedIn = true;
        window.location.hash = '';
        this.userService.user_role = this.sessionService.userRole;
        this.sessionService.userEmail = email;
        this.userService.contactEmail = email,
        this.userService.auth.completedLoad = true;
        this.userService.sharedService.isLoggedInGuest = true;
      }
    });
  }

  goBack(): void {
    this.nextStep = !this.nextStep;
    this.goneForward = true;
    this.checkboxCheck = false;
    this.checkboxIsChecked = false;
    this.checkboxAnimation = false;
  }
}
