import { VendorService } from './../../../vendor.service';
import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild, ElementRef, Input } from '@angular/core';
import { Subscription } from 'rxjs';

import { SharedSource } from '../../../../core/shared-source';
import { PubSubService } from '../../../../core/pubsub.service';
import { NotificationCenterVendorService, TabsEnum } from '../notification-center-vendor.service';
import { SessionService } from '../../../../services/session.service';
import { SharedService } from '../../../../shared/shared.service';
import { ItemsService } from '../../../../shared/services/items.service';
import { Attribute } from '../../../../buyer/interfaces/model';
import { IDropdown } from '../../../../vendor/interface/IDropdown';
import { ChangeDetectorRef } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DropdownComponent } from '../../../../shared/dropdown/dropdown.component';
import { NgIf, NgClass } from '@angular/common';

export interface ICountersResponse {
  priceDropNewTotal: number;
  moreInventoryNewTotal: number;
  waitListNewTotal: number;
  priceDropOldTotal: number;
  moreInventoryOldTotal: number;
  waitListOldTotal: number;
}

@Component({
    selector: 'app-notification-header',
    templateUrl: './notification-header.component.html',
    styleUrls: ['./notification-header.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass, DropdownComponent, FormsModule]
})
export class NotificationHeaderComponent implements OnInit, OnDestroy {
  isAdmin = false;
  activeTab = TabsEnum.allNotif;
  counter = {
    allNew: 0,
    dropPriceNew: 0,
    waitlistNew: 0,
    moreInventoryNew: 0,
  };
  totals = {
    all: 0,
    allNew: 0,
    dropPrice: 0,
    dropPriceNew: 0,
    moreInventory: 0,
    moreInventoryNew: 0,
    waitlist: 0,
    waitlistNew: 0,
  };

  darkenActive = false;

  selectedmanufacturer: string = '';
  subscriptions: Subscription[] = [];
  @ViewChild('search', { static: true }) searchInput: ElementRef;
  @Output() search: EventEmitter<any> = new EventEmitter<any>();
  @Output() manufacturerFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() exportNotifications: EventEmitter<any> = new EventEmitter<any>();
  selectedManufName = 'All Manufacturers';
  @Input() allSelected: boolean;
  @Input() indeterminate: boolean;
  @Input() exporting: boolean;
  @Output() selectAll: EventEmitter<boolean> = new EventEmitter<boolean>()

  constructor(
    private pubSubService: PubSubService,
    private _sessionSvc: SessionService,
    private _sharedSvc: SharedService,
    public _notifCenterSvc: NotificationCenterVendorService,
    private itemsService: ItemsService,
    private _vendorSvc: VendorService,
    private changeDetectorRef: ChangeDetectorRef
  ) {

  }

  ngOnInit() {
    this.getCounters();
    this.isAdmin = this._sessionSvc.userRole === 'ADMIN';

    if (!this._notifCenterSvc.manufacturersData.length) {
      this.subscriptions.push(
        this.itemsService.getManufactures().subscribe(
          manufacturers => {
            this._notifCenterSvc.manufacturersData = [{ id: '', name: 'All Manufacturers' }];
            manufacturers.forEach(manuf => {
              if (manuf.name != '- No Manufacturer -') {
                this._notifCenterSvc.manufacturersData.push({
                  id: manuf.id,
                  name: manuf.name,
                });
              }
            });
          })
      );

    }
  }

  getAllManufacturers(): Attribute[] {
    return this._notifCenterSvc.manufacturersData;
  }

  getCounters() {
    if (this._vendorSvc.isVendorManager && !this._vendorSvc.isImpersonationReady) {
      return;
    }
    this._notifCenterSvc.getTotalCounts()
      .subscribe(
        (counts: ICountersResponse) => {
          if (counts && Object.keys(counts).length) {
            this.counter.allNew = counts.moreInventoryNewTotal + counts.priceDropNewTotal +
              counts.waitListNewTotal;
            this.totals.all = counts.moreInventoryOldTotal + counts.priceDropOldTotal +
              counts.waitListOldTotal;

            this.totals.dropPrice = counts.priceDropOldTotal;
            this.totals.moreInventory = counts.moreInventoryOldTotal;
            this.totals.waitlist = counts.waitListOldTotal;

            this.counter.dropPriceNew = counts.priceDropNewTotal;
            this.counter.moreInventoryNew = counts.moreInventoryNewTotal;
            this.counter.waitlistNew = counts.waitListNewTotal;

            this._sharedSvc['vendorNotificationNew'] = this.counter.allNew;
          }
          setTimeout(() => {
            this.changeDetectorRef.detectChanges();
          }, 0);
        },
        (err) => {
          console.error('Error retriving total counts for header', err);
        });
  }

  onTabClicked(tab: TabsEnum) {
    this.darkenActive = false;
    this.selectAll.emit(false);
    if (tab !== this.activeTab) {
      this._notifCenterSvc.tabSortDirection = [TabsEnum.dropPrice, TabsEnum.waitList, TabsEnum.moreInventory].indexOf(tab) > -1 ? 'DESC' : null;
    } else {
      if ([TabsEnum.dropPrice, TabsEnum.waitList, TabsEnum.moreInventory].indexOf(tab) > -1) {
        this._notifCenterSvc.tabSortDirection = this._notifCenterSvc.tabSortDirection === 'DESC' ? 'ASC' : 'DESC';
      } else {
        this._notifCenterSvc.tabSortDirection = null;
      }
    }
    this.activeTab = tab;
    this.getCounters();
    this._notifCenterSvc.announceTabSelectionChanged(tab);
  }

  markSeen(tab: TabsEnum) {
    const notifType = this._notifCenterSvc.fromTabToNotificationType(tab);
    const totalNewTab = this.counter[Object.keys(this.counter)[tab]];

    // dont mark as seen if theres nothing new
    if (totalNewTab === 0) { return; }

    const payload = {
      notificationTypes: notifType,
      vendorId: sessionStorage.getItem('partner_id'),
      notificationTypeTotal: totalNewTab
    };

    this._notifCenterSvc.markAsSeenRequest(payload)
      .subscribe(
        (res) => {
          if (res) {
            // reset counter
            this.getCounters();
          }
        },
        (err) => console.error('Server error marking as seen', err));
  }

  onSearch(event: any) {
    if (event.keyCode === 13 && this.searchInput.nativeElement.value !== '') {
      this.search.emit(this.searchInput.nativeElement.value);
    }
  }

  onManufacturerFilter(event) {
    if (this.selectedmanufacturer !== event.id) {
      this.manufacturerFilter.emit(event.id);
      this.selectedmanufacturer = event.id;
    }
  }

  export() {
    if(!this.exporting) {
      this.exportNotifications.emit();
    } else {
      return;
    }
  }

  onTabHovered(event) {
    if (!event.target.classList.contains('active')) {
      this.darkenActive = true;
    }
  }

  onTabMouseLeave(event) {
    this.darkenActive = false;
  }

  selectUnselectAll(event) {
    this.selectAll.emit(event.target.checked)
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

}

