import { Component, OnInit, OnDestroy, ViewChild, EventEmitter, Output } from '@angular/core';
import { CarouselComponent, CarouselModule } from 'ngx-owl-carousel-o';

import { TopService } from '../../../services/top.service';

@Component({
    selector: 'app-offers-popup',
    templateUrl: './offers-popup.component.html',
    styleUrls: ['./offers-popup.component.scss'],
    standalone: true,
    imports: [CarouselModule]
})
export class OffersPopupComponent implements OnInit, OnDestroy {
  @Output() onCloseModal = new EventEmitter<null>();
  @ViewChild('owlSlider', { static: true }) owlSlider: CarouselComponent;

  currentSliderIndex = 0;
  sliderOptions = {
    items: 1,
    dots: true,
    nav: false,
    loop: false,
    mouseDrag: false,
    autoWidth: true,
  };

  constructor(
    public topService: TopService
    ) { }

  ngOnInit(): void {
    if (this.topService.isMobile) {
      document.documentElement.classList.add('gg-modal', 'is-modal');
    }
  }
  onSliderChanged(event):void {
    this.currentSliderIndex = event.startPosition + 1;
  }

  goToNextSlide(): void {
    if (this.currentSliderIndex === 3) {
      this.closeModal();
    }
    // this.owlSlider.next([400]);
    this.owlSlider.next();
  }

  ngOnDestroy() {
    if (this.topService.isMobile) {
      document.documentElement.classList.remove('gg-modal', 'is-modal');
    }
  }

  closeModal(): void {
    this.onCloseModal.emit();
  }

  clickOnWrapper(event) {
    if (event.target.className === 'faq-modal') {
      this.closeModal();
    }
  }

}
