import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../shared/shared.service';
import { TopService } from '../../shared/services/top.service';
import { FooterComponent } from '../footer/footer.component';

@Component({
    selector: 'app-about-us',
    templateUrl: './about-us.component.html',
    styleUrls: ['./about-us.component.scss'],
    standalone: true,
    imports: [FooterComponent]
})
export class AboutUsComponent implements OnInit {

  constructor(private sharedService: SharedService, public topService: TopService) { }

  ngOnInit() {
    this.sharedService.isStaticHtml = true;
    this.sharedService.isCorpSite = true;
  }

}
