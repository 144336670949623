import { Component, OnDestroy, OnInit } from '@angular/core';
import { TopService } from '../shared/services/top.service';

@Component({
    selector: 'app-maintenance',
    templateUrl: './maintenance.component.html',
    styleUrls: ['./maintenance.component.scss'],
    standalone: true
})
export class MaintenanceComponent implements OnInit, OnDestroy {

  constructor(public topService: TopService) { }

  ngOnInit() {
    if (this.topService.isMobile) {
      document.documentElement.classList.add('is-modal');
    }
  }

  ngOnDestroy() {
    if (this.topService.isMobile) {
      document.documentElement.classList.remove('is-modal');
    }
  }

}
