import { Component, OnInit, OnDestroy, ViewChild, ViewChildren, QueryList, Input } from '@angular/core';
import { NotificationCenterVendorService, TabsEnum } from './notification-center-vendor.service';
import { Subscription } from 'rxjs';
import { VendorService } from '../../vendor.service';
import { PubSubService } from '../../../core/pubsub.service';
import { SharedSource } from '../../../core/shared-source';
import { Globals } from '../../shared/globals';
import { SharedService } from '../../../shared/shared.service';
import { INotificationVendor } from '../../interface/INotificationVendor';
import { NotificationHeaderComponent } from './notification-header/notification-header.component';
import { SessionService } from '../../../services/session.service';
import { INotificationMessage } from '../../../shared/interfaces/INotificationMessage';
import { IErrorModal } from '../../interface/IErrorModal';
import { IHttpErrorResponse } from '../../interface/IHttpErrorResponse';
import { NotificationComponent } from './notification/notification.component';
import { ConnectionManagerService } from '../../../shared/services/signalR/connection-manager.service';
import { eConnectionType } from '../../../shared/services/signalR/connection-type';
import { INotificationPayload } from '../../interface/INotificataionPayload';
import { environment } from '../../../../environments/environment';
import { LoaderService } from '../../../shared/services/loader.service';
const FileSaver = require('file-saver');
import { ChangeDetectorRef } from '@angular/core';
import { ErrorModalComponent } from '../../../shared/ui-components/error-modal/error-modal.component';
import { NotificationHelperComponent } from './notification-helper/notification-helper.component';
import { NotificationPopupComponent } from './notification-popup/notification-popup.component';
import { FormsModule } from '@angular/forms';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { VendorItemsPreloaderComponent } from '../../../shared/ui-components/vendor-items-preloader/vendor-items-preloader.component';
import { DashboardNotificationPreloaderComponent } from '../../../shared/ui-components/vendor-items-preloader/dashboard-notification-preloader/dashboard-notification-preloader.component';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-notification-center',
    templateUrl: './notification-center-vendor.component.html',
    styleUrls: ['./notification-center-vendor.component.scss'],
    standalone: true,
    imports: [NgIf, NotificationHeaderComponent, VirtualScrollerModule, NgFor, NotificationComponent, DashboardNotificationPreloaderComponent, VendorItemsPreloaderComponent, PaginationModule, FormsModule, NotificationPopupComponent, NotificationHelperComponent, ErrorModalComponent]
})
export class NotificationCenterVendorComponent implements OnInit, OnDestroy {
  @ViewChild(NotificationHeaderComponent) headerComponent: NotificationHeaderComponent;
  @ViewChildren(NotificationComponent) notifComponentChildren: QueryList<NotificationComponent>;
  @Input() isFromDashboard?: boolean;

  hideMoreBtn = true;
  isAdmin = false;
  activeTab: TabsEnum = TabsEnum.allNotif;
  vendors = [];
  loading = true;
  exporting = false;
  onScrollLoading = false;

  showNewItems = {
    value: 0,
    tab: 0
  };

  notifications: INotificationVendor[] = [];
  subscriptions: Subscription[] = [];
  httpSubscriptions: Subscription[] = [];
  errorModal: IErrorModal = {
    isError400: false,
    errorMessage400: ''
  };
  payload: INotificationPayload = {
    vendorId: this._sessionSvc.partnerId,
    notificationType: null,
    pagination: {
      pageNumber: 1,
      pageSize: 25,
      totalCount: 0,
      totalPages: 5
    },
    searchQuery: null,
    manufacturerId: null,
    orderBy: {
      orderByName: null,
      sortDirection: 'DESC'
    },
    fields: null
  };

  selectedNotification = {} as INotificationVendor;
  selectedNotificationIndex:number;
  isNotificationPopupShown = false;

  notificationBulkActionState = {
    total: 0,
    selected: 0
  };

  constructor(
    public _notifCenterSvc: NotificationCenterVendorService,
    private _sharedSvc: SharedService,
    private _sessionSvc: SessionService,
    private pubSubService: PubSubService,
    public _vendorSvc: VendorService,
    private connManagerService: ConnectionManagerService,
    private loaderService: LoaderService,
    private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.isFromDashboard = this.isFromDashboard ? true : false;
    this.isAdmin = this._sessionSvc.userRole === 'ADMIN';

    this.subscriptions.push(

      this.pubSubService.sharedSubject.subscribe((myEvent) => {
        switch (myEvent.name) {
          case SharedSource.priceDropFromBuyer:
          case SharedSource.waitlistFromBuyer:
          case SharedSource.moreInventoryFromBuyer:
            this.handleSignalREvents(myEvent.data);
            break;
          case SharedSource.changeCustomerVendor:
            this.onVendorSelected();
        }
      }));

    this.subscriptions.push(
      this._notifCenterSvc.tabSubject$()
        .subscribe(tab => this.handleTabChanges(tab))
    );
  }

  // I commented this out cuz the checkboxs in popup
  //  were not receiving the clicks
  clickedInside($event: Event): void {
    // $event.preventDefault();
    // $event.stopPropagation();
  }

  openNotificationPopup(notification: any, i: number): void {
    this.selectedNotification = notification;
    this.selectedNotificationIndex = i;
    this.isNotificationPopupShown = true;
  }

  hideNotificationPopup(): void {
    this.isNotificationPopupShown = false;
    this.selectedNotificationIndex = null;
  }

  getItemsWrapperTop() {
    let topPos = (!this.hideMoreBtn && this.showNewItems.value > 0 &&
      this.activeTab === this.showNewItems.tab) ? 306 : 260;

    if (this.isAdmin) {
      // add 40px to fit the vendors dropdown
      topPos += 40;
    }
    return topPos + 'px';
  }

  getItemsWrapperHeight() {
    if (!this.isFromDashboard && this.isAdmin) {
      return 'calc(100% - 310px)';
    } else if (!this.isFromDashboard && !this.isAdmin) {
      return 'calc(100% - 280px)';
    }
    return '600px';
  }

  onVendorSelected(selectedVendor?) {
    this._vendorSvc.vendorId = this.payload.vendorId = this._sessionSvc.partnerId;

    this.fetchNotificationsItems(this.activeTab, true);
    if (this.headerComponent) {
      this.headerComponent.getCounters();
    }

    this.connManagerService.switchConnection(eConnectionType.Notification, this._vendorSvc.vendorId);
  }

  handleSignalREvents(respData: INotificationMessage) {
    if (respData.isAdd && this.notifications.length) {
      // try to update data automatically if not show btn
      this.incrementCounter(respData);
    } else {
      // buyer prob removed a notification
      this.fetchNotificationsItems(this.activeTab, true);
    }
    if (this.headerComponent) {
      this.headerComponent.getCounters();
    }
  }

  incrementCounter(data) {
    const copyAllNotif: INotificationVendor[] = Globals.jsonStringifyParser(this.notifications);
    const modifiedProd = copyAllNotif.find(n => n.vendorItem.id === data.vendorItemId);
    const notificationTab = this._notifCenterSvc.fromNotificationTypeToTab(data.notificationType);

    if (modifiedProd) {
      // updating item in list
      modifiedProd.notificationDetails.map(d => {
        if (d.notificationType === data.notificationType) {
          ++d.newCount;
        }
        return d;
      });

      this.notifications = copyAllNotif;
    } else {

      // show black 'Show x More' btn to update list
      if ([notificationTab, TabsEnum.allNotif].includes(this.activeTab)) {

        ++this.showNewItems.value;
        this.showNewItems.tab = this.activeTab;
        this.hideMoreBtn = false;
      }
    }
    setTimeout(() => {
      this.changeDetectorRef.detectChanges();
    }, 0);
  }

  showNewItemsBtn() {
    const showbtn = !this.hideMoreBtn && this.showNewItems.value > 0 && this.activeTab === this.showNewItems.tab && this.notifications.length > 0;
    return showbtn;
  }

  showNewItemsBtnClicked() {
    this.fetchNotificationsItems(this.showNewItems.tab, true);
    this.hideMoreBtn = true;
    this.showNewItems = {
      value: 0,
      tab: 0
    };
  }

  pageChanged(event) {
    this.loading = true;
    if (event.page === 1) {
      this.fetchNotificationsItems(this.activeTab, true);
    } else {
      this.payload.pagination.pageNumber = event.page;
      this.fetchNotificationsItems(this.activeTab);
    }
  }

  fetchNotificationsItems(tab: TabsEnum, isFirstPage?: boolean, isOnDashboardScroll?: boolean) {
    this.loaderService.setLoaderVendorVisible(true);
    this.httpSubscriptions.forEach(s => s.unsubscribe());
    this.httpSubscriptions = [];
    this.showNewItems.value = 0;
    this.hideMoreBtn = true;
    // show loader in all notif tab but not on dashboard scroll
    if (!isOnDashboardScroll) {
      this.loading = true;
    }
    this.payload.notificationType = this._notifCenterSvc.fromTabToNotificationFilter(tab);
    if (isFirstPage) {
      this.payload.pagination.pageNumber = 1;
    } else {
      this.isFromDashboard ? this.payload.pagination.pageNumber++ : this.payload.pagination.pageNumber;
    }

    if ([TabsEnum.dropPrice, TabsEnum.waitList, TabsEnum.moreInventory].indexOf(tab) > -1) {
      this.payload.orderBy = {
        orderByName: 'TotalCount',
        sortDirection: this._notifCenterSvc.tabSortDirection
      };
    } else {
      this.payload.orderBy = {
        orderByName: null,
        sortDirection: 'DESC'
      };
    }

    this.resetNotificationBulkActionsState();

    this.httpSubscriptions.push(
      this._notifCenterSvc.getNotificationItems(this.payload)
        .subscribe((items: any) => {
          Object.assign(this.payload.pagination, items.pagination);

          this.notifications = this.isFromDashboard ? this.notifications.concat(items.itemNotifications) : items.itemNotifications;
          if (this.activeTab === tab) {
            if (this.notifications && this.notifications.length && !isFirstPage) {
              this.isFromDashboard ? this.notifications =
                this.notifications.concat(items.itemNotifications) : this.notifications = items.itemNotifications;
            } else {
              this.notifications = items.itemNotifications;
            }

            if (this.notifications) {
              this.notifications.map(n => this._notifCenterSvc.addConditionToDescription(n));
            }

            if (this.isNotificationPopupShown && this.notifications.length === 0) {
              this.updateBuyerWaitingCounters();
            }

            if (this.isNotificationPopupShown && this.notifications.length > 0) {
              const modifiedNotif = this.notifications.find((notif) => notif.vendorItem.id === this.selectedNotification.vendorItem.id);

              if (modifiedNotif) {
                this.selectedNotification = modifiedNotif;
              } else {
                this.updateBuyerWaitingCounters();
              }
            }

            this.loading = false;
            this.onScrollLoading = false;
            setTimeout(() => {
              this.loaderService.setLoaderVendorVisible(false);
            }, 500);
            if (tab !== TabsEnum.allNotif && tab !== TabsEnum.archive) {
              if (this.headerComponent) {
                this.headerComponent.markSeen(tab);
              }
            }
          }

          this.notificationBulkActionState.total = items.itemNotifications.length;
          setTimeout(() => {
            this.changeDetectorRef.detectChanges();
          }, 0);
        },
          (err: IHttpErrorResponse) => {
            this.loading = false;
            this.onScrollLoading = false;
            this.loaderService.setLoaderVendorVisible(false);

            this._sharedSvc.handleVendorHttpError(err, this.errorModal);
            if (!this.errorModal.errorMessage400) {
              if (err.message) {
                this.errorModal.errorMessage400 = err.message;
              } else if (err.error) {
                this.errorModal.errorMessage400 = err.error;
              } else {
                this.errorModal.errorMessage400 = 'Unknown Error';
              }
            }
            console.error('Error fetching all notifications: ', err);
          }
        )
    );
  }

  onScrollDown(event) {
    const endPerPage = this.payload.pagination.pageNumber * this.payload.pagination.pageSize;
    if (event.endIndex < endPerPage - 1) {
      return;
    }

    this.fetchNotificationsItems(TabsEnum.allNotif, false, true);
    this.onScrollLoading = true;
  }


  handleTabChanges(tabName) {
    if (this._vendorSvc.isVendorManager && !this._vendorSvc.isImpersonationReady) {
      return;
    }
    this.activeTab = tabName;

    switch (tabName) {
      case TabsEnum.dropPrice:
        this.fetchNotificationsItems(TabsEnum.dropPrice, true);
        break;
      case TabsEnum.waitList:
        this.fetchNotificationsItems(TabsEnum.waitList, true);
        break;
      case TabsEnum.moreInventory:
        this.fetchNotificationsItems(TabsEnum.moreInventory, true);
        break;
      case TabsEnum.archive:
        this.fetchNotificationsItems(TabsEnum.archive, true);
        break;
      default:
        // all notifications tab
        this.fetchNotificationsItems(TabsEnum.allNotif, true);
    }
  }

  hideErrorAlert(): void {
    this.errorModal = { isError400: false, errorMessage400: '' };
  }

  updateAfterVendorAction() {
    this.fetchNotificationsItems(this.activeTab, this.payload.pagination.pageNumber === 1);
    if (this.headerComponent) {
      this.headerComponent.getCounters();
    }
  }

  updateBuyerWaitingCounters() {
    this.selectedNotification.mappedNotDetails.priceDrop.seenCount = 0;
    this.selectedNotification.mappedNotDetails.priceDrop.newCount = 0;
    this.selectedNotification.mappedNotDetails.waitlist.seenCount = 0;
    this.selectedNotification.mappedNotDetails.waitlist.newCount = 0;
    this.selectedNotification.mappedNotDetails.moreInventory.seenCount = 0;
    this.selectedNotification.mappedNotDetails.moreInventory.newCount = 0;
  }

  onSearch(value) {
    this.payload.searchQuery = value;
    this.fetchNotificationsItems(this.activeTab, true);
  }

  onManufacturerFilter(value) {
    this.payload.manufacturerId = value;
    this.fetchNotificationsItems(this.activeTab, true);
  }

  exportNotifications(value) {
    this.exporting = true;
    this._notifCenterSvc.exportNotifications(value).subscribe(data => {
      const blob = new Blob([data.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const filename = data.headers.get('content-disposition').split(';')[1].split('"')[1];
      FileSaver.saveAs(blob, filename);
      this.exporting = false;
    });
  }

  resetNotificationBulkActionsState() {
    this.notificationBulkActionState.total = 0;
    this.notificationBulkActionState.selected = 0;
  }

  onSelect(isSelected: boolean) {
    this.notificationBulkActionState.selected = isSelected
      ? this.notificationBulkActionState.selected + 1
      : this.notificationBulkActionState.selected - 1;
  }

  selectUnselectAll(areSelectedAll: boolean) {
    this.notifications.forEach(noti => {
      noti.isSelected = areSelectedAll;
    });
    this.notificationBulkActionState.selected = areSelectedAll ? this.notificationBulkActionState.total : 0;
    this.notifComponentChildren.forEach(child => {
      child.markForCheck();
    });
  }

  selectedNotifications() {
    let ids = new Array<string>();
    this.notifications.forEach(noti => {
      if (noti.isSelected) {
        ids.push(noti.vendorItem.id);
      }
    });
    return ids;
  }

  archiveSelected() {
    this.loading = true;
    let ids = this.selectedNotifications();
    this._notifCenterSvc.archiveNotifications(ids)
      .subscribe((res) => {
        if (res) {
          setTimeout(() => {
            this.headerComponent.getCounters();
            this.fetchNotificationsItems(this.activeTab, this.payload.pagination.pageNumber === 1)
          }, environment.notificationRefreshingWait * ids.length);
        }
      });
  }

  exportSelectedNotifications() {
    if (!this.exporting) {
      this.exporting = true;
      this.payload.ids = this.selectedNotifications();
      this._notifCenterSvc.exportNotifications(this.payload).subscribe(data => {
        const blob = new Blob([data.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const filename = data.headers.get('content-disposition').split(';')[1].split('"')[1];
        FileSaver.saveAs(blob, filename);
        this.exporting = false;
        this.payload.ids = null;
        this.selectUnselectAll(false);
      });
    } else {
      return;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this._notifCenterSvc.announceTabSelectionChanged(TabsEnum.allNotif);
  }
}
