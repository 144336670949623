import * as enums from '../../enums/enum';
import { IVendorItemRequest } from '../../../vendor/interface/IVendorItemRequest';

export interface Offer {
  id: string,
  customerId: string,
  status: enums.FullOfferStatusEnum,
  qty: number,
  price: number,
  vendorItemId: string,
  createdOn: Date,
  expiresOn: Date,
  offerEvents?: OfferEvent[],
  companiesInfo: OfferContactInfo,
  version: number,
  description: string,
  effectivePrice: number,
  expired?: boolean // we use this to handle offer expired in frontend before receive signlar
}

export interface OfferContactInfo {
  buyer: ContactInfo,
  vendor: ContactInfo,
  taker: ContactInfo
}

export interface ContactInfo {
  companyName: string,
  phoneNumber: string,
  email: string
}

export interface OfferEvent {
  id: string,
  userId: string,
  version: number,
  timestamp: Date,
  eventType: enums.OfferEventTypes,
  metadata?: string
}

export interface OffersByStatusRequest {
  pageSize: number;
  pageNumber: number;
  status: number;
  resetSelectedOffer?: boolean; //not mapped property
}

export interface OffersByStatusResponse {
  pagination: {
    totalCount: number;
    pageSize: number;
    currentPage: number;
    totalPages: number;
  };
  offerDataResponses: OfferByStatusData[];
}

export interface OfferByStatusData {
  offerId: string;
  itemCode: string;
  manufacturerLogoUrl: string;
  lastEventType: enums.OfferEventTypes;
  lastEventTimestampUtc: string;
  unseenEventsCount: number;
  quantity: number;
  offerStatus: number;
  lastEventTypeMsg?: string;
}

export interface seenOfferRequest {
  offerId: string,
  instanceId: string
}

export interface TotalOfferByStatusResponse {
  displayName?: string, // not mapped property
  status: enums.FullOfferStatusEnum,
  total?: number
}

export interface AcceptOfferRequest {
  offerId: string,
  instanceId: string,
  effectivePrice: number,
  version: number
}

export interface RejectOfferRequest {
  instanceId: string,
  version: number
}

export interface CancelOfferRequest {
  instanceId: string,
  version: number
}
export interface AcceptPreviousOfferRequest {
  instanceId: string,
  effectivePrice: number,
  referenceEventId: string,
  version: number
}

export interface AcceptPreviousOfferResponse {
  id: string,
  eventId: string,
  version: number,
  status: number,
  vendorItemId: string,
  customerId: string,
  vendorId: string,
  quantity: number,
  price: number,
  updatedOn: string,
  updatedBy: string,
}
export interface OfferEventUpdate {
  version: number,
  instanceId: string,
  quantity: number,
  price: number,
  effectivePrice: number,
  isFinal: boolean,
  goodFor: number
}

export interface OfferEventUpdateResponse {
  eventId: string,
  expireOn: Date,
  goodFor: number
  isFinal: boolean,
  price: number,
  quantity: number,
  version: number,

  parentEvent: {
    eventId: string,
    eventType: enums.OfferEventTypes,
    goodFor: number,
    quantity: number,
    price: number,
    isFinal: boolean
  },
  mainEventType: enums.OfferEventTypes
}

export interface ItemWithPendingOffers {
  vendorItemId: string,
  partNumber: string,
  itemDescription: string,
  offers: PendingOfferData[],
  // UI properties
  manufacturerLogoUrl: string,
  offersAction?: PendingOfferAction,
  sellerId: string,
  hasPendingOffers: boolean
}

export enum PendingOfferAction {
  CANCEL = 0,
  KEEP = 1
}

export interface PendingOfferData {
  id: string,
  itemPrice: number,
  offerPrice: number,
  units: number,
  total: number,
  status: enums.OfferStatusCancelationWarning
}

export interface PendingOfferRequest {
  itemIds: string[];
}

export interface ItemChangeWillAffectOfferRequest {
  items: IVendorItemRequest[]
}

export interface PendingOfferByAction {
  pendingOffersToKeep: ItemWithPendingOffers[],
  pendingOffersToCancel: ItemWithPendingOffers[]
}

export interface OfferSubscriptionResponse {
  success: boolean,
  id: string,
  offerId: string,
  userId: string,
  createdOn: string,
  isEnabled: boolean,
}
