import { OrderStatusEnum } from '../enums/orderStatusEnum';

export interface Order {
  docEntry: number;
  docNumber: number;
  orderDate: Date;
  dueDate: Date;
  orderStatus: string | OrderStatusEnum;
  orderDiscountAmount: number;
  orderDetails: OrderLine[];
  orderTotal: number;
  billingAddress: string;
  shippingAddress: string;
  salesEmployee: string;
  comments: string;
  tax: number;
  shippingCost: number;
  isCancelled: boolean;
  cardCode: string;
  cardName: string;
  contactName: string;
  contactEmail: string;
  progressStatus: SalesOrderStatus;
  payment: {
    id: string;
    fee: number;
    status: string;
    authorization: string;
    charge: string;
    amount: number;
    firstName: string;
    lastName: string;
    ipAddress: string;
    cardLast4Digits: string;
    cardType: string;
  };
}

export interface OrderLine {
  itemCode: string;
  description: string;
  exwPoint: string;
  unitPrice: number;
  quantity: number;
}

export enum SalesOrderStatus {
  PendingPayment = 1,
  PaymentApproved,
  ProcessingTransit,
  ProcessingStock,
  Processing,
  Ready,
  Shipped
}


