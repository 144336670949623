import { OfferService } from './../../../user/offers/offer.service';
import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map, flatMap } from 'rxjs/operators';

import { PdfGeneratorService } from '../../../services/pdf-generator.service';
import { environment } from '../../../../environments/environment';
import { TopService } from '../../../shared/services/top.service';
import { PageErrorService } from '../../../page-error/page-error.service';
import { ISplitOrderLinePost } from '../../interfaces/ISplitOrderLinePost';
import { IOrder } from '../../interfaces/IOrder';

import * as models from '../../interfaces/model';
import { UserService } from '../../../user/user.service';
import { SharedService } from '../../../shared/shared.service';
import { UtilityService } from '../../../../app/core/utility.service';
import { IOrderListCombo } from '../../interfaces/IOrderListCombo';
import { OrderStatusEnum } from '../../enums/orderStatusEnum';

const PAGE_SIZE = 25;
@Injectable()
export class OrdersService {
  readonly defaultOrderRequest = `?PageNumber=1&PageSize=${PAGE_SIZE}`;
  restApiVerb = '';
  orderChosen: models.IOrder = {};
  orders: models.IOrder[] = [];
  selected_order_number: number;
  selected_doc_number: number;
  handleFirstOrderLoadRequired = false;
  orderLoaded = new EventEmitter<boolean>();
  orderLoaded2: boolean;
  isOrders = false;

  ordersErrorModal: models.IBuyerErrorModal = {
    bodyText: '',
    title: 'Server Error',
    isShown: false
  };

  constructor(
    private http: HttpClient,
    private pdfGenerator: PdfGeneratorService,
    public topService: TopService,
    private pageErrorService: PageErrorService,
    private router: Router,
    private userService: UserService,
    private sharedService: SharedService,
    private utils: UtilityService,
    private offerservice: OfferService) { }

  loadOrderHistory(): void {
    this.orders = [];
    this.isOrders = true;
    this.getOrders(this.defaultOrderRequest).subscribe(
      data => {
        this.orderLoaded2 = true;
        this.topService.loading = false;
        this.orderLoaded.emit(true);
        if (data) {
          data.values.forEach(order => {
            if (order.orderStatus === 'open') {
              order.orderStatus = 'Open';
            }
          });
          this.orders = data.values;
          if (this.orders.length === 0) {
            this.isOrders = false;
          }
        }
      },
      error => {
        this.topService.loading = false;
        this.pageErrorService.errorMessage = error.statusText;
        this.pageErrorService.errorCode = error.status;
        this.router.navigate(['page-error']);
      }
    );
  }

  splitLinePost(docEntry: number, lines: ISplitOrderLinePost): Observable<IOrder> {
    this.restApiVerb = 'orders/';
    const url = environment.buyerPortalBaseUrl + this.restApiVerb + docEntry + '/split';
    return this.http.put<IOrder>(url, lines);
  }

  cancelOrder(docEntry: number): Observable<number> {
    const url = environment.buyerPortalBaseUrl + 'orders/' + docEntry + '/cancellation';
    return this.http.put<number>(url, 'cancel');
  }

  updateOrder(orderNo): Observable<null> {
    this.restApiVerb = 'orders';
    const url = environment.buyerPortalBaseUrl + 'cart/fromOrder';
    return this.http.post<null>(url, orderNo);
  }

  getOrders(orderData: string = this.defaultOrderRequest): Observable<models.IOrderMain> {
    this.restApiVerb = 'orders';
    const url = environment.buyerPortalBaseUrl + this.restApiVerb + orderData;
    return this.http.get<models.IOrderMain>(url).pipe(
      map((orders: models.IOrderMain) => {
        if (orders) {
          orders.values.forEach((order) => {
            if (order.payment.status) {
              order.orderStatus = 'paid';
            }
          });
        }
        return orders;
      })
    );
  }

  getOpenOrders(orderData: string): Observable<models.IOrderMain> {
    this.restApiVerb = 'orders?status=open';
    const url = environment.buyerPortalBaseUrl + this.restApiVerb + '&' + orderData;
    return this.http.get<models.IOrderMain>(url);
  }

  getOrderDetails(docEntry: string): Observable<models.IOrder> {
    this.restApiVerb = 'orders/' + docEntry;
    const url = environment.buyerPortalBaseUrl + this.restApiVerb;
    return this.http.get<models.IOrder>(url).pipe(
      map(res => {
        if (res.payment.status) {
          res.orderStatus = 'paid';
        }
        if (res.orderDetails) {
          res.orderDetails.forEach(orderLine => {
            orderLine.description = orderLine.description ? orderLine.description : orderLine.itemName;
          });
        }
        return res;
      })
    );
  }

  generateOrderPdf(): void {
    this.pdfGenerator.generateHtmlToPdf(this.orderChosen);
  }

  openPdf(event: Event, docEntry: number): void {
    this.topService.loading = true;
    this.userService.getProfile().subscribe(profile => {
      this.userService.billing = profile.billingAddresses;
      this.userService.shipping = profile.shippingAddresses;
      this.userService.companyName = profile.companyName;
      this.userService.bpCode = profile.bpCode;
      this.userService.orderBankInfo = profile.beneficiaryBank;
      for (let i = 0; i < this.userService.billing.length; i++) {
        if (this.userService.billing[i].isDefault) {
          if (this.userService.billing[i].address2 === null) {
            this.userService.billing[i].address2 = '';
          }
          if (this.userService.billing[i].country === null) {
            this.userService.billing[i].country = '';
          }
        }
      }

      this.getOrderDetails(docEntry.toString()).subscribe(
        (orderData) => {
          this.orderChosen = orderData;
          this.generateOrderPdf();
          this.topService.loading = false;
        },
        (err) => {
          this.topService.loading = false;
          this.sharedService.handleBuyerHttpError(err, this.ordersErrorModal);
        });

      event.stopPropagation();
    },
      error => {
        this.topService.loading = false;
        this.sharedService.handleBuyerHttpError(error, this.ordersErrorModal);
      });
  }

  chargeOrder(piid: string, amount: number): Observable<any> {
    const url = environment.buyerPortalBaseUrl + 'payments/' + piid + '/applied';
    const param = {
      finalAmount: amount,
    };
    return this.http.post(url, param);
  }

  toPascalCase(s: string): string {
    return this.utils.toTitleCase(s);
  }

  getExternalId(order: IOrder) {
    if (!order.externalId) {
      return '--';
    } else if (order.payment?.id && ['Card_Pending', 'Pending Payment'].includes(order.externalId)) {
      return 'CC Paid';
    } else {
      return order.externalId;
    }
  }

  getOpenOrdersShort(docEntry = '') {
    const url = environment.buyerPortalBaseUrl + 'orders/open';
    return this.http.get<IOrderListCombo[]>(url)
    .pipe(
      map((orders) => {
        const openOrders = orders.filter((order) => order.docEntry !== +docEntry)
        .sort((o1,o2) => o2.docNumber - o1.docNumber);
        return openOrders;
      }
    ))
  }

  goToFullOrderPage(docEntry: string): void {
    this.topService.loading = true;
    this.getOrderDetails(docEntry).subscribe(
      (orderData) => {
        this.topService.loading = false;
        this.orderChosen = orderData;
        this.router.navigate(['/buyer/full-order', docEntry]);
      },
      (err) => {
        this.topService.loading = false;
        this.sharedService.handleBuyerHttpError(err, this.ordersErrorModal);
      }
    );
  }



  getOrderList(orderData: string = this.defaultOrderRequest): Observable<models.OrderListResponse> {
    this.restApiVerb = 'orders/v2';
    const url = environment.buyerPortalBaseUrl + this.restApiVerb + orderData;
    return this.http.get<models.OrderListResponse>(url);
  }

  getOrder(docEntry: string): Observable<models.Order> {
    this.restApiVerb = '/orders/v2/' + docEntry;
    const url = environment.buyerPortalBaseUrl + this.restApiVerb;
    return this.http.get<models.Order>(url);
  }

  


}
