import { Component, OnInit, Input, EventEmitter, Output, HostListener } from '@angular/core';
import { Router } from '@angular/router';

import { ItemsService } from '../../shared/services/items.service';
import { environment } from '../../../environments/environment';
import { PageErrorService } from '../../page-error/page-error.service';
import { UtilityService } from '../../core/utility.service';
import { SessionService } from '../../services/session.service';

import * as models from '../../buyer/interfaces/model';
import { PubSubService } from '../../core/pubsub.service';
import { SharedSource } from '../../core/shared-source';
import { IBuyerErrorModal, IItemAttribute } from '../../buyer/interfaces/model';
import { GuestService } from '../../shared/services/guest.service';
import { TopService } from '../services/top.service';
import { AuthService } from '../../auth/auth.service';
import { CartService } from '../../buyer/cart/cart.service';
import { InventoryLimitPipe } from '../pipes/inventory-limit.pipe';
import { SharedService } from '../shared.service';
import { UserService } from '../../user/user.service';
import { CurrentDealPipe } from '../pipes/current-deal.pipe';
import { CustomCurrencyPipe } from '../pipes/currency.pipe';
import { OnlyDigitsDirective } from '../directives/only-digits.directive';
import { FormsModule } from '@angular/forms';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { NgIf, NgStyle, NgFor, NgClass } from '@angular/common';


@Component({
    selector: 'app-item-detail-mobile',
    templateUrl: './item-detail-mobile.component.html',
    styleUrls: ['./item-detail-mobile.component.scss'],
    standalone: true,
    imports: [NgIf, NgClickOutsideDirective, NgStyle, FormsModule, NgFor, NgClass, OnlyDigitsDirective, CustomCurrencyPipe, CurrentDealPipe]
})
export class ItemDetailMobileComponent implements OnInit {
  @Input() item: models.IItem;
  @Input() openItemDetails: boolean;
  @Output() onModalClose = new EventEmitter<null>();
  @Output() onError = new EventEmitter<IBuyerErrorModal>();
  @Output() gradingGuideEmitter = new EventEmitter<models.IItem>();
  user_role = '';
  availableToSellItem: number;
  loading = false;
  copied = '';
  zeroQtyAlert = false;
  notEnoughQty = false;
  notEnoughQtyMessage = '';
  notEnoughQtyTitle = '';
  notEnoughMOQ = false;
  animateOut = false;
  itemListErrorModal: IBuyerErrorModal = {
    bodyText: '',
    title: '',
    isShown: false
  };
  itemsAvailable = 0;

  constructor(
    public itemsService: ItemsService,
    public cartService: CartService,
    private router: Router,
    public pageErrorService: PageErrorService,
    public utilService: UtilityService,
    public topService: TopService,
    public sessionService: SessionService,
    public pubSubService: PubSubService,
    private guestService: GuestService,
    private authService: AuthService,
    private inventoryLimitPipe: InventoryLimitPipe,
    public sharedService: SharedService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.user_role = this.sessionService.userRole;
  }

  // @HostListener('document:keydown.escape', ['$event']) onEscape(event: KeyboardEvent) {
  //   this.closeModal();
  // }

  closeModal(): void {
    this.animateOut = true;
    setTimeout(() => {
      this.onModalClose.emit();
      this.pubSubService.sharedSubject.next({ name: SharedSource.selectedItemChanged, data: false });
      this.animateOut = false;
    }, 500);
  }

  onClickedOutside(e: Event): void {
    e.preventDefault();
    if (this.itemListErrorModal.isShown === false) {
      this.closeModal();
    }
  }

  getNumberOfItemsAvailable(item: models.IItem): string {
    this.itemsAvailable = this.item.inventory.availableToSell;
    return (this.user_role === 'BUYER')
      ? this.inventoryLimitPipe.transform(this.itemsAvailable)
      : this.itemsAvailable.toString();
  }

  customerIsSelectedFlag(): string {
    return this.sessionService.isCustomerSelected;
  }

  getVisibleAttributes(): IItemAttribute[] {
    const restrictions = this.item.attributes.filter(attr => attr.label === 'Restrictions')[0];
    this.item.attributes.push(this.item.attributes.splice(this.item.attributes.indexOf(restrictions), 1)[0]);
    return this.item.attributes.filter(a => !a.hide);
  }

  getAttributeUri(attributeLabel: string): string {
    if (attributeLabel === 'Condition') {
      return 'assets/images/new-item-details/Condition.svg';
    } else if (attributeLabel === 'Warranty') {
      return 'assets/images/new-item-details/Warranty.svg';
    } else if (attributeLabel === 'Packaging') {
      return 'assets/images/new-item-details/Packaging.svg';
    } else if (attributeLabel === 'Restrictions') {
      return 'assets/images/new-item-details/restrictions.svg';
    } else if (attributeLabel === 'Price Drop') {
      return 'assets/images/new-item-details/price-drop-item-green.svg';
    } else if (attributeLabel === 'Just Launched') {
      return 'assets/images/new-item-details/rocket-item-red.svg';
    } else if (attributeLabel === 'Today\'s Deals') {
      return 'assets/images/new-item-details/todays-specials-purple.svg';
    } else {
      return 'assets/images/icons/noimage.svg';
    }
  }

  onHandInTransit(): boolean {
    this.availableToSellItem = this.item.inventory.onHand + this.item.inventory.enRoute;
    return this.availableToSellItem >= 300;
  }

  selectText(event: any): void {
    event.target.select();
  }

  copyText(val: string): void {
    event.stopPropagation();
    this.utilService.copyTextToClipboard(val);
    this.copied = 'Copied!';
  }

  copyState(): void {
    this.copied = 'Copy';
  }

  calculateInventoryEnRoute(): string {
    if (this.user_role === 'BUYER') {
      if (this.item.inventory.enRoute + this.item.inventory.onHand > 300) {
        return (300 - this.item.inventory.onHand).toString() + '+';
      } else {
        return (this.item.inventory.enRoute).toString() + '';
      }
    } else {
      return (this.item.inventory.enRoute).toString() + '';
    }
  }

  editItemQty(): void {
    event.stopPropagation();
    this.loading = false;
    this.item.previousQuantityAdded = this.item.qty;
    this.item.added = false;
    this.item.beingEdited = true;
  }

  findCartItem(): number {
    for (let i = 0; i < this.cartService.cartList.length; i++) {
      if (this.item.id === this.cartService.cartList[i].item.id) {
        return this.cartService.cartList[i].lineSequence;
      }
    }
  }

  /**
 * @param  {models.IItem} item
 * @returns void
 */
  updateItemPrice(item: models.IItem): void {
    if (!item.qty || item.qty === 0) {
      item.qty = this.item.previousQuantityAdded;
      return;
    }
    this.itemsService.updateItemPrice(item);
  }

  prepareErrorMessages(error400: string): void {
    const newError = environment.errorMapping.filter(c => error400.startsWith(c.server));

    if (newError && newError.length > 0) {
      this.notEnoughQtyMessage = newError[0].client.message;
      this.notEnoughQtyTitle = newError[0].client.title;
      this.itemListErrorModal.bodyText = newError[0].client.message;
      this.itemListErrorModal.title = newError[0].client.title;
      this.itemListErrorModal.isShown = true;
      this.onError.emit(this.itemListErrorModal);
    } else {
      this.notEnoughQtyTitle = 'Quantity Error';
      this.notEnoughQtyMessage = error400;
      this.itemListErrorModal.bodyText = error400;
      this.itemListErrorModal.title = 'Quantity Error';
      this.itemListErrorModal.isShown = true;
      this.onError.emit(this.itemListErrorModal);
    }

    this.item.qty = this.item.previousQuantityAdded;
    this.notEnoughQty = true;
    this.topService.loading = false;
  }

  addToCartBuyer(): void {
    event.stopPropagation();
    if (this.loading) {
      return;
    }

    if (!this.item.qty) {
      this.item.qty = this.item.previousQuantityAdded;
      return;
    }

    this.loading = true;
    if (this.item.qty.toString() === '0') {
      this.notEnoughQtyMessage = 'Quantity must be more than 0.';
      this.notEnoughQtyTitle = 'Quantity Error';
      this.itemListErrorModal.bodyText = 'Quantity must be more than 0.';
      this.itemListErrorModal.title = 'Quantity Error';
      this.itemListErrorModal.isShown = true;
      this.onError.emit(this.itemListErrorModal);

      if (this.item.previousQuantityAdded) {
        this.item.qty = this.item.previousQuantityAdded;
        this.item.added = true;
        this.item.beingEdited = false;
      }

      this.loading = false;
      return;
    }

    if (this.item.inCart) {
      this.cartService.updateCart(this.findCartItem(), this.item.qty, this.item.unitPrice).subscribe(
        data => {
          this.item.added = true;
          this.item.beingEdited = false;
          this.item.previousQuantityAdded = this.item.qty;
          this.item.inCart = true;
          this.cartService.cartList = data;
          this.cartService.updateCartItemData(this.item);
          this.item.reservedQty > 0 ? this.item.added = false : this.item.added = true;
          this.topService.loading = false;
          this.loading = false;
          if (this.openItemDetails === true) {
            this.closeModal();
          }
        },
        err => {
          if (err.status === 400) {
            this.prepareErrorMessages(err.error);
            this.loading = false;
            return;
          }
          this.topService.loading = false;
          this.pageErrorService.errorMessage = err.statusText;
          this.pageErrorService.errorCode = err.status;
          this.router.navigate(['page-error']);
        }
      );
    } else {
      this.cartService.addToCart(this.item.id, this.item.qty).subscribe(
        data => {
          this.item.previousQuantityAdded = this.item.qty;
          this.item.inCart = true;
          this.cartService.cartList = data;
          this.cartService.updateCartItemData(this.item);
          this.item.reservedQty > 0 ? this.item.added = false : this.item.added = true;
          this.topService.loading = false;
          this.loading = false;
          if (this.openItemDetails === true) {
            this.closeModal();
          }
        },
        err => {
          if (err.status === 400) {
            this.prepareErrorMessages(err.error);
            this.loading = false;
            return;
          }

          this.loading = false;
          this.topService.loading = false;
          this.pageErrorService.errorMessage = err.statusText;
          this.pageErrorService.errorCode = err.status;
          this.router.navigate(['page-error']);
        }
      );
    }
  }


  hideNegative(): boolean {
    return this.user_role === 'BUYER' && this.item.inventory.onHand < 1 && this.item.inventory.enRoute < 1;
  }

  checkMoreThanOnHand(): boolean {
    return this.user_role === 'BUYER' && this.item.inventory.onHand >= 300;
  }

  checkMoreThanEnRoute(): boolean {
    return this.user_role === 'BUYER' && this.item.inventory.enRoute >= 300 && this.item.inventory.onHand === 0;
  }

  isAvailableShown(): boolean {
    if (this.item.inventory.availableToSell === 0 && this.item.inventory.enRoute === 0 && this.item.inventory.onHand === 0) {
      return true;
    }

    return this.item.inventory.onHand && (!this.item.inventory.enRoute || this.item.inventory.onHand >= 300);
  }

  addToCartGuest(): void {
    this.userService.guestPop();
  }

  openGradingGuide(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.gradingGuideEmitter.emit(this.item);
  }

  submitNotification(isWaitlist): void {
    if (isWaitlist) {
      if (this.item.notificationInfoObj.waitlist) { return; }
      this.item.notificationInfoObj.waitlist = true;
    }
  }

}
