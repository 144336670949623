import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { IVendorPendingSoldItem } from '../../../interface/IVendorPendingSoldItem';
import { VendorService } from '../../../vendor.service';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgClass, CurrencyPipe, DatePipe } from '@angular/common';

@Component({
    selector: 'app-pending-sold-items',
    templateUrl: './pending-sold-items.component.html',
    styleUrls: ['./pending-sold-items.component.scss'],
    standalone: true,
    imports: [NgClass, TooltipModule, CurrencyPipe, DatePipe]
})
export class PendingSoldItemsComponent implements OnInit {
  @Input() item: IVendorPendingSoldItem = {};

  constructor(public vendorService: VendorService) { }

  ngOnInit() {
  }

  getTotalRevenue(): number {
    this.item.totalRevenue = this.item.qty * this.item.cost;
    return this.item.totalRevenue;
  }
}
