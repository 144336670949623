import * as models from './model';
import { IOrderBy } from './IOrderBy';
export interface IRequest {
  attributes?: models.IReqAttribute[];   // need to recheck
  pagination?: models.IPaginationRequest;
  savedFilter?: string;
  searchQuery?: string;
  categoryId?: string;
  navId?: string;
  orderBy?: IOrderBy;
  exwPoint?: number;
  inStock?: boolean;
  price?: models.IMinMaxPrice;
}
