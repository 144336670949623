import { map } from 'rxjs/operators';
import { Injectable, Input, Directive } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { environment } from '../../../environments/environment';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { PageErrorService } from '../../page-error/page-error.service';
import { TopService } from './top.service';
import { Category } from '../../buyer/interfaces/Category';
import { PubSubService } from '../../core/pubsub.service';
import { SharedSource } from '../../core/shared-source';
import { IPaginationResponse } from '../../buyer/interfaces/IPaginationResponse';

import * as models from '../../buyer/interfaces/model';

import { ITopNav } from '../../buyer/interfaces/ITopNav';
import { SharedService } from '../shared.service';
import { LeftCategoriesService } from './left-categories.service';
import { UserService } from '../../user/user.service';
import { NotificationService } from './notification.service';
import { IItem, INav, IRequest } from '../../buyer/interfaces/model';
import { IPastPurchaseRequest } from '../../buyer/interfaces/IPastPurchaseRequest';
import { IPastPurchaseResponse } from '../../buyer/interfaces/IPastPurchaseResponse';
import { IJustSoldQuickFilter } from '../interfaces/IJustSoldQuickFilter';
import { AttrtypeEnum } from '../../buyer/enums/AttrTypeEnum';
import { AttrDataTypeEnum } from '../../buyer/enums/AttrDataTypeEnum';
import { ItemFilterService } from '../item-filter/item-filter.service';
import { GuestService } from './guest.service';
import { IManufacturerNavigation } from '../../buyer/interfaces/IManufacturerNavigation';
import { IExw } from '../../buyer/interfaces/IExw';
import { UtilityService } from '../../core/utility.service';
import { sortTypesEnum } from '../enums/sort-types';
import { MappedUserFilter } from '../../buyer/interfaces/MappedUserFilter';
import { IOrderBy } from '../../buyer/interfaces/IOrderBy';
import * as uuid from 'uuid';
import { NotificationTypesEnum } from '../interfaces/INotificationDetail';

@Directive()
@Injectable()
export class ItemsService {
  @Input() searchString: string;
  selectedUrlFilterId = '';
  private _navigationTree: ITopNav[];
  hideText: boolean;
  lastPage: 1;
  attributesData: models.Attribute[] = [];
  categoriesData: models.Category[] = [];
  conditionAttributeValues: models.AttributeValue[] = [{
    value: 'All Conditions', userValue: 'no',
    name: ''
  }];
  conditionId = '';
  conditionValues: string[] = [];
  conditionMapping = null;
  itemsData: models.IItem[];
  itemsRequest: models.IRequest = this.getEmptyRequestPayload();
  itemsRequestHelper: models.IRequest = {};
  loading = false;
  private _manufacturersData: models.Attribute[] = [];
  currentSort: sortTypesEnum = sortTypesEnum.none;
  scrollToTopVar = false;
  manufacturerSelected: string;
  selectedTopAttribute: string;
  subCategoryChosen: models.SubCategory;
  topFilters: ITopNav[];
  visualOrder: models.VisualFilter[] = [];
  paginationInfo: IPaginationResponse;
  isJustSoldUrl = false;
  private _isLandingPage = true; // false;
  isPastPurchase = false;
  isNotificationCenter = false;
  isItemsList = false;
  selectedChildNav: ITopNav = null;
  copied = 'Copy';

  // for top filter functionallity
  currentManufacturerNav;
  private _pageNavigationByManufaturers: IManufacturerNavigation[];
  currentNavigation: ITopNav;
  // prevent the manufacturers request to update
  // manufacturers for current filter
  skipManufacturers = false;
  skipEXW = false;
  skipConditions = false;

  pastPurchaseQtySort = false;
  pastPurchasePriceSort = false;
  pastPurchaseDateSort = false;

  pastPurchasesManuSelected = '';


  // so we dont call navigation api if we are waiting on a response
  isRequestInAir = false;

  itemsSubscription: Subscription;
  itemsReady = false;
  private _isCampainUrl;

  // exw
  private _exwPoints: IExw[];
  showExwPopUp = false;
  private _selectedItemExwPopup: models.IItem;
  private _showFilterComponent = true;

  private _userFilterModalOpen = false;

  set userFilterModalOpen(val) {
    this._userFilterModalOpen = val;
  }

  get userFilterModalOpen() {
    return this._userFilterModalOpen;
  }

  public _allAttributes: models.Attribute[];
  private _allCategories: Category[];
  public _allExwPoints: IExw[];

  set allAttributes(val) {
    this._allAttributes = val;
  }

  get allAttributes() {
    return this._allAttributes || this.attributesData;
  }

  set allCategories(val) {
    this._allCategories = val;
  }

  get allCategories() {
    return this._allCategories || this.categoriesData;
  }

  set allExwPoints(val) {
    this._allExwPoints = val;
  }

  get allExwPoints() {
    return this._allExwPoints || this.exwPoints;
  }

  // React to Navigations change
  public topNavigationsObservable: INav[];
  public leftNavigationsObservable: INav[];

  constructor(
    private http: HttpClient,
    private pageErrorService: PageErrorService,
    private topService: TopService,
    private router: Router,
    private pubSubService: PubSubService,
    private sharedService: SharedService,
    private leftCategoriesService: LeftCategoriesService,
    public notificationService: NotificationService,
    public guestService: GuestService,
    private _userService: UserService,
    public itemFilterService: ItemFilterService,
    private utilService: UtilityService) {

    this.pubSubService.sharedSubject.subscribe(
      mySharedValues => {
        if (mySharedValues.name === SharedSource.navigationsDataChanged) {
          this.topNavigationsObservable = mySharedValues.data['top'];
          this.leftNavigationsObservable = mySharedValues.data['left'];
          this.topService.topFilters = mySharedValues.data['top'];
          this.navigationTree = mySharedValues.data['left'];

          let navResult: ITopNav[] = null;

          for (let tree of this.navigationTree) {
            navResult = this.getSelectedNavigationByFilterId(tree, this.currentNavigation);
            if (navResult)
              break;
          }

          let [selectedParentNavigation, selectedNavigation] = navResult ?? [this.currentNavigation, this.currentNavigation];
          if (!selectedNavigation) {
            selectedNavigation = (this.topService.topFilters && this.currentNavigation) ? this.topService.topFilters
              .find(p => p.filterId === this.currentNavigation.filterId) : undefined;
          }

          if (selectedNavigation && !this.topService.searchString) {
            this.currentNavigation = selectedNavigation;
            this.itemFilterService.selectedZeroLevel = selectedParentNavigation;
          }

          if (this.topService.searchString) {
            this.pubSubService.sharedSubject.next({ name: SharedSource.itemsListChanged, data: true });
            this.topService.loading = false;
          }
        }

        if (mySharedValues.name === SharedSource.filterManufacturersDataChanged) {
          this.itemFilterService.allManufacturers = <models.Attribute[]>mySharedValues.data;
          if (mySharedValues.data !== null) {
            this.initializeManufacturerData(<models.Attribute[]>mySharedValues.data);
          }
          this.pubSubService.sharedSubject.next({ name: SharedSource.itemsListChanged, data: true });
          this.topService.loading = false;
        }

        if (mySharedValues.name === SharedSource.manufacturersDataChanged) {
          this.itemFilterService.allReactiveManufacturers = mySharedValues.data;
          this.itemFilterService.allManufacturers = mySharedValues.data;
          if (this.itemFilterService.selectedManufacturer) {
            this.itemFilterService.selectedManufacturer = this.itemFilterService.allReactiveManufacturers.find(v => v.id == this.itemFilterService.selectedManufacturer?.id)
            this.pubSubService.sharedSubject.next({ name: SharedSource.itemsListChanged, data: true });
            this.topService.loading = false;
          }
        }

        if (mySharedValues.name === SharedSource.manufacturersBySavedFilterDataChanged) {
          this.initializeManufacturerData(mySharedValues.data)
          this._pageNavigationByManufaturers = mySharedValues.data;
          this.pubSubService.sharedSubject.next({ name: SharedSource.itemsListChanged, data: true });
          this.topService.loading = false;
        }

        if (mySharedValues.name === SharedSource.outletNavigationsDataChanged) {
          this.currentManufacturerNav = mySharedValues.data.navChilds;
          if (topService.isMobile)
            this.itemFilterService.secondLevelCats = this.currentManufacturerNav.filter(t => !['Todays Deals', 'Just Launched', 'Price Drop'].includes(t.navName));
          if (this.itemFilterService.selectedSecondLevel)
            this.itemFilterService.selectedSecondLevel = mySharedValues.data.navChilds.find(n => n.navId == this.itemFilterService.selectedSecondLevel?.navId);
          if (this.itemFilterService.selectedThirdLevel)
            this.itemFilterService.selectedThirdLevel = this.itemFilterService.selectedSecondLevel.navChilds.find(n => n.navId == this.itemFilterService.selectedThirdLevel?.navId);

          this.topService.setTopFiltersOutletWithoutReaction(mySharedValues.data.navChilds
            .filter(t => ['Todays Deals', 'Just Launched', 'Price Drop'].includes(t.navName)));

          this.pubSubService.sharedSubject.next({ name: SharedSource.itemsListChanged, data: true });
          this.topService.loading = false;
        }

        if (mySharedValues.name === SharedSource.pageNavigationsDataChanged) {
          if (this.isCampainUrl) {
            this.pageNavigationByManufaturers = mySharedValues.data;
          }
          if (this.itemFilterService.isSecondLevelExpanded || this.itemFilterService.isThirdLevelExpanded) {
            if (this.isCampainUrl)
              this.itemFilterService.secondLevelCats = mySharedValues.data.find(n => n.navName == this.itemFilterService.selectedManufacturer?.name)?.navChilds.filter(n => n.count > 0) ?? [];
            else
              this.itemFilterService.secondLevelCats = mySharedValues.data.find(n => n.filterId == this.itemFilterService.selectedManufacturer?.id)?.navChilds.filter(n => n.count > 0) ?? [];
            if (this.itemFilterService.selectedSecondLevel)
              this.itemFilterService.selectedSecondLevel = this.itemFilterService.secondLevelCats.find(value => value.navId === this.itemFilterService.selectedSecondLevel?.navId);
            if (this.itemFilterService.selectedThirdLevel && this.itemFilterService.selectedThirdLevel)
              this.itemFilterService.selectedThirdLevel = this.itemFilterService.selectedSecondLevel?.navChilds.find(n => n.navId == this.itemFilterService.selectedThirdLevel?.navId);
          }

          this.pubSubService.sharedSubject.next({ name: SharedSource.itemsListChanged, data: true });
          this.topService.loading = false;
        }
      }
    );
  }

  set showFilterComponent(v) {
    if (!v) {
      /**
       hack to destroy the component on dynamic routes
       when the routes are not defined, angular will not destroy the component
       so it wont reset properties
       */
      setTimeout(() => {
        this._showFilterComponent = true;
      }, 5);
    }
    this._showFilterComponent = v;
  }

  get showFilterComponent() {
    return this._showFilterComponent;
  }

  set selectedItemExwPopup(val) {
    this._selectedItemExwPopup = val;
  }

  get selectedItemExwPopup() {
    return this._selectedItemExwPopup;
  }

  set navigationTree(val) {
    this._navigationTree = val.filter(nav => {
      if (nav.count > 0) {
        nav.navChilds = nav.navChilds ? nav.navChilds.filter(navCh => navCh.count > 0 && navCh.url) : null;
        return true;
      }
      return false;
    });
  }

  get navigationTree() {
    return this._navigationTree;
  }
  set isLandingPage(val) {
    this._isLandingPage = val;
  }

  get isLandingPage() {
    return this._isLandingPage;
  }

  get manufacturersData() {
    return this._manufacturersData;
  }

  set isCampainUrl(val) {
    if (val) {
      // guest campains can be in the navtree too
      this._isCampainUrl = !this.isInNavigationTree();
    } else {
      this._isCampainUrl = val;
    }
  }

  get isCampainUrl() {
    return this._isCampainUrl;
  }

  set pageNavigationByManufaturers(values) {
    if (this.isCampainUrl) {
      this._pageNavigationByManufaturers = (this.itemFilterService.allManufacturers && this.itemFilterService.allManufacturers.length) ?
        values.map(mNav => {
          mNav.manufacturerId = this.itemFilterService.allManufacturers.find(m => m.name === mNav.navName)?.id;
          return mNav;
        }) : [];
    } else {
      this._pageNavigationByManufaturers = values.map(mNav => {
        mNav.manufacturerId = mNav.filterId;
        return mNav;
      });
    }
  }

  get pageNavigationByManufaturers() {
    return this._pageNavigationByManufaturers;
  }

  set exwPoints(val) {
    this._exwPoints = [];
    if (val && val.length) {
      val.unshift({ text: 'All EXW', value: -1, count: 0 });
    }
    this._exwPoints = val;
  }

  get exwPoints() {
    if (!this._exwPoints) { return [{ text: 'All EXW', value: -1, count: 0 }]; }
    return this._exwPoints;
  }

  get currentURL() {
    return this.router.url.replace(/\/buyer\/|\/guest\/|^\//g, '').split('?')[0];
  }

  private getSelectedNavigationByFilterId(node: ITopNav, currentNavigation: ITopNav, currentNavigationParent?: ITopNav): ITopNav[] {
    let childNavResult: ITopNav[] = null;

    if (node?.filterId === currentNavigation?.filterId) {
      if (!currentNavigationParent) currentNavigationParent = node;
      return [currentNavigationParent, node];
    }

    if (node?.navChilds && node?.navChilds.length > 0) {
      for (let childNode of node?.navChilds) {
        childNavResult = this.getSelectedNavigationByFilterId(childNode, currentNavigation, node);
        if (childNavResult)
          return childNavResult;
      }
    }
  }

  selectAttrAndGetItems(topFilter: ITopNav): void {
    this.isJustSoldUrl = false;
    this.isLandingPage = false;
    this.isPastPurchase = false;
    this.isNotificationCenter = false;
    this.selectedChildNav = undefined;
    this.selectedUrlFilterId = topFilter.filterId;
    this.selectedTopAttribute = topFilter.navName;
    this.topService.searchString = '';
    this.getItemsBySavedFilter(topFilter.filterId);

    if (!this.skipManufacturers) {
      // to get top header manufacturers breakdown per page
      this.getPageNavigationWithManufacturers(topFilter.navId).subscribe(res => {
        this.pageNavigationByManufaturers = res;
      },
        err => console.error('Error with pageNavigation endpoint ', err)
      );
    }

    this.leftCategoriesService.parentNavName = '';
    this.leftCategoriesService.childNavName = '';
    this.notificationService.highlight = false;
  }

  getItemsBySavedFilter(savedFilterId: string): void {
    if (this.isPastPurchase) {
      // this will make the past purchase part in buyer home show up
      this.selectedTopAttribute = 'Past Purchases';
      // this is necessary so that no top categories is highlighted when we are on the past purchase page
      this.leftCategoriesService.parentNavName = '';
      this.leftCategoriesService.childNavName = '';
      this.resetLeftNav();
      return;
    } else if (this.isJustSoldUrl) {
      this.selectedTopAttribute = 'Just Sold';
      this.leftCategoriesService.parentNavName = '';
      this.leftCategoriesService.childNavName = '';
      this.notificationService.highlight = true;
      this.sharedService.isAnimationTicking = false;
      this.resetLeftNav();
      setTimeout(() => {
        this.sharedService.isAnimationTicking = true;
      }, 2000);
      return;
    } else if (this.isNotificationCenter) {
      this.selectedTopAttribute = 'Notification Center';
      this.leftCategoriesService.parentNavName = '';
      this.leftCategoriesService.childNavName = '';
      this.notificationService.highlight = true;
      this.sharedService.isAnimationTicking = false;
      this.resetLeftNav();
      return;
    }
    this.itemsRequest.savedFilter = savedFilterId;

    if (savedFilterId === 'default') {
      this.removeTopActiveLink();
    }

    this.itemsRequest.categoryId = null;
    this.topService.searchString = null;
    // this.resetSelectedManufacturers();
    // this.resetSelectedConditions();

    this.getItems();
  }

  getPastPurchases(payload: IPastPurchaseRequest): Observable<IPastPurchaseResponse> {
    const restapiverb = 'sales/pastPurchases';
    const url = environment.buyerPortalBaseUrl + restapiverb;
    return this.http.post<IPastPurchaseResponse>(url, payload);
  }

  resetLeftNav(savedFilterId?: string): void {
    this.itemsRequest.savedFilter = null;
    this.deleteTagAttributes();
    this.resetSelectedManufacturers();
    this.resetSelectedConditions();
    this.itemsRequest.categoryId = null;
  }

  resetSelectedManufacturers(): void {
    const filteredAttributes = this.itemsRequest.attributes.filter(attr => !(attr.attrType === AttrtypeEnum.manufacturer));

    this.itemsRequest.attributes = filteredAttributes;

    if (this.manufacturersData && this.manufacturersData.length) {
      for (const m of this.manufacturersData) {
        m.selected = false;
      }

      this.manufacturersData[0].selected = true;
    }
  }

  resetSelectedConditions(): void {
    const filteredAttributes = this.itemsRequest.attributes.filter(attr => !(attr.attrType === AttrtypeEnum.vendorItem &&
      attr.attrDataType === AttrDataTypeEnum.multiple));

    this.itemsRequest.attributes = filteredAttributes;

    if (this.conditionAttributeValues) {
      for (const c of this.conditionAttributeValues) {
        c.userValue = 'no';
      }
    }
  }

  getItems(): void {
    // Cancel previous request if any
    if (this.itemsSubscription) {
      this.itemsSubscription.unsubscribe();
      this.itemsSubscription = undefined;
    }

    this.itemsReady = false;
    this.lastPage = 1;
    this.topService.loading = true;

    if (!this.itemFilterService.userFilter) {
      this.prepareRequest();
    }
    this.topService.showMobileSearch = false;
    this.scrollToTopVar = true;

    this.itemsRequest.pagination = {
      pageSize: environment.itemsInPage,
      pageNumber: this.paginationInfo ? this.paginationInfo.currentPage : 1
    };

    this.itemsRequest.searchQuery = null;
    if (this.topService.searchString) {
      this.itemsRequest.searchQuery = this.topService.searchString;
    } else if (!this.router.url.match(/cart/) && !this.router.url.match(/live_offers/)) {
      // dont update url if we are searching or cart
      this.updateUrl();
    }

    // Default sort if none defined
    if (this.currentSort === sortTypesEnum.none) {
      if (environment.currentDeal.displayLabel === this.selectedTopAttribute) {
        this.itemsRequest.orderBy = { orderByName: 'price', sortDirection: 'desc' };
        this.currentSort = sortTypesEnum.price;
      } else if ('Price Drop' === this.selectedTopAttribute) {
        this.itemsRequest.orderBy = { orderByName: 'pricedrop', sortDirection: 'desc' };
        this.currentSort = sortTypesEnum.pricedrop;
      } else if ('Just Launched' === this.selectedTopAttribute) {
        this.itemsRequest.orderBy = { orderByName: 'justlaunched', sortDirection: 'desc' };
        this.currentSort = sortTypesEnum.justlaunched;
      } else {
        this.itemsRequest.orderBy = null;
      }
    }

    this.itemsRequestHelper = JSON.parse(JSON.stringify(this.itemsRequest));
    const items = this.http.post(environment.buyerPortalBaseUrl + 'items/values', this.itemsRequest);
    const attributes = this.http.post(environment.buyerPortalBaseUrl + 'items/filter/attributes', this.itemsRequest);
    const categories = this.http.post(environment.buyerPortalBaseUrl + 'items/filter/categories', this.itemsRequest);
    // if you are in a subcategory some of the manufactureres will disapear
    // we dont want that now with this ui
    const manufacturers = (!this.skipManufacturers) ?
      this.getManufactures(this.itemsRequest) //this.http.post(environment.buyerPortalBaseUrl + 'items/filter/manufacturers', this.itemsRequest)
      : of(null);


    const visualOrder = this.http.post(environment.buyerPortalBaseUrl + 'items/filter/visualOrder', this.itemsRequest);
    const exw = (!this.skipEXW) ?
      this.http.post<IExw[]>(environment.buyerPortalBaseUrl + 'items/filter/exwPoints', this.itemsRequest) : of(null);

    const manufacturersBySavedFilter = (this.topService.searchString) ? this.getManufacturersBySavedFilters(null) :
      (this.itemFilterService.selectedZeroLevel) ? (this.itemFilterService.selectedFirstLevel) ?
        this.getManufacturersBySavedFilters(this.itemFilterService.selectedFirstLevel?.filterId) :
        this.getManufacturersBySavedFilters(this.itemFilterService.selectedZeroLevel?.filterId)
        : of(null);
    const pageNavigationsWithManufacturers = (this.itemFilterService.selectedZeroLevel && !this.itemFilterService.userFilter && !this.topService.searchString && !this.itemFilterService.selectedFirstLevel) ?
      this.getPageNavigationWithManufacturers(this.itemFilterService.selectedZeroLevel?.navId)
      : of(null);
    const navigationTree = this.getLeftNavigationsItems();
    const allReactiveManufacturers = this.getAllManufacturers();
    const allOutletNavigations = (this.itemFilterService.isOutletsSinglePage && !this.topService.searchString && !this.itemFilterService.userFilter) ? this.getAllOutletNavigations() : of(null);

    this.itemsSubscription = forkJoin([items, attributes, categories, manufacturers, visualOrder, exw, navigationTree, allReactiveManufacturers, allOutletNavigations, manufacturersBySavedFilter, pageNavigationsWithManufacturers])
      .subscribe(
        res => {

          (res[6]) ? this.pubSubService.sharedSubject.next({ name: SharedSource.navigationsDataChanged, data: res[6] }) : null;
          (res[3]) ? this.pubSubService.sharedSubject.next({ name: SharedSource.filterManufacturersDataChanged, data: res[3] }) : null;
          (res[9]) ? this.pubSubService.sharedSubject.next({ name: SharedSource.manufacturersBySavedFilterDataChanged, data: res[9] }) : null;
          (res[7]) ? this.pubSubService.sharedSubject.next({ name: SharedSource.manufacturersDataChanged, data: res[7] }) : null;
          (res[10]) ? this.pubSubService.sharedSubject.next({ name: SharedSource.pageNavigationsDataChanged, data: res[10] }) : null;
          (res[8]) ? this.pubSubService.sharedSubject.next({ name: SharedSource.outletNavigationsDataChanged, data: res[8] }) : null;

          this.selectedItemExwPopup = undefined;

          if (this.itemsRequest.pagination.pageNumber === 1) {
            this.itemsData = <models.IItem[]>res[0]['values'];
          } else {
            this.itemsData = this.itemsData.concat(<models.IItem[]>res[0]['values']);
          }

          this.itemsData.map(item => {
            item.highlightId = uuid.v4();
            this.mapNotificationInfo(item);
          });

          this.itemsReady = true;
          this.paginationInfo = res[0]['pagination'];
          this.attributesData = <models.Attribute[]>res[1];
          this.categoriesData = <models.Category[]>res[2];

          this.itemsRequest.categoryId = this.getSelectedSubCategory();
          this.skipManufacturers = false;
          this.visualOrder = <models.VisualFilter[]>res[4];
          if (res[5] !== null) {
            this.exwPoints = res[5];
          }

          if (!this.skipConditions) {
            this.setConditionAttributeValues();
          }
          this.setTagAttributes();
          this.updateItemLogo();

          // Reset Saved Filter. It will be set again if navigation is clicked.
          if (!this.isCampainUrl) {
            this.itemsRequest.savedFilter = null;
          }

          this.pubSubService.sharedSubject.next({ name: SharedSource.itemsListFetched, data: res[0]['pagination'] });
          this.itemsSubscription = undefined;
          this.skipConditions = false;

          this.itemsRequestHelper = JSON.parse(JSON.stringify(this.itemsRequest));
        },
        err => {
          this.skipManufacturers = false;
          this.itemsReady = true;
          this.itemsSubscription = undefined;
          this.pageErrorService.errorMessage = err.statusText;
          this.pageErrorService.errorCode = err.status;
          this.router.navigate(['page-error']);
          this.topService.loading = false;
        }
      );
  }

  updatePastPurchaseInItems() {
    this.topService.loading = true;
    this.http.post(environment.buyerPortalBaseUrl + 'items/values', this.itemsRequest)
      .subscribe((itemsRes: { values: models.IItem[] }) => {
        this.itemsData.forEach((item, index) => {
          if (itemsRes.values[index] && itemsRes.values[index]?.itemCode === item.itemCode) {
            item.pastPurchaseInfo = itemsRes.values[index].pastPurchaseInfo;
          }
        });
        this.topService.loading = false;
      })
  }

  getAllManufacturers() {
    const url = environment.buyerPortalBaseUrl + 'items/filter/manufacturers';
    return this.http.post<models.Attribute[]>(url, this.getEmptyRequestPayload());
  }

  getManufacturersBySavedFilters(savedFilter) {
    const url = environment.buyerPortalBaseUrl + 'items/filter/manufacturers';
    let itemRequest = (this.itemFilterService.userFilter) ? this.itemFilterService.userFilter : this.getEmptyRequestPayload();
    let payload: IRequest = { ...itemRequest, savedFilter: savedFilter, orderBy: this.itemsRequest.orderBy, searchQuery: this.topService.searchString };
    return this.http.post<models.Attribute[]>(url, payload);
  }

  getManufactures(request = this.getEmptyRequestPayload()) {
    const url = environment.buyerPortalBaseUrl + 'items/filter/manufacturers';
    return this.http.post<models.Attribute[]>(url, request);
  }

  getAllOutletNavigations() {
    const url = environment.buyerPortalBaseUrl + `items/filter/outletNavigation/${this.itemFilterService.selectedManufacturer.id}`;
    return this.http.get<ITopNav>(url);
  }

  getItemsByPartNumber(partNumber: string) {
    const url = environment.buyerPortalBaseUrl + 'items/values';
    const payload = {
      attributes: [],
      categoryId: null,
      exwPoint: null,
      inStock: false,
      orderBy: null,
      pagination: { pageSize: 9999, pageNumber: 1 },
      pageNumber: 1,
      pageSize: 9999,
      price: null,
      savedFilter: null,
      searchQuery: partNumber
    }
    return this.http.post<models.IItem[]>(url, payload);
  }

  getEmptyRequestPayload() {
    return {
      savedFilter: null,
      categoryId: null,
      attributes: [],
      pagination: { pageSize: 9999, pageNumber: 1 },
      searchQuery: null,
      orderBy: {
        orderByName: 'name',
        sortDirection: 0
      } as unknown as IOrderBy
    } as IRequest;
  }

  callMatchNav(): void {
    // error case for match nav
    if (this.itemsRequest.searchQuery === 'default') {
      this.itemsRequest.searchQuery = null;
      this.itemsRequest.savedFilter = 'default';
    }

    this.http.post<models.MatchNav>(environment.buyerPortalBaseUrl + 'items/filter/matchNav',
      this.itemsRequest).subscribe(
        (data) => {
          // MatchNav returned result we emit two event to update title and left nav
          this.sharedService.breadCrumbsTitle = data.title;
          this.sharedService.navId = data.navigationId;
        }
      );
  }

  updateUrl(navObj?: ITopNav): void {
    if (this.currentNavigation || navObj) {
      // navigate to url most likely on floating nav or campaing
      const replaceWith = (navObj) ? navObj : <any>this.currentNavigation;

      const currentUrlPath = this.router.url.replace('/buyer/', '');
      // this prevents making request again for current url
      if (replaceWith && currentUrlPath !== replaceWith.url) {
        // if guest
        if (this._userService.isGuest) {
          this.router.navigateByUrl('/guest/' + replaceWith.url);
          return;
        }
        this.router.navigateByUrl('/buyer/' + replaceWith.url);
      }
    }
  }


  getSelectedManufacturerValue(): string {
    if (!this.manufacturersData || this.manufacturersData.length === 0) { return ''; }
    const selectedManufacturers = this.manufacturersData.filter(m => m.selected && m.id);
    if (!selectedManufacturers || selectedManufacturers.length === 0) { return ''; }
    if (selectedManufacturers.length === 1) {
      this.manufacturerSelected = selectedManufacturers[0].name + ' (' + selectedManufacturers[0].count + ')';
      return selectedManufacturers[0].id;
    }
    if (selectedManufacturers.length > 1) {
      return 'Multiple';
    }
    return '';
  }

  getTotalManufacturerSelected(): number {
    if (!this.manufacturersData || this.manufacturersData.length === 0) { return 0; }
    const selectedManufacturers = this.manufacturersData.filter(m => m.selected && m.id);

    return selectedManufacturers ? selectedManufacturers.length : 0;
  }

  initializeManufacturerData(manufacturerData: models.Attribute[]): void {
    // for user saved filters saved from outlets, show only that manufacturer
    if (this.itemFilterService.userFilter && this.itemFilterService.isOutletUserFilter) {
      manufacturerData = manufacturerData.filter(m => m.id === this.itemFilterService.userFilter.attributes
        .find(att => att.attrType === AttrtypeEnum.manufacturer)?.values[0]);
    }
    this._manufacturersData = manufacturerData.sort((m1, m2) => {
      return (m2.count > m1.count) ? 1 : (m2.count < m1.count ? -1 : 0);
    });
    /*add first element called All Manufacturer*/
    const newTopElement = { count: 0, logoUrl: '', selected: false, id: '', name: 'All Manufacturers' };
    this.manufacturersData.unshift(newTopElement);

    if (this.getTotalManufacturerSelected() === 0) {
      this.manufacturersData[0].selected = true;
    }
  }

  getManufacturersBreakDownById(manufacturerId: string, secondSubCat?: string) {
    const url = environment.buyerPortalBaseUrl + `items/filter/outletNavigation/${manufacturerId}`;
    this.http.get<ITopNav>(url)
      .subscribe(res => {
        this.currentManufacturerNav = res.navChilds;
        this.itemFilterService.secondLevelCats = res.navChilds.filter(n =>
          (n.count > 0 && !['Todays Deals', 'Just Launched', 'Price Drop'].includes(n.navName)));

        // to pre-select cats in outlets
        if (secondSubCat) {
          // try to find parent of this subcategory
          this.itemFilterService.selectedSecondLevel = res.navChilds
            .find(nav => nav.navChilds.some(navChild => navChild.navName.toLowerCase() === secondSubCat.toLowerCase()));
          if (this.itemFilterService.selectedSecondLevel) {
            // asign second level
            this.itemFilterService.selectedThirdLevel = this.itemFilterService.selectedSecondLevel.navChilds
              .find(n => n.navName.toLowerCase() === secondSubCat.toLowerCase());

            this.itemFilterService.isThirdLevelExpanded = true;
            this.skipManufacturers = true;
            this.prepareRequest();
            if (this.itemFilterService.selectedThirdLevel) {
              this.selectAttrAndGetItems(this.itemFilterService.selectedThirdLevel);
            } else {
              this.selectAttrAndGetItems(this.itemFilterService.selectedSecondLevel);
            }
          } else {
            // sub cat not found prob 'All Dell', select manufacturer only
            this.setSelectedManufacturer(manufacturerId, true, true);
          }
        }

        this.topService.topFiltersOutlet = this.currentManufacturerNav
          .filter(t => ['Todays Deals', 'Just Launched', 'Price Drop'].includes(t.navName));
      },
        err => console.error('Error with outletNavigation endpoint ', err));
  }

  getPageNavigationWithManufacturers(navId: string) {
    const url = environment.buyerPortalBaseUrl + `items/filter/pageNavigation/${navId}`;
    return this.http.get<IManufacturerNavigation[]>(url);

  }

  getSelectedSubCategory(): string {
    const selectedCategory = this.categoriesData.find(c => c.subCategories.some(sc => sc.selected));

    if (!selectedCategory) {
      return '';
    }
    return selectedCategory.subCategories.find(sc => sc.selected).id;
  }


  removeTopActiveLink(): void {
    this.selectedTopAttribute = '';
  }

  setConditionAttributeValues(): void {

    this.conditionAttributeValues = [{
      value: 'All Conditions', userValue: 'no',
      name: ''
    }];

    const conditionAttributeId = this.visualOrder.find(vo => vo.name === 'condition');
    if (!conditionAttributeId) { return; }

    if (this.conditionId === '') {
      this.conditionId = conditionAttributeId.globalAttributes[0];
    }

    const attr = this.attributesData.find(ad => ad.id === this.conditionId);
    for (const av of attr.attributeValues) {
      this.conditionAttributeValues.push(av);
    }
  }

  setTagAttributes(): void {
    this.deleteTagAttributes();

    // select top categories from the server if any
    // these are 'Todays Deals', 'Price Drop' or 'Just Launched'
    const selectedTagAttributes = this.attributesData.filter(a => a.attrType === 2 && a.attrDataType === 0 && a.selected);
    if (!selectedTagAttributes || selectedTagAttributes.length === 0) { return; }

    selectedTagAttributes.forEach(ta => {
      this.itemsRequest.attributes.push({
        attrType: AttrtypeEnum.vendorItem,
        attrDataType: AttrDataTypeEnum.tag,
        id: ta.id
      });
    });
  }

  // Here we remove top categories if any
  deleteTagAttributes(): void {

    // Delete Existing Tag Attributes
    if (this.itemsRequest.attributes) {

      // these are 'Todays Deals', 'Price Drop' or 'Just Launched'
      const existingTagAttributes = this.itemsRequest.attributes
        .filter(ta => ta.attrType === AttrtypeEnum.vendorItem && ta.attrDataType === AttrDataTypeEnum.tag);

      if (existingTagAttributes && existingTagAttributes.length > 0) {
        existingTagAttributes.forEach(eta => {
          const existingAttr = this.itemsRequest.attributes.find(a => a.id === eta.id);
          if (existingAttr && existingAttr.id) {
            this.itemsRequest.attributes.splice(this.itemsRequest.attributes.indexOf(existingAttr), 1);
          }
        });
      }
    } else {
      this.itemsRequest.attributes = [];
    }
  }

  updateItemLogo(): void {
    for (var i = (this.paginationInfo.currentPage - 1) * environment.itemsInPage; i < this.itemsData.length; i++) {
      if (this.itemsData[i].manufacturerLogoUrl !== '' && this.itemsData[i].manufacturerLogoUrl != null) {
        this.itemsData[i].manufacturerLogoUrl = environment.imageBaseUrl + this.itemsData[i].manufacturerLogoUrl;
      } else {
        this.itemsData[i].manufacturerLogoUrl = environment.imageBaseUrl + environment.imageDefaultLogoUrl;
      }
    }
  }

  updateItemPrice(item: models.IItem) {
    for (let i = 0; i < item.prices.length; i++) {
      if (item.prices.length - 1 > i) {
        if (item.qty >= item.prices[i].fromQty && item.qty < item.prices[i + 1].fromQty) {
          item.baseUnitPrice = item.prices[i].unitPrice;
          return;
        }
      } else {
        if (item.qty >= item.prices[i].fromQty) {
          item.baseUnitPrice = item.prices[i].unitPrice;
        } else {
          item.baseUnitPrice = item.prevPrice;
        }
      }
    }
  }

  sortItemsByCurrentType(isAsc: boolean, isMobile?: boolean) {
    if(!this._isCampainUrl) this.itemsRequest.savedFilter = null;
    this.itemsRequest.orderBy = { orderByName: this.currentSort, sortDirection: isAsc ? 'asc' : 'desc' };
    if (!isMobile) {
      this.getItems();
    }
    window.scrollTo(0, 0);
  }

  setSelectedManufacturer(manufacturerId: string, selected: boolean, replace?: boolean, isMobile?: boolean): void {
    // Cancel previous request if any
    if (this.itemsSubscription) {
      this.itemsSubscription.unsubscribe();
      this.itemsSubscription = undefined;
    }

    if (replace) {
      // update selected manufacturer
      for (const manufacturer of this.manufacturersData) {
        manufacturer.selected = manufacturer.id === manufacturerId;
      }
      this.itemsRequest.exwPoint = null;
      this.itemsRequest.savedFilter = null;
    } else {
      const changedManufacturer = this.manufacturersData.find(m => m.id === manufacturerId);
      if (changedManufacturer && changedManufacturer.selected === selected) { return; }
      changedManufacturer.selected = selected;
    }


    // this is for user filters were prepareRequest wont be called
    const newAttributes = this.itemsRequest.attributes.filter(att => !(att.attrType === AttrtypeEnum.manufacturer));
    newAttributes.push({
      attrType: AttrtypeEnum.manufacturer,
      values: [manufacturerId]
    });
    this.itemsRequest.attributes = newAttributes;

    // for campains filterId contains selected manufacturer and main filter
    if (this.isCampainUrl && this.pageNavigationByManufaturers && this.itemFilterService.selectedManufacturer) {
      const manuFilter = this.pageNavigationByManufaturers.find(pNav => pNav.manufacturerId === manufacturerId);
      this.itemsRequest.savedFilter = (manuFilter) ? manuFilter.filterId : null;
    }

    if (!this.itemFilterService.selectedFirstLevel &&
      this.itemFilterService.selectedZeroLevel && !this.itemFilterService.userFilter) {
      this.resetCategories(this.itemFilterService.selectedZeroLevel.filterId)
        .subscribe(cats => {
          this.categoriesData = cats;
          this.itemsRequest.categoryId = this.getSelectedSubCategory();
          if (!isMobile) {
            this.getItems();
          }
        });
      return;
    }

    if (!isMobile) {
      this.getItems();
    }
  }

  addFilterIdIfMissing() {
    // e.g ../outlets/dell/todays_deals or top cat it's neither in attr or savedFiler
    if (this.itemFilterService.selectedZeroLevel && !this.itemFilterService.selectedFirstLevel &&
      (this.itemFilterService.isOutletsSinglePage ||
        (this.itemsRequest.attributes && /* if it's already as an attr dont add save filter */
          !this.itemsRequest.attributes.find(a => a.attrType === 2 && a.attrDataType === 0)))) {

      this.itemsRequest.savedFilter = this.itemFilterService.selectedZeroLevel.filterId;
    }
  }

  setSelectedCondition(conditionName: string, isMobile?: boolean): void {
    const selectedConditions = this.conditionAttributeValues.filter(m => m.userValue.toLowerCase() === 'yes');
    if (selectedConditions.length === 1 && selectedConditions[0].name === conditionName) { return; }

    // update selected manufacturer
    for (const condition of this.conditionAttributeValues) {
      condition.userValue = condition.name === conditionName ? 'yes' : 'no';
    }

    // this.itemsRequest.exwPoint = null;
    this.itemsRequest.savedFilter = null;

    // this is for user filters were prepareRequest wont be called
    const newAttributes = this.itemsRequest.attributes.filter(attr => !(attr.attrType === AttrtypeEnum.vendorItem &&
      attr.attrDataType === AttrDataTypeEnum.multiple));
    if (conditionName != "") {
      newAttributes.push({
        id: this.conditionId,
        attrType: AttrtypeEnum.vendorItem,
        attrDataType: AttrDataTypeEnum.multiple,
        values: [conditionName]
      });

    }

    this.itemsRequest.attributes = newAttributes;

    if (this.isCampainUrl && this.currentNavigation) {
      this.itemsRequest.savedFilter = this.currentNavigation.filterId;
    }

    if (!isMobile) {
      this.getItems();
    }
  }

  initializeManufacturerDataForQuickSort(manufacturers: IJustSoldQuickFilter[]): void {
    if (manufacturers.length === 0) {
      const newTopElement = { count: 0, logoUrl: '', selected: true, id: '', name: 'All Manufacturers' };
      manufacturers.push(newTopElement);
    }
  }

  initializeConditionsDataForQuickSort(conditions: IJustSoldQuickFilter[]): void {
    if (conditions.length === 0) {
      const newTopElement = { count: 0, logoUrl: '', selected: true, id: '', name: 'All Conditions' };
      conditions.push(newTopElement);
    }
  }


  // 2 - global attribute type  ( condition , todays deal etc)
  // attribute type 0 - manufacture
  prepareRequest(): void {
    if (!this.itemsRequest.attributes) {
      this.itemsRequest.attributes = [];
    }

    // Remove manufacturer element from request
    const itemAttribute = this.itemsRequest.attributes.find(a => a.attrType === 0);
    if (itemAttribute) {
      this.itemsRequest.attributes.splice(this.itemsRequest.attributes.indexOf(itemAttribute), 1);
    }

    const conditionAttribute = this.itemsRequest.attributes.find(a => a.attrType === 2 && a.id === this.conditionId);
    if (conditionAttribute) {
      this.itemsRequest.attributes.splice(this.itemsRequest.attributes.indexOf(conditionAttribute), 1);
    }

    const selectedCondition = this.conditionAttributeValues
      ? this.conditionAttributeValues.filter(c => c.userValue.toLowerCase() === 'yes' && c.name !== '')
      : [];

    if (this.itemFilterService.selectedCondition &&
      !selectedCondition.find(c => c.name === this.itemFilterService.selectedCondition.name) &&
      this.itemFilterService.selectedCondition.userValue.toLowerCase() === 'yes' &&
      this.itemFilterService.selectedCondition.name !== '') {
      // if there's a condition selected but not longer on conditionAttributeValues
      // because of filtering
      selectedCondition.push(this.itemFilterService.selectedCondition);
    }

    if (selectedCondition && selectedCondition.length > 0) {
      this.conditionValues = [];
      for (const c of selectedCondition) {
        this.conditionValues.push(c.name);
      }
      // condition attrType: 2, attrDataType: 2
      this.itemsRequest.attributes.push(
        {
          id: this.conditionId,
          attrType: AttrtypeEnum.vendorItem,
          attrDataType: AttrDataTypeEnum.multiple,
          values: this.conditionValues
        }
      );
    }

    const selectedManufacturers = this.manufacturersData ? this.manufacturersData.filter(m => m.selected && m.id) : null;
    if (selectedManufacturers && selectedManufacturers.length > 0) {
      const manufacturerIds: string[] = [];
      for (const m of selectedManufacturers) {
        manufacturerIds.push(m.id);
      }

      // manufacturers attrType: 0, values [..]
      this.itemsRequest.attributes.push(
        {
          attrType: AttrtypeEnum.manufacturer,
          values: manufacturerIds
        }
      );
    }
  }

  showInTransitValue(item: models.IItem, user_role: string): string {
    if (item.inventory.enRoute > 0 && item.inventory.onHand >= 0) {
      if ((user_role === 'SUPER' || user_role === 'SALES')) {
        return (item.inventory.enRoute).toString();
      }
      if (item.inventory.enRoute + item.inventory.onHand > 300) {
        if (item.inventory.enRoute - item.inventory.onHand > 300) {
          return '300+';
        } else {
          return item.inventory.enRoute > 300 ? '300+' : item.inventory.enRoute + '';
        }
      }
      if (item.inventory.enRoute + item.inventory.onHand <= 300) {
        return (item.inventory.enRoute).toString() + '';
      }
    }

    if (item.inventory.enRoute < 0 && item.inventory.onHand > 0 && (user_role === 'SUPER' || user_role === 'SALES')) {
      return (item.inventory.enRoute).toString() + '';
    }

    if (item.inventory.enRoute < 0 && item.inventory.onHand <= 0 && (user_role === 'SUPER' || user_role === 'SALES')) {
      return (item.inventory.enRoute).toString() + '';
    }

    if (item.inventory.enRoute > 0 && item.inventory.onHand < 0
      && (user_role === 'SUPER' || user_role === 'SALES')
    ) {
      return (item.inventory.enRoute).toString() + '';
    }
  }

  showInTransitTitle(item: models.IItem, user_role: string): boolean {
    if (item.inventory.enRoute > 0 && item.inventory.onHand >= 0 && (user_role !== 'SUPER' && user_role !== 'SALES')) {
      return true;
    }

    if (item.inventory.enRoute > 0 && item.inventory.onHand < 0 &&
      item.inventory.onHand + item.inventory.enRoute > 0 && (user_role === 'SUPER' || user_role === 'SALES')) {
      return true;
    }

    if (item.inventory.enRoute < 0 && item.inventory.onHand > 0 &&
      item.inventory.onHand + item.inventory.enRoute > 0 && (user_role === 'SUPER' || user_role === 'SALES')) {
      return true;
    }

    if (item.inventory.enRoute < 0 && item.inventory.onHand <= 0 && (user_role === 'SUPER' || user_role === 'SALES')) {
      return true;
    }

    if (item.inventory.enRoute > 0 && item.inventory.onHand > 0 && (user_role === 'SUPER' || user_role === 'SALES')) {
      return true;
    }

    if (item.inventory.enRoute > 0 && item.inventory.onHand === 0 && (user_role === 'SUPER' || user_role === 'SALES')) {
      return true;
    }

    return false;
  }

  showInStockValueToGrid(item: models.IItem, user_role: string): string {
    if (user_role === 'SUPER' || user_role === 'SALES') {
      return (item.inventory.onHand + item.inventory.enRoute).toString() + '';
    } else {
      if (item.inventory.onHand + item.inventory.enRoute <= 0) {
        return '';
      }
      if (item.inventory.onHand + item.inventory.enRoute > 300) {
        return '300' + '+';
      } else {
        return (item.inventory.onHand + item.inventory.enRoute).toString() + '';
      }
    }
  }

  showInStockValue(item: models.IItem, user_role: string): string {
    // Front end will handle on rebranding
    if (item.inventory.enRoute === 0 && item.inventory.onHand > 0) {
      if (item.inventory.onHand <= 300) {
        return (item.inventory.onHand).toString() + '';
      } else {
        if (item.inventory.onHand > 300) {
          if (user_role !== 'SUPER' && user_role !== 'SALES') {
            return ('300' + '+');
          } else {
            return item.inventory.onHand.toString() + '';
          }
        }
      }
    }

    if (item.inventory.enRoute > 0 && item.inventory.onHand === 0) {
      return '';
    }

    if (item.inventory.enRoute > 0 && item.inventory.onHand > 0) {
      if (item.inventory.onHand > 0 && item.inventory.onHand <= 300) {
        return (item.inventory.onHand).toString() + '';
      }

      if (item.inventory.onHand > 300) {
        if (user_role !== 'SUPER' && user_role !== 'SALES') {
          return ('300' + '+');
        } else {
          return item.inventory.onHand.toString() + '';
        }
      }

      if (item.inventory.onHand <= 300) {
        return (item.inventory.onHand).toString() + '';
      }
    }

    if (item.inventory.enRoute > 0 && item.inventory.onHand < 0 && (user_role === 'SUPER' || user_role === 'SALES')) {
      return (item.inventory.onHand).toString() + '';
    }

    if (item.inventory.enRoute > 0 && item.inventory.onHand < 0 && (user_role !== 'SUPER' && user_role !== 'SALES')) {
      if (item.inventory.onHand + item.inventory.enRoute > 300) {
        return ('300' + '+');
      } else {
        return (item.inventory.onHand + item.inventory.enRoute).toString() + '';
      }
    }

    if (item.inventory.enRoute < 0 && item.inventory.onHand > 0 && (user_role === 'SUPER' || user_role === 'SALES')) {
      return (item.inventory.onHand).toString() + '';
    }

    if (item.inventory.enRoute < 0 && item.inventory.onHand > 0 && (user_role !== 'SUPER' && user_role !== 'SALES')) {
      if (item.inventory.onHand + item.inventory.enRoute > 300) {
        return ('300' + '+');
      }
      if (item.inventory.onHand + item.inventory.enRoute <= 0) {
        return ('');
      }
      return (item.inventory.onHand + item.inventory.enRoute).toString() + '';
    }

    if (item.inventory.enRoute > 0 && item.inventory.onHand < 0 && (user_role !== 'SUPER' && user_role !== 'SALES')
      && item.inventory.enRoute + item.inventory.onHand > 0) {
      return (item.inventory.enRoute + item.inventory.onHand).toString() + '';
    }

    if (item.inventory.enRoute === 0 && item.inventory.onHand < 0 && (user_role !== 'SUPER' && user_role !== 'SALES')
      && item.inventory.enRoute + item.inventory.onHand <= 0) {
      return '';
    }

    if (item.inventory.enRoute === 0) {
      if (item.inventory.onHand > 300) {
        return ('300' + '+');
      }
      return (item.inventory.availableToSell).toString() + '';
    }
  }

  showInStockTitle(item: models.IItem, user_role: string): boolean {
    if (item.inventory.enRoute > 0 && item.inventory.onHand > 0) {
      return true;
    }

    if (item.inventory.enRoute > 0 && item.inventory.onHand < 0 && (user_role === 'SUPER' || user_role === 'SALES')) {
      return true;
    }

    if (item.inventory.enRoute <= 0 && item.inventory.onHand > 0) {
      return true;
    }

    if (item.inventory.enRoute > 0 && item.inventory.onHand === 0) {
      return false;
    }

    if (item.inventory.enRoute < 0 && item.inventory.onHand > 0 &&
      item.inventory.onHand + item.inventory.enRoute > 0 && (user_role === 'SUPER' || user_role === 'SALES')) {
      return true;
    }

    return false;
  }

  selectJustSold() {
    this.topService.loading = true;
    // this will close the left nav
    this.sharedService.navId = null;
    this.isJustSoldUrl = true;
    this.selectedChildNav = undefined;

    this.isLandingPage = false;
    this.isPastPurchase = false;
    this.isNotificationCenter = false;
    this.leftCategoriesService.parentNavName = '';
    this.leftCategoriesService.childNavName = '';
    // this.notificationService.highlight = true;
    this.sharedService.isAnimationTicking = false;
    this.resetLeftNav();
    this.selectedTopAttribute = 'Just Sold';

    setTimeout(() => {
      this.sharedService.isAnimationTicking = true;
    }, 2000);
  }

  clearAllFiltersAndNav() {
    this.itemFilterService.clearAll();
    this.resetSelectedConditions();
    this.resetSelectedManufacturers();
    this.deleteTagAttributes();
    this.itemsRequest.categoryId = null;
    this.initializeManufacturerData([]);
    // reset breakdowns per manufacturer
    this.pageNavigationByManufaturers = [];
    this.currentManufacturerNav = []; // outlets 2nd level cats
    this.itemsRequest.exwPoint = null;
    this.itemsRequest.inStock = false;
    this.skipConditions = false;
    this.currentSort = sortTypesEnum.none;
    this.showFilterComponent = false;
    this.itemsRequest.price = null;

    this.pubSubService.sharedSubject.next({ name: SharedSource.clearAllFilter, data: true });
  }

  getSelectedNav(nav?) {
    let selectedNavigation = (nav) ? nav : this.getSelectedNavByUrl();

    this.skipEXW = false;
    this.clearAllFiltersAndNav();

    if (nav) {
      this.currentNavigation = nav;
      this.selectedUrlFilterId = nav.filterId;
      this.itemFilterService.selectedZeroLevel = nav;
      this.selectAttrAndGetItems(nav);
      return;
    }

    if (selectedNavigation) {
      this.currentNavigation = selectedNavigation;
      // ignore these routes the components have their own logic
      if (['searching'].includes(selectedNavigation.navName)) {
        this.itemFilterService.selectedSearch = this.topService.searchString;
        return;
      }
      this.sharedService.isSearch = false;
      if (['Just Sold', 'Past Purchases'].includes(selectedNavigation.navName)) {
        this.itemFilterService.selectedZeroLevel = selectedNavigation;
        this.isLandingPage = false;
        return;
      }

      this.selectedUrlFilterId = selectedNavigation.filterId;

      // it's a campaing url, just get the items
      if (!this.isInNavigationTree(selectedNavigation.filterId)) {
        this.isLandingPage = false;
        this.currentNavigation = selectedNavigation;
        this.itemFilterService.selectedZeroLevel = selectedNavigation;
        this.selectAttrAndGetItems(selectedNavigation);
        return;
      }
    }

    if (this.isInNavigationTree()) {
      // it is a compound route and we are checkign for child
      // this will be the parent
      selectedNavigation = this.navigationTree.find(p => p.navChilds && p.navChilds
        .some(c => c.filterId === this.selectedUrlFilterId));

      if (selectedNavigation) {
        // this is parent nav
        this.leftCategoriesService.parentNavName = selectedNavigation.navName;
        this.itemFilterService.selectedZeroLevel = selectedNavigation;

        selectedNavigation = selectedNavigation.navChilds.find(c => c.filterId === this.selectedUrlFilterId);

        // child of compound route
        if (selectedNavigation) {
          this.currentNavigation = selectedNavigation;
          this.itemFilterService.selectedFirstLevel = selectedNavigation;
          this.selectAttrAndGetItems(selectedNavigation);
        }

      } else {
        // check to see if it is a parent navigation
        selectedNavigation = (this.navigationTree) ? this.navigationTree
          .find(p => p.filterId === this.selectedUrlFilterId) : undefined;

        if (selectedNavigation) {
          this.currentNavigation = selectedNavigation;
          this.itemFilterService.selectedZeroLevel = selectedNavigation;
          this.selectAttrAndGetItems(selectedNavigation);
        }
      }
      this.currentNavigation = selectedNavigation;
      this.selectedChildNav = selectedNavigation;

      if (selectedNavigation) {
        this.isCampainUrl = false;
      }

      // if (!selectedNavigation && this.isCampainUrl === false ) {
      //   // unknown url
      //   this.router.navigate(['page-error']);
      // }
    }

    if (!selectedNavigation) {

      if (!this.isInNavigationTree() && !this.sharedService.isCorpSite) {
        this.getCampaignNavByUrl();
      }
      // if you get here it's not a search, clear the variable
      this.sharedService.isSearch = false;
      this.topService.searchString = '';
      this.currentNavigation = undefined;
      this.selectedUrlFilterId = undefined;
    }
    return selectedNavigation;
  }

  getSelectedNavByUrl() {
    let selectedFilter;

    switch (this.currentURL) {
      case environment.currentDeal.url:
        this.selectedTopAttribute = environment.currentDeal.displayLabel;
        if (this.topService.topFilters) {
          selectedFilter = this.topService.topFilters.find(f => f.navName === environment.currentDeal.displayLabel);
        } else {
          return;
        }
        break;
      case 'price_drop':
        this.selectedTopAttribute = 'Price Drop';
        if (this.topService.topFilters) {
          selectedFilter = this.topService.topFilters.find(f => f.navName === 'Price Drop');
        } else {
          return;
        }
        break;
      case 'just_launched':
        this.selectedTopAttribute = 'Just Launched';
        if (this.topService.topFilters) {
          selectedFilter = this.topService.topFilters.find(f => f.navName === 'Just Launched');
        } else {
          return;
        }
        break;
      case 'past_purchases':
        // this.selectedTopAttribute = 'Just Launched';
        selectedFilter = { navName: 'Past Purchases', filterId: '0000-0000-0000-0000' };
        break;
      case 'just_sold':
        // this.selectedTopAttribute = 'Just Launched';
        selectedFilter = { navName: 'Just Sold', filterId: '0000-0000-0000-1111' };
        break;
      default:
        // search url
        if (this.currentURL.match(/search|items/)) {
          // same search dont make another request
          if (!this.topService.searchString || this.topService.searchString !== this.itemsRequest.searchQuery) {
            this.sharedService.isSearch = true;
          }
          this.isLandingPage = false;
          return selectedFilter = { navName: 'searching', filterId: '0000-0000-0000-2222' };
        }

    }


    if (selectedFilter) {
      this.isCampainUrl = false;
    }

    return selectedFilter;
  }

  isInNavigationTree(filterId?: string) {

    if (this.navigationTree) {
      const isParent = this.navigationTree.find(p => (p.filterId === filterId || p.url === this.currentURL));

      const isChild = this.navigationTree.find(p => p.navChilds && p.navChilds
        .some(c => (c.filterId === filterId || c.url === this.currentURL)));

      const child = (isChild) ? isChild.navChilds.find(c => c.filterId === filterId || c.url === this.currentURL) : null;

      // if it is in the navigationTree select its id
      this.selectedUrlFilterId = (isChild) ? child.filterId : ((isParent) ? isParent.filterId : null);
      // is not campaing if it is in the navigation tree
      if (this.selectedUrlFilterId) { this.isCampainUrl = false; }
      return isChild || isParent;
    }

    return false;
  }


  getCampaignNavByUrl() {
    // campain url
    this.leftCategoriesService.getCampaingNavByUrl(this.currentURL)
      .subscribe(nav => {
        this.isCampainUrl = !!nav;

        if (!nav) {
          this.router.navigate([`${this._userService.getUrlByRole()}/home`]);
          return;
        }

        if (!this.currentNavigation ||
          this.currentNavigation.navId !== nav.navId) {
          this.itemsRequest.savedFilter = nav.filterId;
          this.getSelectedNav(nav);
        }
      },
        (err) => {
          console.error('Error getting campainUrl', err);
          // this.router.navigate([`${this._userService.getUrlByRole()}/home`]);
        });
  }

  getLeftNavigationsItems() {
    return this.leftCategoriesService.getLeftNavItems();
  }

  navigationTreeAndManus() {
    const manufacturers = this.getAllManufacturers();
    const navigationTree = this.getLeftNavigationsItems()
    this.isRequestInAir = true;

    this._userService.loadingCC++;
    forkJoin([navigationTree, manufacturers]).subscribe(
      res => {
        (res[0]) ? this.pubSubService.sharedSubject.next({ name: SharedSource.navigationsDataChanged, data: res[0] }) : null;
        (res[1]) ? this.pubSubService.sharedSubject.next({ name: SharedSource.manufacturersDataChanged, data: res[1] }) : null;
        this.isRequestInAir = false;

        if (!this.currentNavigation && this.isItemsList) {
          this.getSelectedNav();
        }

        this.sharedService.navigationReady = true;
        this._userService.loadingCC--;
      },
      err => {
        this.isRequestInAir = false;
        console.error('Error getting nav and campain', err);
        this._userService.loadingCC--;
      });
  }

  resetCategories(filterId) {
    const catRequest = {
      savedFilter: filterId,
      categoryId: null,
      attributes: [],
      pagination: { pageSize: 9999, pageNumber: 1 },
      searchQuery: null
    };
    return this.http.post<Category[]>(environment.buyerPortalBaseUrl + 'items/filter/categories', catRequest);
  }

  closeExwPopup(event: Event) {
    event.stopPropagation();

    // for animation if any
    setTimeout(() => {
      this.showExwPopUp = false;
      this.selectedItemExwPopup = undefined;
    }, 500);

  }

  copyState(): void {
    this.copied = 'Copy';
  }

  copyText(event: any, val: string): void {
    event.stopPropagation();
    event.preventDefault();
    this.utilService.copyTextToClipboard(val);
    this.copied = 'Copied!';
  }

  adjustSettingsForHomePage() {
    window.history.replaceState({}, '', `${this._userService.getUrlByRole()}/home${window.location.search}`);
    this.isLandingPage = true;
    this.resetNavigationAndCorpSite();
  }

  resetNavigationAndCorpSite() {
    this.sharedService.isStaticHtml = false;
    this.sharedService.isCorpSite = false;
    document.body.style.paddingTop = '';
    this.sharedService.corporateSiteSelectedLink = 'logo';
    this.currentNavigation = undefined;
    this.clearAllFiltersAndNav();
  }

  transFormFilter(filter: IRequest): MappedUserFilter {
    const resultFilter: MappedUserFilter = {};
    const attributes = (filter && filter.attributes) ? filter.attributes : [];

    const selectedManufacturer = attributes.find(attr => attr.attrType === AttrtypeEnum.manufacturer);
    const selectedCondition = attributes.find(attr => attr.attrType === AttrtypeEnum.vendorItem &&
      attr.attrDataType === AttrDataTypeEnum.multiple);
    const selectedPromotion = attributes.find(attr => attr.attrType === AttrtypeEnum.vendorItem &&
      attr.attrDataType === AttrDataTypeEnum.tag);

    const selectedCategory = this.allCategories.find(c =>
      c.subCategories.some(sc => sc.id === filter.categoryId.toLowerCase()));

    const selectedExw = this.allExwPoints.find(exw => exw.value === filter.exwPoint);


    if (selectedManufacturer) {
      const manuList = this.itemFilterService.allManufacturers || this.manufacturersData;
      resultFilter.selectedManufacturer = manuList
        .find(m => m.id === selectedManufacturer.values[0].toLowerCase());
    }

    if (selectedCondition) {
      const conditions = this.allAttributes.find(att => att.name === 'Condition');
      if (conditions) {
        resultFilter.selectedCondition = conditions.attributeValues
          .find(con => con.name === selectedCondition.values[0]);
      }
    }

    if (selectedCategory) {
      resultFilter.selectedCategory = selectedCategory.subCategories.find(sc => sc.id === filter.categoryId.toLowerCase());
    }

    if (selectedExw) {
      resultFilter.selectedExw = selectedExw;
    }


    if (selectedPromotion && this.allAttributes && this.allAttributes.length && !resultFilter.selectedParentNav) {
      resultFilter.selectedPromotion = this.allAttributes.find(nav => nav.id === selectedPromotion.id);
    }

    if (filter.price && (filter.price.minPrice !== 0 || filter.price.maxPrice !== 10000)) {
      resultFilter.priceStr = `$${filter.price.minPrice} - $${filter.price.maxPrice}`;
    }

    return resultFilter;
  }

  getConditionMapping() {
    this.http.get<any>(environment.buyerPortalBaseUrl + 'items/filter/conditionMapping')
      .subscribe(res => {
        this.conditionMapping = res;
      }, err => {
        console.log('Error getting condition mapping. ', err)
      });
  }

  isItemRestrictedForCountry(item: IItem): boolean {
    let bpCountry = this._userService.defaultBillingCountry;

    if (!item.restrictedCountries || !bpCountry) {
      return false;
    }

    return item.restrictedCountries.includes(bpCountry);
  }

  getItem(itemId: string): Observable<IItem> {
    const url = environment.buyerPortalBaseUrl + `items/v2/${itemId}`;
    return this.http.get<IItem>(url).pipe(
      map((itemResponse: IItem) => {
        itemResponse = this.mapNotificationInfo(itemResponse);
        return itemResponse;
      })
    );
  }

  mapNotificationInfo(item: IItem) {
    item.notificationInfoObj = {
      priceDrop: false,
      moreInventory: false,
      waitlist: false,
      priceDropInitialValue: false,
      moreInventoryInitialValue: false,
      waitlistInitialValue: false
    };

    if (item.notificationInfo) {
      if (item.notificationInfo.includes(NotificationTypesEnum.priceDrop)) {
        item.notificationInfoObj.priceDrop = true;
        item.notificationInfoObj.priceDropInitialValue = true;
      }
      if (item.notificationInfo.includes(NotificationTypesEnum.moreInventory)) {
        item.notificationInfoObj.moreInventory = true;
        item.notificationInfoObj.moreInventoryInitialValue = true;
      }
      if (item.notificationInfo.includes(NotificationTypesEnum.waitlist)) {
        item.notificationInfoObj.waitlist = true;
        item.notificationInfoObj.waitlistInitialValue = true;
      }
    }
    return item;
  }

}