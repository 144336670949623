import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class SessionService {
    _user_role;
    _user_id;
    _selected_order_number;
    _selected_doc_number;
    _new_cart_id;
    _is_customer_selected;
    _selected_customer;
    _partner_id;
    _user_email;
    _company_name;
    _is_logged_in;
    _lead_id;
    _is_notification_called;
    readyToShowSessionError = false;

    _version_countdown;

    _close_credit_card_banner;

    _is_cart_abandonment;

    constructor(private authService: AuthService) {
        this.initializeSessionVariables();
        this.authService.roleChanged.subscribe(role => this.onRoleChanged(role));
    }

    initializeSessionVariables(): void {
        this._user_role = sessionStorage.getItem('user_role');
        this._user_id = sessionStorage.getItem('user_id');
        this._is_logged_in = sessionStorage.getItem('is_logged_in');
        this._lead_id = sessionStorage.getItem('_lead_id');
        this._is_notification_called = sessionStorage.getItem('is_notification_called');
        this._close_credit_card_banner = sessionStorage.getItem('close_credit_card_banner');

        if (this._user_role !== 'SELLER' && this._user_role !== 'ADMIN' && this._user_role !== 'VENDORMGR') {
          this._selected_order_number = sessionStorage.getItem('selected_order_number');
          this._selected_doc_number = sessionStorage.getItem('selected_doc_number');
          this._is_customer_selected = sessionStorage.getItem('isCustomerSelected');
          this._new_cart_id = sessionStorage.getItem('_new_cart_id');
          this._selected_customer = sessionStorage.getItem('selected_customer');
          this._partner_id = sessionStorage.getItem('partner_id');
          this._is_cart_abandonment = sessionStorage.getItem('isCartAbandonment');
        }

        this._user_email = sessionStorage.getItem('user_email');
        this._company_name = sessionStorage.getItem('company_name');

        this._version_countdown = sessionStorage.getItem('version_countdown') || null;
    }

    onRoleChanged(role: string): void {
        this._user_role = role;
    }

    removeSessionItem(itemName: string): void {
        sessionStorage.removeItem(itemName);
    }

    resetSessionVariables(): void {
        this._user_role = '';
        this._selected_order_number = '';
        this._selected_doc_number = '';
        this._new_cart_id = '';
        this._is_customer_selected = '';
        this._selected_customer = '';
        this._partner_id = '';
        this._user_email = '';
        this._company_name = '';
        this._is_logged_in = 'false';
        this._lead_id = '';
        this._close_credit_card_banner = 'false';
        this._is_cart_abandonment = '';
        sessionStorage.clear();
        this.isNotificationCalled = 'true';
    }

    /*
        User Role
    */
    get userRole(): string {
        return this._user_role;
    }

    set userRole(newRole: string) {
        this._user_role = newRole;
        sessionStorage.setItem('user_role', newRole);
    }

    set partnerId(partner_id: string){
        this._partner_id = partner_id;
        sessionStorage.setItem('partner_id', partner_id)
    }
    get partnerId(): string {
        //Vendor Portal does not set this._partner_id
        //But it does call this getter.
        if (!this._partner_id)
            return sessionStorage.getItem('partner_id');

        return this._partner_id;
    }

    get isNotificationCalled(): string {
        return this._is_notification_called;
    }

    set isNotificationCalled(_is_notification_called: string) {
        this._is_notification_called = _is_notification_called;
        sessionStorage.setItem('is_notification_called', _is_notification_called);
    }

    /*
        Lead Id
    */
    get leadId(): string {
        return this._lead_id;
    }

    set leadId(_lead_id: string) {
        this._lead_id = _lead_id;
        sessionStorage.setItem('_lead_id', _lead_id);
    }

    /*
        Logged In
    */
    get isLoggedIn(): string {
        return this._is_logged_in;
    }

    set isLoggedIn(_is_logged_in: string) {
        this._is_logged_in = _is_logged_in;
        sessionStorage.setItem('is_logged_in', _is_logged_in);
    }

    /*
        Selected Order Number
    */
    get selectedOrderNumber(): string {
        return this._selected_order_number;
    }

    set selectedOrderNumber(selectedOrderNumber: string) {
        this._selected_order_number = selectedOrderNumber;
        sessionStorage.setItem('selected_order_number', selectedOrderNumber);
    }

    /*
        Selected Doc Number
    */
    get selectedDocNumber(): string {
        return this._selected_doc_number;
    }

    set selectedDocNumber(selectedDocNumber: string) {
        this._selected_doc_number = selectedDocNumber;
        sessionStorage.setItem('selected_doc_number', selectedDocNumber);
    }

    /*
        New Cart ID
    */
    get newCartId(): string {
        return this._new_cart_id;
    }

    set newCartId(new_cart_id: string) {
        this._new_cart_id = new_cart_id;
        sessionStorage.setItem('newCartId', new_cart_id);
    }

    /*
        Is Customer Selected
    */
    get isCustomerSelected(): string {
        return this._is_customer_selected;
    }

    set isCustomerSelected(is_customer_selected: string) {
        this._is_customer_selected = is_customer_selected;
        sessionStorage.setItem('isCustomerSelected', is_customer_selected);
    }

    /*
        Selected Customer
    */
    get selectedCustomer(): string {
        return this._selected_customer;
    }

    set selectedCustomer(selected_customer: string) {
        this._selected_customer = selected_customer;
        sessionStorage.setItem('selected_customer', selected_customer);
    }

    /*
        Selected Customer
    */
    get userEmail(): string {
        if (!this._user_email) {
            return sessionStorage.getItem('user_email');
        }
        return this._user_email;
    }

    set userEmail(user_email: string) {
        this._user_email = user_email;
        // sessionStorage.setItem('user_email', user_email);
    }

    /*
        Selected Customer
    */
    get companyName(): string {
        return this._company_name;
    }

    set companyName(company_name: string) {
        this._company_name = company_name;
        sessionStorage.setItem('company_name', company_name);
    }

    /*
        New Version Available
    */
    get versionCountdown(): string {
        return this._version_countdown;
    }

    set versionCountdown(time: string) {
        this._version_countdown = time;
        sessionStorage.setItem('version_countdown', time);
    }

    get isBuyer() {
        return sessionStorage.getItem('user_role') === 'BUYER';
    }

    get userCanBuy() {
        return this.isBuyer || (!this.isBuyer && this.isCustomerSelected);
    }

    /*
        Logged In
    */
    get closeCreditCardBanner(): string {
        return this._close_credit_card_banner;
    }

    set closeCreditCardBanner(_close_credit_card_banner: string) {
        this._close_credit_card_banner = _close_credit_card_banner;
        sessionStorage.setItem('close_credit_card_banner', _close_credit_card_banner);
    }

    /*
        Is Cart Abandonment
    */
    get isCartAbandonment(): string {
        return this._is_cart_abandonment;
    }

    set isCartAbandonment(cart_abandonment_url: string) {
        this._is_cart_abandonment = cart_abandonment_url;
        sessionStorage.setItem('isCartAbandonment', cart_abandonment_url);
    }
}
