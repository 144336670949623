import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserRoutingModule } from './user-routing.module';
import { UserComponent } from './user.component';
import { MobileNotificationsComponent } from './mobile-notifications/mobile-notifications.component';
import { UserNavigationComponent } from './user-navigation/user-navigation.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { UserSupportComponent } from './user-support/user-support.component';
import { SharedModule } from '../shared/shared.module';
import { OffersComponent } from './offers/offers.component';
import { OfferListComponent } from './offers/offerlist/offer-list.component';
import { OfferChatMessageComponent } from './offers/offer-chat-message/offer-chat-message.component';


@NgModule({
    imports: [
        CommonModule,
        UserRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        UserComponent,
        MobileNotificationsComponent,
        UserNavigationComponent,
        UserProfileComponent,
        UserSupportComponent,
        OffersComponent,
        OfferListComponent,
        OfferChatMessageComponent
    ],
    providers: []
})
export class UserModule { }
