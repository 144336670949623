<div class="loader" *ngIf="loading"></div>
<div class="biddesk-modal-wrap" *ngIf="biddeskBannerService.isBiddeskModalShown" [class.mobile]="topService.isMobile">
	<div class="biddesk-modal animated" [class.zoomIn]="animateIn" [class.zoomOut]="animateOut">
		<div class="biddesk-modal-header">
			<img src="/assets/images/biddesk-banner/biddesk-logo.svg" height="26" width="145">
			<button type="button" aria-label="Close" class="close" (click)="hideBiddeskModal()"></button>
			<span>
				To make an offer through BidDesk please submit offer and follow the requirements below. If your bid is accepted or countered a member of our team will be in touch shortly. 
			</span>
		</div>
		<div class="biddesk-modal-content">
			<form [formGroup]="formBid">
				<div class="grid-row">
                <div class="grid-col">
                  <div class="control" [class.error]="formErrors.email.required || formErrors.email.validateEmailExtension === false">
                    <label class="control__label" for="inputEmail">Your Email</label>
                    <div>
                      <input type="email" id="inputEmail" placeholder="company@gmail.com" formControlName="email" autocomplete="off"
                      [maxLength]="150">
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid-row">
                <div class="grid-col">
                  <div class="control __part-number" [class.error]="formErrors.partNumber.required || validPartNumber===false">
                    <label class="control__label" for="partNumber">Manufacturer Part Number</label>
                    <div>
                      <input type="text" id="partNumberBidDesk" placeholder="INS351218SA" formControlName="partNumber" autocomplete="off" [maxLength]="150"
                      [attr.disabled]="loading ? true : null">
                      <button type="button" (click)="paste()" *ngIf="!isFirefox"><img src="/assets/images/biddesk-banner/paste.svg">Paste</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid-row">
                <div class="grid-col">
                  <div class="control __price" [class.has-value]="validateIfHasValue('hubxListPrice')" [class.error]="formErrors.hubxListPrice.required || validListPrice===false">
                    <label class="control__label" for="actualPrice">HUBX List Price</label>
                    <div>
                      <input type="number" id="actualPrice" placeholder="" formControlName="hubxListPrice" autocomplete="off">
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid-row">
                <div class="grid-col __offer">
                  <span class="title">My Offer</span>                  
                  <!-- bid exceeds 5% -->
                  <span *ngIf="formErrors.invalidPercentRule">
                    <span class="bid bid-exceeds">
                      Your bid exceeds 5%
                    </span><br>                  
                    <span>
                      Sellers are more likely to reply to offers that average 2% off list price. <br>A discount of 4-5% had a low probability of being accepted.
                    </span>
                  </span>
                  <!-- low acceptance rate 
                  <span *ngIf="formErrors.lowAcceptance">
                    <span class="bid bid-low">
                      Low acceptance rate
                    </span><br>                  
                    <span>
                      Sellers are more likely to reply to offers that average 2% off list price. <br>A discount of 4-5% had a low probability of being accepted.
                    </span>
                  </span>-->
                  <!-- High acceptance rate 
                  <span *ngIf="formErrors.highAcceptance">
                    <span class="bid bid-high" >
                      High acceptance rate
                    </span>
                  </span>-->
                </div>
              </div>
              <div class="grid-row">
                <div class="grid-col _half">
                  <div class="control __price" [class.has-value]="validateIfHasValue('offerPrice')" 
                  [class.error]="formErrors.offerPrice.required || formErrors.invalidPercentRule">
                    <label class="control__label" for="offerPrice">OFFER Price</label>
                    <div>
                      <input type="number" id="offerPrice" placeholder="" formControlName="offerPrice" autocomplete="off">
                    </div>
                  </div>
                </div>
                <div class="grid-col _half">
                  <div class="control" [class.has-value]="validateIfHasValue('offerQty')" [class.error]="formErrors.offerQty.required">
                    <label class="control__label" for="quantity">Quantity</label>
                    <div>
                      <input id="quantity" placeholder="" formControlName="offerQty" autocomplete="off" type="text" mask="0*">
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid-row margin-top">
                <div class="grid-col _half __total" [class.has-value]="totalOffer >= 0" [class.error]="formErrors.invalidTotalOffer">
                	<span class="label">Total Offer:</span>
                	<span class="value">{{ totalOffer === null ? '-' :  (totalOffer | currency: 'USD' : 'symbol-narrow' : '1.0-2') }}</span>
                  <!-- <span class="info" *ngIf="formErrors.invalidTotalOffer">
                    Only offers that with a minimum of $10k<br>OR "take all" offers over 100 units will be considered.
                  </span> -->
                </div>
                <div class="grid-col _half">
                  <button class="submit" type="submit" (click)="onSubmit()"  [disabled]="loading || !isValidForm()" >Submit</button>
                </div>
              </div>
			</form>
		</div>
		<div class="biddesk-modal-footer">
			<div class="biddesk-modal-terms"><!-- [class.error]="formErrors.invalidPercentRule || formErrors.invalidTotalOffer" -->
                <div class="icon">
					<svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
						<path d="m14.0537741 5h-4.23019475l-.00012179 4.79482086-4.39008157-1.69076467-1.43337599 4.04296661 4.52957821 1.5063463-2.89105277 3.7879378 3.53739739 2.5586931 2.73326427-3.9778316 2.8860288 3.9730589 3.4820147-2.5567944-2.8882252-3.7830906 4.6109946-1.5059053-1.4327737-4.04126786-4.5143454 1.69257125zm-.9833376.98658865.0008931 5.23656425 4.9044521-1.8407261.7594295 2.141884-4.9700572 1.6256655 3.1201143 4.0876694-1.8764689 1.3782643-3.1218487-4.2983742-2.95064049 4.2934413-1.90299492-1.3762912 3.11721921-4.0817471-4.88856656-1.6256683.75844712-2.14089735 4.78560874 1.84340425-.0001218-5.24318875z" fill="#626262"/>
					</svg>
				</div>
				<div class="text">
          <span>
            Offers are made on a line item basis and can not be more than 5% off the current list price. Sellers are more likely to reply to offers that average 2% off list price.
            Note: A DISCOUNT OF 5% HAS A LOW PROBABILITY OF BEING ACCEPTED. Only offers that with a minimum of $10k OR "take all" offers over 100 units will be considered.
          </span>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="biddesk-modal-wrap" *ngIf="biddeskBannerService.isBidSubmited">
	<div class="biddesk-modal __submited animated" [class.mobile]="topService.isMobile" [class.zoomIn]="animateSubmitIn" [class.zoomOut]="animateSubmitOut">
		<div class="biddesk-modal-content">
			<div class="animated-icon">
				<img src="/assets/images/biddesk-banner/check-path.svg">
			</div>
			<div class="submited-text">
				<span>Offer Submitted</span>
			</div>
		</div>
	</div>
</div>
