import { Component, OnInit, HostListener } from '@angular/core';
import { ItemsService } from '../services/items.service';
import { TopService } from '../services/top.service';
import { LeftCategoriesService } from '../services/left-categories.service';
import { SharedService } from '../shared.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { IItemManufacturerFilter } from '../../buyer/interfaces/IItemManufacturerFilter';
import { TopCategoriesFileSaverService } from '../../buyer/catalog/top-categories/top-categories-file-saver.service';

@Component({
    selector: 'app-item-quick-sort-base',
    template: '',
    standalone: true
})
export class ItemQuickSortBaseComponent implements OnInit {
  qtySort = false;
  priceSort = false;
  manufacturer = null;
  shouldStick = false;
  showMobileFilter = false;
  conditionWidth = 111;
  manufacturerWidth = 134;
  conditionSelected = 'All Conditions';
  manufacturerSelected = 'All Manufacturers';
  isFiltered = false;
  conditionSavedSelected = 'All Conditions';


  constructor(
    public itemsService: ItemsService,
    public topService: TopService,
    public leftCategoriesService: LeftCategoriesService,
    public sharedService: SharedService) { }

  ngOnInit() {
  }

  checkWidth(target: string): void {
    if (target === 'condition') {
      const element = document.getElementsByClassName('condition-selected');
      this.conditionWidth = element[0].scrollWidth + 24;
    } else if (target === 'manufacturer') {
      const element = document.getElementsByClassName('manufacturer-selected');
      if (element[0].scrollWidth > 0) {
        this.manufacturerWidth = element[0].scrollWidth + 25;
      }
    }
  }

  @HostListener('window:scroll', ['$event'])
  handleScrollEvent(e): void {
    if (window.pageYOffset > 29) {
      this.shouldStick = true;
    } else {
      this.shouldStick = false;
    }
  }
}
