import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-listing-item-preloader',
    templateUrl: './listing-item-preloader.component.html',
    styleUrls: ['./listing-item-preloader.component.scss'],
    standalone: true
})
export class ListingItemPreloaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
