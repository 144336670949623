import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { ItemFilterComponent } from '../../../../shared/item-filter/item-filter.component';
import { CurrentDealPipe } from '../../../../shared/pipes/current-deal.pipe';
import { NgIf, NgSwitch, NgSwitchCase, NgFor } from '@angular/common';

@Component({
    selector: 'app-items-breadcrumbs-mobile',
    templateUrl: './items-breadcrumbs-mobile.component.html',
    styleUrls: ['./items-breadcrumbs-mobile.component.scss'],
    standalone: true,
    imports: [NgIf, NgSwitch, NgSwitchCase, NgFor, CurrentDealPipe]
})
export class ItemsBreadcrumbsMobileComponent extends ItemFilterComponent implements OnInit {
  environment = environment;

  breadcrumbCliked(label: {propName: string, text: string}) {
    switch (label.propName) {
      case 'search': this.resetSearch();
      break;
      case 'zeroLevel':
        if (this.itemFilterService.userFilter) {
          this.reloadSavedFilter();
          return;
        }
        if (!this.outletsService.isManufacturerOutlet()) {
          this.resetOriginalNav(this.itemFilterService.selectedZeroLevel);
        }
      break;
      case 'firstLevel': this.resetOriginalNav(this.itemFilterService.selectedFirstLevel, 1);
      break;
      case 'maufacturer': this.changeManufacturer(this.itemFilterService.selectedManufacturer);
      break;
      case 'secondLevel': this.selectChildNav(this.itemFilterService.selectedSecondLevel, 2);
      break;
      case 'thirdLevel': this.selectChildNav(this.itemFilterService.selectedThirdLevel, 3);
      break;
      case 'condition': this.selectCondition(this.itemFilterService.selectedCondition);
    }
  }

  openSaveFilter(event) {
    event.stopPropagation();
    if (!this.itemFilterService.userFilter) {
      this.openUserFilterModal(event);
      this.itemFilterService.isFilterMobileOpen = false;
      this.itemFilterService.searchesList = false;
    } else {
      this.itemFilterService.searchesList = true;
      this.itemsService.userFilterModalOpen = true;
      this.itemFilterService.isFilterMobileOpen = false;
    }
  }
}
