import { Injectable } from '@angular/core';
import { IJustSoldMessage } from '../interfaces/IJustSoldMessage';
import { SharedService } from '../shared.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { IItem } from '../../buyer/interfaces/IItem';
import { IJustSold } from '../interfaces/IJustSold';
import { IJustSoldPayload, QuickFilterPayload } from '../interfaces/IJustSoldPayload';
import { SessionService } from '../../services/session.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  data: IJustSoldMessage;
  itemsData: IItem[];
  restapiverb = '';
  manufacturerSelected = 'All Manufacturers';
  conditionSelected = 'All Conditions';
  highlight = false;


  constructor(
    private sharedService: SharedService,
    private http: HttpClient
  ) { }

  updateItemLogo(items: (IItem | { item: IItem })[]): void {
    items.forEach(cl => {
      const item = 'item' in cl ? (cl as { item: IItem }).item : (cl as IItem);  
      if (item.manufacturerLogoUrl.indexOf('http') < 0) {
        if (item.manufacturerLogoUrl !== '' && item.manufacturerLogoUrl != null) {
          item.manufacturerLogoUrl = environment.imageBaseUrl + item.manufacturerLogoUrl;
        } else {
          item.manufacturerLogoUrl = environment.imageBaseUrl + environment.imageDefaultLogoUrl;
        }
      }
    });
  }

  getJustSoldItems(justSoldPayload: IJustSoldPayload): Observable<IJustSold> {
    this.restapiverb = 'sales/justSold';
    const url = environment.buyerPortalBaseUrl + this.restapiverb;
    return this.http.post<any>(url, justSoldPayload);
  }

  getJustSoldTotals(): void {
    const restapiverb = 'sales/lineitems/total';
    const url = environment.buyerPortalBaseUrl + restapiverb;
    this.http.get<number>(url)
      .subscribe(
        (data) => {
          this.sharedService.justSoldValues = { totalLineItems: data, newLineItems: 0 };
        },
        (err) => { console.log('err occured: ' + err); }
      );
  }

  getJustSoldFilters(payload: QuickFilterPayload[]): Observable<any> {
    const restapiverb = 'sales/justSold/filters';
    const url = environment.buyerPortalBaseUrl + restapiverb;
    return this.http.post<IJustSold>(url, payload);
  }
}



