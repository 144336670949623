import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from '../../../services/session.service';
import { AuthService } from '../../../auth/auth.service';
import { CartService } from '../cart.service';
import { Subscription } from 'rxjs';
import { PubSubService } from '../../../core/pubsub.service';
import { SharedSource } from '../../../core/shared-source';

@Component({
    selector: 'app-cart-abandonment',
    templateUrl: './cart-abandonment.component.html',
    styleUrls: ['./cart-abandonment.component.scss'],
    standalone: true
})
export class CartAbandonmentComponent implements OnInit, OnDestroy {
  emailCartId: string;
  subscription = new Subscription();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private sessionService: SessionService,
    private cartService: CartService,
    private pubSubService: PubSubService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.pubSubService.sharedSubject.subscribe((sharedValue) => {

        if (sharedValue.name === SharedSource.cartFetch) {

          if (this.sessionService.newCartId !== this.emailCartId) {
            this.sessionService.removeSessionItem('isCartAbandonment');
            this.router.navigate(['home']);
          } else {
            this.sessionService.isCartAbandonment = 'true';
            this.cartService.goToCart();
          }
        }
      })
    )

    this.activatedRoute.paramMap.subscribe(params => {
      this.emailCartId = params.get('cartId');
      if (!this.sessionService.isCartAbandonment) {
        this.sessionService.isCartAbandonment = this.emailCartId;
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  }
