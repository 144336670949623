import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-credit-card-notification',
    templateUrl: './credit-card-notification.component.html',
    styleUrls: ['./credit-card-notification.component.scss'],
    standalone: true,
    imports: [RouterLink]
})
export class CreditCardNotificationComponent implements OnInit {
  @Output() onClose = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  dismiss() {
    this.onClose.emit(true);
  }

}
