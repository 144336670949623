import { Component, OnInit, ChangeDetectorRef, OnDestroy, QueryList, ViewChildren, ViewChild } from '@angular/core';
import { ItemsService } from '../../../shared/services/items.service';
import { TopService } from '../../../shared/services/top.service';
import { IItem } from '../../interfaces/IItem';
import { SharedService } from '../../../shared/shared.service';
import { environment } from '../../../../environments/environment';
import { PubSubService } from '../../../core/pubsub.service';
import { SharedSource } from '../../../core/shared-source';
import { IJustSoldMessage } from '../../../shared/interfaces/IJustSoldMessage';
import { IBuyerErrorModal } from '../../interfaces/model';
import { NotificationService } from '../../../shared/services/notification.service';
import { IJustSoldQuickFilter } from '../../../shared/interfaces/IJustSoldQuickFilter';
import { IJustSoldPayload } from '../../../shared/interfaces/IJustSoldPayload';
import { IItemManufacturerFilter } from '../../interfaces/IItemManufacturerFilter';
import { Subscription } from 'rxjs';
import { ItemFilterService } from '../../../shared/item-filter/item-filter.service';
import { GuestService } from '../../../shared/services/guest.service';
import { ListingViewItemComponent } from '../../../shared/listing-view-item/listing-view-item.component';
import { ListingViewItemMobileComponent } from '../../../shared/listing-view-item/listing-view-item-mobile/listing-view-item-mobile.component';
import * as uuid from 'uuid';
import {OfferService} from '../../../user/offers/offer.service';
import {filter} from 'rxjs/operators';
import { SessionService } from '../../../services/session.service';
import { MakeOfferComponent } from '../../../shared/item-detail-desktop/make-offer/make-offer.component';
import { WaitlistTooltipComponent } from '../../../shared/waitlist-tooltip/waitlist-tooltip.component';
import { RestrictionNoticeComponent } from '../../../shared/ui-components/restriction-notice/restriction-notice.component';
import { NotificationTooltipComponent } from '../notification-tooltip/notification-tooltip.component';
import { GradingGuide2Component } from '../../../shared/grading-guide2/grading-guide2.component';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { ItemsPreloaderMobileComponent } from '../../../shared/ui-components/items-preloader/items-preloader-mobile/items-preloader-mobile.component';
import { ItemsBreadcrumbsMobileComponent } from '../../catalog/items-breadcrumbs/items-breadcrumbs-mobile/items-breadcrumbs-mobile.component';
import { ItemDetailDesktopComponent } from '../../../shared/item-detail-desktop/item-detail-desktop.component';
import { ErrorModalComponent } from '../../../shared/ui-components/error-modal/error-modal.component';
import { ItemsPreloaderComponent } from '../../../shared/ui-components/items-preloader/items-preloader.component';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { NothingFoundBuyerComponent } from '../../nothing-found-buyer/nothing-found-buyer.component';
import { ItemFilterComponent } from '../../../shared/item-filter/item-filter.component';
import { NgIf, NgFor, CurrencyPipe } from '@angular/common';

@Component({
    selector: 'app-notification-items',
    templateUrl: './notification-items.component.html',
    styleUrls: ['./notification-items.component.scss'],
    standalone: true,
    imports: [NgIf, ItemFilterComponent, NothingFoundBuyerComponent, VirtualScrollerModule, NgFor, ListingViewItemComponent, ItemsPreloaderComponent, ErrorModalComponent, ItemDetailDesktopComponent, ItemsBreadcrumbsMobileComponent, ListingViewItemMobileComponent, ItemsPreloaderMobileComponent, NgClickOutsideDirective, GradingGuide2Component, NotificationTooltipComponent, RestrictionNoticeComponent, WaitlistTooltipComponent, MakeOfferComponent, CurrencyPipe]
})
export class NotificationItemsComponent implements OnInit, OnDestroy {
  @ViewChildren(ListingViewItemComponent) listItemComponents: QueryList<ListingViewItemComponent>;
  @ViewChildren(ListingViewItemMobileComponent) listItemMobileComponents: QueryList<ListingViewItemMobileComponent>;
  selectedItem: IItem;
  showDetailedPopUp = false;
  private _manufacturers: IJustSoldQuickFilter[] = [];
  conditions: IJustSoldQuickFilter[] = [];
  justSoldItems: IItem[];
  isMobile = false;
  itemListErrorModal: IBuyerErrorModal = {
    bodyText: '',
    title: '',
    isShown: false
  };
  pubServiceSubscription: Subscription;
  itemChangedSubscription: Subscription;
  justSoldPayload: IJustSoldPayload = {};
  throttle = 200;
  scrollDistance = 1;
  pageNumber = 1;
  itemsCount = 25;
  justSoldValues: IJustSoldMessage = {};
  hideMoreBtn = false;
  showNotificationTooltip = false;
  notificationItem: IItem = {};
  tooltipPosition: Event;
  showWaitlistTooltip = false;
  waitlistItem: IItem = {};
  waitlistTooltipPosition: Event;
  blockPreloader = false;
  showRestrictedCountryPopup = false;
  selectedItemOffer: IItem;

  constructor(
    public itemsService: ItemsService,
    public guestService: GuestService,
    public topService: TopService,
    public sharedService: SharedService,
    private pubSubService: PubSubService,
    private _itemFilterService: ItemFilterService,
    private cdr: ChangeDetectorRef,
    private notificationService: NotificationService,
    public offerService: OfferService,
    public sessionService: SessionService
  ) { }

  set manufacturers(manus) {
    this._itemFilterService.justSoldManufacturers = manus;
    this._manufacturers = manus;
  }

  get manufacturers() {
    return this._manufacturers;
  }

  ngOnInit() {
    this.topService.gridView = true;
    this.itemsService.isJustSoldUrl = true;
    this.itemsService.isLandingPage = false;
    // for mobile
    this.topService.showFilterBtn = true;

    if (this.sharedService.justSoldValues.totalLineItems) {
      this.justSoldValues.totalLineItems = this.sharedService.justSoldValues.totalLineItems;
    }
    this.pubServiceSubscription = this.pubSubService.sharedSubject.subscribe(
      mySharedValues => {
        if (mySharedValues.name === SharedSource.JustSoldValues) {
          if (this.justSoldValues.newLineItems) {
            this.justSoldValues.newLineItems += mySharedValues.data['newLineItems'];
          } else {
            this.justSoldValues.newLineItems = mySharedValues.data['newLineItems'];
          }
          this.justSoldValues.totalLineItems = mySharedValues.data['totalLineItems'];
        }
        if (mySharedValues.name === SharedSource.manufacturerChangedInFilter) {
          const manuId = mySharedValues.data;
          this.changeQuickFilter(manuId);
        }
        if (mySharedValues.name === SharedSource.itemRestrictedForCountry) {
          this.showRestrictedCountryPopup = true;
          this.selectedItem = mySharedValues.data;
        }
      });

    // this._itemFilterService.selectedFirstLevel = { navName: 'Just Sold'};
    this.itemsService.initializeConditionsDataForQuickSort(this.conditions);
    this.itemsService.initializeManufacturerDataForQuickSort(this.manufacturers);
    this.getNewItems();
    this.itemChangedSubscription = this.offerService.itemChanged$
      .pipe(
        filter(itemChanged => !!itemChanged)
      )
      .subscribe(itemChanged => {
        if (this.justSoldItems && this.justSoldItems.length) {
          this.justSoldItems = this.justSoldItems.map(item => {
            if (item.id === itemChanged.id) {
              item.offerStatus = itemChanged.offerStatus;
            }
            return item;
          });
          this.cdr.detectChanges();
        }
      });
  }

  addToCart(event) {
    this.itemsService.closeExwPopup(event);

    // gridView is the oposite of expected,
    // if true the listingViewComponent is the one showing
    if (!this.topService.isMobile) {
      const listingItemCmp = this.listItemComponents.find(lItem => {
        return this.itemsService.selectedItemExwPopup &&
          lItem.item.id === this.itemsService.selectedItemExwPopup.id;
      });

      if (listingItemCmp) {
        listingItemCmp.addToCart(true);
      }
    } else {
      const listingItemMobileCmp = this.listItemMobileComponents.find(lItem => {
        return this.itemsService.selectedItemExwPopup &&
          lItem.item.id === this.itemsService.selectedItemExwPopup.id;
      });

      if (listingItemMobileCmp) {
        listingItemMobileCmp.addToCart(true);
      }

    }
  }

  ngOnDestroy() {
    this.itemsService.isJustSoldUrl = false;
    if (this.pubServiceSubscription) {
      this.pubServiceSubscription.unsubscribe();
    }

    this.itemsService.selectedItemExwPopup = undefined;
    // for mobile
    this.topService.showFilterBtn = false;
    this.selectedItem = undefined;
    if (this.itemChangedSubscription) {
      this.itemChangedSubscription.unsubscribe();
    }
  }

  hideDetailedPopUp(): void {
    this.showDetailedPopUp = false;
    this.sharedService.isMobileSortingPopup = false;
    this.selectedItem = { id: '' };
  }

  openitemDetailedMode(item: IItem): void {
    this.selectedItem = item;
    this.showDetailedPopUp = true;
    this.sharedService.isMobileSortingPopup = true;
  }

  openGradingGuide(item: IItem): void {
    this.selectedItem = item;
    this.sharedService.gradingGuideHtmlPath =
      environment.imageBaseUrl + item.gradingGuideUrl + 'index6.html';
    this.sharedService.isGradingGuideOpen = true;
  }

  showError(errorModal: IBuyerErrorModal): void {
    this.itemListErrorModal = errorModal;
  }

  alertConfirmBtn(): void {
    event.preventDefault();
    event.stopPropagation();
    this.sharedService.isGradingGuideOpen = false;
    if (!this.showDetailedPopUp) {
      this.selectedItem = { id: '' };
    }
  }

  onScrollDown(event) {
    this.blockPreloader = true;
    const endPerPage = this.pageNumber * this.justSoldPayload.pagination.pageSize;
    if (event.endIndex < endPerPage - 1) {
      return;
    }
    this.pageNumber += 1;
    this.getNewItems(false, true);
  }

  changeQuickFilter(quickFilters: IItemManufacturerFilter): void {
    this.blockPreloader = false;
    this.justSoldPayload.quickFilters = [];

    if (quickFilters.condition) {
      this.justSoldPayload.quickFilters.push({ id: quickFilters.condition, type: 'condition' });
    }

    if (quickFilters.manufacturer) {
      this.justSoldPayload.quickFilters.push({ id: quickFilters.manufacturer, type: 'manufacturer' });
    }

    this.getNewItems(true, true);
  }

  getMultiFilterItems(quickFilters: IItemManufacturerFilter[]): void {
    this.blockPreloader = false;
    this.justSoldPayload.quickFilters = [];
    quickFilters.forEach(qf => {
      if (qf.condition) {
        this.justSoldPayload.quickFilters.push({ id: qf.condition, type: 'condition' });
      }
      if (qf.manufacturer) {
        this.justSoldPayload.quickFilters.push({ id: qf.manufacturer, type: 'manufacturer' });
      }
    });

    this.getNewItems(true, true);
  }

  getNewItems(isFirstPage?: boolean, isFilterChanged?: boolean): void {
    this.topService.loading = true;

    if (isFirstPage) {
      this.pageNumber = 1;
    }
    if (!isFilterChanged) {
      this.justSoldPayload.quickFilters = null;
    }
    // reset count after getting more items
    this.justSoldValues.newLineItems = 0;
    this.justSoldPayload.pagination = { pageNumber: this.pageNumber, pageSize: this.itemsCount };

    this.notificationService.getJustSoldItems(this.justSoldPayload).subscribe(
      (data) => {
        this.itemsService.selectedItemExwPopup = undefined;
        this.clearQuickFilters();
        this.setManufacturers(data.filters.quickFilters);
        this.setConditions(data.filters.quickFilters);
        if (this.justSoldItems && this.justSoldItems.length && !isFirstPage) {
          this.justSoldItems = this.justSoldItems.concat(data.values);
        } else {
          this.justSoldItems = data.values;
        }
        this.notificationService.updateItemLogo(this.justSoldItems);
        this.justSoldItems.map(item => {
          item.highlightId = uuid.v4();
        });

        this.topService.loading = false;
      },
      (err) => {
        this.topService.loading = false;
        console.log('Error: ' + err);
      }
    );
  }

  hideError(): void {
    this.itemListErrorModal.isShown = false;
  }

  clearQuickFilters(): void {
    this.blockPreloader = false;
    if (this.manufacturers.length) {
      this.manufacturers = this.manufacturers.filter(manu => manu.name === this.notificationService.manufacturerSelected);
    }
    if (this.conditions.length) {
      this.conditions = this.conditions.filter(cond => cond.name === this.notificationService.conditionSelected);
    }
  }

  refresh(): void {
    this.blockPreloader = false;
    this.getNewItems(true, false);
  }

  setManufacturers(quickFilters: IJustSoldQuickFilter[]): void {
    this.blockPreloader = false;

    quickFilters.forEach(qf => {
      if (qf.type === 0 && !this.manufacturers.filter(manu => manu.name === qf.name).length) {
        this.manufacturers.push(qf);
      }
    });

    if (this.manufacturers[0] && this.manufacturers[0].name !== 'All Manufacturers') {
      const newTopElement = { count: 0, logoUrl: '', selected: false, id: '', name: 'All Manufacturers' };
      this.manufacturers.unshift(newTopElement);
    }
  }

  setConditions(quickFilters: IJustSoldQuickFilter[]): void {
    this.blockPreloader = false;
    quickFilters.forEach(qf => {
      if (qf.type === 1 && !this.conditions.filter(cond => cond.name === qf.name).length) {
        this.conditions.push(qf);
      }
    });

    if (this.conditions[0] && this.conditions[0].name !== 'All Conditions') {
      const newTopElement = { count: 0, logoUrl: '', selected: false, id: '', name: 'All Conditions' };
      this.conditions.unshift(newTopElement);
    }
  }

  showNotification(tooltip): void {
    this.showNotificationTooltip = false;
    setTimeout(() => {
      this.notificationItem = tooltip.item;
      this.tooltipPosition = tooltip.event;
      this.showNotificationTooltip = true;
    }, 100);
  }

  hideNotificationTooltip(): void {
    this.showNotificationTooltip = false;
  }

  showWaitlist(tooltip): void {
    if (tooltip.isShowWaitlist) {
      this.waitlistItem = tooltip.item;
      this.waitlistTooltipPosition = tooltip.event;
      this.showWaitlistTooltip = true;
    } else {
      this.showWaitlistTooltip = false;
    }
  }

  hideWaitlistTooltip(): void {
    this.showWaitlistTooltip = false;
  }

  public itemTrackById(index: number, item: IItem): string {
    return item.highlightId;
  }

  closeRestrictedCountryPopup(){
    this.showRestrictedCountryPopup = false;

    if (!this.showDetailedPopUp) {
      this.selectedItem = { id: '' };
    }
  }

  showOfferpopup(item: IItem): void {
    if (this.sessionService.userRole !== 'GUEST') {
      this.selectedItemOffer = item;
    }
  }

  hideOfferpopup(): void {
    this.selectedItemOffer = undefined;
  }

}
