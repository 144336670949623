import { PubSubService } from './../../../core/pubsub.service';
import { SharedSource } from './../../../core/shared-source';
import { ItemsService } from './../../services/items.service';
import { OfferItemStatus, OfferStatusEnum } from './../../../buyer/enums/offerStatusEnum';
import { OfferService } from './../../../user/offers/offer.service';
import { environment } from './../../../../environments/environment';
import { IItem } from './../../../buyer/interfaces/IItem';
import { IMakeOfferErrorResponse } from './../../../buyer/interfaces/IMakeOfferErrorResponse';
import { IMakeOfferRequest } from './../../../buyer/interfaces/IMakeOfferRequest';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { IOfferError } from '../../../buyer/interfaces/IOfferError';
import { TopService } from '../../services/top.service';
import { IOffer } from './../../../buyer/interfaces/IOffer';
import * as _ from 'lodash';
import { PriceOptions } from '../../interfaces/offer/CounterOffer';
import { OfferErrorCodes } from '../../enums/offer-error-codes';
import { OfferErrorCodeMap } from '../../classes/offer-error-code-map';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import { CustomOfferCurrencyPipe } from '../../pipes/offer-currency.pipe';
import { RouterLink } from '@angular/router';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { OnlyDigitsDirective } from '../../directives/only-digits.directive';
import { NgxMaskModule } from 'ngx-mask';
import { NgIf, NgFor, DecimalPipe, CurrencyPipe } from '@angular/common';

@Component({
    selector: 'app-make-offer',
    templateUrl: './make-offer.component.html',
    styleUrls: ['./make-offer.component.scss', '../../confirm-modal/confirm-modal.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgxMaskModule, OnlyDigitsDirective, NgClickOutsideDirective, NgFor, RouterLink, DecimalPipe, CurrencyPipe, CustomOfferCurrencyPipe]
})
export class MakeOfferComponent implements OnInit {
  @Input() item: IItem;
  @Output() notificationTooltipEmitter = new EventEmitter<{ item: IItem, event: Event }>();
  @Output() onCloseEmitter = new EventEmitter<null>();

  public offerForm: FormGroup;
  formErrors: any;
  subscriptions: Subscription[] = [];
  offerStatus: OfferStatusEnum;
  OfferStatusEnum = OfferStatusEnum;
  OfferErrorCodes = OfferErrorCodes;
  OfferErrorCodeMap = OfferErrorCodeMap;
  validTotalOffer = true;
  loading = false;
  goodForDropdown = {
    disabled: false,
    expanded: false,
    selectedValue: 1,
    valueWasSelectedByUser: true
  };
  error: IOfferError;
  offerErrorMapping: IOfferError[] = [];
  animateOut = false;
  offerId = null;
  makeOfferErrorResponse: IMakeOfferErrorResponse;

  priceDropdown = {
    disabled: false,
    expanded: false,
    selectedValue: 0,
    selectedPercent: '0%',
    valueWasSelectedByUser: false
  }
  priceOptions: PriceOptions[];
  priceBasedonQty = 0;
  effectivePrice = 0;

  constructor(
    private formBuilder: FormBuilder,
    public offerService: OfferService,
    public topService: TopService,
    public itemsService: ItemsService,
    public pubSubService: PubSubService,) {
    this.createOfferForm();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.offerService.showSubmitOfferConfirmationPopup = false;
    this.offerService.showOfferInformationPopup = false;
  }

  ngOnInit(): void {
    this.resolvePriceOptions();
    this.formErrors = this.getDefaultFormErrors();
    this.setUpSubscriptions();
    this.getOfferId();
  }

  resolvePriceOptions() {
    this.subscriptions.push(
      this.offerService.getEffectivePrice(this.item.id, this.offerForm.value.quantity || 0)
        .subscribe(
          (effectivePrice) => {
            this.effectivePrice = effectivePrice;
            if (this.priceBasedonQty !== this.effectivePrice) {
              this.offerForm.patchValue({ price: null });
              this.priceDropdown.selectedPercent = null;
              this.priceDropdown.selectedValue = null;
              this.priceDropdown.valueWasSelectedByUser = false;
              this.priceBasedonQty = _.cloneDeep(this.effectivePrice);
            }

            this.priceOptions = this.offerService.priceOptions = this.offerService.counterOfferPrices(effectivePrice, true);
          },
          (err) => {
            console.log('Error trying to get effective price');
          }));
  }

  getOfferId() {
    if (this.item.offerStatus === OfferStatusEnum.offerMade) {

      this.subscriptions.push(
        this.offerService.getOfferId(this.item.id)
          .subscribe(
            (offerId: number) => {
              this.offerId = offerId;
            },
            (err) => {
              console.log('Error trying to get offer by id: ' + err);
            }));
    }

  }

  showNoEnoughInventoryAndPriceError(): boolean {
    return (OfferStatusEnum.disabledNoEnoughInventoryAndPricebasedOnCurrentRules ===
      (this.item.offerStatus & OfferStatusEnum.disabledNoEnoughInventoryAndPricebasedOnCurrentRules));
  }

  setUpSubscriptions() {
    for (const field in this.formErrors) {
      if (!this.formErrors.hasOwnProperty(field)) {
        continue;
      }
      const control = this.offerForm.get(field);
      if (control) {
        this.subscriptions.push(
          control.valueChanges.subscribe(newValue => {
            if (control && !control.valid) {
              this.formErrors[field] = control.errors;
            } else {
              this.formErrors[field] = {};
            }
          })
        );
      }
    }
  }

  getDefaultFormErrors() {
    return {
      quantity: {},
      price: {},
      goodFor: {},
      isFinal: {}
    };
  }

  validateForm() {
    for (const field in this.formErrors) {
      if (!this.formErrors.hasOwnProperty(field)) {
        continue;
      }
      this.formErrors[field] = {};
      const control = this.offerForm.get(field);
      if (control && !control.valid) {
        this.formErrors[field] = control.errors;
      }
    }
  }

  createOfferForm() {
    this.offerForm = this.formBuilder.group({
      quantity: [null, [Validators.required]],
      price: [null, [Validators.required, this.validatePrice.bind(this)]],
    });
    const quantityControl = this.offerForm.get('quantity');
    this.subscriptions.push(
      quantityControl.valueChanges.pipe(
        debounceTime(350),
        distinctUntilChanged()
      ).subscribe(value => {
        this.validateQuantity(quantityControl);
      })
    );
  }

  validateQuantity(control: AbstractControl) {
    if (!this.offerForm || !this.item) {
      control.setErrors(null);
      return null;
    }

    if (this.error && this.error.serverErrorCode == 2) {
      this.error.showed ? this.error = null : this.error.showed = true;
    }

    const qty = control.value;
    const minAmountRequired = this.offerService.offersSummary.validationRules.minAmount;
    const price = this.offerForm?.value.price;

    setTimeout(() => { // required to get updated price options list when manually trigger price control validation
      this.resolvePriceOptions();
    }, 0);

    if (price) {
      setTimeout(() => { // required to get updated value when manually trigger price control validation
        this.offerForm.controls['price'].updateValueAndValidity();
      }, 0);
    }

    if (qty && price) {
      this.validTotalOffer = (qty * price < minAmountRequired) ? false : true;
    }

    if (qty > this.item?.inventory.availableToSell) {
      control.setErrors({ validQuantityBasedOnAvailability: false });
      return { validQuantityBasedOnAvailability: false };
    }
    control.setErrors(null);
    return null;
  }

  validatePrice(control: AbstractControl) {
    if (!this.offerForm || !this.item || control.value === null) {
      return null;
    }

    if (this.error && this.error.serverErrorCode == 1) {
      this.error.showed ? this.error = null : this.error.showed = true;
    }

    const price = parseFloat(control.value);
    const minAmountRequired = this.offerService.offersSummary.validationRules.minAmount;
    const qty = this.offerForm?.value.quantity;
    var minPriceRequired = this.item ? this.effectivePrice - (this.offerService.offersSummary.validationRules.maxPercentage / 100 * this.effectivePrice) : null;
    minPriceRequired = parseFloat(minPriceRequired.toFixed(2));

    if (qty && price) {
      this.validTotalOffer = (qty * price < minAmountRequired) ? false : true;
    }

    if (minPriceRequired && price < minPriceRequired) {
      return { validPrice: false };
    }

    if (price > this.effectivePrice) {
      return { validRegardsToMaxPrice: false };
    }

    return null;
  }

  submitOffer() {
    if (!this.offerForm.valid || !this.validTotalOffer || this.topService.loading) {
      return;
    }

    const makeOfferRequest: IMakeOfferRequest = this.offerForm.value;
    makeOfferRequest.vendorItemId = this.item.id;
    makeOfferRequest.goodFor = this.goodForDropdown.selectedValue;
    makeOfferRequest.isFinal = this.offerService.isFinalCounteroffer(this.priceDropdown.selectedValue, true);
    makeOfferRequest.instanceId = sessionStorage.getItem('sessionId');
    makeOfferRequest.effectivePrice = this.effectivePrice;
    makeOfferRequest.price = makeOfferRequest.price;

    this.topService.loading = true;


    this.offerService.makeOffer(makeOfferRequest)
      .subscribe(
        (offerresponse: IOffer) => {
          this.topService.loading = false;
          this.offerService.showSubmitOfferConfirmationPopup = false;
          this.item.offerStatus = OfferStatusEnum.offerMade;
          this.offerId = offerresponse.id;
          this.offerService.offersSummary = {
            ...this.offerService.offersSummary,
            tokenCount: this.offerService.offersSummary.tokenCount - 1,
            hasMadeOffers: true
          }
          this.offerService.itemChanged$.next(this.item);
        },
        (err) => {
          this.offerService.showSubmitOfferConfirmationPopup = false;
          this.topService.loading = false;

          if (err.status === this.offerService.OFFER_VALIDATION_ERROR_STATUS_CODE) {
            this.makeOfferErrorResponse = err.error;
            let errorDescription = OfferErrorCodeMap.ErrorCodeMap[this.makeOfferErrorResponse.errorCode];
            if (this.makeOfferErrorResponse.errorCode === OfferErrorCodes.CreateOffer_ItemPriceChanged) {
              errorDescription = String(errorDescription).replace(OfferErrorCodeMap.VariablePercentage, this.offerService.offersSummary.validationRules.maxPercentage.toString());
            }

            this.error = {
              serverErrorCode: this.makeOfferErrorResponse.errorCode,
              description: errorDescription,
              showed: false
            }
            this.showInformationPopup();
          }
        }
      )

  }

  toggleGoodForDropdown() {
    if (this.goodForDropdown.disabled) {
      return;
    }
    this.goodForDropdown.expanded = !this.goodForDropdown.expanded;
  }

  onSelectDuration(durationInDays: number, e: Event) {
    if (this.goodForDropdown.disabled && e) {
      e.stopPropagation();
      return;
    }
    this.goodForDropdown.valueWasSelectedByUser = true;
    this.goodForDropdown.selectedValue = durationInDays;
  }

  showConfirm(e: Event) {
    e.stopPropagation();
    if (this.topService.loading || !this.offerForm.valid || !this.validTotalOffer) {
      return;
    }
    this.offerService.showSubmitOfferConfirmationPopup = true;
  }

  closeConfirm(e: Event) {
    e.stopPropagation();
    this.offerService.showSubmitOfferConfirmationPopup = false;
  }

  openNotificationTooltip(event: any): void {
    event.preventDefault();
    this.topService.notificationTooltipHovered = true;
    const item = this.item;
    this.notificationTooltipEmitter.emit({ item, event });
    event.stopPropagation();
  }

  showInformationPopup(e?: Event) {
    e?.stopPropagation();
    this.offerService.showOfferInformationPopup = true;
  }

  closeInformationPopup(e: Event) {
    e.stopPropagation();
    this.offerService.showOfferInformationPopup = false;

    if (this.error.serverErrorCode === OfferErrorCodes.CreateOffer_ItemStatusChanged) {
      if (this.makeOfferErrorResponse.newStatus == OfferItemStatus.itemDeactivated) {
        var foundIndex = this.itemsService.itemsData.findIndex(x => x.id == this.item.id);
        this.itemsService.itemsData.splice(foundIndex, 1);
        this.pubSubService.sharedSubject.next({ name: SharedSource.offerItemChanged, data: null });
        return;
      }
    }

    if (this.error.serverErrorCode == OfferErrorCodes.CreateOffer_NoTokensAvailable) {
      this.offerService.offersSummary = {
        ...this.offerService.offersSummary,
        tokenCount: 0
      }
      return;
    }

    if (this.error.serverErrorCode === OfferErrorCodes.CreateOffer_ItemPriceChanged) {
      this.error = null;
      this.resolvePriceOptions();
    }

    this.refreshItem();
  }

  refreshItem() {
    this.topService.loading = true;
    this.subscriptions.push(
      this.itemsService.getItem(this.item.id)
        .subscribe(
          (itemUpdated) => {
            itemUpdated.manufacturerLogoUrl = this.item.manufacturerLogoUrl;
            itemUpdated.attributes = this.item.attributes;

            var foundIndex = this.itemsService.itemsData.findIndex(x => x.id == this.item.id);
            this.itemsService.itemsData.splice(foundIndex, 1, itemUpdated);
            this.item = itemUpdated;
            this.pubSubService.sharedSubject.next({ name: SharedSource.offerItemChanged, data: itemUpdated });
            this.offerForm.controls['quantity'].updateValueAndValidity();
            this.topService.loading = false;
          },
          (err) => {
            console.log('Error trying to get item by id. ' + err);
            this.topService.loading = false;
          }
        ));
  }

  togglePriceDropdown() {
    if (this.priceDropdown.disabled) {
      return;
    }
    this.priceDropdown.expanded = !this.priceDropdown.expanded;
  }

  onSelectPrice(price: number, percent: string, e: Event) {
    if (this.priceDropdown.disabled && e) {
      e.stopPropagation();
      return;
    }
    this.priceDropdown.valueWasSelectedByUser = true;
    this.priceDropdown.selectedValue = price;
    this.priceDropdown.selectedPercent = percent;

    this.offerForm.patchValue({ price: price })
  }

  closeModal(): void {
      this.onCloseEmitter.emit();
  }
}
