import { map } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, AfterViewChecked, ViewChild, ElementRef } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';

import { CartService } from './cart.service';
import { IOrderResponse } from '../interfaces/IOrderRes';
import { UserService } from '../../user/user.service';
import { PageErrorService } from '../../page-error/page-error.service';
import { TopService } from '../../shared/services/top.service';
import { OrdersService } from '../order-history/orders/orders.service';
import { ItemsService } from '../../shared/services/items.service';
import { environment } from '../../../environments/environment';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { EmailService } from '../../services/email.service';
import { Subscription } from 'rxjs';
import { PubSubService } from '../../core/pubsub.service';
import { SharedService } from '../../shared/shared.service';
import { SharedSource } from '../../core/shared-source';
import { UtilityService } from '../../core/utility.service';
import { SessionService } from '../../services/session.service';

import * as models from '../interfaces/model';
import * as modelsU from '../../shared/interfaces/model';
import { ICartItem, IItem } from '../interfaces/model';
import { ICartCheckout } from '../interfaces/ICartCheckout';
import { CreditCardService } from '../credit-cards/credit-card.service';
import { RestrictionNoticeComponent } from '../../shared/ui-components/restriction-notice/restriction-notice.component';
import { BottomNavMobileComponent } from '../../shared/bottom-nav-mobile/bottom-nav-mobile.component';
import { NotificationTooltipComponent } from '../notifications/notification-tooltip/notification-tooltip.component';
import { ErrorModalComponent } from '../../shared/ui-components/error-modal/error-modal.component';
import { CartProcessingModalComponent } from './cart-processing-modal/cart-processing-modal.component';
import { AddToExistingMobileComponent } from './add-to-existing/add-to-existing-mobile/add-to-existing-mobile.component';
import { AddToExistingComponent } from './add-to-existing/add-to-existing.component';
import { GradingGuide2Component } from '../../shared/grading-guide2/grading-guide2.component';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { CreditCardTermsComponent } from '../credit-cards/credit-card-terms/credit-card-terms.component';
import { ItemDetailDesktopComponent } from '../../shared/item-detail-desktop/item-detail-desktop.component';
import { NothingFoundBuyerComponent } from '../nothing-found-buyer/nothing-found-buyer.component';
import { CartBottomComponent } from './cart-bottom/cart-bottom.component';
import { CartItemsMobileComponent } from './cart-items/cart-items-mobile/cart-items-mobile.component';
import { CartItemsComponent } from './cart-items/cart-items.component';
import { ProceedToCheckoutTopComponent } from './proceed-to-checkout-top/proceed-to-checkout-top.component';
import { EditOrderComponent } from './edit-order/edit-order.component';
import { CartTopComponent } from './cart-top/cart-top.component';
import { NgIf, NgClass, NgFor } from '@angular/common';

@Component({
    selector: 'app-cart',
    templateUrl: './cart.component.html',
    styleUrls: ['./cart.component.scss'],
    animations: [
        trigger('openOrdersDetailsTrigger', [
            state('hide', style({
                height: '0px',
                overflow: 'hidden'
            })),
            state('show', style({
                height: '*'
            })),
            transition('show => hide', animate('200ms ease-out')),
            transition('hide => show', animate('200ms ease-in'))
        ])
    ],
    standalone: true,
    imports: [NgIf, CartTopComponent, NgClass, EditOrderComponent, ProceedToCheckoutTopComponent, NgFor, CartItemsComponent, CartItemsMobileComponent, CartBottomComponent, NothingFoundBuyerComponent, ItemDetailDesktopComponent, CreditCardTermsComponent, NgClickOutsideDirective, GradingGuide2Component, AddToExistingComponent, AddToExistingMobileComponent, CartProcessingModalComponent, ErrorModalComponent, NotificationTooltipComponent, BottomNavMobileComponent, RestrictionNoticeComponent]
})
export class CartComponent implements OnInit, OnDestroy {
  // @ViewChild('orderEmail') set emailTemplate(elem: ElementRef) {
  //   if (elem && this.sendEmail && !this.emailSent) {
  //     this.sendEmailPDF();
  //   }
  // }
  // emailSent = false;
  lastcomments = '';
  // private _sendMail = false;
  copied = 'Copy';
  showWarning: boolean;
  errorMessage: string;
  newOverrittenPrice: number;
  cartList: models.ICartItem[];
  isOutOfStock = false;
  disable_place_order = false;
  Baddress: modelsU.IAddress;
  Saddress: modelsU.IAddress;
  SavedAddressB: modelsU.IAddress;
  SavedAddressS: modelsU.IAddress;
  user_role: string;
  shipping_address: string[];
  billing_address: string[];
  // private _sendEmail = false;
  selectedOpenOrder: models.IOrder = {
    docEntry: 0,
    docNumber: 0,
    orderDate: null,
    orderStatus: '',
    orderDiscountAmount: 0,
    orderDiscountPercent: 0,
    orderDetails: [],
    orderTotal: 0,
    customerRef: '',
    billingAddressCode: '',
    shippingAddressCode: '',
    shippingCost: 0
  };
  showBillTo = false;
  showShipTo = false;
  isCart = true;
  billingAddress: string;
  shippingAddress: string;
  shipToAddress: string;
  billToAddress: string;
  bnkInfo = null;
  completed = false;
  openOrders: models.IOrder[] = [];
  showModal = false;
  defaultLogoUrl: string = environment.imageDefaultLogoUrl;
  isMobile = false;
  openOrdersDetails = false;
  openMobileInfo = '';
  openMoreInfo = '';
  confirmModal = false;
  animateOut = false;
  // defaultEmailSubjectLine = 'HUBX Order Confirmation No ';
  updatingOrder: Subscription;
  updateStatus: string;
  itemsUpdated = false;
  userOrdersList = [];
  loading = false;
  deleteSpinner = false;
  deleteSpinnerItem: string;
  errorCollection: Map<string, string>;
  totalInitialize = false;
  got400 = false;
  error400 = '';
  addToExistingRejected = false;
  imageBaseUrl = environment.imageBaseUrl;
  showNoItems = false;
  editedOrderShippingCost = 0;
  terms = false;
  prepay = false;
  credit = false;
  editedOrderDetails: models.IOrderLine[];
  errorModal: models.IBuyerErrorModal = {
    bodyText: '',
    title: 'Server Error',
    isShown: false
  };
  noOrders = false;
  visualCondition = 'Visual Condition';
  selectedCartItem: models.ICartItem;
  nothingFoundTitle = 'Cart is Empty!';
  nothingFoundMessage = 'Click the Button Below to Continue Shopping';
  nothingFoundButtonText = 'Continue Shopping';
  totalOrderWithFreight = 0;
  emailSubtotal = 0;
  isSelectedOrdANotCart = false;
  isEditOrder = false;
  getEditedOrdersCompleted = false;
  errorFromEmail = false;
  showDetailedPopUp = false;
  isCartReady = false;
  showTerms = false;
  private _purchaseCompleted = true;
  showNotificationTooltip = false;
  notificationItem: IItem = {};
  tooltipPosition: Event;
  processingFee = 0;
  selectedOrder: models.IOrder;
  hideBox: boolean;
  isProcessingPopup = false;
  isProcessingAddToExisting = false;
  addressCheckCounter = 0;
  addressCheckMax = 10;
  showRestrictedCountryPupup: boolean = false;

  constructor(
    private route: ActivatedRoute,
    public cartService: CartService,
    private router: Router,
    public userService: UserService,
    public topService: TopService,
    private pageErrorService: PageErrorService,
    public ordersService: OrdersService,
    public googleAnalyticsService: GoogleAnalyticsService,
    public emailService: EmailService,
    private pubSubService: PubSubService,
    public utilService: UtilityService,
    public sharedService: SharedService,
    public itemsService: ItemsService,
    public sessionService: SessionService,
    public creditCardService: CreditCardService) { }

  // set sendEmail(val) {
  //   if (val) {
  //     this._sendEmail = (this.cartService.isEditing) ?
  //       !this.sharedService.editOrderMode && this.cartService.sendMail : val;
  //   }
  //   // user chose not to receive a email
  //   if (val && !this._sendEmail) {
  //     this.showNoItems = false;
  //     this.router.navigate(['buyer/thank-you/', this.cartService.order.docNumber]);
  //   }
  // }
  // get sendEmail() {
  //   return this._sendEmail;
  // }

  set purchaseCompleted(val) {
    if (val) {
      this.cartService.isPurchaseInProgress = false;
    }
    this._purchaseCompleted = val;
  }

  get purchaseCompleted() {
    return this._purchaseCompleted;
  }

  ngOnInit() {
    this.sharedService.isCorpSite = false;
    this.topService.trendingNavVisible = true;
    this.sharedService.isLogoutPage = false;
    this.cartService.isPurchaseInProgress = false;
    this.bnkInfo = this.userService.orderBankInfo;
    window.scrollTo(0, 0);
    this.errorCollection = new Map();
    if (this.cartService.cartList && !this.cartService.cartList.length) {
      this.showNoItems = true;
      this.setPriceHelper();
    }
    this.pubSubService.sharedSubject.subscribe(
      mySharedValues => {
        if (mySharedValues.name === SharedSource.changeCustomer) {
          if (this.router.url !== '/user/orders') {
            this.router.navigate(['buyer/home']);
          }
        }
        if (mySharedValues.name === SharedSource.cartFetch) {
          this.setPriceHelper();
        }
      },
      error => { }
    );

    this.isMobile = ((window.innerWidth) > 991) ? false : true;

    this.topService.showSearch = true;
    this.topService.isCatalog = false;
    this.ordersService.selected_order_number = +this.sessionService.selectedOrderNumber;
    this.ordersService.selected_doc_number = +this.sessionService.selectedDocNumber;

    if (this.userService.terms === 'terms') {
      this.terms = true;
      this.userService.terms = 'terms';
    } else {
      this.prepay = true;
      this.userService.terms = 'prepay';
    }

    this.updatingOrder = this.route
      .queryParams
      .subscribe(params => {
        this.updateStatus = params['updatingOrder'];
        if (this.updateStatus === 'Y') {
          this.ordersService.handleFirstOrderLoadRequired = true;
          this.isCart = false;
        }
      });

    this.user_role = this.sessionService.userRole;
    this.initializeAddresses();
    this.getItemsFromCart();
    this.getOrderDetails();

    if (this.cartService.erroronline === true) {
      this.cartService.erroronline = false;
    }
  }

  ngOnDestroy() {
    // this.sendEmail = false;
    // this.emailSent = false;
    this.topService.trendingNavVisible = false;
    if (this.updatingOrder) {
      this.updatingOrder.unsubscribe();
    }
    this.hideBox = false;
  }

  // sendEmailPDF() {
  //   // if (this.sendEmail) {
  //   // this.sendEmail = false;
  //   this.emailSent = true;
  //   this.getEmailSubjectLine();
  //   const htmlContent = document.getElementById('order_email').innerHTML;
  //   this.emailService.sendEmail('noreply@hubx.com', '', this.defaultEmailSubjectLine +
  //     this.ordersService.orderChosen.docNumber, htmlContent, this.cartService.sendMail || this.sendEmail)
  //     .subscribe(
  //       (orderData) => {
  //         this.topService.loading = false;
  //         this.completed = true;
  //         this.cartService.sendMail = false;
  //         this.router.navigate(['/buyer/thank-you', this.ordersService.orderChosen.docNumber]);
  //       },
  //       (err) => {
  //         this.topService.loading = false;
  //         this.completed = true;
  //         if (err.status === 409) {
  //           this.errorFromEmail = true;
  //           this.errorModal.isShown = true;
  //           this.errorModal.title = 'Server Error';
  //           this.errorModal.bodyText = 'Unknown server error.';
  //         } else if (err.status === 400) {
  //           this.errorFromEmail = true;
  //           this.errorModal.isShown = true;
  //           this.errorModal.title = 'Server Error';
  //           this.errorModal.bodyText = isString(err.error) ? err.error : err.error.message;
  //         } else {
  //           this.pageErrorService.errorCode = err.status;
  //           this.pageErrorService.errorMessage = err.error_description ? err.error_description : err.error.message;
  //           this.router.navigate(['page-error']);
  //         }
  //       }
  //     );
  //   /*collect info to send email*/
  //   // }
  // }

  onCancel(): void {
    this.cancelCart();
    this.cartService.deleteCart();
  }

  get getCartList(): models.ICartItem[] {
    return this.cartService.cartList;
  }

  getOrderDetails(): void {
    if (this.ordersService.selected_order_number) {
      this.getEditedOrdersCompleted = false;
      this.topService.loading = true;
      this.ordersService.getOrderDetails(this.ordersService.selected_order_number.toString())
        .subscribe(
          (orderData) => {
            this.selectedOrder = orderData;
            this.credit = this.selectedOrder.payment.id ? true : false;
            this.editedOrderDetails = orderData.orderDetails;
            this.editedOrderShippingCost = orderData.shippingCost;
            this.ordersService.selected_doc_number = orderData.docNumber;

            let assignAddresses = ()=>{
              if(this.userService.shipping){
                this.Saddress = Object.assign({}, this.userService.shipping.find(address => address.addressCode === orderData.shippingAddressCode));
                this.SavedAddressS = Object.assign({}, this.Saddress);
              }
              if (this.userService.billing){
                this.Baddress = Object.assign({}, this.userService.billing.find(address => address.addressCode === orderData.billingAddressCode));
                this.SavedAddressB = Object.assign({}, this.Baddress);
              }
              let addressNotReady = this.userService.shipping == null && this.userService.billing == null;
              return addressNotReady;
            }

            if(assignAddresses()){
              //In case of refresh cart page, address info for customer BP will return over the wire after order details
              //Max 10 intervals of one second to obtain customer BP info response. Will also clear after success.
              var interval = setInterval(() => {
                if (this.addressCheckCounter >= this.addressCheckMax || !assignAddresses()){
                  this.addressCheckCounter = 0;
                  clearInterval(interval);
                  return;
                }
                this.addressCheckCounter++;
              },1000);
            }

            if (this.ordersService.handleFirstOrderLoadRequired) {
              this.cartService.comment = orderData.comments;
              this.ordersService.handleFirstOrderLoadRequired = false;
            }

            this.getEditedOrdersCompleted = true;
            this.topService.loading = false;
          },
          (err) => {
            this.topService.loading = false;
            this.getEditedOrdersCompleted = true;
            if (err.status === 400) {
              this.cartService.erroronline = true;
            }

            this.sharedService.handleBuyerHttpError(err, this.errorModal);
          }
        );
    }
  }

  openOrderDetail(): void {
    this.openOrdersDetails = !this.openOrdersDetails;
  }

  prepareErrorMessages(error400: string): void {
    const newError = environment.errorMapping.filter(c => error400.startsWith(c.server));

    if (newError && newError.length > 0) {
      this.errorModal.bodyText = newError[0].client.message;
      this.errorModal.title = newError[0].client.title;
    } else {
      this.errorModal.bodyText = error400;
      this.errorModal.title = 'Quantity Error';
    }

    this.topService.loading = false;
  }

  showError(errorModal: models.IBuyerErrorModal): void {
    this.errorModal = errorModal;
  }

  hideError(): void {
    this.errorModal.isShown = false;
    if (this.errorFromEmail) {
      this.router.navigate(['users/orders']);
    }
  }

  closeOrderModal(): void {
    this.openOrdersDetails = false;
    this.showModal = !this.showModal;
    /* check whether order exists or not*/
    this.ordersService.getOrders(this.ordersService.defaultOrderRequest).subscribe(
      (data) => {
        if (data) {
          this.userOrdersList = data.values;
        }
        if (!this.showAddToExistingOrder()) {
          this.isCart = false;
        }
      },
      (err) => {
        // need to take care of error
      });
  }

  // for demo payment type
  ChoosePaymentType(paymentType: string): void {
    if (paymentType === 'terms' && this.userService.terms !== 'prepay') {
      this.terms = true;
      this.prepay = false;
      this.credit = false;
      this.cartService.terms = 'terms';
    } else if (paymentType === 'prepay') {
      this.terms = false;
      this.prepay = true;
      this.credit = false;
      this.cartService.terms = 'prepay';
    } else if (paymentType === 'credit') {
      this.terms = false;
      this.prepay = false;
      this.credit = true;
    } else if (!paymentType) {
      this.credit = false;
    }
  }

  /* Show change billing or shipping address pencil*/
  showAddToExistingOrder(): boolean {
    return !this.ordersService.selected_order_number && this.topService.hasOpenOrders && !this.addToExistingRejected && !this.completed
      && !this.sharedService.noOrders;
  }

  showTooltip(id): void {
    this.openMobileInfo = id;
  }

  getKeys(map): {}[] {
    return Array.from(map.values());
  }

  toggleConfirmModal(): void {
    this.confirmModal = !this.confirmModal;
  }

  onClickedOutside(e: Event): void {
    this.openMobileInfo = '';
  }

  clearAddressDefault(address: modelsU.IAddress[]): void {
    for (let i = 0; i < address.length; i++) {
      address[i].isDefault = false;
    }
  }

  initializeAddresses(): void {
    if(this.ordersService.selected_order_number && ['SALES', 'SUPER'].includes(this.user_role))
      return;

    let assignAddresses = ()=>{
      this.Baddress = Object.assign({}, this.userService.billingDefault);
      this.SavedAddressB = Object.assign({}, this.userService.billingDefault);
      this.Saddress = Object.assign({}, this.userService.shippingDefault);
      this.SavedAddressS = Object.assign({}, this.userService.shippingDefault);
      this.shipToAddress = this.Saddress.addressCode;
      this.billToAddress = this.Baddress.addressCode;
      let cartNotReady = this.userService.billingDefault == null && this.userService.shippingDefault == null;
      return cartNotReady;
    };

    if(assignAddresses()){
      //In case user click refresh on cart page and BP info is not available on first try during Init
      var interval = setInterval(() => {
        if (this.addressCheckCounter >= this.addressCheckMax || !assignAddresses()){
          this.addressCheckCounter = 0;
          clearInterval(interval);
          return;
        }
        this.addressCheckCounter++;
      },1000);
    }
  }

  rejectAddtoExisting(): void {
    if (this.checkForRestrictedItems()) {
      this.showRestrictedCountryPupup = true
    } else {
      this.addToExistingRejected = true;
      this.isCart = false;
    }
  }

  onShowModalToggle(): void {
    if(this.checkForRestrictedItems()) {
      this.showRestrictedCountryPupup = true;
    } else {
      this.loading = true;
      this.getOpenOrders();
    }
  }

  gotoCheckout(): void {
    if (this.cartService.cartList.length) {
      this.router.navigate(['cart']);
    }
  }

  openGradingGuide(cartItem: ICartItem): void {
    this.sharedService.gradingGuideHtmlPath =
      environment.imageBaseUrl + cartItem.item.gradingGuideUrl + 'index6.html';
    this.sharedService.isGradingGuideOpen = true;
  }

  totalUnits(): number {
    let quantity = 0;

    for (let i = 0; i < this.cartService.cartList.length; i++) {
      quantity = quantity + this.cartService.cartList[i].quantity;
    }

    return quantity;
  }

  alertConfirmBtn(): void {
    event.preventDefault();
    event.stopPropagation();
    this.sharedService.isGradingGuideOpen = false;
    if (!this.showDetailedPopUp && this.selectedCartItem) {
      this.selectedCartItem.item = { id: '' };
    }
  }

  totalAmount(): number {
    let total = 0;
    for (let i = 0; i < this.cartService.cartList.length; i++) {
      if (this.cartService.cartList[i].overwrittenPrice > 0) {
        this.newOverrittenPrice = this.cartService.cartList[i].overwrittenPrice;
        total = total + (this.cartService.cartList[i].quantity * this.cartService.cartList[i].overwrittenPrice);
      } else {
        total = total + (this.cartService.cartList[i].quantity * this.cartService.cartList[i].unitPrice);
      }
    }

    total = total - (total * (this.userService.discount / 100));

    if (this.credit) {
      this.processingFee = total * (this.cartService.paymentPercent / 100);
      total += this.processingFee;
    } else {
      this.processingFee = 0;
    }


    return total;
  }

  updateComment(comment: string): void {
    this.cartService.comment = comment;
  }

  onSelectedAddress(event: modelsU.IAddressEmitter): void {
    if (event.addressType === 'shipping') {
      this.Saddress = Object.assign({}, this.userService.shipping.find(address => address.addressCode === event.addressCode));
      this.SavedAddressS = Object.assign({}, this.Saddress);
      this.userService.shippingDefault = this.userService.shipping.find(address => address.addressCode === event.addressCode);
    }
    if (event.addressType === 'billing') {
      this.Baddress = Object.assign({}, this.userService.billing.find(address => address.addressCode === event.addressCode));
      this.SavedAddressB = Object.assign({}, this.Baddress);
      this.userService.billingDefault = this.userService.billing.find(address => address.addressCode === event.addressCode);
    }
  }

  prepareVisibleIcons(item: models.IItem): void {
    const visConditionAttribute = item.attributes.find(a => a.label === this.visualCondition);
    if (visConditionAttribute) {
      visConditionAttribute.hide = true;
    }
  }

  setCartItemValues(cartItem: models.ICartItem): void {
    this.selectedCartItem.item.added = true;
    this.selectedCartItem.item.qty = cartItem.quantity;
    this.selectedCartItem.item.previousQuantityAdded = cartItem.item.previousQuantityAdded;
    this.selectedCartItem.item.inCart = true;
    if (this.selectedCartItem.item.manufacturerLogoUrl !== '' && this.selectedCartItem.item.manufacturerLogoUrl != null) {
      this.selectedCartItem.item.manufacturerLogoUrl = environment.imageBaseUrl + this.selectedCartItem.item.manufacturerLogoUrl;
    } else {
      this.selectedCartItem.item.manufacturerLogoUrl = environment.imageBaseUrl + environment.imageDefaultLogoUrl;
    }
    this.prepareVisibleIcons(this.selectedCartItem.item);
  }

  hideDetailedPopUp(): void {
    this.showDetailedPopUp = false;
    this.sharedService.isMobileSortingPopup = false;
    // this.selectedCartItem.id =
  }

  getOpenOrders(): void {
    this.topService.loading = true;
    this.openOrders = [];
    this.ordersService.getOpenOrders('PageNumber=1&PageSize=100').subscribe(
      (data) => {
        if (data) {
          for (let i = 0; i < data.values.length; i++) {
            if (data.values[i].orderStatus !== 'Pending' && !data.values[i].payment.id) {
              this.openOrders.push(data.values[i]);
            }
          }
          if (this.openOrders.length) {
            this.selectedOpenOrder = this.openOrders[0];
            this.lastcomments = this.selectedOpenOrder.comments;
          }
        } else {
          this.openOrders = [];
        }
        this.loading = false;
        this.topService.loading = false;
        this.showModal = !this.showModal;
      },
      (err) => {
        this.topService.loading = false;
        if (err.status === 400) {
          this.cartService.erroronline = true;
        }

        this.sharedService.handleBuyerHttpError(err, this.errorModal);
      }
    );
  }

  deleteItem(item: models.ICartItem): void {
    this.deleteSpinner = true;
    this.deleteSpinnerItem = item.id;
    this.cartService.deleteFromCart(item.lineSequence).subscribe(
      (data) => {
        this.cartService.cartList = data;
        this.setPriceHelper();
        this.cartService.checkValid();
        this.removeInCartAttributeFromItem(item);
        this.topService.loading = false;
        this.deleteSpinner = false;
        this.deleteSpinnerItem = '';
        this.cartService.getCart();
        this.checkForTerritoryRestrictedItems(this.cartService.cartList, this.userService.defaultBillingCountry);
      },
      (err) => {
        this.topService.loading = false;
        this.deleteSpinner = false;
        this.deleteSpinnerItem = '';
        if (err.status === 400) {
          this.cartService.erroronline = true;
          this.cartService.resetCart();
          this.errorModal.title = 'Cart Is Not Valid';
          this.errorModal.bodyText = typeof err.error === 'string' ? err.error : err.error.message;
          this.sharedService.handleBuyerHttpError(err, this.errorModal, true);
        } else {
          this.sharedService.handleBuyerHttpError(err, this.errorModal);
        }
      }
    );
  }

  removeInCartAttributeFromItem(cartItem: models.ICartItem): void {
    if (this.itemsService.itemsData) {
      for (let i = 0; i < this.itemsService.itemsData.length; i++) {
        if (cartItem.item.id === this.itemsService.itemsData[i].id) {
          this.itemsService.itemsData[i].inCart = false;
          this.itemsService.itemsData[i].added = false;
          break;
        }
      }
    }
  }

  setPriceHelper(): void {
    this.cartService.cartList.forEach(cart => {
      if (cart.item.prices.length > 1) {
        if (cart.item.prices.filter(x => x.fromQty === 1).length > 1) {
          cart.item.prices.splice(cart.item.prices.find(x => x.fromQty === 1)[1], 1);
        }
      }
      if (cart.item.prices && cart.item.prices[0]) {
        cart.item.unitPrice = cart.item.prices[0].unitPrice;
        cart.item.qty = cart.item.prices[0].fromQty;
        cart.item.previousQuantityAdded = cart.item.qty;
      } else {
        cart.item.unitPrice = -1;
      }

      cart.unitPriceHelper = cart.unitPrice;

      if (cart.overwrittenPrice) {
        cart.unitPriceHelper = cart.overwrittenPrice;
      }
    });
  }

  findAllErrors(): boolean {
    return this.errorCollection.size > 0 || this.cartService.cartList.filter(item => item.statusCode !== 'Ok' && item.statusCode !== 'RestrictedCountry').length > 0;
  }

  checkForTerritoryRestrictedItems(items: models.ICartItem[], country: string) {
    // setTimeout(() => {
      this.cartService.checkForTerritoryRestrictedItems(items, country);
    // }, 0);
  }

  getItemsFromCart(): void {
    if(!this.sessionService.userCanBuy){
      this.isCartReady=true;
      return;
    }
    this.isCartReady = false;
    this.topService.loading = true;
    this.cartService.getcartItems().subscribe(
      (data) => {
        this.topService.loading = false;
        this.isCartReady = true;
        if (data) {
          this.cartService.cartList = data;
          this.setPriceHelper();
          this.checkForTerritoryRestrictedItems(this.cartService.cartList, this.userService.defaultBillingCountry);
        }
      },
      (err) => {
        this.isCartReady = true;
        if (err.status === 400) {
          this.cartService.erroronline = true;
        }

        this.sharedService.handleBuyerHttpError(err, this.errorModal);
      }
    );
  }

  gotoHistory(orderId: string): void {
    this.router.navigate(['/buyer/full-order', orderId]);
    this.topService.loading = false;
  }

  gotoCatalog(): void {
    this.completed = false;
    this.refresh();
    this.itemsService.isLandingPage = true;
    this.router.navigate(['/buyer/home']);
  }

  getInventory(cartItem): string {
    if (cartItem.statusCode !== 'Ok') {
      return 'Sold Out';
    } else {
      return cartItem.item.inventory.availableToSell;
    }
  }

  refresh(): void {
    this.getItemsFromCart();
  }

  calculateOrderTotalWithFreight(): number {
    return this.totalAmount() + this.editedOrderShippingCost;
  }

  updateFreight(newFreight: number): void {
    this.editedOrderShippingCost = newFreight;
  }

  pushUpdatedOrderToBackend(creditCardId = null): void {
    const updateOrder: ICartCheckout = {
      billingAddressCode: this.Baddress.addressCode,
      shippingAddressCode: this.Saddress.addressCode,
      orderId: this.ordersService.selected_order_number,
      shippingCost: this.editedOrderShippingCost,
      comments: this.cartService.comment,
      sendEmail: this.cartService.sendMail,
      creditCardInfo: {
        creditCardId: creditCardId,
        legalPaymentInfo: null
      }

    }
    this.cartService.pushUpdatedOrder(updateOrder).subscribe(
        (data) => {
          this.topService.loading = false;
          this.sharedService.editOrderMode = false;
          this.refresh();
          this.disable_place_order = false;
          if (this.itemsService.itemsData) {
            for (let i = 0; i < this.itemsService.itemsData.length; i++) {
              if (this.itemsService.itemsData[i].added === true) {
                this.itemsService.itemsData[i].added = false;
                this.itemsService.itemsData[i].inCart = false;
              }
            }
          }
          if (data.payment.status) {
            data.orderStatus = 'paid';
          }
          this.cartService.order = data;
          this.cartService.comment = '';
          this.completed = true;
          this.isProcessingPopup = false;
          this.updateItemsFlag(true);
          this.googleAnalyticsService.sendOrderDataToGoogleAnalytics(false);
          this.getChosen(data);
          this.sessionService.removeSessionItem('selected_order_number');
          this.sessionService.removeSessionItem('selected_doc_number');
          this.sessionService.selectedOrderNumber = '';
          this.sessionService.selectedDocNumber = '';
          this.router.navigate(['/buyer/thank-you', this.ordersService.orderChosen.docNumber]);
        },
        (err) => {
          this.loading = false;
          this.purchaseCompleted = true;
          this.topService.loading = false;
          this.isProcessingPopup = false;
          if (err.status === 400) {
            this.cartService.erroronline = true;
          } else {
            this.getItemsFromCart();
          }

          this.sharedService.handleBuyerHttpError(err, this.errorModal);
        }
      );
  }

  isNumber(event): boolean {
    return event.keyCode >= 48 && event.keyCode < 57;
  }

  onHandlePriceUpdateEmitter(event: any): void {
    this.handlePriceUpdate(event.cartItem, event.newPrice);
  }

  handlePriceUpdate(cartItem: models.ICartItem, newPrice: number): void {
    cartItem.statusCode = 'Ok';
    cartItem.statusMessage = '';
    if (newPrice && newPrice !== cartItem.unitPrice && newPrice > 0) {
      cartItem.overwrittenPrice = newPrice;
      cartItem.unitPriceHelper = newPrice;
    } else {
      cartItem.overwrittenPrice = null;
      cartItem.unitPriceHelper = cartItem.unitPrice;
    }
    if (this.cartService.validateLine(cartItem)) {
      this.postCartItem(cartItem);
    }
  }

  setErrorMessage(cartItem: models.ICartItem): void {
    this.errorMessage = ` ${cartItem.statusMessage} `;
  }

  updateCartQtyFromPopup(item: models.IItem) {
    this.selectedCartItem.quantity = item.qty;
    this.handleQuantityUpdate(this.selectedCartItem);
  }

  handleQuantityUpdate(cartItem: models.ICartItem): void {
    cartItem.unitPrice = this.cartService.handleQuantityUpdate(cartItem);

    if (cartItem.overwrittenPrice && cartItem.overwrittenPrice === cartItem.unitPrice) {
      cartItem.overwrittenPrice = null;
    }

    cartItem.unitPriceHelper = cartItem.overwrittenPrice ? cartItem.overwrittenPrice : cartItem.unitPrice;
    cartItem.statusMessage = '';
    cartItem.statusCode = 'Ok';
    if (this.cartService.validateLine(cartItem)) {
      this.postCartItem(cartItem);
    }
  }

  validateCartItem(cartItem: models.ICartItem): void {
    cartItem.statusMessage = '';
    cartItem.statusCode = 'Ok';
    const valid = this.cartService.validateLine(cartItem);
    if (!valid) {
      this.setErrorMessage(cartItem);
    } else {
      this.errorCollection.delete(cartItem.id);
      cartItem.statusMessage = '';
      cartItem.statusCode = 'Ok';
    }
  }

  calculatePrice(qty: number, unitPrice: number, qtyDiscount: models.IPrice[]): number {
    return this.cartService.calculatePrice(qty, unitPrice, qtyDiscount);
  }

  postCartItem(cartItem: models.ICartItem): void {
    this.cartService.updateCart(cartItem.lineSequence, cartItem.quantity, cartItem.overwrittenPrice)
      .subscribe(
        (data) => {
          cartItem.statusCode = 'Ok';
          this.updateItemFromCart(cartItem, data);
          this.totalAmount();
          this.updateCartItemQty(cartItem, cartItem.quantity);
          this.updateItemsFlag(true);
          this.cartService.getCart();
          this.topService.loading = false;
          //   this.cartService.validateLine(cartItem);
        },
        (err) => {
          this.topService.loading = false;
          if (err.status === 400) {
            cartItem.statusCode = 'Failed';
            this.cartService.erroronline = true;
            this.cartService.getItemInventory(cartItem.item.id).subscribe(inv => {
              this.cartService.updateCartAvailQty(cartItem.lineSequence, inv.availableToSell);
            });
            let message = typeof err.error === 'string' ? err.error : err.error.message;
            if (message.includes("Need to add")) {
              this.cartService.resetCart();
            }
            this.errorModal.title = 'Cart Is Not Valid';
            this.errorModal.bodyText = message;
            !this.checkForRestrictedItems() ? this.sharedService.handleBuyerHttpError(err, this.errorModal, true) : '';
            // this.sharedService.handleBuyerHttpError(err, this.errorModal, true)
          } else {
            this.getItemsFromCart();
            this.sharedService.handleBuyerHttpError(err, this.errorModal);
          }
          this.cartService.getCart();
        });
  }

  updateItemFromCart(cartItem: models.ICartItem, cartData: models.ICartItem[]): void {
    const cartItemData = cartData.find(ci => ci.lineSequence === cartItem.lineSequence);

    if (!cartItemData) {
      return;
    }
    cartItem.quantity = cartItemData.quantity;
    cartItem.unitPrice = cartItemData.unitPrice;
    cartItem.item.qty = cartItemData.item.qty;
    cartItem.overwrittenPrice = cartItemData.overwrittenPrice === cartItemData.unitPrice ? null : cartItemData.overwrittenPrice;
  }

  updateCartItemQty(cartItem: models.ICartItem, newQty: number): void {
    for (let i = 0; i < this.cartService.cartList.length; i++) {
      if (cartItem.id === this.cartService.cartList[i].id) {
        this.cartService.cartList[i].quantity = Number(newQty);
        this.cartService.cartList[i].item.qty = Number(newQty);
      }
    }
  }

  totalAmountWithExistingOrders(orders: models.IOrderLine[]): number {
    let total = 0;
    for (let i = 0; i < orders.length; i++) {
      if (orders[i].overwrittenPrice > 0) {
        total = total + (orders[i].quantity * orders[i].overwrittenPrice);
      } else {
        total = total + (orders[i].quantity * orders[i].unitPrice);
      }
    }

    total = total - (total * (this.userService.discount / 100));
    return total;
  }

  getChosen(order: IOrderResponse): void {
    this.ordersService.orderChosen = order;
    if (this.ordersService.orderChosen.docNumber) {
      if (this.ordersService.orderChosen.shippingAddress) {
        this.shipping_address = this.ordersService.orderChosen.shippingAddress.split('\r\r');
      }
      if (this.ordersService.orderChosen.billingAddress) {
        this.billing_address = this.ordersService.orderChosen.billingAddress.split('\r\r');
      }
      // tslint:disable-next-line:max-line-length
      this.ordersService.orderChosen.comments = this.ordersService.orderChosen.comments ? this.ordersService.orderChosen.comments.replace(/[\r\n]+|[\r\n]\s+\S*/g, ' | ') : '';

      this.emailSubtotal = this.totalAmountWithExistingOrders(order.orderDetails);
      this.editedOrderShippingCost = order.shippingCost;
      this.totalOrderWithFreight = this.emailSubtotal + order.shippingCost + this.processingFee;

      // this.sendEmail = true;
      this.purchaseCompleted = true;
    }
  }

  // getEmailSubjectLine(): void {
  //   if (this.ordersService.selected_order_number || this.itemsUpdated) {
  //     this.defaultEmailSubjectLine = this.defaultEmailSubjectLine + '| Updated Order ';
  //   }
  // }

  checkForRestrictedItems(): boolean {
    const checkForRestricted = this.cartService.cartList.filter(item => item.item.restrictedCountries.includes(this.userService.defaultBillingCountry)).length > 0;
    return checkForRestricted;
  }

  initPlaceNew(): void {
    if (this.credit && this.creditCardService.selectedCreditCard.id) {
      this.showTerms = true;
      return;
    }

    // const checkForRestricted = this.cartService.cartList.filter(item => this.cartService.restrictedItemIds.includes(item.id)).length > 0;
    // const checkForRestricted = this.cartService.cartList.filter(item => item.statusCode === 'RestrictedCountry').length > 0;

    this.checkForRestrictedItems() ? this.showRestrictedCountryPupup = true : this.placeNew();
  }

  hideAgreement() {
    this.showTerms = false;
  }

  sendAgreement(agreement: any) {
    this.hideAgreement();
    if (!agreement) {
      return;
    }

    if (this.checkForRestrictedItems()) {
      this.showRestrictedCountryPupup = true
      this.topService.loading = false;
    } else {
      agreement.creditCardId = this.creditCardService.selectedCreditCard.id;
      this.topService.loading = true;
      this.cartService.agreementInfo = agreement;
      this.placeNew();
    }
  }

  placeNew(): void {
    this.cartService.isPurchaseInProgress = true;
    this.purchaseCompleted = false;
    if (this.errorCollection.has('-1')) {
      this.errorCollection.delete('-1');
    }

    this.loading = true;
    this.isProcessingPopup = true;
    this.topService.loading = true;
    this.confirmModal = false;
    this.addToExistingRejected = true;
    this.topService.searchString = null;
    if (this.ordersService.selected_doc_number) {
      this.updateItemsFlag(true);
      const creditCardId = this.credit ? this.creditCardService.selectedCreditCard.creditCardId : null;
      this.pushUpdatedOrderToBackend(creditCardId);
    } else {
      this.cartService.sendMail = true;
      this.updateItemsFlag(false);
      this.disable_place_order = true;

      const checkoutObj = this.prepareToCheckout(0, this.Baddress.addressCode, this.Saddress.addressCode);
      this.cartService.placeOrder(checkoutObj).subscribe(
        (data) => {
          this.hideBox = true;
          this.topService.loading = false;
          this.refresh();
          this.sharedService.editOrderMode = false;
          this.loading = false;
          this.isProcessingPopup = false;
          this.disable_place_order = false;

          if (this.itemsService.itemsData) {
            for (let i = 0; i < this.itemsService.itemsData.length; i++) {
              if (this.itemsService.itemsData[i].added === true) {
                this.itemsService.itemsData[i].added = false;
                this.itemsService.itemsData[i].inCart = false;
              }
            }
          }
          this.cartService.order = data;
          this.cartService.comment = '';
          this.completed = true;

          // if its the first order we need to update the hasOpenOrders var
          if (!this.topService.hasOpenOrders && data && data.orderStatus === "open") {
            this.topService.hasOpenOrders = true;
          }

          this.googleAnalyticsService.sendOrderDataToGoogleAnalytics(true);

          // we need to refresh inventory so we call again the getItems (DZ)
          this.getChosen(data);
          this.itemsService.getItems();
          this.sessionService.isCartAbandonment = 'false';
          this.router.navigate(['/buyer/thank-you', this.ordersService.orderChosen.docNumber]);
        },
        (err) => {
          this.topService.loading = false;
          this.purchaseCompleted = true;
          this.loading = false;
          this.isProcessingPopup = false;
          if (err.error && err.error.payment) {
            this.cartService.isCreditCardDeclined = (err.error.payment.error.code === 'card_declined');
            return;
          }
          if (err.status === 400) {
            this.cartService.erroronline = true;
            // this.checkForTerritoryRestrictedItems(this.cartService.cartList, this.userService.shippingDefault.country);
            if (err.error.cartIsNotValid) {
              this.getItemsFromCart();
            }
          }
          this.sharedService.handleBuyerHttpError(err, this.errorModal);
        }
      );
    }
  }

  continueShopping(): void {
    this.got400 = !this.got400;
    this.gotoShopping();
  }

  placeExisting(order: number): void {
    const defaultBillingCountry = this.userService.defaultBillingCountry;
    const restrictedItems = this.cartService.cartList
                            .filter((item: any) => item.item.restrictedCountries
                            .includes(defaultBillingCountry))
                            .length > 0;

    if (!restrictedItems) {
      this.cartService.isPurchaseInProgress = true;
      this.cartService.sendMail = true;
      if (this.errorCollection.has('-1')) {
        this.errorCollection.delete('-1');
      }
      this.topService.loading = true;
      this.loading = true;
      this.isProcessingPopup = true;
      this.isProcessingAddToExisting = true;
      this.topService.searchString = null;

      const checkoutObj = this.prepareToCheckout(order, this.Baddress.addressCode, this.Saddress.addressCode, this.lastcomments,this.selectedOpenOrder.shippingCost, true);

      this.cartService.placeOrder(checkoutObj).subscribe(
        (data) => {
          this.topService.loading = false;
          this.loading = false;
          this.isProcessingPopup = false;
          this.isProcessingAddToExisting = false;
          this.showModal = !this.showModal;
          this.refresh();
          if (this.itemsService.itemsData) {
            for (let i = 0; i < this.itemsService.itemsData.length; i++) {
              if (this.itemsService.itemsData[i].added === true) {
                this.itemsService.itemsData[i].added = false;
                this.itemsService.itemsData[i].inCart = false;
              }
            }
          }
          this.cartService.order = data;
          // we need to refresh inventory so we call again the getItems
          this.itemsService.getItems();
          this.refresh();
          this.getChosen(data);
          this.cartService.comment = '';
          this.completed = true;
          this.updateItemsFlag(true);
          this.googleAnalyticsService.sendOrderDataToGoogleAnalytics(false);
          this.hideBox = true;
          this.router.navigate(['/buyer/thank-you', this.ordersService.orderChosen.docNumber]);
        },
        (err) => {
          this.topService.loading = false;
          this.purchaseCompleted = true;
          this.showModal = !this.showModal;
          this.loading = false;
          this.isProcessingAddToExisting = false;
          this.isProcessingPopup = false;
          if (err.status === 400) {
            this.cartService.erroronline = true;
            if (err.error.cartIsNotValid) {
              this.getItemsFromCart();
            }
          }

          this.sharedService.handleBuyerHttpError(err, this.errorModal);
        }
      );
    } else {
      this.closeOrderModal();
      this.showRestrictedCountryPupup = true;
    }

  }

  gotoShopping(): void {
    if (this.itemsService.selectedTopAttribute === 'Past Purchase') {
      this.router.navigate(['/buyer/past_purchases']);
    } else if (this.itemsService.selectedTopAttribute === 'Just Sold') {
      this.router.navigate(['/buyer/just_sold']);
    } else if (this.itemsService.selectedTopAttribute === 'Notification Center') {
      this.router.navigate(['/notifications']);
    } else if (this.itemsService.selectedTopAttribute === environment.currentDeal.displayLabel) {
      this.router.navigate([`/buyer/${environment.currentDeal.url}`]);
    } else if (this.itemsService.selectedTopAttribute === 'Price Drop') {
      this.router.navigate(['/buyer/price_drop']);
    } else if (this.itemsService.selectedTopAttribute === 'Just Launched') {
      this.router.navigate(['/buyer/just_launched']);
    } else {
      this.router.navigate(['/buyer/home']);
    }
  }

  onSelectOrder(event: models.IOrder): void {
    this.selectOrder(event);
  }

  selectOrder(order: models.IOrder): void {
    this.lastcomments = order.comments;
    this.selectedOpenOrder = order;
  }

  updateItemsFlag(updatedItem: boolean): void {
    this.itemsUpdated = updatedItem;
  }

  onCancelCart(): void {
    this.cancelCart();
    this.deleteCart();
  }

  cancelCart(): void {
    this.topService.searchString = null;
    this.cartService.erroronline = false;
    this.cartService.comment = '';
    this.errorCollection.clear();

    this.sessionService.removeSessionItem('selected_order_number');
    this.sessionService.removeSessionItem('selected_doc_number');
    this.cartService.cartList = [];
    this.sessionService.selectedOrderNumber = '';
    this.sessionService.selectedDocNumber = '';
    this.ordersService.selected_order_number = -1;
    this.ordersService.selected_doc_number = -1;
    this.topService.loading = true;
    this.cartService.deleteCart().subscribe(
      (response) => {
        this.sharedService.editOrderMode = false;
        this.refresh();
        this.cartService.comment = '';
        this.router.navigate(['/user/orders']);
      },
      (err) => {
        this.refresh();
        this.router.navigate(['/user/orders']);
      });
  }

  allowsEdition(item: models.ICartItem): boolean {
    return (item.isEditable || this.user_role === 'SUPER');
  }

  isQtyEditable(item: models.ICartItem): boolean {
    const res = item.orderLineNum === -1 || this.user_role === 'SUPER';
    return !res;
  }

  copyText(val: string): void {
    this.utilService.copyTextToClipboard(val);
    this.copied = 'Copied!';
  }

  copyState(): void {
    this.copied = 'Copy';
  }

  checkMoreThanOnHand(cartItem: models.ICartItem): boolean {
    return this.user_role === 'BUYER' && cartItem.item.inventory.onHand >= 300;
  }

  checkMoreThanEnRoute(cartItem: models.ICartItem): boolean {
    return this.user_role === 'BUYER' && cartItem.item.inventory.enRoute >= 300 && cartItem.item.inventory.onHand === 0;
  }

  calculateInventoryEnRoute(cartItem: models.ICartItem): string {
    if (this.user_role === 'BUYER') {
      if (cartItem.item.inventory.enRoute + cartItem.item.inventory.onHand > 300) {
        return (300 - cartItem.item.inventory.onHand).toString() + '+';
      } else {
        return (cartItem.item.inventory.enRoute).toString() + '';
      }
    } else {
      return (cartItem.item.inventory.enRoute).toString() + '';
    }
  }

  isCartEmpty(): boolean {
    return this.getCartList.length === 0;
  }

  deleteCart() {
    this.cartService.deleteCart();
  }

  isCompletedAndOrderSelected(): boolean {
    return !this.completed && this.ordersService.selected_order_number > 0;
  }

  isCartEmptyAndCompleted(): boolean {
    return this.getCartList.length === 0 || this.completed;
  }

  isErrorCollectionEmpty(): boolean {
    return this.errorCollection.size > 0;
  }

  isOutOfStockAndSelectedOrderNumber(): boolean {
    return !this.isOutOfStock && !this.ordersService.selected_order_number;
  }

  isSelectedOrderAndNotCart(): boolean {
    return this.ordersService.selected_order_number && !this.isCart;
  }

  openitemDetailedMode(cartItem: models.ICartItem): void {
    // this.selectedCartItem = JSON.parse(JSON.stringify(cartItem));
    this.selectedCartItem = cartItem;

    this.selectedCartItem.item.qty = cartItem.quantity;
    this.selectedCartItem.item.added = true;
    this.selectedCartItem.item.inCart = true;
    this.selectedCartItem.item.unitPrice = cartItem.unitPrice;
    this.prepareVisibleIcons(this.selectedCartItem.item);

    if (this.selectedCartItem.item.manufacturerLogoUrl !== '' && this.selectedCartItem.item.manufacturerLogoUrl != null) {
      if (!this.selectedCartItem.item.manufacturerLogoUrl.includes(environment.imageBaseUrl)) {
        this.selectedCartItem.item.manufacturerLogoUrl = environment.imageBaseUrl + this.selectedCartItem.item.manufacturerLogoUrl;
      }
    } else {
      if (!this.selectedCartItem.item.manufacturerLogoUrl.includes(environment.imageBaseUrl)) {
        this.selectedCartItem.item.manufacturerLogoUrl = environment.imageBaseUrl + environment.imageDefaultLogoUrl;
      }
    }

    this.showDetailedPopUp = true;
    this.sharedService.isMobileSortingPopup = true;
  }

  showNotification(tooltip): void {
    this.showNotificationTooltip = false;
    setTimeout(() => {
      this.notificationItem = tooltip.item;
      this.tooltipPosition = tooltip.event;
      this.showNotificationTooltip = true;
    }, 100);
  }

  hideNotificationTooltip(): void {
    this.showNotificationTooltip = false;
  }

  prepareToCheckout(docEntry: number, billingAddressCode: string, shippingAddressCode: string,
    orderComments: string = null, freight: number = null, isFromAddToExisting?: boolean): ICartCheckout {

        orderComments = orderComments && orderComments.length > 0
        ? orderComments + '|' + this.cartService.comment
        : this.cartService.comment;

      if (orderComments.length > this.cartService.orderCommentsLength) {
        orderComments = orderComments.substr(0, this.cartService.orderCommentsLength);
      }

      const checkoutObj: ICartCheckout = {
        billingAddressCode: billingAddressCode,
        shippingAddressCode: shippingAddressCode,
        comments: orderComments,
        sendEmail: this.cartService.sendMail,
        orderId: docEntry,
        shippingCost: freight
      }

      if (this.credit) {
        checkoutObj.creditCardInfo = {
          creditCardId: this.creditCardService.selectedCreditCard.id,
          legalPaymentInfo: {
            firstName: this.cartService.agreementInfo.firstName,
            lastName: this.cartService.agreementInfo.lastName,
            agreement: this.cartService.agreementInfo.agreement
          }
        }
      }

      return checkoutObj;

  }
}
