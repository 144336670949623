import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NotificationCenterHeaderComponent } from '../notification-center-header.component';
import { NotificationFiltersEnum } from '../../../../shared/interfaces/INotificationDetail';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-notification-center-header-mobile',
    templateUrl: './notification-center-header-mobile.component.html',
    styleUrls: ['./notification-center-header-mobile.component.scss'],
    standalone: true,
    imports: [NgIf]
})
export class NotificationCenterHeaderMobileComponent  extends NotificationCenterHeaderComponent  implements OnInit, OnDestroy {
  @Input() show: boolean;


  ngOnInit() {
  }

  hideFilter() {
    document.documentElement.classList.remove('is-modal');
    this.notificationSvcBuyer.showHeaderMobile = false;
  }

  ngOnDestroy() {
     document.documentElement.classList.remove('is-modal');
     this.notificationSvcBuyer.showHeaderMobile = false;
     this.notificationSvcBuyer.activeTab = NotificationFiltersEnum.all;
  }


}
