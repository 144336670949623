import { Component, OnInit, Input } from '@angular/core';
import { TopService } from '../../../shared/services/top.service';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-cart-processing-modal',
    templateUrl: './cart-processing-modal.component.html',
    styleUrls: ['./cart-processing-modal.component.scss'],
    standalone: true,
    imports: [NgIf]
})
export class CartProcessingModalComponent implements OnInit {
  @Input() isAddToExisting: boolean;

  constructor(
  	public topService: TopService
  	) { }

  ngOnInit(): void {
  }

}
