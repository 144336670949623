import * as models from './model';

export interface INav {
    navId: string;
    sequence: number;
    navName: string;
    iconUri?: string;
    count: number;
    filterId: string;
    url?: string;
    label?: string;
    redableTitle?: string;
    manufacturerId?: string;
    navChilds?: INav[];
}
