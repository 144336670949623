import { OfferService } from './offers/offer.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';

import { environment } from '../../environments/environment';
import { IProfile, UserProfile } from '../shared/interfaces/model';
import { AUTH_CONFIG } from '../auth/hubx-auth-variables';
import { DEFAULT_URL } from '../shared/static';

import * as models from '../shared/interfaces/model';
import { ICountry } from '../shared/interfaces/ICountry';
import { SessionService } from '../services/session.service';
import { AuthService } from '../auth/auth.service';
import { Router, UrlTree } from '@angular/router';
import { GuestService } from '../shared/services/guest.service';
import { TopService } from '../shared/services/top.service';
import { nonItemRoutesRgx, SharedService } from '../shared/shared.service';
import { PageErrorService } from '../page-error/page-error.service';
import { NotificationService } from '../shared/services/notification.service';
import { skip, map } from 'rxjs/operators';
import { VendorService } from '../vendor/vendor.service';
import { IMobileNotification } from '../shared/interfaces/IMobileNotification';
import { ConnectionManagerService } from '../shared/services/signalR/connection-manager.service';
import { eConnectionType } from '../shared/services/signalR/connection-type';
import { Location } from '@angular/common';
import { EmailService } from '../services/email.service';
import * as JWT from 'jwt-decode';
import { ILeadProfile } from '../shared/interfaces/ILeadProfile';
import { StaticService } from '../static/static.service';
import { AuctionSignalrService } from '../buyer/auctions/signalr/auction-signalr.service';
import { CreditCardService } from '../buyer/credit-cards/credit-card.service';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  businessPartnersProfile: IProfile;
  private _userProfile: UserProfile;
  isInIframe = false;
  restapiverb = 'users';
  private _currentCustomer;
  restapicustomer = 'users/customers';
  loggedInUserRestApiVerb = 'businessPartners';
  discount = 0;
  SalesDiscount = 0;
  companyName: string;
  bpCode: string;
  orderBankInfo = null;
  billing: models.IAddress[];
  shipping: models.IAddress[];
  prepay: string;
  billingDefault: models.IAddress;
  defaultBillingCountry: string;
  shippingDefault: models.IAddress;
  contactEmail: string;
  terms: string;
  canSeeAllCustomers = 0;
  user_role = '';
  foundBP = false;
  loading: boolean;
  loginFail = false;
  loginSuccess = false;
  isUserProfileReady = false;
  isVendor = false;
  isCreditCardAllowed = false;
  isCreditCardListEmpty = -1;
  showCreditCardBanner = true;
  loadingCC = 0;

  leadProfile: ILeadProfile = {};
  loginFirstPopup = false;
  loginSecondPopup = false;
  isLogginPopupOpenAnimation = false;
  isLogginPopupOpen = false;
  profileSubscription = new Subscription();

  constructor(
    private http: HttpClient,
    public sessionService: SessionService,
    public auth: AuthService,
    private router: Router,
    private guestService: GuestService,
    public topService: TopService,
    public sharedService: SharedService,
    private _location: Location,
    private pageErrorService: PageErrorService,
    private notificationService: NotificationService,
    private vendorService: VendorService,
    private connManagerService: ConnectionManagerService,
    private staticService: StaticService,
    private auctionSignalrService: AuctionSignalrService,
    private creditCardService: CreditCardService,
    private offerService: OfferService) {

    const callbackObj = {
      fn: this.initialize,
      scope: this
    };

    if(this.auth.verifyCookies(callbackObj)){
      this.sessionService.isLoggedIn = 'true';
      this.auth.hubxAuth.recordCookieLogin();
    }

    this.getLeadProfile();

    // for reload
    if (sessionStorage.getItem('user_role') === 'BUYER'
      && !this.contactEmail && !this.isUserProfileReady) {
      this.fetchUserInfo();
    } else {
      // super and sales
      this.isUserProfileReady = true;
    }
  }

  get isGuest() {
    return !sessionStorage.getItem('user_role') ||
      ['GUEST', 'null'].includes(sessionStorage.getItem('user_role'));
  }

  set userProfile(profile) {
    this._userProfile = profile;
  }

  get userProfile() {
    return this._userProfile;
  }

  set currentCustomer(cus) {
    this._currentCustomer = cus;
  }

  get currentCustomer() {
    // reload
    if (!this._currentCustomer && sessionStorage.getItem('isCustomerSelected') === 'YES') {
      return sessionStorage.getItem('selected_customer');
    } else {
      return this._currentCustomer;
    }
  }

  getUrlByRole(nullIfBuyer?: boolean) {
    return (this.isGuest) ? '/guest' : ( nullIfBuyer ? '' : '/buyer');
  }

  resetBilling(): void {
    this.billingDefault = {
      addressCode: '',
      isDefault: false,
      address1: '',
      address2: '',
      city: '',
      state: '',
      country: '',
      zip: ''
    };
  }

  resetShipping(): void {
    this.shippingDefault = {
      addressCode: '',
      isDefault: false,
      address1: '',
      address2: '',
      city: '',
      state: '',
      country: '',
      zip: ''
    };
  }

  getProfile(consumeAPI = false): Observable<models.IProfile> {
    if (this.businessPartnersProfile && !consumeAPI) {
      return new Observable((observer) => {
              return observer.next(this.businessPartnersProfile);
      });
    } else {
      const url = environment.adminBaseUrl + this.loggedInUserRestApiVerb;
      return this.http.get<models.IProfile>(url).pipe(
        map((res: models.IProfile) => {
          if (res.isCreditCardAllowed !== null || res.isCreditCardAllowed !== undefined) {
            this.isCreditCardAllowed = res.isCreditCardAllowed;
          }
          if (this.isCreditCardAllowed) {
            this.sessionService.closeCreditCardBanner = 'false';
          }
          this.businessPartnersProfile = res;
          this.defaultBillingCountry = this.businessPartnersProfile.billingAddresses.find(x => x.isDefault)?.country;
          return res;
        })
      );
    }
  }

  getLoggedInUserProfile(): Observable<models.UserProfile> {
    const url = environment.adminUrl + this.restapiverb;
    return this.http.get<models.UserProfile>(url);
  }

  // https://admin-dev.hubx.com/api/users/customers?showAll=true (for superuser to get all customers)
  getCustomers(role): Observable<models.ICustomers> {
    const url = environment.adminUrl + this.restapicustomer + ((this.canSeeAllCustomers) ? '?showAll=true' : '');
    return this.http.get<models.ICustomers>(url);
  }

  sendSelectedCustomerToAPI(code): Observable<null> {
    const url = environment.adminUrl + 'users/impersonate/' + code;
    return this.http.put<null>(url, '');
  }

  getToken(): string {
    return sessionStorage.getItem('access_token');
  }

  getEmptyProfile(): IProfile {
    return {
      companyName: '',
      bpCode: '',
      country: '',
      contact: null,
      beneficiaryBank: null,
      billingAddresses: [],
      shippingAddresses: [],
      discount: 0,
      hasOpenOrders: false,
      terms: '',
      isCreditCardAllowed: false,
    };
  }

  getRole(): string {
    return this.sessionService.userRole;
  }

  resetCustomer(): void {
    this.companyName = null;
    this.bpCode = null;
    this.billing = [];
    this.shipping = [];
    this.discount = 0;
    this.foundBP = false;
  }

  login(email: string, password: string): void {
    /**Storing cartId if exists */
    const isCALink = this.sessionService.isCartAbandonment ? this.sessionService.isCartAbandonment : false;

    this.sessionService.resetSessionVariables();
    localStorage.clear();

    /**Restoring cartId if exists */
    if (isCALink) {
      this.sessionService.isCartAbandonment = isCALink;
    }

    this.auth.hubxAuth.login({
      realm: AUTH_CONFIG.realm,
      username: email,
      password: password
    }, (err, authResult) => {
      if (err) {
        this.loginGuest(email, password);
      } else {
        if (authResult && authResult.accessToken) {
          this.loginSuccess = true;
          this.loginFail = false;
          this.loading = false;
          if (this.topService.isMobile) {
            this.sessionService.isLoggedIn = 'true';
            this.initialize(authResult, this, true);
          } else {
            setTimeout(() => {
              this.sessionService.isLoggedIn = 'true';
              this.initialize(authResult, this, true);
            }, 1000);
          }
        }
      }
    });
  }

  loginGuest(email: string, password: string): void {
    // this.auth.auth0.client.login({
    this.auth.hubxAuth.login({
      realm: environment.hubx_auth_guest_realm,
      username: email,
      password: password
    }, (err, authResult) => {
      if (err) {
        this.loading = false;
        this.loginSuccess = false;
        this.loginFail = true;
        this.sharedService.isLoggedInGuest = false;
      } else {
        setTimeout(() => {
          this.auth.setSession(authResult);
          this.sessionService.readyToShowSessionError = true;
          this.user_role = this.sessionService.userRole;

          this.handleNavigationAfterAuth();

          this.sharedService.isLoggedInGuest = true;
          this.sessionService.userEmail = email;
          this.sessionService.isLoggedIn = 'true';
          this.auth.completedLoad = true;
          if (!this.leadProfile.fullName) {
            this.getLeadProfile(email);
          }
          this.contactEmail = email;
        }, 1000);

        this.loginSuccess = true;
        this.loginFail = false;
        this.loading = false;
      }
    });
  }

  async checkCreditCardListEmpty() {
    this.loadingCC++;
    await this.creditCardService.checkCreditCardListEmpty()
    .subscribe(
      (length: number) => {
        this.isCreditCardListEmpty = length;
        this.loadingCC--;
      },
      (err) => {
        console.log('Error trying to get a credit card: ' + err);
        this.loadingCC--;
      });
  }

  fetchUserInfo(): void {
    this.billing = [];
    this.shipping = [];
    this.topService.loading = true;
    this.loadingCC++;

    this.profileSubscription.add(
      this.getProfile().subscribe(data => {
        this.sharedService.profileData = data;
        this.auth.completedLoad = true;
        if (this.sessionService.userRole === 'BUYER') {
          if (data.discount !== null) {
            this.discount = data.discount;
          } else {
            this.discount = 0;
          }
          this.topService.hasOpenOrders = data.hasOpenOrders;
          this.companyName = data.companyName;
          this.bpCode = data.bpCode;
          this.contactEmail = data.contact.email;
          this.billing = data.billingAddresses;
          this.shipping = data.shippingAddresses;
          this.billingDefault = data.billingAddresses.find(x => x.isDefault);
          this.defaultBillingCountry = data.billingAddresses.find(x => x.isDefault)?.country;
          this.shippingDefault = data.shippingAddresses.find(x => x.isDefault);
          this.orderBankInfo = data.beneficiaryBank;
          this.terms = data.terms;
          this.topService.loading = false;
          this.isUserProfileReady = true;
          this.sharedService.isLoggedInInternal = true;
        }
        if (this.sessionService.userRole === 'SELLER') {

            this.sessionService.companyName = data.companyName;
            this.companyName = data.companyName;
            this.sharedService.vendorLoggedIn = this.companyName;
            this.sharedService.isLoggedInInternal = true;
            this.bpCode = data.bpCode;
            this.foundBP = true;
        }
        this.loadingCC--;
      },
        err => {
          this.pageErrorService.errorMessage = err.statusText;
          this.pageErrorService.errorCode = err.status;
          this.sharedService.isLoggedInInternal = false;
          this.router.navigate(['page-error']);
          this.topService.loading = false;
          this.loadingCC--;
        }
      )
    )
  }

  getDefaultPayloadPhoneRequest() {
    const payload: IMobileNotification = {
      userId: sessionStorage.getItem('user_id'),
      phoneNumber: this.userProfile.phoneNumber,
      countryCode: this.userProfile.countryCode,
      countryId: this.userProfile.countryId,
      messageOptions: this.userProfile.messageOptions,
      isMobileEnabled: this.userProfile.isMobileEnabled,
      isVerified: this.userProfile.isVerified,
      verificationCode: ''
    };

    return payload;
  }


  addPhoneNumber(payload): Observable<IMobileNotification> {
    const url = environment.adminUrl + 'users/userPhone/';
    return this.http.post<IMobileNotification>(url, payload);
  }

  verifyPhoneNumber(payload): Observable<IMobileNotification> {
    const url = environment.adminUrl + 'users/userPhone/verification';
    return this.http.post<IMobileNotification>(url, payload);
  }

  getCountriesCodes(): Observable<ICountry[]> {
    const url = environment.adminUrl + 'users/countryCodes';
    return this.http.get<ICountry[]>(url);
  }

  logout(): void {
    // Cannot include due to circular reference
    // Must reset cart prior to calling this method.
    // --> this.cartService.resetCart();

    const isVendor = this.sharedService.isVendorPortal();
    if (isVendor) {
      this.auctionSignalrService.startConnection();
      this.vendorService.resetDashboardVariables();
    }
    this.userProfile = undefined;
    this.currentCustomer = undefined;
    this.isUserProfileReady = false;
    this.sessionService.selectedCustomer = undefined;
    sessionStorage.removeItem('selected_customer');

    this.resetCustomer();
    this.vendorService.vendors = [];
    this.sessionService.isLoggedIn = 'false';
    this.sessionService.readyToShowSessionError = false;
    this.sessionService.resetSessionVariables();
    this.auth.logout(isVendor);
    this.connManagerService.stopConnection(eConnectionType.Notification);
    this.connManagerService.stopConnection(eConnectionType.Offer);

    this.businessPartnersProfile = null;
    this.isCreditCardAllowed = false;
    this.sharedService.editOrderMode = false;
    this.offerService.resetService();
  }

  initialize(authResult, scope, afterLogin = false) {

    if (authResult && authResult.accessToken) {
      // for animation
      scope.loginSuccess = true;
      scope.loginFail = false;
      scope.loading = false;

      const access_token_decoded = JWT(authResult.accessToken);
      const email = JSON.parse(access_token_decoded['https://www.hubx.com/user_info'])['email'];

      scope.auth.setSession(authResult);
      scope.user_role = scope.sessionService.userRole;
      scope.sessionService.readyToShowSessionError = true;

      switch (scope.user_role) {
        case 'BUYER': {
          scope.checkCreditCardListEmpty();
          scope.fetchUserInfo();
          const userId = sessionStorage.getItem('user_id');
          scope.connManagerService.addConnection(eConnectionType.Notification, userId);
          scope.connManagerService.addConnection(eConnectionType.Offer);
          break;
        }
        case 'SALES': {
          const userId = sessionStorage.getItem('user_id');
          scope.connManagerService.addConnection(eConnectionType.Notification, userId);
          scope.connManagerService.addConnection(eConnectionType.Offer);
          scope.sessionService.userEmail = email;
          scope.contactEmail = email;
          scope.auth.completedLoad = true;
          scope.sharedService.isLoggedInInternal = true;
          scope.isUserProfileReady = true;
          break;
        }
        case 'SUPER': {
          const userId = sessionStorage.getItem('user_id');
          scope.connManagerService.addConnection(eConnectionType.Notification, userId);
          scope.connManagerService.addConnection(eConnectionType.Offer);
          scope.sessionService.userEmail = email;
          scope.contactEmail = email;
          scope.auth.completedLoad = true;
          scope.sharedService.isLoggedInInternal = true;
          scope.isUserProfileReady = true;
          break;
        }
        case 'SELLER': {
          scope.connManagerService.stopConnection(eConnectionType.JustSold);
          scope.auctionSignalrService.stopConnection();
          const partnerId = sessionStorage.getItem('partner_id');
          scope.connManagerService.addConnection(eConnectionType.Notification, partnerId);
          scope.fetchUserInfo();
          break;
        }
        case 'ADMIN':
        case 'VENDORMGR': {
          scope.connManagerService.stopConnection(eConnectionType.JustSold);
          scope.auctionSignalrService.stopConnection();
          scope.sessionService.companyName = email;
          scope.contactEmail = email;
          scope.foundBP = true;
          scope.topService.isDone = false;
          scope.auth.completedLoad = true;
          scope.sharedService.isLoggedInInternal = true;
          break;
        }
      }

      scope.handleNavigationAfterAuth(afterLogin);
    }
  }

  handleNavigationAfterAuth(fromLoginPage = false) {
    const currentUrl = window.location.pathname + window.location.search;
    const urlTree: UrlTree = this.router.parseUrl(currentUrl);
    const redirectToFromUrl = urlTree.queryParams;
    if (this.auth.redirectUrl && redirectToFromUrl?.redirectTo) {
      this.router.navigateByUrl(redirectToFromUrl.redirectTo);
      this.auth.redirectUrl = '';
      return;
    }

    let newUrl = '';
    switch(this.sessionService.userRole) {
      case 'BUYER':
      case 'SALES':
      case 'SUPER':
        if (window.location.pathname === '/') {
          newUrl = '/buyer/home';
        } else if (/\/outlets/.test(window.location.pathname)) {
            newUrl = window.location.pathname.replace(/\/guest/, '');
        } else {
          newUrl = window.location.pathname.replace(/guest/, 'buyer') +
            window.location.search + window.location.hash;
        }
        break;
      case 'GUEST':
        if ( window.location.pathname.match(/buyer/)) {
          newUrl = window.location.pathname.replace(/buyer/, 'guest') +
          window.location.search;
        }
        break;
      case 'ADMIN':
      case 'SELLER':
      case 'VENDORMGR':
        if (fromLoginPage && !this.auth.redirectUrl) {
          if (this.topService.isMobile) {
            this.router.navigate(['/vendor/user/offers']);
          } else {
            this.router.navigate(['/vendor/dashboard']);
          }
        }
    }

    if (newUrl) {
      window.history.replaceState( {}, '', newUrl);
      // if it is items route update the items with past purchase info if any
      if(!nonItemRoutesRgx.test(newUrl)) {
        this.sharedService.refreshItems = true;
      }
    }
  }

  guestPop(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isLogginPopupOpenAnimation = true;
    this.isLogginPopupOpen = true;

    if (this.leadProfile.isEmail || this.leadProfile.isCall || this.staticService.isSubmittedForm) {
      this.loginSecondPopup = true;
    } else {
      this.loginFirstPopup = true;
    }
  }

  getLeadProfile(email?: string): void {
    if (this.auth.isAuthenticated() && this.sessionService.userRole === 'GUEST' && !this.leadProfile.leadId) {
      if (!email) {
        const access_token_decoded = JWT(this.auth.getAccessToken());
        email = JSON.parse(access_token_decoded['https://www.hubx.com/user_info'])['email'];
      }
      this.guestService.getGuestEmailByEmail(email).subscribe(
        (data) => {
          this.leadProfile = data;
          this.sessionService.leadId = this.leadProfile.email;
        },
        (error) => {
          console.log('error on login: ' + error);
        }
      );
    }
  }

  resetSessionWrongPortal() {
    this.sessionService.resetSessionVariables();
    this.auth.clearSessionIfNotVendor();

    this.profileSubscription.unsubscribe();
    this.profileSubscription = new Subscription();
    this.auth.completedLoad = false;

    this.auth.completedLoad = false;
    this.loginFail = false;
    this.loginSuccess = false;
  }

}
