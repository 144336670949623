import { Component, OnInit, OnDestroy, Output, EventEmitter, HostListener, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../user/user.service';
import { SharedService } from '../../../shared/shared.service';
import { PubSubService } from '../../../core/pubsub.service';
import { SharedSource } from '../../../core/shared-source';
import { IRegistrationDataRequest } from '../../../shared/interfaces/IRegistrationDataRequest';
import { IRegistrationData } from '../../../shared/interfaces/IRegistrationData';
import { GuestRegistrationComponent } from '../guest-registration/guest-registration.component';
import { GuestRegistrationMobileComponent, UserDataEmitter } from '../guest-registration-mobile/guest-registration-mobile.component';
import { TopService } from '../../../shared/services/top.service';
import { AuthService } from '../../../auth/auth.service';
import { IBuyerErrorModal } from '../../../buyer/interfaces/model';
import { GuestService } from '../../../shared/services/guest.service';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { BonusOfferComponent } from '../bonus-offer/bonus-offer.component';
import { GuestLoginComponent } from '../guest-login/guest-login.component';


@Component({
    selector: 'app-guest-modal',
    templateUrl: './guest-modal.component.html',
    styleUrls: ['./guest-modal.component.scss'],
    standalone: true,
    imports: [GuestLoginComponent, GuestRegistrationComponent, BonusOfferComponent, NgClickOutsideDirective]
})
export class GuestModalComponent implements OnInit, OnDestroy {
  public showModalTrigger = false;
  public animateIn = false;
  public animateOut = false;
  public message = '';
  loading = false;
  registrationDataRequest: IRegistrationDataRequest;
  isScrollDisabled = false;
  passwordSubmited = false;

  @ViewChild(GuestRegistrationComponent, { static: true }) registrationComponent: GuestRegistrationComponent;
  @ViewChild(GuestRegistrationMobileComponent) registrationMobileComponent: GuestRegistrationMobileComponent;

  constructor(
    private router: Router,
    private userService: UserService,
    public topService: TopService,
    private sharedService: SharedService,
    private pubSubService: PubSubService,
    private guestService: GuestService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.topService.isMobileMode();
    if (this.topService.isMobile) {
      document.documentElement.classList.add('is-modal');
    }
    this.activatedRoute.queryParams.subscribe(params => {
      if (!this.passwordSubmited) {
        this.passwordSubmited = params['passmtd'] ? true : false;
      }
      if (this.passwordSubmited) {
        setTimeout(() => {
          this.hideInfo();
        }, 4000);
      }
    });
  }

  ngOnDestroy() {
    if (this.topService.isMobile) {
      document.documentElement.classList.remove('is-modal');
    }
  }

  goToLoginPage(): void {
    this.showModalTrigger = false;
    // this.userService.isGuest = false;
    this.router.navigate(['']);
  }

  onClickedOutside(e: Event): void {
    this.closeModal();
  }

  register(): void {
    // this.registerEmitter.emit(null);
  }

  closeModal(): void {
    this.animateOut = true;
    this.animateIn = false;
    setTimeout(() => {
      this.showModalTrigger = false;
      this.message = '';
      this.animateOut = false;
    }, 400);
  }

  registerUserInformation(userDataEmitter: UserDataEmitter): void {
    this.initRegistrationDataRequest(userDataEmitter.userData);
    this.loading = true;
    // console.log(this.registrationDataRequest);
    this.guestService.registerUser(this.registrationDataRequest).subscribe(
      (data) => {
          this.registrationComponent.closeModal();
          this.registrationComponent.loading = false;
          this.userService.leadProfile = data;
          this.guestService.isRegistered = true;
          this.userService.isLogginPopupOpen = true;
          this.userService.loginFirstPopup = true;

          // hard coded for now.
          this.userService.loginGuest(userDataEmitter.userData.email, userDataEmitter.userData.password);
      },
      (err) => {
        if (userDataEmitter.isMobile) {
          // alert('Registration Failed: ' + err.error);
        } else {
          if (!err.status) {
            err.status = 400;
            err.error = 'Failed to register.';
          }
          this.sharedService.handleBuyerHttpError(err, this.guestService.errorModal);
        }
        this.registrationFailed();
      });
  }

  registrationFailed(): void {
    this.loading = false;
    this.registrationComponent.registrationFailed = true;
    this.registrationComponent.loading = false;
  }

  initRegistrationDataRequest(userData: IRegistrationData): void {
    this.registrationDataRequest = {
      'fullName': userData.fullName,
      'password': userData.password,
      'email': userData.email,
      'phoneNumber': userData.phoneNumber,
      'companyName': userData.companyName,
      'companyWeb': userData.companyWeb,
      'country': userData.country,
      'companyRevenue': userData.companyRevenue,
      'interestedIn': userData.companyCategory,
      'companyType': userData.companyType
    };
  }

  disableScroll(isScrollDisabled) {
    this.isScrollDisabled = isScrollDisabled;
  }

  hideInfo() {
    this.passwordSubmited = false;
  }

  @HostListener('mousewheel', ['$event'])
  onWindowScroll($event) {

    if (this.topService.isMobile) {
      return;
    }

    if (!this.isScrollDisabled && $event.clientX && $event.deltaY) {
      if ($event.deltaY > 1) {
        this.sharedService.scrollOnOverlay = 1;
      } else if ($event.deltaY < 1) {
        this.sharedService.scrollOnOverlay = -1;
      }
    }
  }
}
