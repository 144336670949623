import { Component, OnInit, PipeTransform, Pipe, OnDestroy, Input } from '@angular/core';
import { SharedService } from '../../shared/shared.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SafeResourceUrl } from '@angular/platform-browser';
import { TopService } from '../../shared/services/top.service';
@Component({
    selector: 'app-static-html',
    templateUrl: './static-html.component.html',
    styleUrls: ['./static-html.component.scss'],
    standalone: true
})

export class StaticHtmlComponent implements OnInit, OnDestroy {
  myTemplate: any = '';
  routeParamsSubscription: Subscription;
  htmlFilePath = '';
  isCorpSite = false;
  url: SafeResourceUrl;
  constructor(
    private sharedService: SharedService,
    private router: Router,
    private sanitizer: DomSanitizer,
    public topService: TopService
    ) { }

  ngOnInit() {
    this.sharedService.isStaticHtml = true;
    this.sharedService.isCorpSite = true;
    if (this.topService.isMobile) {
      document.documentElement.classList.add('is-modal');
    }

  //  alert(this.router.url);
  if (this.router.url === '/products') {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl('/assets/corp-site/index.html#products');
  } else  if (this.router.url === '/sell') {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl('/assets/corp-site/index.html#sell');
   } else {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl('/assets/corp-site/index.html#home');
   }

    // this.sharedService.isStaticHtml = true;
    // this.isCorpSite = true;
    // this.htmlFilePath = 'assets/corp-site/index.html';
    // this.getHtmlDataFromServer(this.htmlFilePath);
  }

  ngOnDestroy(): void {
    this.sharedService.isStaticHtml = false;
    if (this.topService.isMobile) {
      document.documentElement.classList.remove('is-modal');
    }
}

  getHtmlDataFromServer(htmlPath: string): void {
    this.sharedService.loadHtml(htmlPath).subscribe(
      data => {
        this.myTemplate = data;
      }
    );
  }
}
