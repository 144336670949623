import { Component, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';

import { TopService } from '../../services/top.service';
import { TopFiltersEnum } from '../../../buyer/enums/topFiltersEnum';
import { UserService } from '../../../user/user.service';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-doorbuster-deals-banner',
    templateUrl: './doorbuster-deals-banner.component.html',
    styleUrls: ['./doorbuster-deals-banner.component.scss'],
    standalone: true,
    imports: [RouterLink]
})
export class DoorbusterDealsBannerComponent implements OnInit {
  topFiltersEnum = TopFiltersEnum;
  environment = environment;

  constructor(
    public topService: TopService,
    public userService: UserService
    ) { }

  ngOnInit() {}
}
