import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'CustomCurrency',
    standalone: true
})

export class CustomCurrencyPipe implements PipeTransform {
  transform(value: number): string {
    value = ((value) ? value : 0);
    const tmp_value = value.toString();
    const Price = tmp_value.split('.');
    let Display_Price = '$';
    try {
      Display_Price = '$' + value.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,').replace(/\.?0+$/, '');
      if (Price.length === 2 && Price[1] !== '00') {
        Display_Price = '$' + value.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
      }
      return Display_Price;
    } catch (ex) {
      // console.log(ex);
      return Display_Price;
    }
  }
}
