import { Component, OnInit, Input } from '@angular/core';
import { IItem } from '../../buyer/interfaces/IItem';
import { TopService } from '../../shared/services/top.service';
import { ItemsService } from '../../shared/services/items.service';
import { PubSubService } from '../../core/pubsub.service';
import { SharedSource } from '../../core/shared-source';
import { Router } from '@angular/router';
import { UserService } from '../../user/user.service';
import { NgIf, CurrencyPipe, DatePipe } from '@angular/common';


@Component({
    selector: 'app-past-purchase-title',
    templateUrl: './past-purchase-title.component.html',
    styleUrls: ['./past-purchase-title.component.scss'],
    standalone: true,
    imports: [NgIf, CurrencyPipe, DatePipe]
})
export class PastPurchaseTitleComponent implements OnInit {
  @Input() item: IItem = {};
  showInfo = false;

  constructor(
    private _router: Router,
    private _userService: UserService,
    private itemsService: ItemsService,
    public topService: TopService,
    public pubSubService: PubSubService
    ) { }

  ngOnInit() {
  }
  triggerInfo(): void {
    this.showInfo = !this.showInfo;
  }
  serachInPastPurchase(query: string): void {
    if ( this._userService.isGuest ) { return; }

    this._router.navigate(['/buyer/past_purchases']);
    this.topService.pastPurchasesSearchString = query;
    this.pubSubService.sharedSubject.next({ name: SharedSource.pastPurchaseSearch, data: true });

    // this will turn off the highlight of just sold in top categories to default since it is not chosen
    this.itemsService.notificationService.highlight = false;
  }

}
