import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../shared/shared.service';
import { Router } from '@angular/router';
import { IUserInfo } from '../interfaces/IUserInfo';
import { NgForm, FormGroup, FormControl, Validators, FormsModule } from '@angular/forms';
import { StaticService } from '../static.service';
import { TopService } from '../../shared/services/top.service';
import { environment } from '../../../environments/environment';
import { IBuyerErrorModal } from '../../buyer/interfaces/model';
import { ErrorModalComponent } from '../../shared/ui-components/error-modal/error-modal.component';
import { FooterComponent } from '../footer/footer.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { NgClass, NgIf } from '@angular/common';
@Component({
    selector: 'app-contact-support',
    templateUrl: './contact-support.component.html',
    styleUrls: [
        '../../../assets/corp-site/css/style.min.v2.css',
        './contact-support.component.scss'
    ],
    standalone: true,
    imports: [FormsModule, NgClass, NgIf, RecaptchaModule, FooterComponent, ErrorModalComponent]
})
export class ContactSupportComponent implements OnInit {
  userInfo: IUserInfo = {};
  invalidFeedback = '';
  isCaptcha: any;
  firstName = '';
  lastName = '';
  isResolved = false;
  form: any;
  recaptchaKey = '';
  loading = false;
  errorModal: IBuyerErrorModal = {
    bodyText: '',
    title: '',
    isShown: false
  };

  constructor(
    private sharedService: SharedService,
    private router: Router,
    private staticService: StaticService,
    public topService: TopService
  ) { }

  ngOnInit() {
    document.documentElement.scrollTop = 0;
    this.sharedService.isStaticHtml = true;
    this.sharedService.isCorpSite = true;
    this.recaptchaKey = environment.recaptchaKey;
  }

  navigateTo(url: string): void {
    this.router.navigate([url]);
  }

  onSubmit(form: NgForm, step, $event): void {
    // event.preventDefault();
    // event.stopPropagation();
    this.userInfo.sourceInfo = 'service';
    this.userInfo.fullName = this.firstName + ' ' + this.lastName;
    this.loading = true;

    this.staticService.registerUser(this.userInfo).subscribe(
      (data) => {
        this.navigateTo('thank-you');
        this.loading = false;
      },
      (err) => {
        this.errorModal.bodyText = 'Unable to register customer.';
        this.errorModal.title = 'Server Error';
        this.errorModal.isShown = true;
        this.loading = false;
      }
    );
  }

  resolved(event: string): void {
    this.isResolved = true;
  }


  hideError(): void {
    this.errorModal.isShown = false;
  }

  showError(errorModal: IBuyerErrorModal): void {
    this.errorModal = errorModal;
  }
}
