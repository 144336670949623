import { IOffersSummary } from './../../buyer/interfaces/IOffersSummary';
import { OfferService } from './../../user/offers/offer.service';
import { Component, OnInit, OnDestroy, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Router, RouterLinkActive, RouterLink } from '@angular/router';
import { PubSubService } from '../../core/pubsub.service';
import { SharedSource } from '../../core/shared-source';
import { Subscription } from 'rxjs';
import { NotificationCenterVendorService } from '../notifications/notification-center-vendor/notification-center-vendor.service';
import { ICountersResponse } from '../notifications/notification-center-vendor/notification-header/notification-header.component';
import { SharedService } from '../../shared/shared.service';
import { VendorService } from '../vendor.service';
import { SessionService } from '../../services/session.service';
import { Location, NgIf } from '@angular/common';
import {TopService} from '../../shared/services/top.service';
import { ConfirmationPopupComponent } from '../../shared/confirmation-popup/confirmation-popup.component';
import { FormsModule } from '@angular/forms';
import { DropdownComponent } from '../../shared/dropdown/dropdown.component';

@Component({
    selector: 'app-top-vendor',
    templateUrl: './top-vendor.component.html',
    styleUrls: ['./top-vendor.component.scss'],
    standalone: true,
    imports: [NgIf, DropdownComponent, FormsModule, RouterLinkActive, RouterLink, ConfirmationPopupComponent]
})
export class TopVendorComponent implements OnInit, OnDestroy {
  @Output() impersonationListEmpty = new EventEmitter<null>();
  companyName: string;
  notificationCount = 0;
  isDropdownOpen = false;
  customers = [];
  selectedCustomer: string;
  vendorId: string;
  impersonationReady = false;
  isInPendingPo = false;
  selectedBpCode = null;
  browserRefresh = false;

  constructor(
    private router: Router,
    private pubSubService: PubSubService,
    private _notificationCenterSvc: NotificationCenterVendorService,
    private _sharedSvc: SharedService,
    public vendorService: VendorService,
    public sessionService: SessionService,
    public offerService:OfferService,
    private cdr: ChangeDetectorRef,
    private location: Location,
    public topService: TopService,
  ) { this.detectBrowserRefresh(); }

  detectBrowserRefresh() {
    this.vendorService.onBrowserRefreshEvent()
      .subscribe(event => {
        if (event.id === 1 && event.url === event.urlAfterRedirects) {
          this.browserRefresh = true;
          this.vendorService.isImpersonationReady = sessionStorage.getItem('impersonation_ready') == 'true' ? true : false;
        }
      })
  }

  ngOnInit() {
    this.customers.length > 0 ? this.customers = [] : '';
    this.companyName = this.vendorService.isAdmin || this.vendorService.isVendorManager ?
      sessionStorage.getItem('user_email') : sessionStorage.getItem('company_name');
    this.setUpPubSub();

    if (!this.vendorService.isVendorManager || this.vendorService.isImpersonationReady) {
      this.getNotificationCountHeader();
    }

    this.vendorService.isAdmin || this.vendorService.isVendorManager ? this.getAllCustomers() : '';
  }

  storeSelected(bpCode: string, companyName: string) {
    sessionStorage.setItem('selected_customer', bpCode);
    this.sessionService.companyName = companyName;
  }

  getAllCustomers(notifyCustomerVendorChange = true) {
    this.vendorService.getAllCustomers().subscribe((data: any) => {
      if(!data || !data.customers || data.customers.length === 0){
        this.logOutVendorManager();
        return;
      }
      for (let i = 0; i < data.customers.length; i++) {
        this.customers.push(data.customers[i]);
      }

      const currentSelectedCustomer = sessionStorage.getItem('selected_customer');
      const defaultSelected = (['',null,undefined].includes(currentSelectedCustomer))
        ? this.customers.find(x => x.isDefault)
        : this.customers.find(x => x.bpCode ===  currentSelectedCustomer);

      if (defaultSelected && (this.vendorService.isVendorManager || this.vendorService.isAdmin)) {
        this.chooseVendor(defaultSelected, notifyCustomerVendorChange);
      }
      else{
        this.browserRefresh = false;
      }

      if (defaultSelected.isDefault && sessionStorage.getItem('selected_customer') === null) {
        this.storeSelected(defaultSelected.bpCode, defaultSelected.companyName);
        this.selectedBpCode = defaultSelected.bpCode;
      } else {
        if (!this.isInPendingPo) {
          this.selectedBpCode = sessionStorage.getItem('selected_customer');
        }
      }

      this.customers.sort((x, y) => {
        return x.companyName.localeCompare(y.companyName);
      });
      this.vendorService.vendorManagerVendors = this.customers;

      const allOption = this.customers.find(x => x.bpCode === 'All');
      if (allOption) {
        this.customers = this.customers.filter((c) => c.bpCode !== 'All');
        this.customers.unshift({ bpCode: 'All', companyName: 'All' });
      }

      this.selectedCustomer = sessionStorage.getItem('selected_customer');
      this.getVendorId(this.selectedCustomer);
      this.pubSubService.sharedSubject.next({ name: SharedSource.partnerIdReady, data: true });
    });
  }

  chooseVendor(item, notifyCustomerVendorChange) {
    if (this.browserRefresh) {
      this.browserRefresh = false;
      return;
    }
    if (this.vendorService.isPendingPo && item.bpCode === 'All') {
      sessionStorage.setItem('pending_po_all', item.bpCode);
      this.vendorService.isPendingPoAll = true;
      this.pubSubService.sharedSubject.next({ name: SharedSource.changeCustomerVendor, data: item.bpCode });
      return;
    }

    this.getVendorId(item.bpCode);
    this.vendorService.isPendingPoAll = false;
    sessionStorage.removeItem('pending_po_all');

    this.vendorService.impersonate(item.bpCode).subscribe(() => {
      this.offerService.selectedOffer = this.offerService.getEmptyOfferState();
      this.offerService.getOffersSummary().subscribe(
        (offerSummary: IOffersSummary)=>{
          this.offerService.offersInitialized=false;
          if (this.router.url.match(/offers/)) {
            this.location.replaceState('vendor/user/offers');
          }
          this.pubSubService.sharedSubject.next({ name: SharedSource.sessionSwitchedAndSummaryReady, data: true });
        },
        (err) => {
          console.log('Error trying to get offers summary: ', err);
        }
      )
      this.offerService.getBpUnseenOffersCounterNoStream();
      const compName = this.customers.find(vendor => vendor.bpCode === item.bpCode).companyName;
      if (notifyCustomerVendorChange) {
        this.pubSubService.sharedSubject.next(
          { name: SharedSource.changeCustomerVendor, data: { bpCode: item.bpCode, companyName: compName } });
      }

      // Store bpCode to local storage so we know which customer is selected after refresh
      this.storeSelected(item.bpCode, compName);
    });
  }

  getVendorId(selectedVendor) {
    const partnerId = this.customers.find(vendor => vendor.bpCode === selectedVendor).vendorId;
    sessionStorage.setItem('partner_id', partnerId);
    this.vendorService.vendorId = partnerId;
    this.pubSubService.sharedSubject.next({ name: SharedSource.vendorId, data: true });
  }

  getNotificationCountHeader() {
    this._notificationCenterSvc.getTotalCounts()
      .subscribe((counts: ICountersResponse) => {

        if (counts && Object.keys(counts).length) {
          this.notificationCount = counts.moreInventoryNewTotal + counts.priceDropNewTotal +
            counts.waitListNewTotal;
        } else {
          this.notificationCount = 0;
        }
        this._sharedSvc['vendorNotificationNew'] = this.notificationCount;
      });
  }

  ngOnDestroy() {
    this.vendorService.unsubscribePubSub();
  }

  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  setUpPubSub(): void {
    this.vendorService.pubSubSubscription.add(
      this.pubSubService.sharedSubject
        .subscribe(data => {
          switch (data.name) {
            case SharedSource.vendorLoggedIn:
              this.companyName = data.data;
              break;
            case SharedSource.vendorNotificationCount:
              this.notificationCount = data.data;
              break;
            case SharedSource.priceDropFromBuyer:
            case SharedSource.waitlistFromBuyer:
            case SharedSource.moreInventoryFromBuyer:
            case SharedSource.changeCustomerVendor:
              if (this.router.url != '/vendor/notifications') {
                this.getNotificationCountHeader();
              }
              break;
            case SharedSource.isPendingPo:
              if (data.data) {
                this.isInPendingPo = true;
                this.customers.unshift({ bpCode: 'All', companyName: 'All' });
                this.selectedBpCode = 'All';
              } else {
                this.isInPendingPo = false;
                this.customers.length > 0 ? this.customers = [] : '';
                this.vendorService.isAdmin || this.vendorService.isVendorManager ? this.getAllCustomers(false) : '';
              }
              break;
            case SharedSource.offerSummary:
            case SharedSource.bpUnseenEventCountUpdated:
              this.cdr.detectChanges();
              break;
          }
        }));
  }

  clickLogoToReload(): void {
    if (this.topService.isMobile) {
      this.router.navigate(['vendor/user/offers']);
    } else {
      this.router.navigate(['vendor/dashboard']);
    }
  }

  logOutVendorManager(): void {
    this.impersonationListEmpty.emit();
  }
}
